import { useFlags } from 'launchDarkly/FlagsProvider';
import { useEffect } from 'react';
import useQueryFeatureViews from 'ts-frontend/hooks/featureViews/useQueryFeatureViews';
import useMutationFeatureViews from 'ts-frontend/hooks/featureViews/useMutationFeatureViews';
import { useOpenModal } from '@/utils/ModalsContextProvider';
import useNavShellData from '@/hooks/useNavShellData';
import {
  LOCAL_STORAGE_UPDATE_COVERAGE_COMPLETE,
  SESSION_STORAGE_IGNORE_EAP_TO_BH_MODAL,
} from '@/community/constants';

const CheckCoverageVerifier = ({ userID }) => {
  const openModal = useOpenModal();
  const { eapToBhActivationFf } = useFlags();
  const { isLoading: featureViewsLoading, data: featureViewsData } = useQueryFeatureViews(userID);
  const { mutate: markFeatureViewed } = useMutationFeatureViews();

  const { dataByRoomID } = useNavShellData();

  useEffect(() => {
    const didUpdateCoverageCompleted = localStorage.getItem(LOCAL_STORAGE_UPDATE_COVERAGE_COMPLETE);

    const eapRoomWithNoCredits = Object.keys(dataByRoomID || {})
      .map((roomID: string | number) => dataByRoomID?.[roomID])
      .find((room) => room?.subscription?.subscription?.noEapSessionsLeft);

    const isFFOn = !!eapToBhActivationFf?.variant;
    const featureNotMarked =
      !featureViewsLoading && !!featureViewsData && !featureViewsData.noEapSessionsLeft;
    const ignoreEapToBHModal = sessionStorage.getItem(SESSION_STORAGE_IGNORE_EAP_TO_BH_MODAL);
    const shouldShowModal =
      !ignoreEapToBHModal && isFFOn && featureNotMarked && !!eapRoomWithNoCredits;

    if (didUpdateCoverageCompleted) {
      localStorage.removeItem(LOCAL_STORAGE_UPDATE_COVERAGE_COMPLETE);
      if (eapRoomWithNoCredits && !featureNotMarked) {
        markFeatureViewed({ featureName: 'noEapSessionsLeft', userID });
      }

      const completedUpdateCoverageNotMarked =
        !featureViewsLoading && !!featureViewsData && !featureViewsData.eapUpdateCoverageCompleted;
      if (eapRoomWithNoCredits && !completedUpdateCoverageNotMarked) {
        markFeatureViewed({ featureName: 'eapUpdateCoverageCompleted', userID });
      }
      return;
    }

    if (shouldShowModal) {
      sessionStorage.setItem(SESSION_STORAGE_IGNORE_EAP_TO_BH_MODAL, 'true');
      openModal(`/check-coverage`);
    }
  }, [
    eapToBhActivationFf,
    featureViewsLoading,
    featureViewsData,
    dataByRoomID,
    openModal,
    markFeatureViewed,
    userID,
  ]);

  return null;
};

export default CheckCoverageVerifier;
