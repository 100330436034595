import { VoidFunctionComponent } from 'react';
import { View, Button, Large, Big, styled, Huge, BookingTimeZone } from '@talkspace/react-toolkit';

import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';

const Subtitle = styled(Huge)({
  textAlign: 'center',
  maxWidth: 280,
  marginTop: 30,
  marginBottom: 30,
});

interface Props {
  shouldShowConfirmAndJoin: boolean;
  timezone: string;
  handleConfirmAndJoinClick: () => void;
  handleJoinClick: () => void;
}

const ConfirmBookingJoin: VoidFunctionComponent<Props> = ({
  shouldShowConfirmAndJoin,
  timezone,
  handleConfirmAndJoinClick,
  handleJoinClick,
}) => {
  const { isLoading } = useInRoomSchedulingState();

  return (
    <>
      <View flex={1} align="center">
        <BookingTimeZone timezone={timezone} />
        <Subtitle>Your session is about to begin</Subtitle>
        <Large style={{ marginBottom: 20, maxWidth: 270 }}>
          Find a private and quiet place so that you can get the most out of your session.
        </Large>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onPress={shouldShowConfirmAndJoin ? handleConfirmAndJoinClick : handleJoinClick}
          style={{ marginTop: 43 }}
        >
          <Big variant="bigWide">Join session</Big>
        </Button>
        <Large variant="largeDarkGrey" style={{ marginTop: 23, textAlign: 'center' }}>
          {shouldShowConfirmAndJoin
            ? 'This session will use a live session credit included in your subscription.'
            : 'This session has used a live session credit included in your subscription.'}
        </Large>
      </View>
    </>
  );
};

export default ConfirmBookingJoin;
