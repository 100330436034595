import { BusinessLine, ChargeType, CreditMinutes, SessionModality } from 'ts-frontend/types';
import { AccountType } from 'ts-frontend/entities/Room';

import { CancellationPolicyFlow, StepTitle } from '@/utils/analytics/trackerTypes';
import { trackEvent } from '@/utils/analytics/eventTracker';
import {
  Target,
  PageName,
  Platforms,
  BHEligibilityFormFieldName,
  BHEligibilityErrorReasons,
  UpdateCoverageSource,
} from '../types';

export const trackCancellationPolicyPrompt = (params: {
  userID: number;
  roomID: number;
  providerID: number;
  planID?: number;
  sessionModality: SessionModality;
  sessionLength: number | null;
}) => {
  trackEvent('Cancellation Policy Prompt', params);
};

export const trackCancellationPolicyClick = (params: {
  userID: number;
  roomID: number;
  providerID: number;
  planID?: number | null;
  sessionModality: SessionModality;
  flow: CancellationPolicyFlow;
}) => {
  trackEvent('Cancellation Policy Click', params);
};

export const trackMessagingCTAClick = (params: {
  userID: number;
  roomID: number;
  providerID: number;
  planID?: number | null;
  confirmationScreen: string | StepTitle;
  action: 'I agree' | 'Next';
}) => {
  trackEvent('Messaging CTA', params);
};

export const trackBookingSession = (params: {
  userID: number;
  roomID: number;
  providerID: number;
  planID?: number | null;
  sessionModality: SessionModality;
  sessionLength: CreditMinutes | null;
  copayFeatureFlag: boolean;
  businessLine?: BusinessLine;
  accountType?: AccountType;
  bookSession0CopayFF: boolean;
  copayAmount?: number | null;
  bookingSource: string | null;
}) => {
  trackEvent('Booking session', params);
};

export const trackNotEligibleToBookSession = (params: {
  userID: number;
  roomID: number;
  providerID: number;
  isPermanentIneligible: boolean;
}) => {
  trackEvent('Not eligible to book session', params);
};

export const trackTrizettoErrorScreen = (params: {
  reason: string;
  userID?: number | string;
  roomID?: number | string;
}) => {
  trackEvent('Trizetto error screen on eligibility check', params);
};

export const trackHasPendingPayments = (params: {
  userID: number;
  roomID: number;
  providerID: number;
  enabledChargeTypes?: Array<ChargeType>;
  shownChargeTypes?: Array<ChargeType>;
}) => {
  trackEvent('Has pending payments', params);
};

export const trackPaidPendingPayments = (params: {
  userID: number;
  roomID: number;
  providerID: number;
}) => {
  trackEvent('Paid pending payments', params);
};

export const trackFailedToPayPendingPayments = (params: {
  userID: number;
  roomID: number;
  providerID: number;
}) => {
  trackEvent('Failed to pay pending payments', params);
};

export const trackContinueWithBookingSuccessfulPayment = (params: {
  userID: number;
  roomID: number;
  providerID: number;
}) => {
  trackEvent('Continue with booking (successful payment)', params);
};

export const trackBookingCompleted = (params: {
  userID: number;
  roomID: number;
  providerID: number;
  planID?: number | null;
  sessionModality: SessionModality;
  sessionLength: CreditMinutes | null;
}) => {
  trackEvent('Booking completed', params);
};

export const trackClickOnPendingInvoicesBanner = (params: {
  userID: number;
  roomID: number;
  providerID: number;
  planID?: number | null;
}) => {
  trackEvent('Banner pending invoices', params);
};

export const trackJoiningAdHocSession = (params: {
  userID: number;
  roomID: number;
  providerID: number;
}) => {
  trackEvent('Joining ad hoc session', params);
};

export const trackPayingAdHocSession = (params: {
  userID: number;
  roomID: number;
  providerID: number;
  planID?: number | null;
  sessionModality: SessionModality;
  sessionLength: number | null;
}) => {
  trackEvent('Paying ad hoc session', params);
};

export const trackJoinedAdHocSession = (params: {
  userID: number;
  roomID: number;
  providerID: number;
  planID?: number | null;
  sessionModality: SessionModality;
  sessionLength?: number | null;
}) => {
  trackEvent('Joined ad hoc session', params);
};

export const trackConfirmOrDeclineRecurringSessionInteraction = ({
  mode,
  bookingID,
  userID,
}: {
  mode: 'click' | 'view';
  userID: number;
  bookingID: string;
}) => {
  let eventName;
  switch (mode) {
    case 'click':
      eventName = 'Recurring System Message CTA Click';
      break;
    case 'view':
      eventName = 'View Recurring Sessions';
      break;
    default:
      throw new Error(`Unknown mode: ${mode}`);
  }
  trackEvent(
    eventName,
    {
      actionName: 'confirmOrDeclineRecurringSessionInteraction',
      userID,
      bookingID,
    },
    ['tsAnalytics']
  );
};

export const trackViewPostLVSBookSessionPrompt = ({
  completedSessionsWithTherapist,
  videoCallID,
  roomID,
  clientID,
  therapistID,
}: {
  completedSessionsWithTherapist: number;
  videoCallID: string;
  roomID: number;
  clientID: number;
  therapistID: number;
}) => {
  trackEvent('View Post-LVS Book Session Prompt', {
    roomID,
    videoCallID,
    clientID,
    therapistID,
    completedSessionsWithProvider: completedSessionsWithTherapist > 3 ? '4+' : '1-3',
  });
};

export const trackClickPostLVSBookSessionPrompt = ({
  buttonName,
  videoCallID,
  roomID,
  clientID,
  therapistID,
}: {
  buttonName: 'CTA' | 'Skip';
  videoCallID: string;
  roomID: number;
  clientID: number;
  therapistID: number;
}) =>
  trackEvent(`Click Post-LVS Book Session Prompt ${buttonName}`, {
    roomID,
    videoCallID,
    clientID,
    therapistID,
  });

export const trackClickClosedCaptions = (params: {
  roomID: number;
  videoCallID: number;
  captionsLanguage: string;
  targets: Target[];
}) => {
  trackEvent('Click Closed Captions', { ...params, actionName: 'closedCaptionsChanged' }, [
    'tsAnalytics',
  ]);
};

export const trackClickVirtualBackground = (params: {
  roomID: number;
  videoCallID: number;
  virtualBackground: string;
  targets: Target[];
}) => {
  trackEvent('Click Virtual Background', { ...params, actionName: 'virtualBackgroundChanged' }, [
    'tsAnalytics',
  ]);
};

export interface TrackCTAClickOptions extends Record<string, unknown> {
  roomID?: number | string;
  clientID?: number | string;
  therapistID?: number | string;
  videoCallID?: number | string;
  component?: string;
  Platform?: Platforms;
  updatedFields?: BHEligibilityFormFieldName[];
  errorSeen?: boolean;
  errorReason?: BHEligibilityErrorReasons[] | string[];
}

export const trackCTAClick = (
  buttonText: string,
  pageName: PageName,
  options: TrackCTAClickOptions = {}
) => {
  trackEvent(
    'CTA Click',
    {
      Page: pageName,
      buttonText,
      ...options,
    },
    ['mixpanel'],
    false
  );
};

export const trackUpdateCoverageSourceEvent = (roomID: number, source: UpdateCoverageSource) => {
  trackEvent(
    'View select what you would like to do screen',
    {
      roomID,
      source,
    },
    ['mixpanel']
  );
};

export const trackRegistrationErrorEvent = (reason: string) => {
  trackEvent(
    'Registration Error',
    {
      reason,
    },
    ['mixpanel']
  );
};
