import { FunctionComponent } from 'react';
import {
  View,
  TouchableView,
  Big,
  CaretLeft,
  ResponsiveLayoutWithHeader,
  useEmotionTheme,
  PanelHeader,
  SkipNavigation,
  usePanelManagerActions,
  spacing,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import StarredMessagesScreen from 'chat/screens/StarredMessagesScreen';
import Star from 'chat/components/Icons/Star';
import { RouteComponentProps, withRouter } from '../core/routerLib/index';
import { useMainActions } from '../hooks/mainContext';
import styled from '../core/styled';

const { space100 } = spacing;

const StarredMessagesPanelContainer: FunctionComponent<
  RouteComponentProps<
    {
      roomID: number;
      starredRoomID?: number;
    },
    {},
    { from?: string | null }
  >
> = ({ match, history, location }) => {
  const { setDropdownMenuVisible } = useMainActions();
  const { setIsLeftPanelOnTop } = usePanelManagerActions();
  const { isMobile } = useWindowWidthState();

  const handleOnRoomPanelClosePress = () => {
    history.push(`/room/${match.params.roomID}`);
    if (!match.params.starredRoomID && isMobile) setIsLeftPanelOnTop(true);
  };

  const handleOnBackPress = () => {
    setDropdownMenuVisible(true);
    history.goBack();
  };

  const CaretLeftWrapper = styled(TouchableView)(({ theme: { window } }) => {
    return {
      display: window.isSmall || window.isMedium ? 'flex' : 'none',
      justifyContent: 'center',
      marginRight: 20,
      lineHeight: 0, // fixes bootstrap lineHeight 20px causing this to render at 26px height
    };
  });

  const returnToRoomDetails = () => {
    history.push({
      pathname: `/room/${match.params.roomID}/room-details`,
      state: {
        from: null,
      },
    });
  };
  const cameFromRoomDetails =
    location.state && location.state.from ? location.state.from === 'room-details' : false;

  const skipNavs: SkipNavigation[] | undefined = cameFromRoomDetails
    ? [
        {
          buttonText: 'Return to room details',
          onPress: returnToRoomDetails,
        },
      ]
    : undefined;
  const { colors } = useEmotionTheme();
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => (
        <PanelHeader
          title="Starred messages"
          skipNavs={skipNavs}
          onRightPress={handleOnRoomPanelClosePress}
          renderLeft={() => (
            <View row align="center">
              {!match.params.starredRoomID && (
                <CaretLeftWrapper onPress={handleOnBackPress}>
                  <CaretLeft color={colors.green} />
                </CaretLeftWrapper>
              )}
              <Star fillColor={colors.cobalt} strokeColor={colors.cobalt} />
              <Big style={{ marginLeft: space100 }}>Starred messages</Big>
            </View>
          )}
        />
      )}
    >
      <StarredMessagesScreen isTherapistChat={false} roomID={match.params.starredRoomID} />
    </ResponsiveLayoutWithHeader>
  );
};

export default withRouter(StarredMessagesPanelContainer);
