import { OptionType } from '../components/Select';

type SearchConfig<T extends OptionType> = { extraOptionsWhenEmpty?: Array<T> };

export const filterSelectOptions = (inputValue: string) => (option: OptionType) =>
  option.label.toLowerCase().includes(inputValue.toLowerCase());

export const getSelectSearchResults = <T extends OptionType>(
  options: Array<T>,
  input: string,
  config?: SearchConfig<T>
) => {
  const results = options?.filter(filterSelectOptions(input)) || [];
  results.sort(
    (a, b) =>
      a.label.toLowerCase().indexOf(input.toLowerCase()) -
      b.label.toLowerCase().indexOf(input.toLowerCase())
  );
  if (!!config?.extraOptionsWhenEmpty?.length && results.length === 0) {
    results.push(...config.extraOptionsWhenEmpty);
  }
  return results;
};

export const selectLoadOptions =
  <T extends OptionType>(options: Array<T>, config?: SearchConfig<T>) =>
  (input: string, callback: (options: Array<T>) => void) =>
    callback(getSelectSearchResults(options, input, config));
