import { services } from '@talkspace/react-toolkit';
import { ROOM_TYPE_TO_SERVICE_TYPE, RoomType } from 'chat/constants';

export const getServiceTypeByRoomType = (roomType: RoomType) => ROOM_TYPE_TO_SERVICE_TYPE[roomType];

export const getServiceNameByRoomType = (roomType: RoomType, isTeen: boolean) => {
  const serviceType = getServiceTypeByRoomType(roomType);
  const service = roomType === 'privateRoom' && isTeen ? 'therapyTeen' : serviceType;
  return services.find((s) => s.value === service)?.name || ROOM_TYPE_TO_SERVICE_TYPE.privateRoom;
};
