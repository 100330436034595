import { useCallback } from 'react';

import {
  addPushNotificationListener,
  promptPushNotificationPermissionOrSettings,
  pushNotificationLogger,
} from '../../../../plugins/pushNotifications';
import { useHistory } from '@/core/routerLib';
import { useIonicEffect } from '../../../../hooks';
import { saveDeviceToken } from '@/utils/token';
import { sendDeviceToken } from '@/auth/auth';
import { logError } from '../../../../helpers/errorReporting';

const { debug, error, log } = pushNotificationLogger;

export type ListenerActions = {
  onRegistration?(deviceToken: string): void;
  onPushReceived?(notification: unknown): void;
  onActionPerformed?(notification: unknown): void;
};

const pushMessageActionType = {
  UNREAD_MESSAGE: 7,
  OPEN_ROOM: 9,
};

interface PushNotificationsContainerProps {
  listenerActions?: ListenerActions;
  shouldRequestPermission?: boolean;
  children: React.ReactNode;
}

const PushNotificationsContainer = ({
  listenerActions,
  shouldRequestPermission,
  children,
}: PushNotificationsContainerProps) => {
  const history = useHistory();
  const onTokenRegistration = useCallback((token: string) => {
    saveDeviceToken(token);
    sendDeviceToken(token).catch((err) => {
      logError(err, pushNotificationLogger);
    });
  }, []);
  const onRouteActionPerformed: ListenerActions['onActionPerformed'] = useCallback(
    (notification) => {
      const userInfo = notification?.userInfo;
      // const category = notification?.category;
      const typeID: number = notification?.pushMessageTypeId;
      const roomID: number = notification?.roomId;
      pushNotificationLogger.debug('Handling push notification', notification, userInfo);
      switch (typeID) {
        case pushMessageActionType.UNREAD_MESSAGE:
        case pushMessageActionType.OPEN_ROOM:
          if (!roomID) {
            pushNotificationLogger.error(`roomID not found for pushMessageTypeId ${typeID}`);
            return;
          }
          history.push(`/room/${roomID}`, { postActionRoute: 'chat' });
          break;
        default:
          pushNotificationLogger.warn('Unhandled notification', notification);
          break;
      }
    },
    [history]
  );

  const { onRegistration, onActionPerformed } = listenerActions || {
    onRegistration: onTokenRegistration,
    onActionPerformed: onRouteActionPerformed,
  };

  useIonicEffect(() => {
    debug('Setting notification listeners');
    const removeListenerArr = [
      addPushNotificationListener('registration', (token) => {
        debug(`deviceToken: ${JSON.stringify(token)}`);
        onRegistration?.(token.value);
      }),
      addPushNotificationListener('registrationError', (registrationError) => {
        error(`Error: ${JSON.stringify(registrationError)}`);
      }),
      addPushNotificationListener('pushNotificationReceived', async (notification) => {
        log(`Push received: ${JSON.stringify(notification)}`, notification);
      }),
      addPushNotificationListener('pushNotificationActionPerformed', async (notification) => {
        const data = notification?.notification?.data?.aps;
        log(`Action performed: ${JSON.stringify(notification.notification)}`, notification);
        onActionPerformed?.(data);
      }),
    ];

    return () => {
      removeListenerArr.forEach((removeListener) => removeListener.remove?.());
    };
  }, [onActionPerformed, onRegistration]);

  useIonicEffect(() => {
    if (!shouldRequestPermission) return;
    promptPushNotificationPermissionOrSettings();
  }, [shouldRequestPermission]);

  return <>{children}</>;
};

export default PushNotificationsContainer;
