import { Link, RHFCheckbox, TextDS, View } from '@talkspace/react-toolkit';

const PCPCheckBox = () => (
  <View>
    <RHFCheckbox
      alignCenter={false}
      fieldName="consentShareInfo"
      label={
        <>
          <TextDS variant="bodySm" colorRole="textSubtlest">
            I give my consent to share my medical information with my primary care physician for
            coordination of my care. I acknowledge that I can withdraw my consent at any time by
            contacting{' '}
            <Link
              href="mailto:privacy@talkspace.com."
              style={{ textDecoration: 'underline', margin: 0 }}
              target="_blank"
              stopPropagation
            >
              <TextDS inline variant="headingSm" colorRole="textInteractiveDefault">
                privacy@talkspace.com
              </TextDS>
            </Link>
            .
          </TextDS>
        </>
      }
    />
  </View>
);

export default PCPCheckBox;
