import { VoidFunctionComponent } from 'react';
import {
  View,
  BaseButton,
  Button,
  Large,
  useWindowWidthState,
  BookingTimeZone,
} from '@talkspace/react-toolkit';

import { useTranslation } from '@talkspace/i18n';
import BottomButtonContainer from '../BottomButtonContainer';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';

interface Props {
  handleReserveClick: () => void;
  onClickCancellationPolicy: () => void;
  timezone: string;
  isLoading?: boolean;
  isSuccess?: boolean;
}

const ConfirmBookingWithoutPolicyOnboarding: VoidFunctionComponent<Props> = ({
  handleReserveClick,
  onClickCancellationPolicy,
  isLoading,
  isSuccess,
  timezone,
}) => {
  const { isLoading: isInRoomSchedulingLoading } = useInRoomSchedulingState();
  const { isMobile } = useWindowWidthState();
  const { t: tBookingScreen } = useTranslation('bookingScreen');

  return (
    <>
      <View flex={1} align="center">
        <BookingTimeZone timezone={timezone} style={{ marginBottom: 15 }} />
        <BaseButton style={{ marginTop: 40 }} onPress={onClickCancellationPolicy}>
          <Large variant="largeBoldWideGreen">
            {tBookingScreen('available.review', 'Review cancellation policy', undefined)}
          </Large>
        </BaseButton>
      </View>

      <BottomButtonContainer
        propsV0={{ style: isMobile ? { bottom: 15 } : { position: 'static' } }}
      >
        <Button
          disabled={isLoading || isInRoomSchedulingLoading || isSuccess}
          isLoading={isLoading}
          onPress={handleReserveClick}
          text={tBookingScreen('available.bookSession', 'Book session', undefined)}
          dataQa="inRoomSchedulingOnBoardingConfirmBooking"
          stretch
        />
      </BottomButtonContainer>
    </>
  );
};

export default ConfirmBookingWithoutPolicyOnboarding;
