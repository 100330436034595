import { FunctionComponent } from 'react';
import {
  ExtraHuge,
  View,
  useWindowWidthState,
  ComplianceBadges,
  Screen,
} from '@talkspace/react-toolkit';
import { BillingPrice, LineItem } from 'ts-frontend/types';
import { PaymentSummary } from 'checkout';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '@/core/styled';
import BaseModal from '../../../components/BaseModal';

interface NextRenewalPaymentDetailsProps {
  lineItems: LineItem[];
  total: number;
  savings: number;
  billingPrice: BillingPrice;
  renewalDate: string;
}

const MarginView = styled(View)({
  marginBottom: 27,
});

const NextRenewalPaymentDetailsContent: FunctionComponent<NextRenewalPaymentDetailsProps> = ({
  lineItems,
  total,
  savings,
  billingPrice,
  renewalDate,
}) => (
  <>
    <View style={{ marginBottom: 25 }}>
      <PaymentSummary
        lineItems={lineItems}
        total={total}
        savings={savings}
        billingPrice={billingPrice}
        isTrial={false}
        isLoading={false}
        showRenewalNotice={false}
        showInsuranceDisclaimer={false}
        renewalDate={renewalDate}
      />
    </View>
    <ComplianceBadges style={{ margin: 'auto', marginBottom: 56 }} />
  </>
);

const NextRenewalPaymentDetails: FunctionComponent<
  RouteComponentProps & NextRenewalPaymentDetailsProps
> = ({ history, lineItems, total, savings, billingPrice, renewalDate }) => {
  const closeModal = useCloseModal();
  const { isMobile } = useWindowWidthState();

  const dataQa = 'nextRenewalPaymentDetailsView';
  if (isMobile) {
    return (
      <Screen
        showBackButton
        onCloseButtonClick={history.goBack}
        showCloseButton={false}
        title="Next renewal"
        containerDataQa={dataQa}
      >
        <MarginView />
        <NextRenewalPaymentDetailsContent
          lineItems={lineItems}
          total={total}
          savings={savings}
          billingPrice={billingPrice}
          renewalDate={renewalDate}
        />
      </Screen>
    );
  }
  return (
    <BaseModal
      onClosePress={() => closeModal()}
      isLoading={false}
      hasPaddingTop={false}
      focusMainOnClose
      headerStyle={{ paddingTop: 15, paddingLeft: 15, paddingRight: 15 }}
      hasMinHeight={false}
      dataQa={dataQa}
    >
      <ExtraHuge style={{ marginBottom: 26, alignSelf: 'start' }} as="h2">
        Next renewal
      </ExtraHuge>
      <NextRenewalPaymentDetailsContent
        lineItems={lineItems}
        total={total}
        savings={savings}
        billingPrice={billingPrice}
        renewalDate={renewalDate}
      />
    </BaseModal>
  );
};

export default withRouter(NextRenewalPaymentDetails);
