import { FunctionComponent } from 'react';
import { useWindowWidthState, View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import CallGradient from './CallGradient';
import SmallSpeakerVideoSettings from './SmallSpeakerVideoSettings';
import VideoCall from './VideoCall';

const LocalVideoWrapper = styled(View)<{
  isSmallerScreen: boolean;
  isMinimized: boolean;
  bottomOffset: number;
}>(({ isSmallerScreen, isMinimized, bottomOffset }) => {
  const minimizedStyles = {
    height: isSmallerScreen ? 75 : 60,
    width: isSmallerScreen ? 55 : 60,
    right: 8,
    bottom: 8,
    borderRadius: 10,
    borderBottomRightRadius: 20,
  };
  const styles = {
    width: isSmallerScreen ? 85 : 100,
    height: isSmallerScreen ? 114 : 100,
    position: 'absolute',
    bottom: isSmallerScreen ? bottomOffset + 115 : bottomOffset + 20,
    right: 20,
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: 'grey',
  };
  return isMinimized ? { ...styles, ...minimizedStyles } : styles;
});

const LocalVideoCall: FunctionComponent<{
  isCurrentUserTherapist?: boolean;
  currentUserID?: number;
  isAudioOn: boolean;
  isVideoOn: boolean;
  isMinimized: boolean;
  bottomOffset: number;
}> = ({
  currentUserID,
  isAudioOn,
  isVideoOn,
  isCurrentUserTherapist = false,
  isMinimized,
  bottomOffset = 0,
}) => {
  const { width } = useWindowWidthState();
  const isTherapistLVSBreakpoint = isCurrentUserTherapist && width > 750 && width < 925;
  const isSmallerScreen = width < 550 || isTherapistLVSBreakpoint;

  if (!isVideoOn) return null;

  return (
    <LocalVideoWrapper
      isSmallerScreen={isSmallerScreen}
      isMinimized={isMinimized}
      bottomOffset={bottomOffset}
    >
      <VideoCall userID={currentUserID} />
      <CallGradient isBottom isLarge={false} />
      <SmallSpeakerVideoSettings
        isTherapistLVSBreakpoint={isTherapistLVSBreakpoint}
        isAudioOn={isAudioOn}
        isVideoOn={isVideoOn}
      />
    </LocalVideoWrapper>
  );
};

export default LocalVideoCall;
