import { FunctionComponent, useState, useEffect } from 'react';
import {
  View,
  Huge,
  Big,
  Button,
  SelectRounded,
  BaseButton,
  Standard,
  StepTip,
  OptionType,
  TextDS,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { ValueType } from 'react-select';
import { states, countries } from '@talkspace/configs';
import { formatStateOptions } from 'ts-frontend/helpers/optionsHelpers';
import { useFlags } from 'launchDarkly/FlagsProvider';
import styled from '@/core/styled';
import { StickyBottomContainer } from '../StepWizard/StepWizardStyles';

const Header = styled(Huge)({
  marginBottom: 50,
  width: 150,
  textAlign: 'center',
});

const SubmitButton = styled(Button)(({ theme: { colors } }) => {
  return {
    width: 320,
    height: 55,
    marginTop: 20,
    backgroundColor: colors.permaTalkspaceDarkGreen,
  };
});

const SecondaryButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    marginTop: 20,
    padding: 0,
    backgroundColor: colors.white,
  };
});

const orderCountries = (countriesOptions: Record<string, string>): OptionType[] =>
  Object.entries(countriesOptions)
    .map(([value, label]) => {
      return { value, label };
    })
    .filter(({ value }) => value !== 'US')
    .sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB, 'en'));

const stateOptions = formatStateOptions(states);
const countryOptions = orderCountries(countries);

interface Props {
  onSelect: (value: string) => void;
  formerValue: string;
  isPsych?: boolean;
}

const StateAndCountrySelector: FunctionComponent<Props> = ({
  onSelect,
  formerValue,
  isPsych = false,
}) => {
  const { availabilitySwitch } = useFlags();
  const { isMobile } = useWindowWidthState();

  const [isCountrySelector, setIsCountrySelector] = useState(false);
  const [selectOptions, setSelectOptions] = useState(stateOptions);
  const [selectedValue, setSelectedValue] = useState<OptionType | null>(null);
  const getFormattedValue = (value: string, options?: OptionType[]) => {
    const searchInOptions = options || selectOptions;
    return searchInOptions.find((option) => option.value === value) || null;
  };
  const toggleStateCountrySelector = () => {
    setSelectOptions(isCountrySelector ? stateOptions : countryOptions);
    setIsCountrySelector(!isCountrySelector);
    setSelectedValue(null);
  };
  const handleOnSubmit = () => {
    if (selectedValue) {
      let result = selectedValue.value;
      if (!isCountrySelector) {
        result = `US-${result}`;
      }
      onSelect(result);
    }
  };
  const handleOnSelect = (value: ValueType<OptionType, false>) => {
    setSelectedValue(getFormattedValue(((value || {}) as OptionType).value));
  };
  useEffect(() => {
    if (formerValue && !selectedValue) {
      const split = formerValue.split('-');
      if (split.length > 1) {
        setIsCountrySelector(false);
        setSelectOptions(stateOptions);
        setSelectedValue(getFormattedValue(split[1], stateOptions));
      } else {
        setSelectOptions(countryOptions);
        setIsCountrySelector(true);
        setSelectedValue(getFormattedValue(formerValue, countryOptions));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formerValue]);

  return (
    <View align="center">
      <Header>{`What ${isCountrySelector ? 'country' : 'state'} do you live in?`}</Header>
      <View style={{ width: 320 }}>
        <SelectRounded options={selectOptions} value={selectedValue} onChange={handleOnSelect} />
      </View>
      {availabilitySwitch && isMobile ? (
        <StickyBottomContainer noBorder isSticky>
          <SubmitButton onPress={handleOnSubmit}>
            <Big variant="bigWide">Continue</Big>
          </SubmitButton>
          {isPsych ? (
            <StepTip tip="noPsychOutsideUS" style={{ marginTop: 35 }} />
          ) : (
            <SecondaryButton onPress={toggleStateCountrySelector}>
              <TextDS variant="headingMd" colorRole="buttonBrandSecondary">
                {`Living ${isCountrySelector ? 'in the US' : 'outside of the US'}?`}
              </TextDS>
            </SecondaryButton>
          )}
        </StickyBottomContainer>
      ) : (
        <>
          <SubmitButton onPress={handleOnSubmit}>
            <Big variant="bigWide">Next</Big>
          </SubmitButton>
          {isPsych ? (
            <StepTip tip="noPsychOutsideUS" style={{ marginTop: 35 }} />
          ) : (
            <SecondaryButton onPress={toggleStateCountrySelector}>
              <Standard
                variant={availabilitySwitch ? 'standardInteractiveGreen' : 'standardDarkGrey'}
              >{`Live ${isCountrySelector ? 'in the US' : 'outside of the US'}?`}</Standard>
            </SecondaryButton>
          )}
        </>
      )}
    </View>
  );
};

export default StateAndCountrySelector;
