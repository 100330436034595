import { VoidFunctionComponent } from 'react';
import {
  View,
  Button,
  Checkbox,
  Huge,
  Large,
  Tiny,
  XCheckbox,
  styled,
  useEmotionTheme,
  BookingTimeZone,
} from '@talkspace/react-toolkit';
import { SessionModality, VideoCreditOffer } from 'ts-frontend/types';

import BottomButtonContainer from '../BottomButtonContainer';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';

const getSubtitleText = ({
  creditOption,
  modality,
  clientDisplayName,
}: {
  creditOption: VideoCreditOffer;
  modality: SessionModality;
  clientDisplayName?: string;
}) => {
  const { type, typeDisplay } = creditOption;
  let text = 'You are about to';
  if (type === 'introduction') {
    text += ` schedule a 10 minute live ${modality} session.`;
  } else {
    const modalityTypeDisplay = type === 'therapy' ? `live ${modality} session` : typeDisplay;
    text += ` book a ${modalityTypeDisplay} session with ${clientDisplayName}`;
    text += '.';
  }

  return text;
};

const NoTimeForBreak = () => {
  const { colors } = useEmotionTheme();

  return (
    <View row>
      <XCheckbox />
      <View>
        <Large
          style={{
            marginLeft: 8,
          }}
          variant="largeSlateGreyStrikethrough"
        >
          Add a 15 minute break after this session
        </Large>
        <Tiny style={{ marginLeft: 8, color: colors.slateGrey }}>
          Not enough time available after this session
        </Tiny>
      </View>
    </View>
  );
};

const Subtitle = styled(Huge)({
  textAlign: 'center',
  maxWidth: 280,
  marginTop: 24,
});

interface Props {
  timezone: string;
  clientDisplayName?: string;
  onAdd15MinuteBreakCheck: (hasBreakAfterSession: boolean) => void;
  onScheduleSessionClick: () => void;
}

const ConfirmBookingTherapist: VoidFunctionComponent<Props> = ({
  timezone,
  clientDisplayName,
  onAdd15MinuteBreakCheck,
  onScheduleSessionClick,
}) => {
  const { isLoading, hasBreakAfterSession, selectedCreditOption, modality, hasTimeForBreak } =
    useInRoomSchedulingState();
  const { colorRoles } = useEmotionTheme();

  return (
    <>
      <View flex={1} align="center">
        <BookingTimeZone timezone={timezone} />
        {selectedCreditOption && (
          <Subtitle>
            {getSubtitleText({
              creditOption: selectedCreditOption,
              modality,
              clientDisplayName,
            })}
          </Subtitle>
        )}
      </View>

      <BottomButtonContainer>
        {hasTimeForBreak ? (
          <Checkbox
            label="Add a 15 minute break after this session"
            isChecked={!!hasBreakAfterSession}
            containerStyle={{ flex: 1 }}
            isDisabled={false}
            isRequired={false}
            setIsChecked={onAdd15MinuteBreakCheck}
            isLabelOnRight
            labelStyle={{ fontWeight: 'bold' }}
          />
        ) : (
          <NoTimeForBreak />
        )}
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onPress={onScheduleSessionClick}
          text="Book session"
          dataQa="inRoomSchedulingConfirmBookingSchedule"
          style={{ backgroundColor: colorRoles.system.actionPrimaryDefault }}
          stretch
        />
      </BottomButtonContainer>
    </>
  );
};

export default ConfirmBookingTherapist;
