import { FunctionComponent } from 'react';
import { Button, View, Large, useEmotionTheme, A11Y_COLORS } from '@talkspace/react-toolkit';
import { Trans, useTranslation } from '@talkspace/i18n';
import styled from '@/core/styled';
import { useHistory } from '@/core/routerLib';
import { safeRouteNavigateOrPush } from '@/utils/analytics/helpers';

const Wrapper = styled(View)({
  textAlign: 'center',
});

const RedText = styled(Large)<{ colors: typeof A11Y_COLORS }>(({ colors }) => {
  return {
    color: colors.red,
    display: 'inline',
    fontWeight: 'bold',
  };
});

const EmergencyResourcesBodyText: FunctionComponent = () => {
  const { colors } = useEmotionTheme();
  const history = useHistory();
  const { t: tTeenOnboarding } = useTranslation('teenOnboarding');

  return (
    <Wrapper>
      <Large>
        <Trans t={tTeenOnboarding} key="whatEmergencyPhoneNumber.call">
          Call <RedText colors={colors}>988</RedText> for immediate crisis support.
        </Trans>
      </Large>
      <br />
      <Large>
        {tTeenOnboarding(
          'whatEmergencyPhoneNumber.description',
          'If you’re feeling suicidal but are not in immediate danger, contact someone immediately such as your doctor, a friend, a relative, or someone you can trust.',
          undefined
        )}
      </Large>
      <Button
        dataQa="viewEmergencyResourcesButton"
        style={{ marginTop: 26, width: 320, backgroundColor: colors.permaTalkspaceDarkGreen }}
        onClick={() => {
          safeRouteNavigateOrPush(history, 'https://helpnow.talkspace.com/', true);
        }}
      >
        {tTeenOnboarding('whatEmergencyPhoneNumber.view', 'View emergency resources', undefined)}
      </Button>
    </Wrapper>
  );
};

export default EmergencyResourcesBodyText;
