import { ComponentProps } from 'react';
import Chip from './Chip';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

const versionMap: VersionMap<ComponentProps<typeof Chip>> = {
  DefaultComponent: Chip,
};

export default withVersioning(versionMap);
