import React, { useState, Children } from 'react';
import camelCase from 'lodash/camelCase';

import View from '../View';
import styled, { EmotionStyle } from '../../core/styled';
import { useWindowWidthState } from '../../index.next';

const TabContainerStyled = styled(View)<{ tabContainerStyle?: EmotionStyle }>(
  ({ tabContainerStyle }) => {
    return {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '1px solid #D8D8D8',
      ...tabContainerStyle,
    };
  }
);

const TabContentWrapper = styled(View)<{ tabContentWrapperStyle?: EmotionStyle }>(
  ({ tabContentWrapperStyle }) => {
    return {
      ...tabContentWrapperStyle,
    };
  }
);

const TabStyled = styled(View)<{
  tabStyle?: EmotionStyle;
  tabHoverStyle?: EmotionStyle;
  activeTabStyle?: EmotionStyle;
  isActive: boolean;
  isMobile: boolean;
}>(({ tabStyle, tabHoverStyle, activeTabStyle, isMobile, theme: { colors }, isActive }) => {
  return {
    cursor: 'pointer',
    padding: '10px',
    marginRight: isMobile ? 5 : 10,
    '&:hover': tabHoverStyle,
    fontWeight: 500,
    color: colors.slateGrey,
    fontSize: 15,
    paddingLeft: isMobile ? 8 : 20,
    paddingRight: isMobile ? 8 : 20,
    ...tabStyle,
    ...(isActive && {
      fontWeight: 700,
      color: colors.accessibilityGreenDark,
      borderBottom: `solid ${colors.accessibilityGreenDark} 4px`,
      ...activeTabStyle,
    }),
  };
});

interface TabContainerProps {
  tabContainerStyle?: EmotionStyle;
  tabStyle?: EmotionStyle;
  activeTabStyle?: EmotionStyle;
  tabContentWrapperStyle?: EmotionStyle;
  tabHoverStyle?: EmotionStyle;
  children?: React.ReactNode;
  currentTab?: number;
  handleSelect?: (value: number) => void;
}

export const TabContainer = ({
  tabContainerStyle,
  tabStyle,
  activeTabStyle,
  tabContentWrapperStyle,
  tabHoverStyle,
  currentTab,
  children,
  handleSelect,
}: TabContainerProps) => {
  const [activeTab, setActiveTab] = useState(currentTab || 0);
  const { isMobile } = useWindowWidthState();

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    if (handleSelect) {
      handleSelect(index);
    }
  };

  const renderTabs = () =>
    Children.map(children, (child, index) => {
      if (!React.isValidElement(child) || !child.props.title) {
        return null;
      }
      return (
        <TabStyled
          isMobile={isMobile}
          key={`tab${camelCase(child.props.title)}`}
          isActive={activeTab === index}
          onClick={() => handleTabClick(index)}
          tabStyle={tabStyle}
          tabHoverStyle={tabHoverStyle}
          activeTabStyle={activeTabStyle}
        >
          {child.props.title}
        </TabStyled>
      );
    });

  const renderContent = () =>
    Children.map(children, (child, index) => {
      if (!React.isValidElement(child)) {
        return null;
      }
      return activeTab === index && !child.props.hide ? child : null;
    });

  return (
    <View>
      <TabContainerStyled tabContainerStyle={tabContainerStyle}>{renderTabs()}</TabContainerStyled>
      <TabContentWrapper tabContentWrapperStyle={tabContentWrapperStyle}>
        {renderContent()}
      </TabContentWrapper>
    </View>
  );
};

export default TabContainer;
