import { Button, TextDS, View, spacing, useWindowWidthState } from '@talkspace/react-toolkit';
import CircleUnderlay from '@talkspace/react-toolkit/src/designSystems/components/CircleUnderlay';
import {
  CalendarPlus,
  ClockPersonWaiting,
} from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { ClientUsageStats, Subscription } from 'ts-frontend/types';
import { getIsMessagingEnabled } from 'ts-frontend/helpers/subscription';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { isDummyNoMatchesProvider } from 'ts-frontend/entities/Therapist';
import { useMemo } from 'react';
import { useTranslation } from '@talkspace/i18n';
import { trackCTAClick } from '../../utils/analytics/events';
import { useOpenModal } from '@/utils/ModalsContextProvider';
import BookingsListLoading from './BookingsListLoading';

const { space100, space300, space400, space800 } = spacing;

const BookingsListEmpty = ({
  clientUsageStats,
  subscriptions,
  isLoading,
}: {
  clientUsageStats: ClientUsageStats | undefined;
  subscriptions: Subscription[];
  isLoading: boolean;
}) => {
  const { isMobile } = useWindowWidthState();
  const openModal = useOpenModal();

  const bookableSubscriptions = useMemo(
    () => subscriptions?.filter((subscription) => subscription.subscription.canCreateBooking) || [],
    [subscriptions]
  );
  const { completedSessionsTotal } = clientUsageStats || { completedSessionsTotal: 0 };
  const matchedWithTherapist = subscriptions.some(
    (subscription) =>
      !isDummyNoMatchesProvider({ therapistFullName: subscription.therapist.fullName })
  );
  const hasMessagingEnabledPlan = !!bookableSubscriptions.find((subscription) =>
    getIsMessagingEnabled({ subscription })
  );
  const onPressBookSession = () => {
    trackCTAClick('Book a session', 'schedule');
    openModal('/in-room-scheduling/choose-provider');
  };
  const onPressFindProvider = () => {
    trackCTAClick('Find new provider', 'schedule');
    openModal('/change-provider', { source: 'schedule-no-bookings' });
  };

  const { availabilitySwitch } = useFlags();
  const { t: tScheduler } = useTranslation('scheduler');
  const matchedWithTherapistTitle = tScheduler(
    'BookingsListEmpty.matchedWithTherapistTitle',
    'You don’t have anything booked yet',
    undefined
  );
  const waitingToBeMatchedTitle = tScheduler(
    'BookingsListEmpty.waitingToBeMatchedTitle',
    'Waiting to be matched',
    undefined
  );
  const titleText = matchedWithTherapist ? matchedWithTherapistTitle : waitingToBeMatchedTitle;
  const matchedWithTherapistSubtitle1 = tScheduler(
    'BookingsListEmpty.matchedWithTherapistSubtitle1',
    'Let’s keep your momentum going! It can take about 12 sessions to start feeling better.',
    undefined
  );
  const matchedWithTherapistSubtitle2 = tScheduler(
    'BookingsListEmpty.matchedWithTherapistSubtitle2',
    'Let’s keep your momentum going! Book another session to continue your progress.',
    undefined
  );
  const matchedWithTherapistDynamicSubtitle =
    completedSessionsTotal < 12 ? matchedWithTherapistSubtitle1 : matchedWithTherapistSubtitle2;
  const waitingToBeMatchedSubtitle = tScheduler(
    'BookingsListEmpty.waitingToBeMatchedSubtitle',
    `You'll be able to book and view sessions here once you're matched with a provider`,
    undefined
  );
  const subtitleText = !matchedWithTherapist
    ? waitingToBeMatchedSubtitle
    : matchedWithTherapistDynamicSubtitle;
  const buttonText1 = tScheduler(
    'BookingsListEmpty.buttonText1',
    'Book or start a session',
    undefined
  );
  const buttonText2 = tScheduler('BookingsListEmpty.buttonText2', 'Book a session', undefined);
  const buttonText = hasMessagingEnabledPlan ? buttonText1 : buttonText2;
  const secondaryButtonText = tScheduler(
    'BookingsListEmpty.secondaryButtonText',
    'Find new provider',
    undefined
  );

  return (
    <View align="center" style={{ marginTop: space800 }}>
      {isLoading ? (
        <BookingsListLoading />
      ) : (
        <>
          <CircleUnderlay size={136}>
            {matchedWithTherapist ? (
              <CalendarPlus height={73} width={74} />
            ) : (
              <ClockPersonWaiting />
            )}
          </CircleUnderlay>
          <TextDS variant="headingLg" style={{ marginTop: space400 }}>
            {titleText}
          </TextDS>
          <TextDS
            variant="bodySm"
            colorRole="textSubtle"
            style={{ textAlign: 'center', marginTop: space100 }}
          >
            {subtitleText}
          </TextDS>
          {matchedWithTherapist && (
            <Button
              dataQa="bookingsListEmptyBookOrStartSession"
              text={buttonText}
              style={{ width: isMobile ? '100%' : 'fit-content', marginTop: space400 }}
              onPress={onPressBookSession}
            />
          )}
          {availabilitySwitch && (
            <Button
              variant="tertiary"
              size="large"
              text={secondaryButtonText}
              onPress={onPressFindProvider}
              style={{ marginTop: space300 }}
            />
          )}
        </>
      )}
    </View>
  );
};

export default BookingsListEmpty;
