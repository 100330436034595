/* eslint-disable no-console, no-param-reassign */

/*

This module exports a handful of methods that are useful when you want to log things to the console,
but not always in *production*.

The simplest usage is shown below. By default, the exported methods are configured to *ignore*
calls that are made in production.

```
import { log, debug, warn } from 'ts-frontend/utils/dev-console';

log('You will not see this in production.');
debug('You will not see this in production.');
warn('You will not see this in production.');
```

If you *do* want to enable production logging, you can do so as shown below.

```
import { createNamespace } from 'ts-frontend/utils/dev-console';

const { log, debug, warn } = createNamespace(null, true);
log('You will see this in production.');
```

When creating your own namespaced collection of methods, you can also force each call to be prefixed
with a specified string:

```
import { createNamespace } from 'ts-frontend/utils/dev-console';

const { log, debug, warn } = createNamespace('QuickMatch', false);
log("You will not see this in production. Also, I will be prefixed with the string 'QuickMatch'.");
```

Finally, you can *extend* namespaces like so:

```
import { createNamespace } from 'ts-frontend/utils/dev-console';

const appNamespace = createNamespace('QuickMatch Web', false);
const { log } = appNamespace.extend('Homepage');

log("You will not see this in production. Also, I will be prefixed with the string 'QuickMatch Web:Homepage'.");
```

*/

export type ConsoleLogger = {
  enabled: () => boolean;
  extend: (newNamespace: string) => ConsoleLogger;
  log: (...args: any[]) => void;
  debug: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
  info: (...args: any[]) => void;
  table: (...args: any[]) => void;
  assert: (...args: any[]) => void;
  dir: (...args: any[]) => void;
  trace: (...args: any[]) => void;
};

export const createNamespace = (
  namespace?: string | string[],
  allowProduction = false
): ConsoleLogger => {
  const namespaceString = (() => {
    if (!namespace) {
      return '';
    }
    if (Array.isArray(namespace)) {
      return namespace.join(':');
    }
    return namespace;
  })();

  const result = {
    extend: (newNamespace: string) => {
      const namespaceArray = [newNamespace];
      const currentNamespace = (() => {
        if (!namespace) {
          return [];
        }
        if (Array.isArray(namespace)) {
          return namespace;
        }
        return [namespace];
      })();
      currentNamespace.reverse().forEach((value) => {
        namespaceArray.unshift(value);
      });
      return createNamespace(namespaceArray, allowProduction);
    },
    enabled: () => (process.env.NODE_ENV !== 'production' ? true : allowProduction),
    log: (...args) => {
      if (!allowProduction && process.env.NODE_ENV === 'production') {
        return;
      }
      if (namespaceString) {
        args = [namespaceString, ...args];
      }
      console.log(...args);
    },
    debug: (...args) => {
      if (!allowProduction && process.env.NODE_ENV === 'production') {
        return;
      }
      if (namespaceString) {
        args = [namespaceString, ...args];
      }
      console.debug(...args);
    },
    warn: (...args) => {
      if (!allowProduction && process.env.NODE_ENV === 'production') {
        return;
      }
      if (namespaceString) {
        args = [namespaceString, ...args];
      }
      console.warn(...args);
    },
    error: (...args) => {
      if (!allowProduction && process.env.NODE_ENV === 'production') {
        return;
      }
      if (namespaceString) {
        args = [namespaceString, ...args];
      }
      console.error(...args);
    },
    info: (...args) => {
      if (!allowProduction && process.env.NODE_ENV === 'production') {
        return;
      }
      if (namespaceString) {
        args = [namespaceString, ...args];
      }
      console.info(...args);
    },
    table: (...args) => {
      if (!allowProduction && process.env.NODE_ENV === 'production') {
        return;
      }
      if (namespaceString) {
        args = [namespaceString, ...args];
      }
      console.table(...args);
    },
    assert: (...args) => {
      if (!allowProduction && process.env.NODE_ENV === 'production') {
        return;
      }
      if (namespaceString) {
        args = [namespaceString, ...args];
      }
      console.assert(...args);
    },
    dir: (...args) => {
      if (!allowProduction && process.env.NODE_ENV === 'production') {
        return;
      }
      if (namespaceString) {
        args = [namespaceString, ...args];
      }
      console.dir(...args);
    },
    trace: (...args) => {
      if (!allowProduction && process.env.NODE_ENV === 'production') {
        return;
      }
      if (namespaceString) {
        args = [namespaceString, ...args];
      }
      console.trace(...args);
    },
  };

  return result;
};

const { log, enabled, debug, warn, error, info, table, assert, dir, trace } = createNamespace();
export { log, enabled, debug, warn, error, info, table, assert, dir, trace };
