import { FunctionComponent } from 'react';
import moment from 'moment';
import { View, Large, Tiny } from '@talkspace/react-toolkit';
import { VideoCredit } from 'ts-frontend/types';
import { useTranslation } from '@talkspace/i18n';
import styled from '../../../core/styled';

const DATE_FORMAT_STRING = 'MMM DD, YYYY';

const Container = styled(View)(() => {
  return {
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 360,
  };
});

function formatCreditMinuteOptions(videoCredit: VideoCredit) {
  if (videoCredit.creditMinutesOptions && videoCredit.creditMinutesOptions.length) {
    const opts = videoCredit.creditMinutesOptions;
    // format options like: '30, 45 or 60'
    return opts.length === 1
      ? opts[0]
      : `${opts.slice(0, opts.length - 1).join(', ')} or ${opts[opts.length - 1]}`;
  }
  return videoCredit.creditMinutes;
}

function getVideoCreditsString(videoCredit: VideoCredit) {
  let amountPart;
  let remainingType;
  let typePart = 'live session';
  const creditTime = `(${formatCreditMinuteOptions(videoCredit)} min)`;
  if (videoCredit.creditRemaining !== null && videoCredit.creditTotal) {
    amountPart = `${videoCredit.creditRemaining} of ${videoCredit.creditTotal} `;
  } else if (videoCredit.creditRemaining) {
    amountPart = `${videoCredit.creditRemaining} x `;
  } else {
    amountPart = `1 x `;
  }
  if (
    videoCredit.creditTotal > 1 ||
    (videoCredit.creditRemaining && videoCredit.creditRemaining > 1)
  ) {
    typePart = `${typePart}s`;
  }
  if (videoCredit.type === 'introduction') {
    typePart = `Complimentary ${typePart}`;
  } else if (videoCredit.type === 'psychiatry') {
    typePart = `Psychiatry ${typePart}`;
  } else if (videoCredit.type === 'therapy') {
    typePart = `Therapy ${typePart}`;
  } else if (videoCredit.type === 'eap') {
    typePart = `EAP ${typePart}`;
  }
  if (videoCredit.validTo && videoCredit.creditTotal) {
    remainingType = ` - expires ${moment(videoCredit.validTo).format(DATE_FORMAT_STRING)}`;
  }
  if (amountPart || remainingType) {
    return `${amountPart || ''}${typePart || ''} ${creditTime} ${remainingType || ''}`;
  }
  return '-';
}

interface LiveVideoCreditsProps {
  videoCredits: VideoCredit[];
}

const LiveVideoCredits: FunctionComponent<LiveVideoCreditsProps> = ({ videoCredits }) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return (
    <Container>
      <Tiny>{tnewMemberNav('payment.live', 'Live sessions', undefined)}</Tiny>
      {videoCredits.length > 0
        ? videoCredits.map((videoCredit, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Large key={idx} dataQa={`paymentAndPlanSession-${idx}`}>
              {getVideoCreditsString(videoCredit)}
            </Large>
          ))
        : '-'}
    </Container>
  );
};

export default LiveVideoCredits;
