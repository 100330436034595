import { FunctionComponent, VoidFunctionComponent } from 'react';
import {
  View,
  Big,
  Button,
  BaseButton,
  EmotionStyle,
  StickyDrawer,
  useEmotionTheme,
  useWindowWidthState,
  CancellationPolicyMessage,
  Mini,
} from '@talkspace/react-toolkit';

import { LineItem, BillingPrice } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useTranslation } from '@talkspace/i18n';
import styled from '@/core/styled';
import PaymentCardWrapper from '../PaymentCardWrapper';
import Totals from './Totals';
import RenewalNotice from './RenewalNotice';

const PaymentSummaryWrapper = styled(PaymentCardWrapper)(({ theme: { window } }) => {
  const { isMobile, isLarge } = window;
  let marginRight = 26;
  if (isLarge) marginRight = 0;
  if (isMobile) marginRight = 0;
  return {
    width: isMobile ? 'auto' : 355,
    minWidth: 'initial',
    marginLeft: isMobile || isLarge ? 0 : 26,
    marginRight,
    backgroundColor: '#fff',
    paddingBottom: 37,
  };
});

const ButtonContainer = styled(View)({
  backgroundColor: 'white',
  paddingTop: 8,
  paddingBottom: 20,
  width: '100%',
});

interface PaymentSummaryProps {
  onCompleteCheckout?: () => void;
  onSecondaryClick?: () => void;
  lineItems: LineItem[];
  total: number;
  savings: number;
  billingPrice: BillingPrice;
  isTrial: boolean;
  isLoading: boolean;
  buttonText?: string;
  showRenewalNotice: boolean;
  showInsuranceDisclaimer?: boolean;
  onReviewCancellationPolicyClick?: () => void;
  renewalDate?: string;
  isNoMatches?: boolean;
  totalDueText?: string;
  maxCostPostSession?: number;
  chargeInfoText?: string;
  chargeTimeText?: string;
  showSavings?: boolean;
  plural?: boolean;
  isConfirmSession?: boolean;
  secondaryCtaText?: string;
  hideLineItems?: boolean;
  hideFooter?: boolean;
}

interface BottomButtonCopyProps {
  onReviewCancellationPolicyClick?: () => void;
  style?: EmotionStyle;
  isConfirmSession?: boolean;
  plural?: boolean;
}

const BottomButtonCopy: VoidFunctionComponent<BottomButtonCopyProps> = (props) => {
  const { onReviewCancellationPolicyClick, isConfirmSession, plural, style } = props;
  const { t: tBookingScreen } = useTranslation('bookingScreen');

  if (onReviewCancellationPolicyClick && isConfirmSession && plural) {
    return (
      <CancellationPolicyMessage
        dataQa="checkoutPaymentSummary"
        onCancellationPolicyPress={onReviewCancellationPolicyClick}
        style={{ marginBottom: 22 }}
      />
    );
  }

  return (
    <Mini style={{ marginBottom: 12, textAlign: 'center', ...style }}>
      {tBookingScreen('cost.this', 'This is a secure SSL encrypted payment.', undefined)}
    </Mini>
  );
};

const CTAContainer = ({ children }) => {
  const { isMobile } = useWindowWidthState();
  if (isMobile) {
    return <StickyDrawer>{children}</StickyDrawer>;
  }
  return <View style={{ marginTop: 24 }}>{children}</View>;
};
const PaymentSummary: FunctionComponent<PaymentSummaryProps> = ({
  onCompleteCheckout,
  onSecondaryClick,
  lineItems,
  total,
  savings,
  billingPrice,
  isTrial,
  isLoading,
  buttonText,
  showRenewalNotice,
  showInsuranceDisclaimer,
  onReviewCancellationPolicyClick,
  renewalDate,
  isNoMatches = false,
  totalDueText,
  maxCostPostSession,
  chargeInfoText,
  chargeTimeText,
  showSavings,
  plural,
  isConfirmSession,
  secondaryCtaText,
  hideLineItems = false,
  hideFooter = false,
}) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const { repeatingSessionsFull2 } = useFlags();
  return (
    <PaymentSummaryWrapper highlighted>
      <Totals
        lineItems={lineItems}
        total={total}
        savings={savings}
        onReviewCancellationPolicyClick={onReviewCancellationPolicyClick}
        renewalDate={renewalDate}
        isNoMatches={isNoMatches}
        totalDueText={totalDueText}
        maxCostPostSession={maxCostPostSession}
        chargeInfoText={chargeInfoText}
        chargeTimeText={chargeTimeText}
        showSavings={showSavings}
        hideLineItems={hideLineItems}
      />
      {showRenewalNotice && (
        <RenewalNotice
          billingPrice={billingPrice}
          isTrial={isTrial}
          total={total}
          lineItems={lineItems}
          isNoMatches={isNoMatches}
          showInsuranceDisclaimer={showInsuranceDisclaimer}
        />
      )}
      {onCompleteCheckout && !(repeatingSessionsFull2 && plural && isMobile) && !hideFooter && (
        <CTAContainer>
          <ButtonContainer align="center">
            <Button
              type="button"
              isLoading={isLoading}
              disabled={isLoading}
              onPress={onCompleteCheckout}
              style={{ marginTop: 0, backgroundColor: colors.permaTalkspaceDarkGreen }}
              roundedFocusStyle
              primaryColor={colors.permaTalkspaceDarkGreen}
            >
              <Big variant="bigWide">{buttonText}</Big>
            </Button>
            {onSecondaryClick && (
              <BaseButton
                style={{ marginTop: 14 }}
                onPress={onSecondaryClick}
                dataQa="checkoutPaymentSummarySecondaryCTA"
              >
                <Big variant="bigWide" style={{ color: colors.permaTalkspaceDarkGreen }}>
                  {secondaryCtaText}
                </Big>
              </BaseButton>
            )}
          </ButtonContainer>
          <BottomButtonCopy
            onReviewCancellationPolicyClick={onReviewCancellationPolicyClick}
            isConfirmSession={isConfirmSession}
            plural={plural}
          />
        </CTAContainer>
      )}
    </PaymentSummaryWrapper>
  );
};

export default PaymentSummary;
