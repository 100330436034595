import { FunctionComponent } from 'react';
import moment from 'moment';

import { View, Small, Standard, Large, Button, BookingTimeZone } from '@talkspace/react-toolkit';
import TabRadioGroup from '@talkspace/react-toolkit/src/designSystems/components/TabRadioGroup';
import { TherapistTimeslot } from 'ts-frontend/types';
import { ERoom } from 'ts-frontend/entities/Room';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import styled from '@/core/styled';

import { ErrorXSmallIcon } from '../icons';
import { TherapistInfo } from '../../types';

import { CancelActionCard } from './ActionCards';
import AvatarRow from './CancelBookingAvatarRow';
import { getLateCancellationText } from './cancelBookingUtils';

const TextWrapper = styled(View)({ marginTop: 22, marginBottom: 47 });

interface Props extends RouteComponentProps<{ roomID: string; bookingID: string }> {
  therapist: TherapistInfo;
  isBookingDeclinedByTherapist?: boolean;
  room?: ERoom;
  isBookingConfirmed?: boolean;
  isBookingInLessThan24hrs: boolean;
  checkedPendingInvoices: boolean;
  canDeclineOrCancel: 'decline' | 'cancel';
  selectedAction: string;
  handleCancelPress: () => void;
  handleContinuePress: () => void;
  repeatingTimeslots: TherapistTimeslot[];
  timezone: string;
}

const CancelBookingRecurring: FunctionComponent<Props> = ({
  therapist,
  isBookingDeclinedByTherapist,
  room,
  isBookingConfirmed,
  isBookingInLessThan24hrs,
  checkedPendingInvoices,
  canDeclineOrCancel,
  selectedAction,
  handleCancelPress,
  handleContinuePress,
  repeatingTimeslots,
  timezone,
}) => {
  const weekday = moment(repeatingTimeslots[0].start).format('dddd');
  const timeOfDay = moment(repeatingTimeslots[0].start).format('h:mma');
  const year = moment(repeatingTimeslots[0].start).format('yyyy');
  const startDay = moment(repeatingTimeslots[0].start).format('MMM D');
  const endDay = moment(repeatingTimeslots[repeatingTimeslots.length - 1].start).format('MMM D');

  const header = `${weekday}s at ${timeOfDay}`;
  const subheader = `from ${startDay} – ${endDay} ${year}`;
  return (
    <View flex={1} align="center" justify="space-between">
      <View align="center">
        <AvatarRow therapistUserID={therapist.id} />
        <BookingTimeZone timezone={timezone} style={{ marginTop: 10, marginBottom: 10 }} />

        <TextWrapper align="center">
          <Standard>{header}</Standard>
          <Small>{subheader}</Small>
        </TextWrapper>

        {isBookingDeclinedByTherapist ? (
          <CancelActionCard
            Icon={ErrorXSmallIcon}
            text="Dismiss session"
            onPress={() => {}}
            isSelected
            dataQAText="Dismiss"
          />
        ) : (
          <>
            {room && isBookingConfirmed && isBookingInLessThan24hrs && (
              <Large
                variant="largeDarkGrey"
                style={{ width: 278, textAlign: 'center', marginBottom: 40 }}
              >
                {getLateCancellationText(room)}
              </Large>
            )}
            <TabRadioGroup legendText="Choose to reschedule or cancel this session.">
              <CancelActionCard
                Icon={ErrorXSmallIcon}
                text={canDeclineOrCancel === 'decline' ? 'Decline sessions' : 'Cancel sessions'}
                onPress={handleCancelPress}
                isSelected={selectedAction === 'cancel'}
                dataQAText="Cancel"
              />
            </TabRadioGroup>
          </>
        )}
      </View>
      <Button
        onPress={handleContinuePress}
        stretch
        text={isBookingDeclinedByTherapist ? 'Dismiss' : 'Continue'}
        dataQa="schedulerChangeBookingContinue"
        disabled={!checkedPendingInvoices}
        isLoading={!checkedPendingInvoices}
      />
    </View>
  );
};

export default withRouter(CancelBookingRecurring);
