import {
  useWindowWidthState,
  ResponsiveLayoutWithHeader,
  PanelHeader,
} from '@talkspace/react-toolkit';

import HelpScreen from './HelpScreen';
import { CloseModal } from '../../../utils/ModalsContextProvider';

export interface HelpScreenContainerProps {
  closeModal: CloseModal;
}

export const HelpScreenContainer = (props: HelpScreenContainerProps) => {
  const { closeModal } = props;
  const { isMobile } = useWindowWidthState();

  return isMobile ? (
    <ResponsiveLayoutWithHeader
      renderHeader={() => <PanelHeader onRightPress={closeModal} />}
      noHeaderLine
    >
      <HelpScreen />
    </ResponsiveLayoutWithHeader>
  ) : (
    <>
      <HelpScreen />
    </>
  );
};

export default HelpScreenContainer;
