import { FunctionComponent, useState } from 'react';
import {
  ExtraHuge,
  View,
  Large,
  useWindowWidthState,
  ComplianceBadges,
  FrequentlyAskedQuestions,
  FAQQuestion,
  StickyDrawerParentContainer,
} from '@talkspace/react-toolkit';

import {
  PaymentDetails,
  LineItem,
  PlanData,
  CouponState,
  SessionModality,
} from 'ts-frontend/types';
import styled from '@/core/styled';
import RowColumnToggle from '../RowColumnToggle';
import PlanReviewCard from '../PlanReviewCard';
import PaymentSummary from '../PaymentSummary';

const Header: FunctionComponent<{ title: string; subtitle?: string; titleId?: string }> = ({
  title,
  subtitle,
  titleId,
}) => (
  <>
    <ExtraHuge style={{ marginBottom: 13 }} as="h1" id={titleId}>
      {title}
    </ExtraHuge>
    {subtitle && (
      <Large as="h2" variant="largeDarkGrey" style={{ marginBottom: 40, fontWeight: 400 }}>
        {subtitle}
      </Large>
    )}
  </>
);

const LeftView = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    flexShrink: 1,
    width: isMobile ? undefined : 475,
  };
});

const ResponsivePadding = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return isMobile
    ? {
        paddingLeft: 14,
        paddingRight: 7,
      }
    : {};
});

interface Props {
  modality?: SessionModality;
  title?: string;
  subtitle?: string;
  titleId?: string;
  planReviewCardTitle: string;
  selectedSubscription: PlanData;
  selectedPlanSavings?: number;
  lineItems: LineItem[];
  total: number;
  savings: number;
  initialCouponCode?: string;
  paymentDetails?: PaymentDetails;
  therapistInfo?: {
    firstName: string;
    lastName: string;
    therapistID: number;
    therapistLicenses: string[];
    therapistImage: string;
  };
  hideProviderInfo?: boolean;
  isTrial?: boolean;
  isProcessing?: boolean;
  isCouponInputDisabled?: boolean;
  isNoMatches?: boolean;
  showCouponField?: boolean;
  buttonText: string;
  faqQuestions?: FAQQuestion[];
  showRenewalNotice?: boolean;
  scrollTop: number;
  showInsuranceDisclaimer?: boolean;
  couponState?: CouponState;
  onChangePayment: () => void;
  onCompleteCheckout: () => void;
  onSecondaryClick?: () => void;
  onApplyCoupon?: (couponCode: string) => void;
  onChangePlan?: () => void;
  onChangeTherapist?: () => void;
  onCouponChange?: () => void;
  onReviewCancellationPolicyClick?: () => void;
  secondaryCtaText?: string;
  totalDueText?: string;
  maxCostPostSession?: number;
  chargeInfoText?: string;
  chargeTimeText?: string;
  showSavings?: boolean;
  flowID?: number | null;
  isBackButtonClickedInMobile?: boolean;
  isOutOfNetwork?: boolean;
  plural?: boolean;
  isConfirmSession?: boolean;
  hideLineItems?: boolean;
  isInMobileFrame?: boolean;
}

const Checkout: FunctionComponent<Props> = ({
  modality,
  title,
  subtitle,
  titleId,
  planReviewCardTitle,
  selectedSubscription,
  selectedPlanSavings,
  lineItems,
  total,
  savings,
  initialCouponCode,
  paymentDetails,
  therapistInfo,
  hideProviderInfo = false,
  isTrial = false,
  isProcessing = false,
  isCouponInputDisabled = false,
  showCouponField = false,
  buttonText,
  faqQuestions = [],
  showRenewalNotice = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollTop,
  showInsuranceDisclaimer,
  couponState,
  isNoMatches = false,
  onApplyCoupon,
  onChangePayment,
  onCompleteCheckout,
  onSecondaryClick,
  onChangePlan,
  onChangeTherapist,
  onCouponChange,
  onReviewCancellationPolicyClick,
  secondaryCtaText,
  totalDueText,
  maxCostPostSession,
  chargeInfoText,
  chargeTimeText,
  showSavings,
  flowID,
  isBackButtonClickedInMobile,
  isOutOfNetwork,
  plural,
  isConfirmSession,
  hideLineItems = false,
  isInMobileFrame,
}) => {
  const { isMobile } = useWindowWidthState();
  const [hideFooter, setHideFooter] = useState(false);
  const { offerPrice } = selectedSubscription;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const totalSavings =
    savings +
    (selectedPlanSavings || 0) +
    (offerPrice && offerPrice.originalPrice ? offerPrice.originalPrice - offerPrice.amount : 0);
  const showFrequentlyAskedQuestions = faqQuestions.length > 0 && !isNoMatches;

  return (
    <StickyDrawerParentContainer>
      <RowColumnToggle justify="center" reverse>
        <LeftView>
          {!isMobile && title && <Header title={title} subtitle={subtitle} titleId={titleId} />}
          <PlanReviewCard
            modality={modality}
            title={planReviewCardTitle}
            initialCouponCode={initialCouponCode}
            onChangePayment={onChangePayment}
            onChangePlan={onChangePlan}
            plan={selectedSubscription}
            onChangeTherapist={onChangeTherapist}
            onApplyCoupon={onApplyCoupon}
            showCouponField={showCouponField}
            isCouponInputDisabled={isCouponInputDisabled}
            paymentDetails={paymentDetails}
            therapistDetails={therapistInfo}
            hideProviderInfo={hideProviderInfo}
            couponState={couponState}
            onCouponChange={onCouponChange}
            flowID={flowID}
            isBackButtonClickedInMobile={isBackButtonClickedInMobile}
            isOutOfNetwork={isOutOfNetwork}
            hideFooter={hideFooter}
            setHideFooter={isInMobileFrame ? setHideFooter : undefined}
          />
          {showFrequentlyAskedQuestions && (
            <FrequentlyAskedQuestions questions={faqQuestions} style={{ marginTop: 40 }} />
          )}
        </LeftView>
        <View>
          {isMobile && title && subtitle && (
            <ResponsivePadding>
              <Header title={title} subtitle={subtitle} titleId={titleId} />
            </ResponsivePadding>
          )}
          <View>
            <PaymentSummary
              hideFooter={hideFooter}
              lineItems={lineItems}
              total={total}
              savings={savings}
              billingPrice={selectedSubscription.billingPrice}
              onCompleteCheckout={onCompleteCheckout}
              onSecondaryClick={onSecondaryClick}
              secondaryCtaText={secondaryCtaText}
              isTrial={isTrial}
              isLoading={isProcessing}
              buttonText={buttonText}
              showRenewalNotice={showRenewalNotice}
              showInsuranceDisclaimer={showInsuranceDisclaimer}
              onReviewCancellationPolicyClick={onReviewCancellationPolicyClick}
              isNoMatches={isNoMatches}
              totalDueText={totalDueText}
              maxCostPostSession={maxCostPostSession}
              chargeInfoText={chargeInfoText}
              chargeTimeText={chargeTimeText}
              showSavings={showSavings}
              plural={plural}
              isConfirmSession={isConfirmSession}
              hideLineItems={hideLineItems}
            />
            <View
              style={{
                zIndex: -1,
              }}
            >
              <ComplianceBadges style={{ margin: 'auto', marginBottom: 25, marginTop: 25 }} />
            </View>
          </View>
        </View>
      </RowColumnToggle>
    </StickyDrawerParentContainer>
  );
};

export default Checkout;
