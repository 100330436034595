import { useWindowWidthState } from '@talkspace/react-toolkit';
import { LiveVideoSessionStatus, VideoCredit, Subscription, ChargeType } from 'ts-frontend/types';
import { getPsychiatryReminders, ReminderStatus } from 'ts-frontend/helpers/lvsReminderStatuses';
import { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useQueryBookings, useQueryClientTransactions } from 'inRoomScheduling';
import { ChatBannerType, GeneralNoticePayload } from 'chat/redux/constants/chatTypes';
import { useSharedChatState, useSharedChatActions } from 'chat/hooks/sharedChatContext';
import ChatBannerManager from 'chat/components/ChatBannerManager';
import Chat from 'chat';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { isPsychDischarged } from 'ts-frontend/helpers';
import { isDummyCTReactivationProvider } from 'ts-frontend/entities/Therapist';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { trackClickOnPendingInvoicesBanner } from 'ts-analytics/mixpanel/events';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useNewMemberNav } from 'launchDarkly/hooks';
import useClosedCaptionsReceivedSocket from 'chat/hooks/useClosedCaptionsReceivedSocket';
import { useNetworkProviderState } from 'ts-ionic/plugins/network';
import { useOpenModal } from '@/utils/ModalsContextProvider';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { useMainState } from '@/hooks/mainContext';
import UserSettingsSocketService from '../utils/UserSettingsSocketService';
import UserEventsSocketService from '../utils/UserEventsSocketService';
import { UserSettings } from '../utils/ApiHelper';
import useUserPrompts, { UserPrompt } from '../hooks/useUserPrompts';
import { useChatBannerState, useChatBannerActions } from '../hooks/chatBannerContext';
import {
  useRoomInviteState,
  getIsInviteRoomWithMissingPartnerAndNoInvites,
} from '../../roomInvites';
import { EmailVerificationStatus } from '../../entities/Me';
import { RouteComponentProps } from '../../core/routerLib';
import { getEmailVerificationLastDisplay, getUserData } from '../../auth/helpers/token';
import ApiHelper from '../../utils/ApiHelper';
import useEligibilityWarning from '../../EligibilityWarning/hooks/useEligibilityWarning';
import EligibilityWarningBanner from '../../EligibilityWarning/components/EligibilityWarningBanner';
import SocketService from '@/utils/socket';
import useNavShellWindowHeight from '../../hooks/useNavShellWindowHeight';

export type ReactivationKey = 'reactivationCheckDone' | 'reactivationPopupOpened';

const delayToPrompt = 10000;

type Props = RouteComponentProps<{
  roomID: number;
}>;

const getReactivationStatus = (key: ReactivationKey) =>
  JSON.parse(sessionStorage.getItem(key) || 'false');

const getPrompt = (userPrompts: UserPrompt[] | null, eventType: UserPrompt['eventType']) =>
  userPrompts?.find((userPrompt) => userPrompt.eventType === eventType);

const ClientChatContainer: FunctionComponent<Props> = (props) => {
  const { match, history } = props;
  const { roomID } = match.params;

  const openModal = useOpenModal();
  const { isMobile } = useWindowWidthState();
  const useNewNav = useNewMemberNav();
  const { data: bookings } = useQueryBookings({ roomID });
  const { roomsByID, clientsByRoomID, subscriptionsByID, therapistsByID } = useMainState();
  const subscription = subscriptionsByID[roomID] as Subscription | undefined;

  const [userSettings, setUserSettings] = useState<UserSettings>();
  const [selectedBanner, setSelectedBanner] = useState<ChatBannerType | null>(null);
  const [isFocusedAfterModal, setIsFocusedAfterModal] = useState(false);

  const { isOffline } = useNetworkProviderState();

  let userPromptsTimeoutID;
  const isSessionBased = roomsByID && roomsByID[roomID] && roomsByID[roomID].isSessionBased;

  useEffect(() => {
    const updateUserSettings = (data) => {
      setUserSettings(data);
    };

    UserSettingsSocketService.instance().addListener(updateUserSettings);
    return () => {
      UserSettingsSocketService.instance().removeListener(updateUserSettings);
    };
  }, []);

  const [state, { getUserPrompts }] = useUserPrompts();
  const {
    banners = [],
    reactivationPayload,
    awaitingSessionPayload,
    emailVerificationPayload: { email, pendingEmail, emailStatus },
    generalNoticePayload,
  } = useChatBannerState();

  const {
    dismissReactivationBanner,
    updateRoomExpirationDate,
    getClientReactivationAction,
    getClientAwaitingSessionAction,
    getEmailVerificationStatusAction,
    sendEmailVerificationAction,
    setEmailVerificationPayload,
    setCreditCardDeclined,
    setPartnerMissing,
    setPsychiatristReminder,
    setEligibilityWidget,
    get2FAClientAction,
    setPendingInvoices,
    setMatchWithNewProvider,
    setMatchWithSameProvider,
    setGeneralNotice,
  } = useChatBannerActions();
  const {
    roomExpirationDate: sharedExpirationDate,
    therapistFirstName,
    adminConfigs: { isEmailVerificationEnabled },
    roomStatus,
    sessionStatus,
    sharedBanners = [],
    therapistID,
    primaryClientID,
    hasSeenWelcomeBackInactivityTray,
    hasSeenWelcomeBackActivityTray,
  } = useSharedChatState();

  const { applyReactivationTray } = useSharedChatActions();

  const { id: currentUserID } = getUserData();

  const {
    pendingInvoicesChargeTypes,
    b2BMemberInactivity,
    postAsyncPromptExperiment,
    generalNoticeBanner,
  } = useFlags<{
    pendingInvoicesChargeTypes?: Array<ChargeType>;
    generalNoticeBanner?: GeneralNoticePayload;
  }>();

  const isClientTransactionsEnabled =
    !!pendingInvoicesChargeTypes &&
    pendingInvoicesChargeTypes.length > 0 &&
    !!currentUserID &&
    isSessionBased;
  useQueryClientTransactions(
    {
      chargeTypes: pendingInvoicesChargeTypes,
      clientUserID: currentUserID,
    },
    {
      enabled: isClientTransactionsEnabled,
      onSuccess: (pendingInvoices) => {
        setPendingInvoices(!!pendingInvoices && pendingInvoices.length > 0);
      },
    }
  );

  const disableMatchWithSameProviderBanner = useCallback(() => {
    applyReactivationTray && applyReactivationTray();
    setMatchWithSameProvider(false);
  }, [applyReactivationTray, setMatchWithSameProvider]);

  const { isInsuranceIneligible, isClosedUpdatedCoverage } = useEligibilityWarning({
    roomID: Number(roomID),
  });
  const { data: { shouldActivateDueToDischarge } = {} } = useQueryRoomDetails(match.params.roomID);
  useEffect(() => {
    const {
      subscription: { isB2B },
    } = subscription || {
      subscription: {},
    };

    const isInactivity = b2BMemberInactivity && roomStatus === RoomStatus.CLOSED && isB2B;

    setMatchWithNewProvider(
      !!(hasSeenWelcomeBackInactivityTray && (shouldActivateDueToDischarge || isInactivity))
    );
    setMatchWithSameProvider(
      !!(hasSeenWelcomeBackActivityTray && (shouldActivateDueToDischarge || isInactivity))
    );
  }, [
    b2BMemberInactivity,
    roomStatus,
    setMatchWithNewProvider,
    subscription,
    hasSeenWelcomeBackInactivityTray,
    hasSeenWelcomeBackActivityTray,
    setMatchWithSameProvider,
    shouldActivateDueToDischarge,
  ]);

  useEffect(() => {
    if (therapistFirstName) {
      setEligibilityWidget(isDummyCTReactivationProvider({ therapistName: therapistFirstName }));
    }
  }, [setEligibilityWidget, therapistFirstName]);

  const inviteStateByRoom = useRoomInviteState();
  const inviteState = inviteStateByRoom[roomID];

  const showPartnerMissingBanner = getIsInviteRoomWithMissingPartnerAndNoInvites(
    roomsByID[roomID],
    clientsByRoomID[roomID],
    inviteState?.roomInviteStatus
  );

  const room = roomsByID[roomID];
  const roomStatusByRoomID = room.status; // roomID and roomStatus are NOT synced and causes multiple renders
  const therapistFirstNameByTherapistID = therapistsByID[room.therapistID].firstName;

  const shouldPromptSwitchProvider = useCallback(() => {
    if (roomStatusByRoomID && therapistFirstNameByTherapistID) {
      return isPsychDischarged({
        status: roomStatusByRoomID,
        therapistFirstName: therapistFirstNameByTherapistID,
      });
    }
    return false;
  }, [roomStatusByRoomID, therapistFirstNameByTherapistID]);

  const openSwitchProviderModal = useCallback(() => {
    openModal(`/switch-provider/room/${roomID}`, { roomID }, true, false);
  }, [roomID, openModal]);

  useEffect(() => {
    if (shouldPromptSwitchProvider()) {
      openSwitchProviderModal();
    }
  }, [shouldPromptSwitchProvider, openSwitchProviderModal]);

  useClosedCaptionsReceivedSocket(SocketService.instance(), currentUserID);

  useEffect(() => {
    const shouldDismissReactivation = (data: { roomId: number }) => {
      if (selectedBanner === 'reactivation' && data.roomId === Number(roomID))
        dismissReactivationBanner();
    };

    const handleChangedEmailEvent = (data: {
      pendingEmail: string | null;
      emailVerificationStatus: EmailVerificationStatus;
    }) => {
      setEmailVerificationPayload({
        email,
        pendingEmail: data.pendingEmail || '',
        emailStatus: data.emailVerificationStatus,
      });
    };

    const userEventsSocketService = new UserEventsSocketService();
    userEventsSocketService.on('changedPayment', shouldDismissReactivation);
    userEventsSocketService.on('changedEmail', handleChangedEmailEvent);

    return () => {
      userEventsSocketService.off('changedPayment', shouldDismissReactivation);
      userEventsSocketService.off('changedEmail', handleChangedEmailEvent);
      userEventsSocketService.unmount(false);
    };
  }, [dismissReactivationBanner, setEmailVerificationPayload, roomID, email, selectedBanner]);

  useEffect(() => {
    const onClose = () => {
      setIsFocusedAfterModal(true);
    };

    const keepMessagingModal = () => {
      openModal('/keep-messaging', { roomID }, false, true, onClose);
    };
    document.addEventListener('keepMessagingModal', keepMessagingModal);
    return () => {
      document.removeEventListener('keepMessagingModal', keepMessagingModal);
    };
  }, [openModal, roomID, setIsFocusedAfterModal]);

  useEffect(() => {
    if (sharedExpirationDate) updateRoomExpirationDate(sharedExpirationDate);
  }, [sharedExpirationDate, updateRoomExpirationDate]);

  useEffect(() => {
    if (primaryClientID === currentUserID) {
      getClientReactivationAction({ roomID });
    }
  }, [roomID, getClientReactivationAction, primaryClientID, currentUserID]);

  useEffect(() => {
    getEmailVerificationStatusAction(Boolean(isEmailVerificationEnabled));
  }, [getEmailVerificationStatusAction, isEmailVerificationEnabled]);

  useEffect(() => {
    get2FAClientAction();
  }, [get2FAClientAction]);

  useEffect(() => {
    getClientAwaitingSessionAction({ roomID, isSessionBased });
  }, [isSessionBased, getClientAwaitingSessionAction, roomID]);

  useEffect(() => {
    setTimeout(() => {
      if (getReactivationStatus('reactivationCheckDone')) {
        getUserPrompts(roomID);
      }
    }, delayToPrompt);
  }, [getUserPrompts, roomID]);

  useEffect(() => {
    setPartnerMissing(Boolean(showPartnerMissingBanner));
  }, [setPartnerMissing, showPartnerMissingBanner]);

  useEffect(() => {
    if (roomStatus) setCreditCardDeclined(roomStatus);
  }, [roomStatus, roomID, setCreditCardDeclined]);

  // handles the psy banner to display or not
  useEffect(() => {
    if (
      subscription?.roomType === 'psychiatryRoom' &&
      subscription?.therapist?.type === 'psychiatrist' &&
      !!subscription?.liveVideoSessionStatus &&
      bookings?.length === 0
    ) {
      setPsychiatristReminder(true);
    } else {
      setPsychiatristReminder(false);
    }
  }, [bookings, setPsychiatristReminder, subscription]);

  useEffect(() => {
    setGeneralNotice(generalNoticeBanner);
  }, [generalNoticeBanner, setGeneralNotice]);

  const openPromptModal = ({
    path,
    isFullScreen,
    isTransparent,
  }: {
    path: string;
    isFullScreen: boolean;
    isTransparent: boolean;
  }) => {
    if (history.location.pathname.includes('modal')) {
      userPromptsTimeoutID = setTimeout(
        () => openPromptModal({ path, isFullScreen, isTransparent }),
        delayToPrompt
      );
    } else {
      if (userPromptsTimeoutID) clearTimeout(userPromptsTimeoutID);
      openModal(path, {}, isFullScreen, isTransparent);
    }
  };

  const shouldPromptEmailVerification = useCallback(() => {
    const emailVerificationDisplayedAt = new Date(Number(getEmailVerificationLastDisplay()));
    const weekAfterEmailDisplayed = new Date(emailVerificationDisplayedAt);
    weekAfterEmailDisplayed.setDate(weekAfterEmailDisplayed.getDate() + 7);

    const isEmailUnverified = emailStatus === 'unverified_existing';
    const weekPassed = new Date() > weekAfterEmailDisplayed;
    if (!weekPassed) {
      return false;
    }
    if (!isEmailUnverified) {
      return false;
    }
    if (!reactivationPayload) {
      return true;
    }
    const { shouldReactivate } = reactivationPayload;
    if (!shouldReactivate || getReactivationStatus('reactivationPopupOpened')) {
      return true;
    }
    return false;
  }, [emailStatus, reactivationPayload]);

  const openEmailVerificationModal = useCallback(
    ({ onClickTriggered = false }) => {
      const targetEmail = pendingEmail || email;
      if (onClickTriggered) {
        sendEmailVerificationAction(targetEmail);
      }
      openModal(`/email-verification/sent`, {
        email: targetEmail,
        roomID,
      });
    },
    [email, pendingEmail, roomID, sendEmailVerificationAction, openModal]
  );

  useEffect(() => {
    const promptTimeout = setTimeout(() => {
      if (shouldPromptEmailVerification()) {
        openEmailVerificationModal({ onClickTriggered: false });
      }
    }, delayToPrompt);
    return () => {
      if (promptTimeout) {
        clearTimeout(promptTimeout);
      }
    };
  }, [shouldPromptEmailVerification, openEmailVerificationModal]);

  // Prompt check in pop up / NPS survey pop up
  useEffect(() => {
    // Prioritize PostAsync over NPS, NPS over CheckInWizard
    const postAsync = getPrompt(state.userPrompts, 'post_async_prompt');
    const nps = getPrompt(state.userPrompts, 'nps');
    const checkIn = getPrompt(state.userPrompts, 'therapist_check_in');

    if (postAsync && state.roomID === roomID) {
      // Mark prompt as done on backend. NOTE: backend creates prompt for control variant as well, this is used to send experiment session event only once
      new ApiHelper().updateUserPromptToDone(roomID, postAsync.id);

      // NOTE: experimentActive is always true if prompt exists, added for extra safety
      if (postAsyncPromptExperiment.experimentActive) {
        // send experiment session event for all variants
        trackEvent('TS Experiment Session', {
          experimentName: 'post-async-prompt',
          variantName: postAsyncPromptExperiment.variant,
        });

        // if treatment variant, open prompt
        if (postAsyncPromptExperiment.variant === 'treatment') {
          openPromptModal({
            path: `/post-async-prompt/room/${roomID}/source/room/user-prompt/${postAsync.id}`,
            isFullScreen: true,
            isTransparent: false,
          });
        }
      }
    } else if (nps && state.roomID === roomID) {
      openPromptModal({
        path: `/nps/room/${roomID}/source/room/user-prompt/${nps.id}`,
        isFullScreen: isMobile,
        isTransparent: !isMobile,
      });
    } else if (checkIn && state.roomID === roomID) {
      openPromptModal({
        path: `/check-in/room/${roomID}/source/room/check-in-source/check-in/user-prompt/${checkIn.id}`,
        isFullScreen: isMobile,
        isTransparent: !isMobile,
      });
    }
  }, [history, state.userPrompts]); // eslint-disable-line react-hooks/exhaustive-deps

  const onModalPress = useCallback(
    (bannerType: ChatBannerType) => {
      setSelectedBanner(bannerType);
      switch (bannerType) {
        case 'reactivation': {
          if (reactivationPayload && reactivationPayload.reactivationURL)
            openModal(reactivationPayload.reactivationURL);
          else openModal(`/room-reactivation/${roomID}`);
          break;
        }
        case 'emailVerification': {
          openEmailVerificationModal({ onClickTriggered: true });
          break;
        }
        case 'enable2fa': {
          history.push('/2fa/reminder');
          break;
        }
        case 'awaitingSession': {
          openModal(`/insurance-eligibility/room/${roomID}/checkout`);
          break;
        }
        case 'partnerMissing': {
          history.push({
            pathname: `/room/${roomID}/invite-partner`,
            state: {
              from: history.location.pathname,
            },
          });
          break;
        }
        case 'eligiblityWidget': {
          history.push(`/eligibility-widget?source=ctReactivation`);
          break;
        }
        case 'creditCardDeclined': {
          history.push(`/room/${roomID}/my-account/manage-subscription`);
          break;
        }
        case 'introLVSReminder': {
          history.push(
            `/in-room-scheduling/room/${roomID}/select-timeslot?creditID=introduction10`
          );
          break;
        }
        case 'sessionStatus': {
          history.push(`/in-room-scheduling/room/${roomID}`);
          break;
        }
        case 'pendingInvoices': {
          trackClickOnPendingInvoicesBanner({
            userID: currentUserID,
            roomID,
            providerID: room.therapistID,
            planID: room.planID,
          });
          history.push(
            `/in-room-scheduling/room/${roomID}/pending-invoices?source=pendingInvoicesBanner`
          );
          break;
        }
        case 'matchWithNewProvider': {
          history.push(`/switch-provider/room/${roomID}`, { source: 'b2bInactivity' });
          break;
        }
        case 'matchWithSameProvider': {
          disableMatchWithSameProviderBanner();
          break;
        }
      }
    },
    [
      reactivationPayload,
      openModal,
      roomID,
      openEmailVerificationModal,
      history,
      currentUserID,
      room.therapistID,
      room.planID,
      disableMatchWithSameProviderBanner,
    ]
  );
  const { status } = getPsychiatryReminders({
    ...(subscription?.liveVideoSessionStatus as LiveVideoSessionStatus),
    videoCredits: subscription?.videoCredits as VideoCredit[],
  });

  useEffect(() => {
    if (
      ![
        ReminderStatus.NEVER_SCHEDULE_INITIAL_EVALUATION,
        ReminderStatus.NEVER_SCHEDULE_FOLLOW_UP,
        ReminderStatus.NEVER_SCHEDULE_FOLLOW_UP_LONGTIME,
      ].includes(status)
    )
      setPsychiatristReminder(false);
  }, [setPsychiatristReminder, status]);

  const getPsychiatristBanner = () => {
    const handleBooking = () =>
      history.push({
        pathname: `/in-room-scheduling/room/${match.params.roomID}`,
        state: {
          from: history.location.pathname,
        },
      });
    switch (status) {
      case ReminderStatus.NEVER_SCHEDULE_INITIAL_EVALUATION:
        return {
          boldTitle: true,
          onPress: handleBooking,
          title: 'Schedule your initial evaluation ',
          subtitle: 'Get started with your psychiatric care',
        };
      case ReminderStatus.NEVER_SCHEDULE_FOLLOW_UP:
        return {
          boldTitle: true,
          onPress: handleBooking,
          title: 'Schedule your next follow up',
          subtitle: 'We recommend a follow up every 2-3 months',
        };
      case ReminderStatus.NEVER_SCHEDULE_FOLLOW_UP_LONGTIME:
        return {
          boldTitle: true,
          onPress: handleBooking,
          title: 'You’re due for a follow up session',
          subtitle: 'Schedule a follow up to refill your prescription',
        };
      default:
        return undefined;
    }
  };

  const reminderStatus = getPsychiatristBanner();

  const buildBanners = () => {
    let filteredBanners = [...banners, ...sharedBanners];
    if (filteredBanners.includes('generalNotice')) {
      filteredBanners = ['generalNotice'];
    } else if (sharedBanners.includes('sessionStatus')) {
      filteredBanners = filteredBanners.filter((banner) => banner !== 'awaitingSession');
    }

    return filteredBanners.map((bannerType) => (
      <ChatBannerManager
        key={bannerType}
        onPress={() => onModalPress(bannerType)}
        therapistFirstName={therapistFirstName}
        banner={bannerType as ChatBannerType}
        reactivationProps={reactivationPayload}
        awaitingSessionProps={awaitingSessionPayload}
        psychiatristReminderProps={reminderStatus}
        sessionStatusProps={sessionStatus}
        generalNoticeProps={generalNoticePayload}
        therapistID={therapistID}
        roomID={roomID}
      />
    ));
  };

  const getPriorityBanner = useCallback(
    (): JSX.Element | undefined =>
      isInsuranceIneligible ? (
        <EligibilityWarningBanner
          roomID={roomID}
          isClosedUpdatedCoverage={isClosedUpdatedCoverage}
        />
      ) : undefined,
    [isInsuranceIneligible, roomID, isClosedUpdatedCoverage]
  );

  const { outsideHeaderHeight = 0, outsideFooterHeight = 0 } = useNavShellWindowHeight();

  return (
    <>
      <Chat
        {...props}
        isTherapistChat={false}
        isAcking={userSettings && userSettings.sendMessagesReceipts}
        shouldPlaySoundNotifications={userSettings && userSettings.soundNotification}
        banners={banners.length > 0 || sharedBanners.length > 0 ? buildBanners() : undefined}
        priorityBanner={getPriorityBanner()}
        isFocusedAfterModal={isFocusedAfterModal}
        stopInputAutofocus={useNewNav}
        isOffline={isOffline}
        outsideHeaderHeight={outsideHeaderHeight}
        outsideFooterHeight={outsideFooterHeight}
        isVideoCallOutsideChat
      />
    </>
  );
};

export default ClientChatContainer;
