import { View, spacing, useWindowWidthState } from '@talkspace/react-toolkit';
import {
  ArrowRotateRight,
  CalendarCheck,
  Check,
  XMarkLarge,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import { Booking } from 'ts-frontend/types';
import { useTranslation } from '@talkspace/i18n';
import {
  isBookingActive,
  isBookingUpcoming,
  isBookingConfirmed,
  isBookingTentative,
  isBookingScheduledByProvider,
  isBookingScheduledByClient,
} from '../../utils/booking';
import { useHistory } from '@/core/routerLib';
import BookingsListControlButton from './BookingsListControlButton';
import { UseNavShellData } from '../../hooks/useNavShellData';
import { trackCTAClick } from '../../utils/analytics/events';

import { useOpenModal } from '@/utils/ModalsContextProvider';

const { space150 } = spacing;

const BookingsListControlButtons = ({
  activeSessionData,
  booking,
}: Pick<UseNavShellData, 'activeSessionData'> & { booking: Booking }) => {
  const { id: bookingID, roomID } = booking;
  const history = useHistory();
  const { isMobile } = useWindowWidthState();
  const openModal = useOpenModal();
  const wrapperStyle = {
    width: 174,
    marginTop: isMobile ? space150 : undefined,
    marginLeft: isMobile ? -1 * space150 : undefined,
    alignItems: 'flex-start',
  };
  const baseSchedulingURL = `/in-room-scheduling/room/${roomID}`;
  const { t: tnewMemberNav } = useTranslation('newMemberNav');

  if (isBookingActive(booking, activeSessionData)) return null;

  if (isBookingTentative(booking) && isBookingScheduledByProvider(booking)) {
    return (
      <View style={wrapperStyle}>
        <BookingsListControlButton
          dataQa="confirmBookingsListButton"
          Icon={Check}
          text={tnewMemberNav('schedule.confirm', 'Confirm', undefined)}
          onPress={() => history.push(`${baseSchedulingURL}/confirm-booking/booking/${bookingID}`)}
        />
        <BookingsListControlButton
          dataQa="declineBookingsListButton"
          Icon={XMarkLarge}
          text={tnewMemberNav('schedule.decline', 'Decline', undefined)}
          onPress={() => history.push(`${baseSchedulingURL}/decline-booking/booking/${bookingID}`)}
        />
      </View>
    );
  }
  if (
    (isBookingConfirmed(booking) && isBookingUpcoming(booking)) ||
    (isBookingTentative(booking) && isBookingScheduledByClient(booking))
  ) {
    return (
      <View style={wrapperStyle}>
        <BookingsListControlButton
          dataQa="addToCalendarBookingsListButton"
          Icon={CalendarCheck}
          text={tnewMemberNav('schedule.add', 'Add to calendar', undefined)}
          onPress={() => {
            trackCTAClick('Add to calendar', 'schedule', { roomID });
            openModal(`${baseSchedulingURL}/add-to-calendar?bookingID=${bookingID}`);
          }}
        />
        <BookingsListControlButton
          dataQa="rescheduleBookingsListButton"
          Icon={ArrowRotateRight}
          text={tnewMemberNav('schedule.reschedule', 'Reschedule', undefined)}
          onPress={() => {
            trackCTAClick('Reschedule', 'schedule', { roomID });
            openModal(`${baseSchedulingURL}/select-timeslot`, {
              isReschedule: true,
              selectedBookingID: bookingID,
            });
          }}
        />
        <BookingsListControlButton
          dataQa="cancelBookingsListButton"
          Icon={XMarkLarge}
          text={tnewMemberNav('schedule.cancel', 'Cancel', undefined)}
          onPress={() => {
            trackCTAClick('Cancel', 'schedule', { roomID });
            openModal(`/room/${roomID}/session-details/${bookingID}/cancel-session`);
          }}
        />
      </View>
    );
  }
  return null;
};

export default BookingsListControlButtons;
