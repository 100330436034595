import { SpacingView, Col, BannerCard } from '@talkspace/react-toolkit';
import { ShieldPlus } from '@talkspace/react-toolkit/src/designSystems/icons';
import useQueryFeatureViews from 'ts-frontend/hooks/featureViews/useQueryFeatureViews';
import useMutationFeatureViews from 'ts-frontend/hooks/featureViews/useMutationFeatureViews';
import { useTranslation } from '@talkspace/i18n';
import useNavShellData from '@/hooks/useNavShellData';

const SessionsLeftBanner = ({ userID, isMobile }) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');

  const {
    queries: { isLoadingRoomsList },
    dataByRoomID,
  } = useNavShellData();

  const { data: featureViewsData } = useQueryFeatureViews(userID);

  const { mutate: markFeatureViewed } = useMutationFeatureViews();

  const markModalViewed = () => {
    markFeatureViewed({ featureName: 'fewEapSessionsLeft', userID });
  };

  if (isLoadingRoomsList || !dataByRoomID) {
    return null;
  }

  const eapRoomWithFewCredits = Object.keys(dataByRoomID)
    .map((roomID) => dataByRoomID[roomID])
    .find((room) => room.subscription?.subscription?.fewEapSessionsLeft);

  if (!eapRoomWithFewCredits) {
    return null;
  }

  const promptBanner =
    !!eapRoomWithFewCredits && !!featureViewsData && !featureViewsData.fewEapSessionsLeft;

  const sessionsLeft = eapRoomWithFewCredits.subscription?.subscription?.remainingSessions || 0;
  const therapistName = eapRoomWithFewCredits.therapistInfo?.firstName || '-';

  const title = `${sessionsLeft} ${tnewMemberNav(
    sessionsLeft === 1 ? 'home.oneEapSessionTitle' : 'home.fewEapSessionTitle',
    'sessions left with',
    undefined
  )} ${therapistName}`;

  return (
    <>
      {promptBanner && (
        <Col sm={8} lg={8}>
          <SpacingView itemSpacing="space200">
            <BannerCard
              title={title}
              subtitle={tnewMemberNav(
                'home.fewEapSessionSubtitle',
                `Once completed, we'll help you check your coverage options such as insurance`,
                undefined
              )}
              variant="info"
              size="large"
              secondaryButtonAction={() => {
                markModalViewed();
              }}
              secondaryButtonTitle="Dismiss"
              isMobile={isMobile}
              dataQa="fewEapSessionsLeftBanner"
              secondaryButtonDataQa="dismissButton"
              fullWidth
              icon={<ShieldPlus size="major" colorType="info" variant="filled" />}
            />
          </SpacingView>
        </Col>
      )}
    </>
  );
};

export default SessionsLeftBanner;
