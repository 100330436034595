import { WizardScreen, useWizardActions, useWizardState } from 'stepWizard';
import { Screen, useWindowWidthState } from '@talkspace/react-toolkit';
import { Route, Switch } from '../core/routerLib';
import UnderAge from './containers/underAge';
import SubmitMatchingIntake from './containers/submitMatchingIntake';
import matchingIntakeScheme from './matchingIntakeScheme';
import matchingIntakePsychScheme from './matchingIntakePsychScheme';

const Routes = () => {
  const { onExitClick } = useWizardActions();
  const { isMobile } = useWindowWidthState();
  const { roomType } = useWizardState();
  return (
    <Switch>
      <Screen onCloseButtonClick={onExitClick} title={isMobile ? 'Matching intake' : ''}>
        <Route exact path="/matching-intake/submit" component={SubmitMatchingIntake} />
        <Route exact path="/matching-intake/under13" render={() => <UnderAge age="13" />} />
        <Route exact path="/matching-intake/under18" render={() => <UnderAge age="18" />} />
        <Route
          exact
          path="/matching-intake/room/:roomID/source/:source"
          render={() => {
            if (roomType === 'psychiatry_room')
              return <WizardScreen wizardScheme={matchingIntakePsychScheme} />;
            return <WizardScreen wizardScheme={matchingIntakeScheme} />;
          }}
        />
      </Screen>
    </Switch>
  );
};

export default Routes;
