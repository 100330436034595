import { useState, useEffect, useCallback } from 'react';
import {
  View,
  Button,
  BaseButton,
  Standard,
  Big,
  Large,
  VerificationCodeInput,
  useEmotionTheme,
  Spinner,
} from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { Trans, useTranslation } from '@talkspace/i18n';
import useSend2FARequestWithTimeout from '@/TwoFactorAuthentication/hooks/useSend2FARequestWithTimeout';
import styled from '../../../core/styled/styled';
import { VERIFICATION_CODE_LENGTH } from '../../../TwoFactorAuthentication/utils/helpers';

const spinnerStyles = {
  width: 68,
  height: 68,
  margin: 'auto',
};

const Container = styled(View)({ width: 336, flex: 1 });

const ContinueButton = styled(Button)({ marginTop: 30, width: '100%' });

const Separator = styled(Large)(({ theme: { colors } }) => {
  return {
    color: colors.altoGrey,
    fontSize: 20,
    fontWeight: 100,
  };
});

interface Props {
  currentPhoneNumber: string;
  pendingPhoneNumber?: string;
  verificationCodeError: boolean;
  twoFATokenExpiredError: string;
  isLoading: boolean;
  isResendOTPLoading: boolean;
  isVerifyCodeLoading: boolean;
  verifyCode: (code: number) => void;
  clearVerificationCodeError: () => void;
  resendVerificationCode: () => void;
  handleBackToChangePhoneNumber: () => void;
  clearErrorsAndOTP: () => void;
  verifyNumber: () => void;
}

const VerifyPhoneModal: React.VFC<Props> = ({
  currentPhoneNumber,
  pendingPhoneNumber,
  verificationCodeError,
  twoFATokenExpiredError,
  isResendOTPLoading,
  isVerifyCodeLoading,
  clearVerificationCodeError,
  resendVerificationCode,
  handleBackToChangePhoneNumber,
  verifyCode,
  clearErrorsAndOTP,
  verifyNumber,
}) => {
  const { colors, colorRoles } = useEmotionTheme();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const isLoading = isResendOTPLoading || isVerifyCodeLoading;
  const isError = verificationCodeError || !!twoFATokenExpiredError;
  const useNewNav = useNewMemberNav();

  useEffect(() => {
    verifyNumber();
  }, [verifyNumber]);

  useEffect(() => () => clearErrorsAndOTP(), [clearErrorsAndOTP]);

  const handleOnChangeVerificationCode = (code: string) => setVerificationCode(code);

  const handleContinue2faPress = useCallback(() => {
    if (!isLoading && !isResendOTPLoading && !isVerifyCodeLoading) {
      verifyCode(Number(verificationCode));
    }
  }, [isLoading, isResendOTPLoading, verifyCode, verificationCode, isVerifyCodeLoading]);

  useSend2FARequestWithTimeout(isError, isLoading, verificationCode, handleContinue2faPress);

  const handleResendVerificationCode = () => {
    clearVerificationCodeError();
    resendVerificationCode();
  };

  const last2Digits = pendingPhoneNumber
    ? pendingPhoneNumber.slice(-2)
    : currentPhoneNumber.slice(-2);

  const { t: tTwoFactor } = useTranslation('twoFactor');

  return (
    <Container>
      {verificationCodeError && (
        <Large
          aria-live="polite"
          variant="largeDarkGrey"
          style={{ color: colors.red, fontWeight: 500 }}
        >
          {tTwoFactor('enterCode.invalid', 'Invalid verification code', undefined)}
        </Large>
      )}
      {twoFATokenExpiredError && (
        <Large
          aria-live="polite"
          variant="largeDarkGrey"
          style={{ color: colors.red, fontWeight: 500, marginBottom: 20 }}
        >
          {twoFATokenExpiredError}
        </Large>
      )}
      {twoFATokenExpiredError ? (
        <Spinner style={spinnerStyles} containerStyle={spinnerStyles} />
      ) : (
        <>
          <Trans t={tTwoFactor} key="enterCode.title">
            <Large variant="largeDarkGrey" style={{ textAlign: 'center' }}>
              Please enter the 6-digit verification code sent to your phone number ending in{' '}
              {last2Digits}.
            </Large>
          </Trans>
          <VerificationCodeInput
            inputLength={VERIFICATION_CODE_LENGTH}
            isError={verificationCodeError}
            style={{ marginTop: 30 }}
            onChange={handleOnChangeVerificationCode}
            clearVerificationCodeError={clearVerificationCodeError}
            onSubmit={handleContinue2faPress}
            clearVerificationCode={isResendOTPLoading || verificationCodeError}
            autoFocus
            isDisabled={isLoading && verificationCode.length === VERIFICATION_CODE_LENGTH}
          />
          <ContinueButton
            isLoading={isResendOTPLoading || isVerifyCodeLoading}
            data-qa="myAccountChange2faContinue2faButton"
            onPress={handleContinue2faPress}
            disabled={verificationCode.length < 6 || isLoading}
            primaryColor={useNewNav ? colorRoles.button.brandPrimarySurfaceDefault : colors.green}
            roundedFocusStyle
          >
            <Big variant="bigWide">{tTwoFactor('enterCode.continue', 'Continue', undefined)}</Big>
          </ContinueButton>
          <Large variant="largeDarkGrey" style={{ marginTop: 20 }}>
            {tTwoFactor('enterCode.receive', "Didn't receive a verification code?", undefined)}
          </Large>
          <View row justify="center" style={{ columnGap: 9, alignItems: 'center' }}>
            <BaseButton
              data-qa="myAccountChange2faResendCodeButton"
              onPress={handleResendVerificationCode}
              style={{ height: 23, borderRadius: 5, border: 'none' }}
              primaryColor={colors.green}
              roundedFocusStyle
            >
              <Standard variant="standardBoldGreen">
                {tTwoFactor('enterCode.resend', 'Resend code', undefined)}e
              </Standard>
            </BaseButton>
            <Separator>|</Separator>
            <BaseButton
              data-qa="myAccountChange2faChangePhoneButton"
              onPress={handleBackToChangePhoneNumber}
              style={{ height: 23, borderRadius: 5, border: 'none' }}
              primaryColor={colors.green}
              roundedFocusStyle
            >
              <Standard variant="standardBoldGreen">
                {tTwoFactor('enterCode.change', 'Change phone number', undefined)}
              </Standard>
            </BaseButton>
          </View>
        </>
      )}
    </Container>
  );
};

export default VerifyPhoneModal;
