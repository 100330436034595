import { FunctionComponent } from 'react';
import {
  View,
  Big,
  Large,
  BaseButton,
  useEmotionTheme,
  BookingAvatars,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';
import { TherapistInfo } from '../../types';
import InRoomSchedulingError from '../inRoomSchedulingError';
import apiHelper from '@/core/api/apiHelper';

const SuccessTitleText = styled(Big)({
  width: 284,
  marginTop: 22,
  textAlign: 'center',
});

const ConfirmCancel: FunctionComponent<{
  onClosePress: () => void;
  therapist?: TherapistInfo;
}> = ({ onClosePress, therapist }) => {
  const { shouldShowBookingSuccess, isError } = useInRoomSchedulingState();
  const { colors } = useEmotionTheme();
  if (!therapist) {
    return null;
  }

  if (isError) {
    return <InRoomSchedulingError onClosePress={onClosePress} />;
  }

  return (
    <View flex={1} align="center" justify="space-between">
      <View align="center">
        <BookingAvatars
          therapistUserID={therapist.id}
          shouldShowBookingSuccess={shouldShowBookingSuccess}
          cdnBaseUrl={apiHelper().cdnEndpoint}
        />
        <SuccessTitleText style={{ marginBottom: 55 }}>
          Your session has been cancelled
        </SuccessTitleText>
        <BaseButton onPress={onClosePress}>
          <Large variant="largeBoldWide" style={{ color: colors.greenText }}>
            Done
          </Large>
        </BaseButton>
      </View>
    </View>
  );
};

export default ConfirmCancel;
