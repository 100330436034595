import { useEffect, useState } from 'react';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { QMSessionTypeEnum } from 'ts-frontend/types/QuickMatch';
import { getUserData } from '@/auth/helpers/token';
import ApiHelper from '../../utils/ApiHelper';
import { ERoom } from '../../entities/Room';
import { UserAuthProvider } from '../../entities/Me';
import { useLocation } from '../../core/routerLib';
import { getSessionType } from '../eligibilityWidgetUtils';

type Source = QMSessionTypeEnum;

const providerFlowMap = {
  [UserAuthProvider.AETNA]: '/flow/44',
};
const DISPATCHER_PATH = '/dispatcher';

const getUserEligibilityWidgetData = async (userID?: number, updateCoverageRoomID?: number) => {
  const api = new ApiHelper();

  if (!userID) {
    return { qmFlowPath: DISPATCHER_PATH, hasRooms: false };
  }

  const [
    { data: rooms, included },
    {
      data: { authProvider },
    },
  ] = await Promise.all([api.getAllRooms(userID), api.getClient(userID)]);
  let providerInfo;
  if (updateCoverageRoomID) {
    const room = rooms.find((record: ERoom) => record.roomID === updateCoverageRoomID);
    providerInfo = included.therapistInfo.find(
      (record: ETherapistInfo) => record.id === room?.therapistID
    );
  }
  const hasRooms = rooms.length > 0;

  if (!hasRooms && authProvider && providerFlowMap[authProvider]) {
    return {
      qmFlowPath: providerFlowMap[authProvider],
      hasRooms,
      providerInfo,
    };
  }

  return {
    qmFlowPath: DISPATCHER_PATH,
    hasRooms,
    providerInfo,
  };
};

interface GetQMSearchParams {
  searchParams: URLSearchParams;
  hasRooms: boolean;
  manualFlowNumber?: number | null;
  updateCoverageRoomID?: number | null;
}

const getQMSearch = ({
  searchParams,
  hasRooms,
  manualFlowNumber,
  updateCoverageRoomID,
}: GetQMSearchParams): string => {
  const searchCoupon = searchParams.get('coupon');
  const source: Source = getSessionType(searchParams.get('source'));

  const keepProvider = searchParams.get('keepProvider');
  const skipCapacityCheck = searchParams.get('skipCapacityCheck');
  const serviceType = searchParams.get('serviceType');
  const previousTherapistId = searchParams.get('previousTherapistId') || '';
  const fromPage = searchParams.get('fromPage') || '';

  const newSearch = {
    source,
    ...(fromPage ? { fromPage } : {}),
    ...(keepProvider ? { keepProvider } : {}),
    ...(skipCapacityCheck ? { skipCapacityCheck } : {}),
    ...(updateCoverageRoomID ? { updateCoverageRoomID: `${updateCoverageRoomID}` } : {}),
    ...(serviceType && { serviceType, skipServiceSelection: 'true' }),
    previousTherapistId,
  };
  if (hasRooms && manualFlowNumber && searchCoupon) {
    return new URLSearchParams({
      ...newSearch,
      cc: searchCoupon,
    }).toString();
  }
  return new URLSearchParams(newSearch).toString();
};

interface GetManualFlowParams {
  searchParams: URLSearchParams;
  qmFlow?: number;
  hasRooms: boolean;
}

const getManualFlow = ({
  searchParams,
  qmFlow,
  hasRooms,
}: GetManualFlowParams): number | null | undefined => {
  if (!hasRooms) return null;
  if (qmFlow) return qmFlow;
  const searchQMFlow = searchParams.get('qm-flow');
  if (searchQMFlow && !Number.isNaN(searchQMFlow)) return +searchQMFlow;
  return null;
};

interface EligibilityWidgetState {
  loading: boolean;
  qmFlowPath: string;
  showClose: boolean;
  autoStart: boolean;
  providerInfo?: ETherapistInfo | null;
}

const useEligibilityWidgetState = (qmFlow: number | undefined, updateCoverageRoomID?: number) => {
  const location = useLocation();
  const { search } = location;
  const { id: userID } = getUserData();
  const [eligibilityWidgetState, setEligibilityWidgetState] = useState<EligibilityWidgetState>({
    loading: true,
    qmFlowPath: DISPATCHER_PATH,
    showClose: false,
    autoStart: false,
    providerInfo: undefined,
  });

  useEffect(() => {
    (async () => {
      const {
        qmFlowPath: userQMFlow,
        hasRooms,
        providerInfo,
      } = await getUserEligibilityWidgetData(userID, updateCoverageRoomID);
      const searchParams = new URLSearchParams(search);
      const manualFlowNumber = getManualFlow({ searchParams, qmFlow, hasRooms });
      const serviceType = searchParams.get('serviceType');

      const autoStart = Boolean(!hasRooms || manualFlowNumber || serviceType);
      const showClose = !!hasRooms;

      const qmPath = manualFlowNumber ? `/flow/${manualFlowNumber}` : userQMFlow;
      const qmSearch = getQMSearch({
        searchParams,
        hasRooms,
        manualFlowNumber,
        updateCoverageRoomID,
      });

      setEligibilityWidgetState({
        qmFlowPath: `${qmPath}?${qmSearch}`,
        showClose,
        autoStart,
        loading: false,
        providerInfo,
      });
    })();
  }, [userID, search, qmFlow, updateCoverageRoomID]);

  return eligibilityWidgetState;
};

export default useEligibilityWidgetState;
