import { FunctionComponent, useState, useEffect } from 'react';
import { useEmotionTheme, useWindowWidthState } from '@talkspace/react-toolkit';
import { isStripeUsingLink, useStripeTs } from 'stripe/stripeContext';
import PausePlanModal from '../PausedPlanModal';
import SuccessModal from '../SuccessModal';
import ErrorModal from '../ErrorModal';
import BaseModal from '../../../components/BaseModal';
import UpdatePaymentDetailsModalWithLink from '../UpdatePaymentDetailsModalWithLink';
import UpdatePaymentDetailsModal from '../UpdatePaymentDetailsModal';
import { ModalType } from '../../reducers/subscriptions';

interface Props {
  isLoading: boolean;
  error: string;
  openModalType?: ModalType;
  successModalText: string;
  userEmail: string;
  closeModal: () => void;
  pausePlan: () => void;
  updatePaymentDetails: (cardToken: string) => void;
  onLoadStripeLink: () => void;
  isLinkLoaded: boolean;
  dispatchPaymentMethodError: (error: any) => void;
}

const unCamelCaseIntoTitle = (openModalType: string) => openModalType.split(/(?=[A-Z])/).join(' ');

const ManageSubscriptionModal: FunctionComponent<Props> = ({
  isLoading,
  error,
  openModalType,
  successModalText,
  userEmail,
  closeModal,
  pausePlan,
  updatePaymentDetails,
  onLoadStripeLink,
  isLinkLoaded,
  dispatchPaymentMethodError,
}) => {
  const [titleText, setTitleText] = useState('');
  const [useStripeLink, setUseStripeLink] = useState(true);
  const [isSuccessOrError, setIsSuccessOrError] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const stripe = useStripeTs();

  const { colors } = useEmotionTheme();

  const { isMobile } = useWindowWidthState();

  useEffect(() => {
    if (successModalText) {
      setTitleText(successModalText);
      setIsSuccessOrError(true);
    } else if (error) {
      setTitleText(error);
      setIsSuccessOrError(true);
    } else if (openModalType) {
      setTitleText(unCamelCaseIntoTitle(openModalType));
    }

    setUseStripeLink(isStripeUsingLink(stripe));
  }, [successModalText, error, openModalType, stripe]);

  function renderModalContent() {
    if (successModalText) {
      return (
        <SuccessModal
          message={successModalText}
          onCancelPress={closeModal}
          setIsCancel={setIsCancel}
        />
      );
    }

    if (error) {
      return <ErrorModal error={error} onCancelPress={closeModal} setIsCancel={setIsCancel} />;
    }

    switch (openModalType) {
      case 'pausePlan':
        return <PausePlanModal onCancelPress={closeModal} pausePlan={pausePlan} />;
      case 'updatePaymentDetails':
        if (useStripeLink) {
          return (
            <UpdatePaymentDetailsModalWithLink
              isEditMode
              email={userEmail}
              updatePaymentDetails={updatePaymentDetails}
              onLoadStripeLink={onLoadStripeLink}
              isLinkLoaded={isLinkLoaded}
              dispatchPaymentMethodError={dispatchPaymentMethodError}
              onCancelPress={closeModal}
            />
          );
        }
        return (
          <UpdatePaymentDetailsModal
            isEditMode
            email={userEmail}
            updatePaymentDetails={updatePaymentDetails}
            onCancelPress={closeModal}
          />
        );

      default:
        return null;
    }
  }

  return (
    <BaseModal
      isLoading={isLoading}
      onClosePress={closeModal}
      titleText={titleText}
      focusMainOnClose={isSuccessOrError || isCancel}
      isCancel={isCancel}
      underlayStyle={isMobile ? { backgroundColor: colors.white } : {}}
    >
      {renderModalContent()}
    </BaseModal>
  );
};

export default ManageSubscriptionModal;
