/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: @Eric remove above line
import { useEffect } from 'react';
import { Trans } from '@talkspace/i18n';
import { TFKeysReactToolkit } from '@talkspace/i18n/types';

import { useFormContext } from 'react-hook-form';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import View from '../View';
import Text from '../Text';
import Input from '../Input';
import { Tiny } from '../Typography';
import PasswordEye from '../Svgs/PasswordEye';
import { useUniqueID } from '../../hooks/a11yHelper';
import styled, { EmotionStyle, EmotionTheme, useEmotionTheme } from '../../core/styled';

// Temporary fix until https://github.com/react-hook-form/react-hook-form/discussions/6665
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Path<T> = any;

export interface RHFPasswordViewProps<
  TFieldValues extends Record<string, string>,
  FieldName extends Path<TFieldValues> = Path<TFieldValues>
> {
  fieldName: FieldName;
  value?: string;
  label?: string;
  displayError?: boolean;
  errorMessage?: string;
  masking?: boolean;
  hasFocus?: boolean;
  canViewPassword?: boolean;
  toggleCanViewPassword?: () => void;
  score: number;
  isConfirmationInput?: boolean;
  passwordsMatch?: boolean;
  showScore?: boolean;
  outerContainerStyle?: EmotionStyle;
  containerStyle?: EmotionStyle;
  labelStyle?: EmotionStyle;
  wrapperStyle?: EmotionStyle;
  inputStyle?: EmotionStyle;
  errorStyle?: EmotionStyle;
  scoreStyle?: EmotionStyle;
  placeholderStyle?: EmotionStyle;
  placeholder?: string;
  shouldCheckStrength?: boolean;
  showInfoMessage?: boolean;
  infoMessageContent?: string;
  /**
   * Used to help Password Managers know when the password was updated.
   */
  isNewPassword?: boolean;
  dataQa?: string;
  onBlur?: (e) => void;
  onFocus?: (e) => void;
  onSubmitEditing?: (() => void) | undefined;
}

interface ScoreTextAndColor {
  text: string;
  translationKey?: TFKeysReactToolkit;
  color: keyof EmotionTheme['colors'];
}

const scoreTextDict: Record<number, ScoreTextAndColor> = {
  0: {
    text: 'Weak',
    translationKey: 'RHFPassword.weakScoreText',
    color: 'razzmatazz',
  },
  1: {
    text: 'Weak',
    translationKey: 'RHFPassword.weakScoreText',
    color: 'razzmatazz',
  },
  2: {
    text: 'So-so',
    translationKey: 'RHFPassword.mediumScoreText',
    color: 'yellowSea',
  },
  3: {
    text: 'Strong',
    translationKey: 'RHFPassword.strongScoreText',
    color: 'green',
  },
  4: {
    text: 'Very strong',
    translationKey: 'RHFPassword.veryStrongScoreText',
    color: 'neptuneGreen',
  },
};

const emptyScoreObj: ScoreTextAndColor = {
  text: '',
  color: 'white',
};

const ScoreContainer = styled(View)<{ hide?: boolean }>(({ hide }) => {
  return {
    transition: 'all .25s',
    padding: hide ? 0 : 4,
    height: hide ? 0 : 'initial',
    opacity: hide ? 0 : 1,
    pointerEvents: hide ? 'none' : 'initial',
  };
});

const RHFPasswordView = <TFieldValues extends Record<string, string>>(
  props: RHFPasswordViewProps<TFieldValues>
) => {
  const {
    fieldName,
    label,
    displayError,
    errorMessage,
    masking,
    hasFocus,
    canViewPassword,
    toggleCanViewPassword,
    score,
    isConfirmationInput,
    showScore,
    value,
    onBlur,
    onFocus,
    containerStyle,
    labelStyle,
    wrapperStyle,
    inputStyle,
    errorStyle,
    scoreStyle,
    placeholderStyle,
    placeholder,
    shouldCheckStrength,
    showInfoMessage,
    infoMessageContent,
    isNewPassword,
    dataQa,
    outerContainerStyle,
    onSubmitEditing,
    ...otherProps
  } = props;

  const { register, setError } = useFormContext<TFieldValues>();

  useEffect(() => {
    if (displayError && errorMessage) {
      setError(fieldName, { message: errorMessage });
    }
  }, [displayError, errorMessage, fieldName, setError]);

  const { colors } = useEmotionTheme();
  const scoreData = score > -1 ? scoreTextDict[score] : emptyScoreObj;
  const { text: scoreText, translationKey: scoreTranslationKey, color: scoreColor } = scoreData;
  const shouldMaskInput = masking && !canViewPassword;
  const showStrengthIndicator =
    shouldCheckStrength && !(isConfirmationInput || !showScore || score === -1);

  const { t: tReactToolkit } = useTranslation('react-toolkit');

  const inputLabel =
    label ||
    ((isConfirmationInput
      ? tReactToolkit('RHFPassword.confirmPasswordInputLabel', 'Confirm password', undefined)
      : tReactToolkit('RHFPassword.passwordInputLabel', 'Password', undefined)) as string);
  const infoID = useUniqueID('info');

  const ariaDescribedBy = showInfoMessage ? infoID : undefined;
  const field = register(fieldName);
  return (
    <View
      align="start"
      style={{ position: 'relative', width: '100%', marginBottom: 18, ...outerContainerStyle }}
    >
      <Input
        dataQa={dataQa || 'passwordInput'}
        ariaDescribedBy={ariaDescribedBy}
        ariaRequired
        placeholder={placeholder}
        type={shouldMaskInput ? 'password' : 'text'}
        autoComplete={isNewPassword ? 'new-password' : 'current-password'}
        autoCorrect="off"
        autoCapitalize="off"
        placeHolderStyle={placeholderStyle}
        wrappedInputProps={{
          label: inputLabel,
          isError: displayError,
          errorMessage,
          containerStyle: { width: '100%', maxWidth: 335, marginBottom: 0, ...containerStyle },
          labelStyle: { marginBottom: 0, ...labelStyle },
          wrapperStyle,
          inputStyle: { width: '100%', color: colors.black, ...inputStyle },
          errorStyle,
        }}
        onSubmitEditing={onSubmitEditing}
        value={value}
        onFocus={onFocus}
        {...field}
        onChange={(e) => {
          field.onChange(e);
        }}
        onBlur={(e) => {
          field.onBlur(e);
          onBlur?.(e);
        }}
        {...otherProps}
      />
      <View
        style={{ marginLeft: 'auto', marginRight: 15, position: 'absolute', right: 0, top: 37 }}
      >
        <PasswordEye
          passwordInputHasFocus={hasFocus}
          strikethrough={canViewPassword}
          onPress={toggleCanViewPassword}
          hasHoverStyles
          primaryColor={colors.whiteSmoke}
          roundedFocusStyle
        />
      </View>
      {showInfoMessage && hasFocus && (
        <Tiny id={infoID} style={{ marginLeft: 4 }}>
          {infoMessageContent}
        </Tiny>
      )}
      <ScoreContainer
        row
        aria-live="polite"
        aria-atomic="true"
        style={scoreStyle}
        hide={!showStrengthIndicator}
      >
        {showStrengthIndicator && (
          <Text style={{ marginRight: 5 }}>
            <Trans t={tReactToolkit} i18nKey="RHFPassword.strengthText">
              Strength:
            </Trans>{' '}
            <Text inline style={{ color: colors[scoreColor], fontWeight: 500 }}>
              <Trans t={tReactToolkit} i18nKey={scoreTranslationKey}>
                {scoreText}
              </Trans>
            </Text>
          </Text>
        )}
      </ScoreContainer>
    </View>
  );
};

export default RHFPasswordView;
