import { FunctionComponent, useState, useEffect, useRef } from 'react';
import customAuthSignIn from '@talkspace/auth/customAuthSignIn';
import { routePromise, sleep } from 'ts-frontend/helpers';
import { useA11yActions, useA11yState } from '@talkspace/react-toolkit';
import Base64 from 'ts-frontend/utils/Base64';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import { getAuthSessionContext, refreshToken } from '../auth';
import ReactFrameService from '../reactFrame/ReactFrameService';
import ssoHelper from '../../utils/sso';
import ApiHelper from '../../utils/ApiHelper';
import {
  AnalyticsData,
  trackRegisterUserEvent,
  trackAliasUserEvent,
} from '../../utils/analytics/events';
import LoadingScreen from '../../screens/LoadingScreen';
import { setTrackerUserID } from '../../utils/analytics/eventTracker';
import { getUserData } from '../helpers/token';
import sessionStorage from '../../core/storage/sessionStorage';
import { getInviteHashParams, processInvitation } from '../../roomInvites';
import { useClientAuthActions, useClientAuthState } from '../../hooks/clientAuthContext';
import { TwoFAStatus } from '../../reducers/clientAuthReducer';

const AuthContainer: FunctionComponent<
  RouteComponentProps & {
    successRoute: string;
  }
> = ({ children, history, successRoute }) => {
  const [ready, setReady] = useState(false);
  const { getAdminConfigOptionAction, setTwoFAStatusAction, setTokenCheckAction } =
    useClientAuthActions();
  const {
    adminConfigs: { isCognitoLoginEnabled },
    showVerificationCodeError,
    isTokenChecked,
  } = useClientAuthState();
  const didCognitoLoginEnabledLoad = isCognitoLoginEnabled !== undefined;

  // sets to accessible color contrast if user isn't logged in (needed for things like plans and payment accessed via quickmatch) and then back to original setting
  const { setColorContrast } = useA11yActions();
  const { isHighContrast } = useA11yState();
  const contrastSettingRef = useRef<boolean>(isHighContrast);
  useEffect(() => {
    const currentRef = contrastSettingRef.current;
    const sessionContext = getAuthSessionContext();
    if (sessionContext !== 'auth' && !ReactFrameService.isMobile()) {
      setColorContrast(true);
    } else setColorContrast(currentRef);
  }, [setColorContrast]);

  useEffect(() => {
    getAdminConfigOptionAction('cognito_user_migration');
  }, [getAdminConfigOptionAction]);

  useEffect(() => {
    if (isTokenChecked) {
      return;
    }
    if (!didCognitoLoginEnabledLoad) {
      return;
    }
    const { pathname, search, hash } = document.location;
    const isLoginScreen = pathname === '/' || !!pathname.match(/\/(login(\/.*)*)$/g);
    const isEmailVerification = pathname.startsWith('/email-verification');
    const isSSOLogout = pathname.startsWith('/sso/logout/');
    const is2faScreen = pathname.startsWith('/2fa/');
    const isUnauthorizedScreen =
      pathname === '/' ||
      !!pathname.match(
        /\/(login(\/.*)*|sso\/logout\/aetna|oauth|signup|unauthorized|signup\/(.*)|2fa\/(.*)|2fa|forgot-password|reset-password|change-email|change-password|parental-consent-form|support-israel|email-verification(\/.*)?)$/g
      );
    const reactFrameService = ReactFrameService.instance();
    const sessionContext = getAuthSessionContext();
    const urlHashParams = new URLSearchParams(hash.replace('#', '?'));
    const tltToken = urlHashParams.get('tlt');
    const email = urlHashParams.get('email');
    const firstEmailVerification = urlHashParams.get('firstEmailVerification') === 'true';
    const analyticsDataURLParam = urlHashParams.get('analytics');
    // Remove from the `urlHashParams.toString()`
    urlHashParams.delete('tlt');
    urlHashParams.delete('email');
    urlHashParams.delete('analytics');
    urlHashParams.delete('firstEmailVerification');

    if (sessionContext === 'auth') {
      if (analyticsDataURLParam) {
        // this case is covering landing in our app after registering from external landing pages
        try {
          const analyticsData: AnalyticsData = JSON.parse(
            Base64.atob(analyticsDataURLParam)
          ) as AnalyticsData;

          const { sendToTrackers } = analyticsData;
          const { userID } = sendToTrackers;

          trackAliasUserEvent(String(userID));
          setTrackerUserID(String(userID));
          trackRegisterUserEvent(sendToTrackers);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn('parsed signup & alias event failed');
        }
      }

      refreshToken(true).then(async (success) => {
        if (success) {
          const { invitationKey } = getInviteHashParams();
          if (invitationKey) {
            await processInvitation(invitationKey).catch((err) => {
              // eslint-disable-next-line no-console
              console.error('Problem processing invitation', err);
            });
          }
          // set trackers userID
          setTrackerUserID(getUserData().id);

          if (isUnauthorizedScreen) {
            if (isLoginScreen && ssoHelper.isSSO()) {
              ssoHelper.loginAction();
            } else if (isEmailVerification) {
              // we get to this case when clicking on email verification link on the email.
              setReady(true);
            } else if (isSSOLogout) {
              setReady(true);
            } else if (is2faScreen) {
              setReady(true);
            } else {
              await routePromise(successRoute);
            }
          } else {
            /** Final route the user will land on */
            const goTo = `${pathname}${search}${hash}`;
            /** replaceRoute is a variation of the dashboard */
            let replaceRoute = '';
            const roomID = pathname.replace(/\/room\/(\d+)\/?.*/g, '$1');
            if (!roomID) replaceRoute = successRoute;
            else replaceRoute = `/room/${roomID}`;
            // In order for the back button in some screens to redirect back to rooms
            // We need to push rooms into the stack at least once
            if (replaceRoute !== pathname) {
              /** Whether or not the url is inside rooms (dashboard) */
              const isNestedDashboardPath = goTo.match(/^\/room\/\d+\/(?!\?).+/g);
              if (isNestedDashboardPath) {
                history.replace(replaceRoute);
                history.push(goTo);
              } else {
                history.replace(goTo);
              }
            }
            setReady(true);
          }
        } else if (!isUnauthorizedScreen) {
          sessionStorage.setItem('redirectTo', `${pathname}${search}`);
          await routePromise(`/login${search}`);
        } else if (isLoginScreen && tltToken) {
          // First time client login
          const api = new ApiHelper();
          let twoFAStatus: TwoFAStatus = 'off';
          api
            .postLoginClientWithTLT(tltToken)
            .then(async (res) => {
              ({ twoFAStatus } = res);
              setTwoFAStatusAction(twoFAStatus, res.userPhone);
              if (email) await customAuthSignIn({ email });
            })
            .then(async () => {
              const urlHashParamsString =
                urlHashParams.toString() && `#${urlHashParams.toString()}`;
              let routePrefix = successRoute;
              if (firstEmailVerification) {
                if (['suggested', 'required'].includes(twoFAStatus)) {
                  sessionStorage.setItem('goTo', `/rooms/therapist-details`);
                  routePrefix = '/2fa/reminder';
                } else {
                  routePrefix = '/rooms/therapist-details';
                }
              }
              if (analyticsDataURLParam) {
                // delay for analytics register case
                await sleep(500);
                await routePromise(`${routePrefix}${urlHashParamsString}`);
              } else {
                history.replace(`${routePrefix}${urlHashParamsString}`);
                setReady(true);
              }
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);
              // just render the login
              setReady(true);
            });
        } else {
          if (isEmailVerification && analyticsDataURLParam) {
            const urlHashParamsString = urlHashParams.toString() && `#${urlHashParams.toString()}`;

            history.replace(`${pathname}${search}${urlHashParamsString}`);
          }
          setReady(true);
        }
      });
    }

    // JWT Tokens are immediate authentication and don't need refreshing
    if (sessionContext === 'jwt') {
      if (reactFrameService.isJWTTokenExpired()) {
        history.replace('/unauthorized');
      }
      setReady(true);
    }

    if (sessionContext === 'frame') {
      const timeout = setTimeout(() => {
        if (ReactFrameService.isMobile()) {
          reactFrameService.closePopup();
        } else {
          history.replace('/unauthorized');
          setReady(true);
        }
      }, 12 * 1000);
      reactFrameService.addTokenListeners((token) => {
        if (token && token.length) {
          setReady(true);
          clearTimeout(timeout);
        }
      });
    }
    setTokenCheckAction(true);
  }, [
    history,
    successRoute,
    didCognitoLoginEnabledLoad,
    showVerificationCodeError,
    setTwoFAStatusAction,
    isTokenChecked,
    setTokenCheckAction,
  ]);

  // Show spinner while we wait
  if (!ready) return <LoadingScreen />;
  return <>{children}</>;
};

export default withRouter(AuthContainer);
