import { WizardScheme } from 'stepWizard';
import { TFAdultOnboarding } from '@talkspace/i18n/types';

const medicalHealthScheme: (tAdultOnboarding: TFAdultOnboarding) => WizardScheme = (
  tAdultOnboarding
) => {
  return {
    wizardModalTitle: 'medical intake',
    wizardVersion: 1,
    wizardType: 'medicalIntake',
    disablePersist: true,
    steps: [
      {
        title: tAdultOnboarding(
          'currentTreatment.title',
          'Are you currently being treated or have you ever been treated for any of the following medical issues?',
          undefined
        ),
        inputType: 'searchableMultiSelect',
        inputState: 'medicalIssues',
        inputTypePlaceholder: tAdultOnboarding('currentTreatment.select', 'Select', undefined),
        nextButton: {
          displayText: tAdultOnboarding('currentTreatment.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('currentTreatment.skip', 'Skip', undefined),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        inputOptions: 'medicalIssuesOptions',
        name: 'medicalIssues',
      },
      {
        title: tAdultOnboarding('chronic.title', 'Are you experiencing chronic pain?', undefined),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('chronic.select', 'Select', undefined),
        inputState: 'hasChronicPain',
        nextButton: {
          displayText: tAdultOnboarding('chronic.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'hasChronicPainOptions',
        name: 'hasChronicPain',
        postAction: 'setSelectedHasChronicPain',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding('pregnant.title', 'Are you currently pregnant?', undefined),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('pregnant.select', 'Select', undefined),
        inputState: 'isPregnant',
        nextButton: {
          displayText: tAdultOnboarding('pregnant.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'isPregnantOptions',
        postAction: 'setSelectedIsPregnant',
        name: 'isPregnant',
        backOption: 'back',
        displayCondition: 'showPregnancyQuestion',
      },
      {
        title: tAdultOnboarding(
          'medication.title',
          'Are you currently taking any of the following psychiatric medications?',
          undefined
        ),
        inputType: 'creatableSearchableMultiSelect',
        inputState: 'medications',
        inputTypePlaceholder: tAdultOnboarding('medication.select', 'Select', undefined),
        nextButton: {
          displayText: tAdultOnboarding('medication.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('medication.skip', 'Skip', undefined),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        inputOptions: 'medicationsOptions',
        name: 'medications',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'list.title',
          "List any over-the-counter medications, supplements, vitamins, or herbs you're currently taking",
          undefined
        ),
        inputType: 'multilineText',
        inputState: 'otcMedications',
        inputTypePlaceholder: tAdultOnboarding(
          'list.description',
          'Over-the-counter medication...',
          undefined
        ),
        nextButton: {
          displayText: tAdultOnboarding('list.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('list.skip', 'Skip', undefined),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        name: 'otcMedications',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'pharmacyAddress.title',
          'Please provide your preferred pharmacy address',
          undefined
        ),
        inputType: 'address',
        inputState: 'pharmacyAddress',
        nextButton: {
          displayText: tAdultOnboarding('pharmacyAddress.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('pharmacyAddress.skip', 'Skip', undefined),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        name: 'pharmacyAddress',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'drugAllergies.title',
          'Please describe any drug allergies you have and your reaction(s) to them',
          undefined
        ),
        inputType: 'multilineText',
        inputState: 'drugAllergies',
        inputTypePlaceholder: tAdultOnboarding(
          'drugAllergies.description',
          'Drug allergies...',
          undefined
        ),
        nextButton: {
          displayText: tAdultOnboarding('drugAllergies.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('drugAllergies.skip', 'Skip', undefined),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        name: 'drugAllergies',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'previousSubstance.title',
          'Do you currently use or have you previously used any of the following controlled substances?',
          undefined
        ),
        inputType: 'searchableMultiSelect',
        inputState: 'controlledSubstances',
        inputTypePlaceholder: tAdultOnboarding('previousSubstance.select', 'Select', undefined),
        nextButton: {
          displayText: tAdultOnboarding('previousSubstance.submit', 'Submit', undefined),
          actionDispatch: (_, wizardContext) => {
            if (wizardContext.showPrimaryCareQuestion) return 'next';
            if (wizardContext.source === 'onboarding') {
              return `/room/${wizardContext.roomID}/onboarding/medical-health/source/onboarding/submit`;
            }
            return '/medical-health-wizard/submit';
          },
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding(
            'previousSubstance.10',
            'I don’t use any controlled substances',
            undefined
          ),
          actionDispatch: (_, wizardContext) => {
            if (wizardContext.showPrimaryCareQuestion) return 'next';
            if (wizardContext.source === 'onboarding') {
              return `/room/${wizardContext.roomID}/onboarding/medical-health/source/onboarding/submit`;
            }
            return '/medical-health-wizard/submit';
          },
          whenVisible: 'always',
        },
        inputOptions: 'controlledSubstancesOptions',
        name: 'controlledSubstances',
        backOption: 'back',
      },
      {
        title: 'Who is your primary care doctor?',
        inputType: 'pcpSearch',
        inputState: 'primaryCarePractitioner',
        nextButton: {
          displayText: 'Continue',
          actionDispatch: (_, wizardContext) => {
            if (wizardContext.source === 'onboarding') {
              return `/room/${wizardContext.roomID}/onboarding/medical-health/source/onboarding/submit`;
            }
            return '/medical-health-wizard/submit';
          },
          whenVisible: 'autoAdvance',
        },
        secondaryButton: {
          displayText: 'Skip',
          actionDispatch: (_, wizardContext) => {
            if (wizardContext.source === 'onboarding') {
              return `/room/${wizardContext.roomID}/onboarding/medical-health/source/onboarding/submit`;
            }
            return '/medical-health-wizard/submit';
          },
        },
        displayCondition: 'showPrimaryCareQuestion',
        name: 'primaryCarePractitioner',
        backOption: 'back',
      },
    ],
  };
};

export default medicalHealthScheme;
