import { Styles } from 'react-select';
import { OptionType } from '../../components/Select';
import { ColorRolesVersion, SelectRoundedVariant } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const getSelectStyles = (
  colorRoles: ColorRolesVersion,
  variant: SelectRoundedVariant
): Styles<OptionType, boolean> => {
  return {
    input: (provided) => {
      const variantStyle = variant === 'slim' ? { padding: 0, minHeight: 0 } : {};
      return { ...provided, ...variantStyle };
    },
    container: (provided) => {
      const variantStyle = variant === 'slim' ? { padding: 0, minHeight: 0 } : {};
      return { ...provided, ...variantStyle };
    },
    valueContainer: (provided) => {
      const variantStyle = variant === 'slim' ? { padding: '0 6px', minHeight: 0 } : {};
      return { ...provided, ...variantStyle };
    },
    control: (provided) => {
      const variantStyle = variant === 'slim' ? { padding: 0, minHeight: 0 } : {};
      return { ...provided, ...variantStyle };
    },
    multiValue: (provided) => {
      const variantStyle =
        variant === 'slim'
          ? {
              padding: '3px 8px',
            }
          : {
              padding: '6px 12px',
            };
      return {
        ...provided,
        backgroundColor: colorRoles.system.interactivePrimaryDefault,
        '&:hover': {
          backgroundColor: colorRoles.system.interactivePrimaryHovered,
        },
        '&:focus': {
          backgroundColor: colorRoles.system.interactivePrimaryPressed,
        },
        '&:active': {
          backgroundColor: colorRoles.system.interactivePrimaryPressed,
        },
        ...variantStyle,
      };
    },
    singleValue: (provided) => {
      const variantStyle =
        variant === 'slim'
          ? {
              padding: '3px 8px',
            }
          : {
              padding: '6px 12px',
            };
      return {
        ...provided,
        ...variantStyle,
      };
    },
    multiValueLabel: (provided) => {
      const variantStyle =
        variant === 'slim'
          ? {
              fontSize: 14,
              padding: '0 6px 0 0',
              paddingLeft: 0,
              paddingRight: 6,
            }
          : {
              fontSize: 16,
              padding: '0 8px 0 0',
              paddingLeft: 0,
              paddingRight: 8,
            };
      return {
        ...provided,
        color: 'white',
        fontWeight: 400,
        margin: 0,
        ...variantStyle,
      };
    },
  };
};
