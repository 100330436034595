import { VoidFunctionComponent } from 'react';
import {
  View,
  Tiny,
  Button,
  Large,
  useWindowWidthState,
  useEmotionTheme,
  BookingTimeZone,
} from '@talkspace/react-toolkit';

import { TherapistInfo } from '../../types';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';
import styled from '@/core/styled';

const BottomButtonWrapper = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    ...(isMobile
      ? {
          bottom: 0,
          height: 80,
          position: 'absolute',
        }
      : { position: 'static' }),
    width: 400,
    marginTop: 15,
    alignItems: 'center',
    justifyContent: 'left',
    backgroundColor: 'white',
  };
});

const Paragraph = styled(Large)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      textAlign: 'center',
      maxWidth: isMobile ? 335 : 340,
    };
  }
);

export const BottomButtonContainer = styled(View)({
  width: 335,
});

interface Props {
  onClosePress: () => void;
  therapist?: TherapistInfo;
  handleReserveClick: () => void;
  onClickCancellationPolicy: () => void;
  timezone: string;
  isLoading?: boolean;
  isSuccess?: boolean;
}

const ConfirmBookingWithoutPolicyOnboarding: VoidFunctionComponent<Props> = ({
  onClosePress,
  therapist,
  handleReserveClick,
  onClickCancellationPolicy,
  timezone,
  isLoading,
  isSuccess,
}) => {
  const { isLoading: isInRoomSchedulingLoading } = useInRoomSchedulingState();
  const { isMobile } = useWindowWidthState();
  const { colorRoles } = useEmotionTheme();
  return (
    <>
      <View flex={1} align="center">
        <BookingTimeZone timezone={timezone} style={{ marginBottom: 33 }} />
        <Paragraph
          variant="largeDarkGrey"
          style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}
        >
          If you need to reschedule or cancel session, you may do so for free until 24 hours before
          your appointment’s start time.
        </Paragraph>
        <Paragraph variant="largeDarkGrey" style={{ textAlign: 'center' }}>
          Late cancellations or no-shows will result in a fee for the cost of service, which can be
          <Large variant="largeDarkGrey" style={{ fontWeight: 700 }} inline>
            <br />
            up to $175.
          </Large>
        </Paragraph>
      </View>
      <BottomButtonWrapper flex={1} align="center" isMobile={isMobile}>
        <BottomButtonContainer>
          <Button
            disabled={isLoading || isInRoomSchedulingLoading || isSuccess}
            isLoading={isLoading}
            onPress={handleReserveClick}
            text="Book Session"
            dataQa="inRoomSchedulingOnBoardingConfirmBooking"
            stretch
          />
          <Tiny
            style={{
              textAlign: 'center',
              marginTop: 16,
              color: colorRoles.typography.textSubtlest,
            }}
          >
            By clicking “I agree” you are agreeing to
            <Tiny
              inline
              variant="tinyBoldGreen"
              style={{ cursor: 'pointer', color: colorRoles.typography.textBrandDefault }}
              onPress={onClickCancellationPolicy}
            >
              {' '}
              Talkspace Cancellation Policy
            </Tiny>
          </Tiny>
        </BottomButtonContainer>
      </BottomButtonWrapper>
    </>
  );
};

export default ConfirmBookingWithoutPolicyOnboarding;
