import { Screen, useWindowWidthState } from '@talkspace/react-toolkit';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import LoadingScreen from '../../screens/LoadingScreen';

import WelcomeScreen from './WelcomeScreen';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import useEligibilityWidgetState from '../hooks/useEligibilityWidgetState';

interface WelcomeScreenWrapperProps extends RouteComponentProps<{ roomID: string }> {
  qmFlow?: number;
}

const WelcomeScreenWrapper = ({ qmFlow, match }: WelcomeScreenWrapperProps) => {
  const { roomID } = match.params;

  const { isMobile } = useWindowWidthState();
  const { loading, showClose, autoStart, qmFlowPath, providerInfo } = useEligibilityWidgetState(
    qmFlow,
    +roomID
  );

  const closeModal = useCloseModal();

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Screen
      safeAreaVariant="setHeightAndAddTop"
      showCloseButton={showClose}
      onCloseButtonClick={closeModal}
      title={isMobile ? 'Check my coverage' : ''}
    >
      <WelcomeScreen
        qmFlowPath={qmFlowPath}
        autoStart={autoStart}
        showCloseButton={showClose}
        providerInfo={providerInfo}
      />
    </Screen>
  );
};

export default withRouter(WelcomeScreenWrapper);
