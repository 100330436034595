import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import {
  Big,
  Button,
  Large,
  SelectRounded,
  ScrollView,
  Small,
  Tiny,
  TouchableView,
  View,
  useEmotionTheme,
  useWindowWidthState,
  BookingTimeZone,
} from '@talkspace/react-toolkit';
import { isApple } from 'ts-frontend/utils/device';
import ChatApiHelpers from 'chat/redux/helpers/ChatApiHelpersClass';
import { useTranslation } from '@talkspace/i18n';
import { useHistory } from '@/core/routerLib';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';

type Day =
  | 'Mondays'
  | 'Tuesdays'
  | 'Wednesdays'
  | 'Thursdays'
  | 'Fridays'
  | 'Saturdays'
  | 'Sundays';
type TimeOfDay = 'Morning' | 'Afternoon' | 'Evening';
type TimeBucket = { name: TimeOfDay; timeRange: string };
type SelectedOption = { day: Day; timeName: TimeOfDay; timeRange: string };

enum Cadance {
  oneTime = 'One Time',
  recurring = 'Recurring',
}

type SelectedCadanceOption = { value: Cadance; label: string };

const SUGGESTED_DAYS: Day[] = ['Mondays', 'Tuesdays', 'Wednesdays', 'Thursdays', 'Fridays'];
const SUGGESTED_TIME_OF_DAYS: TimeOfDay[] = ['Morning', 'Afternoon', 'Evening'];
const SELECTIONS_REQUIRED = 3;
const DAILY_TIME_BUCKETS: TimeBucket[] = [
  {
    name: 'Morning',
    timeRange: '7AM - 12PM',
  },
  {
    name: 'Afternoon',
    timeRange: '12PM - 3PM',
  },
  {
    name: 'Evening',
    timeRange: '3PM - 7PM',
  },
];
const getCadanceOptions = (tBookingScreen): SelectedCadanceOption[] => [
  { value: Cadance.oneTime, label: 'Prefer one-time booking' },
  { value: Cadance.recurring, label: 'Prefer recurring booking' },
];

const sortSelectedOptions = (options: SelectedOption[]) =>
  options.sort((o1, o2) => {
    const dayDiff =
      SUGGESTED_DAYS.findIndex((d) => o1.day === d) - SUGGESTED_DAYS.findIndex((d) => o2.day === d);
    if (dayDiff !== 0) return dayDiff;
    const timeOfDayDiff =
      SUGGESTED_TIME_OF_DAYS.findIndex((t) => o1.timeName === t) -
      SUGGESTED_TIME_OF_DAYS.findIndex((t) => o2.timeName === t);
    return timeOfDayDiff;
  });

const SelectionCircle = ({ label }) => {
  const { colors } = useEmotionTheme();
  return (
    <View
      align="center"
      justify="center"
      style={{
        position: 'relative',
        top: -9,
        right: 27,
        height: 32,
        width: 32,
        borderRadius: '50%',
        border: `2px solid ${colors.white}`,
        backgroundColor: colors.permaEden,
      }}
    >
      <Big variant="bigWide">{label}</Big>
    </View>
  );
};

export const Option = ({
  day,
  timeBucket,
  selectedOptions,
  setSelectedOptions,
  isLast,
  hideSelectionCircle = false,
}: {
  day: Day;
  timeBucket: TimeBucket;
  selectedOptions: SelectedOption[];
  setSelectedOptions: Dispatch<SetStateAction<SelectedOption[]>>;
  isLast: boolean;
  hideSelectionCircle?: boolean;
}) => {
  const { colors } = useEmotionTheme();
  const { name, timeRange } = timeBucket;

  const [isSelected, setIsSelected] = useState<boolean>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    const index = selectedOptions.findIndex(
      (option) => option.day === day && option.timeName === timeBucket.name
    );
    setSelectedIndex(index);
    setIsSelected(index !== -1);
  }, [selectedOptions, day, timeBucket]);

  const toggleOption = () => {
    if (isSelected) {
      setSelectedOptions(
        selectedOptions.filter(
          (option) => !(option.day === day && option.timeName === timeBucket.name)
        )
      );
    } else {
      setSelectedOptions([
        ...selectedOptions,
        { day, timeName: timeBucket.name, timeRange: timeBucket.timeRange },
      ]);
    }
  };
  return (
    <TouchableView
      dataQa={`selectTimeslotPreferredTimesTimeRange${day}${timeRange.replace(/\s/g, '')}`}
      row
      style={{ marginRight: !hideSelectionCircle && isSelected ? -32 : undefined }}
      onPress={toggleOption}
    >
      <View
        align="center"
        style={{
          marginTop: 4,
          marginRight: isLast ? 0 : 8,
          padding: 8,
          borderRadius: 5,
          border: !hideSelectionCircle && isSelected ? undefined : `1px solid ${colors.lightGray}`,
          width: 106,
          backgroundColor: isSelected ? colors.green : colors.white,
        }}
      >
        <Large variant={isSelected ? 'largeWhite' : 'largeDarkGrey'}>{name}</Large>
        <Tiny variant={isSelected ? 'tinyWhite' : 'tinyBlack'}>{timeRange}</Tiny>
      </View>
      {!hideSelectionCircle && isSelected && <SelectionCircle label={selectedIndex + 1} />}
    </TouchableView>
  );
};

const GradientBlock = () => (
  <span
    style={{
      zIndex: 1,
      width: '100%',
      height: 50,
      marginTop: -50,
      background: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))',
    }}
  />
);

const SelectTimeslotPreferredTimes: FunctionComponent<{ dismissOnboarding?: () => void }> = ({
  dismissOnboarding = () => {},
}) => {
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  const {
    localTimezone,
    selectedCreditOption,
    room: { roomID } = {},
    therapistInfo: { id: therapistID } = {},
  } = useInRoomSchedulingState();
  const timezoneAbbreviation = moment().tz(localTimezone).zoneAbbr();
  const { colors } = useEmotionTheme();
  const closeModal = useCloseModal();
  const history = useHistory();
  const isOnboarding = history.location.pathname.includes('/onboarding');
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
  const [selectedCadance, setSelectedCadance] = useState<SelectedCadanceOption>(
    getCadanceOptions(tBookingScreen)[0]
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { isMobile } = useWindowWidthState();

  const selectedCount = selectedOptions.length;
  const leftToSelect = SELECTIONS_REQUIRED - selectedCount;
  const canProceed = leftToSelect <= 0;

  const onChangeDropdown = (cadance: { value: Cadance; label: string }) => {
    setSelectedCadance(cadance);
  };

  const getMessageToSend = useCallback(
    (isAvailableAnyTime?: boolean) => {
      const baseMessage = `I’d like to book my ${selectedCreditOption?.displayName}. `;
      const preferredTimesMessage = isAvailableAnyTime
        ? 'I am available at any time. '
        : `My preferred times are:\n${sortSelectedOptions(selectedOptions)
            .map((option) => `${option.day} (${option.timeRange} ${timezoneAbbreviation})`)
            .join('\n')}\n`;
      const cadanceMessage =
        selectedCadance.value === Cadance.recurring
          ? 'I’m interested in discussing your availability for recurring bookings.'
          : '';
      return `${baseMessage}${preferredTimesMessage}${cadanceMessage}`;
    },
    [selectedCreditOption, selectedOptions, selectedCadance, timezoneAbbreviation]
  );

  const sendMessageAndContinue = useCallback(
    (message) => {
      if (roomID) {
        setIsSubmitting(true);
        const api = new ChatApiHelpers();
        return api
          .postMessage({ roomID, message })
          .then(() => {
            trackEvent(
              'Asked About Availability',
              {
                roomID,
                therapistID,
                recurringPreference: selectedCadance.value,
                timePreference: selectedOptions.map(
                  (option) => `${option.day} ${option.timeRange}`
                ),
                planID: selectedCreditOption?.planID,
                sessionLength: selectedCreditOption?.creditMinutes,
                creditType: selectedCreditOption?.type,
                creditID: selectedCreditOption?.id,
              },
              ['mixpanel']
            );
            closeModal({ navigateTo: 'room', metadata: { roomID } });
            setIsSubmitting(false);
          })
          .catch(() => setIsSubmitting(false));
      }
      return Promise.resolve();
    },
    [roomID, therapistID, selectedCadance, selectedOptions, selectedCreditOption, closeModal]
  );

  const onPressContinue = () => {
    if (isOnboarding) dismissOnboarding();
    sendMessageAndContinue(getMessageToSend());
  };

  const onPressAvailableAnyTime = () => {
    if (isOnboarding) dismissOnboarding();
    sendMessageAndContinue(getMessageToSend(true));
  };

  const ctaText = canProceed ? 'Send to my provider' : `Select ${leftToSelect} Preferred Times`;
  const mobileContentHeight = isApple ? '78vh' : '88vh';
  return (
    <View align="center" style={{ height: isMobile ? mobileContentHeight : undefined }}>
      <Small
        variant="smallDarkGrey"
        style={{ textAlign: 'center', margin: `0 15px 35px`, maxWidth: 280 }}
      >
        {tBookingScreen(
          'share.title',
          'Share your preferred times to meet and your provider will find a time for you.',
          undefined
        )}
      </Small>
      <View align="start" style={{ marginBottom: 12, padding: '0 5px', width: '100%' }}>
        <BookingTimeZone timezone={localTimezone} />
        <SelectRounded
          options={getCadanceOptions(tBookingScreen)}
          value={selectedCadance}
          onChange={onChangeDropdown}
          wrapperStyle={{ width: '100%' }}
          dataQa="selectTimeslotPreferredTimesSelectCadance"
        />
      </View>
      <ScrollView
        style={{
          maxHeight: 'calc(100vh - 400px)',
          width: 355,
          overflowX: 'clip',
          paddingBottom: 40,
          paddingLeft: 5,
          alignItems: 'center',
        }}
      >
        {SUGGESTED_DAYS.map((day) => (
          <View style={{ marginTop: 16 }}>
            <Big>{day}</Big>
            <View row>
              {DAILY_TIME_BUCKETS.map((bucket, i) => (
                <Option
                  day={day as Day}
                  timeBucket={bucket}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  isLast={i === DAILY_TIME_BUCKETS.length - 1}
                />
              ))}
            </View>
          </View>
        ))}
      </ScrollView>
      <GradientBlock />
      <View style={{ width: '100%', padding: '0 5px' }}>
        <Button
          text={ctaText}
          onPress={onPressContinue}
          isLoading={isSubmitting}
          disabled={!canProceed}
          style={{ marginTop: 4 }}
          stretch
          dataQa="selectTimeslotPreferredTimesSubmit"
        />
        <Button
          text="I'm available any time"
          onPress={onPressAvailableAnyTime}
          isLoading={isSubmitting}
          style={{
            marginTop: 4,
            color: colors.greenText,
            backgroundColor: 'transparent',
          }}
          stretch
          dataQa="selectTimeslotPreferredTimesAvailableAnyTime"
        />
      </View>
    </View>
  );
};

export default SelectTimeslotPreferredTimes;
