import { useEffect, VoidFunctionComponent } from 'react';
import moment from 'moment';

import {
  PaymentDetails,
  ClientHeader,
  BookingCheckout,
  useInRoomSchedulingActions,
  useInRoomSchedulingState,
  BookingConfirmationSuccess,
  CANCELLATION_WINDOW_HOURS,
  ConfirmAsyncSessionParams,
} from 'inRoomScheduling';
import { useWindowWidthState, getScreenSafeAreaInsets } from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import useAccountDetails from '@/myAccount/hooks/useAccountDetails';
import { Route, Switch, useHistory, useParams } from '@/core/routerLib';
import { getUserData } from '@/auth/helpers/token';
import { useMainActions, useMainState } from '@/hooks/mainContext';
import { extractRoomEntitiesFromState } from '../../utils/extractRoomEntitiesFromState';

import BookAsyncSessionView from '../../meetYourProvider/components/BookAsyncSessionView';
import BookAsyncSessionCancellationPolicyContainer from './BookAsyncSessionCancellationPolicyContainer';
import BookAsyncSessionMessagingSession from '../components/BookAsyncSessionMessagingSession';

interface Props {
  useStripeLink?: boolean;
  onLoadStripeLink?: () => void;
}

const BookAsyncSessionContainer: VoidFunctionComponent<Props> = ({
  useStripeLink = true,
  onLoadStripeLink = () => {},
}) => {
  const history = useHistory();
  const { isMobile } = useWindowWidthState();
  const { roomID } = useParams<{ roomID: string }>();
  const baseURL = `/room/${roomID}/onboarding/book-async-session`;

  const handleBookLiveSessionClick = () => {
    history.push(`/room/${roomID}/onboarding/book-session`);
  };

  const handleStartMessagingSessionClick = () => {
    history.push(`${baseURL}/messaging-session`, {
      asyncOnboarding: true,
    });
  };

  const { id: userID } = getUserData();
  const mainState = useMainState();
  const { room, therapist } = extractRoomEntitiesFromState(mainState, Number(roomID));
  const { getAllRooms } = useMainActions();
  const { t: tBookingScreen } = useTranslation('bookingScreen');

  const { selectedCreditOption, selectedTimeslot, hasBreakAfterSession } =
    useInRoomSchedulingState();
  const {
    dispatchSetRoomAndTherapistInfo,
    dispatchCreateAsyncSession,
    dispatchModalityType,
    dispatchGetCheckoutData,
    dispatchGetSubscriptionsAndCreditOptions,
    dispatchSetIsError,
  } = useInRoomSchedulingActions();

  const [{ paymentDetails }, { getPaymentDetails }] = useAccountDetails();

  useEffect(() => {
    getPaymentDetails();
  }, [getPaymentDetails]);

  useEffect(() => {
    getAllRooms(userID);
  }, [getAllRooms, userID]);

  useEffect(() => {
    dispatchGetSubscriptionsAndCreditOptions(userID, Number(roomID));
  }, [dispatchGetSubscriptionsAndCreditOptions, userID, roomID]);

  useEffect(() => {
    const { planID } = selectedCreditOption || {};
    if (planID) {
      dispatchGetCheckoutData(planID);
    }
  }, [dispatchGetCheckoutData, selectedCreditOption]);

  useEffect(() => {
    dispatchModalityType('messaging');
    dispatchSetRoomAndTherapistInfo(room, therapist);
  }, [room, therapist, dispatchSetRoomAndTherapistInfo, dispatchModalityType]);

  const handleConfirmAsyncSession = ({
    isPurchase = false,
    creditCardToken,
  }: ConfirmAsyncSessionParams) => {
    dispatchCreateAsyncSession({
      isPurchase,
      creditCardToken,
    });
  };

  const isWithin24Hours = moment(selectedTimeslot?.start).isBefore(
    moment().add(CANCELLATION_WINDOW_HOURS, 'hours')
  );
  const handleReviewCancellationPolicy = () => history.push(`${baseURL}/cancellation-policy`);
  const handleClose = () => {
    history.push(`/room/${roomID}/onboarding/next`);
  };
  const safeAreaInsets = getScreenSafeAreaInsets();
  return (
    <Switch>
      <Route
        exact
        path="/room/:roomID/onboarding/book-async-session"
        render={() => (
          <BookAsyncSessionView
            roomID={Number(roomID)}
            handleBookLiveSessionClick={handleBookLiveSessionClick}
            handleStartMessagingSessionClick={handleStartMessagingSessionClick}
          />
        )}
      />
      <Route
        exact
        path="/room/:roomID/onboarding/book-async-session/cancellation-policy"
        component={BookAsyncSessionCancellationPolicyContainer}
      />
      <Route
        exact
        path="/room/:roomID/onboarding/book-async-session/messaging-session"
        render={() => <BookAsyncSessionMessagingSession paymentDetails={paymentDetails} />}
      />
      <Route
        exact
        path="/room/:roomID/onboarding/book-async-session/booking-checkout"
        render={() => (
          <BookingCheckout
            containerStyle={{
              paddingTop: safeAreaInsets.top,
              paddingBottom: safeAreaInsets.bottom,
            }}
            handleConfirmAppointment={handleConfirmAsyncSession}
            therapist={therapist}
            paymentDetails={paymentDetails}
            isTherapist={false}
            onClosePress={handleClose}
            isFromCheckInWizard={false}
            isFromPostLVSCheckInWizard={false}
            handleReviewCancellationPolicy={handleReviewCancellationPolicy}
          />
        )}
      />
      <Route
        exact
        path="/room/:roomID/onboarding/book-async-session/payment-details"
        render={() => (
          <PaymentDetails
            handleConfirmAppointment={handleConfirmAsyncSession}
            onClosePress={handleClose}
            useStripeLink={useStripeLink}
            onStripeLinkError={(error: any) => dispatchSetIsError(error)}
            onLoadStripeLink={onLoadStripeLink}
            bookingSuccessRoute="booking-success"
          />
        )}
      />
      <Route
        exact
        path="/room/:roomID/onboarding/book-async-session/booking-success"
        render={() => (
          <ClientHeader
            isMobile={isMobile}
            titleText={tBookingScreen('titles.bookASession', 'Book a session', undefined)}
            handleBackClick={() => history.goBack()}
            shouldHideBackArrow
          >
            <BookingConfirmationSuccess
              therapist={therapist}
              isTherapist={false}
              handleOnClosePress={handleClose}
              handleOnCTAPress={handleClose}
              isWithin24Hours={isWithin24Hours}
              hasBreakAfterSession={hasBreakAfterSession}
              hideAddToCalendar={false}
            />
          </ClientHeader>
        )}
      />
    </Switch>
  );
};
export default BookAsyncSessionContainer;
