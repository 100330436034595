import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const smallPath =
  'M13.0314 4.46973C13.3244 4.7627 13.3244 5.23848 13.0314 5.53145L7.03145 11.5314C6.73848 11.8244 6.2627 11.8244 5.96973 11.5314L2.96973 8.53145C2.67676 8.23848 2.67676 7.7627 2.96973 7.46973C3.2627 7.17676 3.73848 7.17676 4.03145 7.46973L6.50176 9.9377L11.9721 4.46973C12.265 4.17676 12.7408 4.17676 13.0338 4.46973H13.0314Z';
const standardPath =
  'M16.7063 5.69019C17.0969 6.08081 17.0969 6.71519 16.7063 7.10581L8.70627 15.1058C8.31565 15.4964 7.68127 15.4964 7.29065 15.1058L3.29065 11.1058C2.90002 10.7152 2.90002 10.0808 3.29065 9.69019C3.68127 9.29956 4.31565 9.29956 4.70627 9.69019L8.00002 12.9808L15.2938 5.69019C15.6844 5.29956 16.3188 5.29956 16.7094 5.69019H16.7063Z';

const pathConfig: IconConfig = {
  small: {
    default: { path: smallPath },
  },
  standard: {
    default: { path: standardPath },
  },
};

const Check = withDSIconMaker(pathConfig, 'check');

export default Check;
