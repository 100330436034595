import { VoidFunctionComponent } from 'react';
import {
  SessionModality,
  TherapistTimeslot,
  RepeatingMeta,
  CreditMinutes,
} from 'ts-frontend/types';
import RepeatingBookingDates from './RepeatingBookingDates';
import SingleBookingDates from './SingleBookingDates';

interface Props {
  modality: SessionModality;
  sessionStart?: string;
  sessionDuration?: CreditMinutes | undefined;
  isRecurringBooking?: boolean;
  repeatingMeta?: RepeatingMeta | null;
  repeatingTimeslots?: TherapistTimeslot[] | null;
  isTherapist: boolean;
}

const BookingDates: VoidFunctionComponent<Props> = ({
  modality,
  sessionStart,
  sessionDuration,
  isRecurringBooking,
  repeatingMeta,
  repeatingTimeslots,
  isTherapist,
}) => {
  const showRepeatingBookingDates = isRecurringBooking && repeatingMeta && repeatingTimeslots;

  if (showRepeatingBookingDates) {
    return <RepeatingBookingDates repeatingTimeslots={repeatingTimeslots} />;
  }

  return (
    <SingleBookingDates
      modality={modality}
      isTherapist={isTherapist}
      sessionStart={sessionStart}
      sessionDuration={sessionDuration}
    />
  );
};

export default BookingDates;
