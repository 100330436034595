import { RefObject } from 'react';
import { A11Y_COLORS, RHFInput, RHFSelect, View } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useTranslation } from '@talkspace/i18n';

const textFieldContainerStyle = { width: '98%', marginLeft: 2 };
const textFieldLabelStyle = { color: A11Y_COLORS.permaWaikawaGreyNew };
const textFieldInputStyle = { borderColor: A11Y_COLORS.permaLividBlueNew };

const languageOptions = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'es',
    label: 'Spanish',
  },
];

const handleInputFocus = (e) => {
  const scrollY = e.target.getBoundingClientRect().top + window.scrollY - 160;

  window.scrollTo({
    top: scrollY,
    behavior: 'smooth',
  });
};

const ConsenterDetailsForm = ({
  relation,
  detailsRef,
}: {
  relation: string;
  detailsRef?: RefObject<HTMLInputElement>;
}) => {
  const { localizationParentalConsent } = useFlags();
  const { t: tTeenOnboarding } = useTranslation('teenOnboarding');
  return (
    <View ref={detailsRef}>
      <RHFInput
        fieldName="firstName"
        label={
          relation === 'parent'
            ? tTeenOnboarding('sendConsentFormTo.parentFirst', 'Parent first name', undefined)
            : tTeenOnboarding('sendConsentFormTo.guardianFirst', 'Guardian first name', undefined)
        }
        containerStyle={textFieldContainerStyle}
        labelStyle={textFieldLabelStyle}
        inputStyle={textFieldInputStyle}
        handleInputFocus={handleInputFocus}
      />
      <RHFInput
        fieldName="lastName"
        label={
          relation === 'parent'
            ? tTeenOnboarding('sendConsentFormTo.parentLast', 'Parent last name', undefined)
            : tTeenOnboarding('sendConsentFormTo.guardianLast', 'Guardian last name', undefined)
        }
        containerStyle={textFieldContainerStyle}
        labelStyle={textFieldLabelStyle}
        inputStyle={textFieldInputStyle}
        handleInputFocus={handleInputFocus}
      />
      <RHFInput
        fieldName="email"
        label={
          relation === 'parent'
            ? tTeenOnboarding('sendConsentFormTo.parentRegEmail', 'Parent email', undefined)
            : tTeenOnboarding('sendConsentFormTo.guardianRegEmail', 'Guardian email', undefined)
        }
        containerStyle={textFieldContainerStyle}
        labelStyle={textFieldLabelStyle}
        inputStyle={textFieldInputStyle}
        handleInputFocus={handleInputFocus}
      />
      {localizationParentalConsent && (
        <RHFSelect
          fieldName="language"
          label={tTeenOnboarding(
            'sendConsentFormTo.theirLanguage',
            'Their preferred language',
            undefined
          )}
          containerStyle={textFieldContainerStyle}
          labelStyle={textFieldLabelStyle}
          inputStyle={textFieldInputStyle}
          handleInputFocus={handleInputFocus}
          options={languageOptions}
          menuPosition="fixed"
          registerOptionValue
        />
      )}
    </View>
  );
};

export default ConsenterDetailsForm;
