import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Button,
  BaseButton,
  TextDS,
  View,
  useEmotionTheme,
  languagesList,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import {
  FullWidthContainer,
  ColumnContainer,
  TitlesView,
  StickyBottomContainer,
} from 'stepWizard/components/StepWizard/StepWizardStyles';
import { useHistory } from 'react-router';
import { ThumbsUp as ThumbsUpIllustration } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import CircleUnderlay from '@talkspace/react-toolkit/src/designSystems/components/CircleUnderlay';
import {
  useWizardState,
  useWizardActions,
  createStepResponse,
  WizardCompletedStep,
} from 'stepWizard';
import { states } from '@talkspace/configs';
import capitalize from 'lodash/capitalize';
import { useTranslation } from '@talkspace/i18n';
import { trackCTAClick } from 'ts-analytics/mixpanel/events';
import { useInRoomSchedulingState, useInRoomSchedulingActions } from 'inRoomScheduling';
import { trackEvent } from '@/utils/analytics/eventTracker';
import MatchPreferenceRow from './MatchPreferenceRow';
import { State } from '../reducers/switchWizardState';
import { extractRoomEntitiesFromState } from '../../utils/extractRoomEntitiesFromState';

import { useMainState } from '../../hooks/mainContext';

const MatchPreferenceSummary: FunctionComponent<{
  handleStartButton: () => void;
}> = ({ handleStartButton }) => {
  const { spacing } = useEmotionTheme();
  const history = useHistory();
  const titlesView = useRef<HTMLDivElement>(null);
  const { isMobile } = useWindowWidthState();

  const { t: tSwitchWizard } = useTranslation('switchWizard');

  const {
    roomID,
    countryState,
    presentingProblemsOptions,
    existingPreferences,
    clientUserID,
    therapistGender,
    responses,
  } = useWizardState<State>();
  const mainState = useMainState();
  const { therapist } = extractRoomEntitiesFromState(mainState, roomID);
  const { setState } = useWizardActions();
  const {
    dispatchGetSubscriptionsAndCreditOptions,
    dispatchSetSelectedBookingDuration,
    dispatchSetSelectedCreditOption,
    dispatchModalityType,
  } = useInRoomSchedulingActions();
  const { creditOptions } = useInRoomSchedulingState();

  useEffect(() => {
    trackEvent('View Review Match Preferences', {}, ['mixpanel'], false);
  }, []);

  useEffect(() => {
    dispatchGetSubscriptionsAndCreditOptions(clientUserID, roomID);
  }, [dispatchGetSubscriptionsAndCreditOptions, clientUserID, roomID]);

  // Set default values for inRoomScheduling
  useEffect(() => {
    if (creditOptions?.length) {
      if (existingPreferences?.sessionModality) {
        dispatchModalityType(existingPreferences?.sessionModality);
      }
      if (existingPreferences?.sessionModality !== 'messaging') {
        dispatchSetSelectedBookingDuration(creditOptions[0].creditMinutes);
        dispatchSetSelectedCreditOption(creditOptions[0]);
      }
    }
  }, [
    creditOptions,
    existingPreferences?.sessionModality,
    dispatchSetSelectedBookingDuration,
    dispatchSetSelectedCreditOption,
    dispatchModalityType,
  ]);

  const onPressConfirmPreferences = useCallback(() => {
    const newStepResponses: WizardCompletedStep[] = [];
    const additionalData = therapist
      ? {
          therapistInfo: {
            id: therapist.id,
            firstName: therapist.firstName,
          },
        }
      : {};
    const stepOptions = {
      skip: true,
      additionalData,
    };

    newStepResponses.push(
      createStepResponse('Focus on', existingPreferences?.fieldsOfExpertise || null, stepOptions)
    );

    newStepResponses.push(createStepResponse('State country', countryState || null, stepOptions));

    newStepResponses.push(
      createStepResponse('Therapist gender', therapistGender || null, stepOptions)
    );
    if (existingPreferences?.languages && existingPreferences?.languages.length) {
      newStepResponses.push(
        createStepResponse('Therapist language', existingPreferences?.languages[0], stepOptions)
      );
      newStepResponses.push(
        createStepResponse('Therapist language filter type', 'languages', stepOptions)
      );
    } else if (existingPreferences?.languagesSoft && existingPreferences?.languagesSoft.length) {
      newStepResponses.push(
        createStepResponse('Therapist language', existingPreferences?.languagesSoft[0], stepOptions)
      );
      newStepResponses.push(
        createStepResponse('Therapist language filter type', 'languagesSoft', stepOptions)
      );
    } else {
      newStepResponses.push(createStepResponse('Therapist language', null, stepOptions));
    }
    newStepResponses.push(
      createStepResponse(
        'Select Modality',
        existingPreferences?.sessionModality || null,
        stepOptions
      )
    );

    const creditName = creditOptions && creditOptions.length ? creditOptions[0].displayName : null;
    newStepResponses.push(createStepResponse('Select Session Length', creditName, stepOptions));
    responses.steps = newStepResponses;

    setState({ responses });
    trackCTAClick('Review Match Preferences Skip', 'switch-wizard', { roomID });
    history.push('/switch-provider/therapist-matches', { roomID: Number(roomID) });
  }, [
    roomID,
    history,
    existingPreferences,
    countryState,
    creditOptions,
    setState,
    therapistGender,
    responses,
    therapist,
  ]);

  const onPressUpdatePreferences = useCallback(() => {
    trackCTAClick('Review Match Preferences Update', 'switch-wizard', { roomID });
    handleStartButton();
  }, [handleStartButton, roomID]);

  const [, clientState] = countryState?.split('-') || ['', ''];
  const preferenceClientState: string = states[clientState];

  const preferenceFocusAreas = useMemo(() => {
    if (!existingPreferences || !presentingProblemsOptions?.length) return '';
    const focusAreaArr = (existingPreferences.fieldsOfExpertise as number[]).map((field) => {
      const option = presentingProblemsOptions.find(
        (presentingProblem) => presentingProblem.value === field
      );
      return option ? option?.label : '';
    });
    return focusAreaArr.join('<br>');
  }, [existingPreferences, presentingProblemsOptions]);

  const preferenceGender = capitalize(therapistGender) || 'No preference';

  const preferenceSessionType = useMemo(() => {
    if (existingPreferences?.sessionModality === 'messaging') {
      return 'Messaging';
    }

    if (!creditOptions || !creditOptions?.length) return 'None';
    const prefModality = existingPreferences?.sessionModality || 'video';
    return creditOptions[0].displayName.replace('minute', 'min').replace('session', prefModality);
  }, [creditOptions, existingPreferences]);

  const preferenceLanguage = useMemo(() => {
    if (existingPreferences?.languages?.length) {
      return existingPreferences?.languages
        .map((langID) => {
          const foundLang = languagesList.find((langOption) => langOption.id === langID);
          return foundLang ? `${foundLang.name} only` : null;
        })
        .filter(Boolean)[0];
    }
    if (existingPreferences?.languagesSoft?.length) {
      return existingPreferences?.languagesSoft
        .map((langID) => {
          const foundLang = languagesList.find((langOption) => langOption.id === langID);
          return foundLang ? `${foundLang.name}` : null;
        })
        .filter(Boolean)
        .join(', ');
    }
    return null;
  }, [existingPreferences]);

  return (
    <FullWidthContainer align="center" justify="center" tabIndex={-1} as="main">
      <ColumnContainer
        align="center"
        style={{ paddingTop: spacing('space150'), paddingBottom: 180 }}
      >
        <CircleUnderlay size={64} colorRole="brandSubtleDefault">
          <ThumbsUpIllustration />
        </CircleUnderlay>
        <View style={{ position: 'relative', paddingTop: spacing('space150') }}>
          <TitlesView ref={titlesView}>
            <TextDS
              variant="headingXl"
              style={{ paddingTop: spacing('space150'), paddingBottom: spacing('space150') }}
            >
              {tSwitchWizard(
                'MatchPreferenceSummary.titleText',
                'Take a moment to review your preferences',
                undefined
              )}
            </TextDS>
            {preferenceFocusAreas && (
              <MatchPreferenceRow
                preferenceCategory={tSwitchWizard(
                  'MatchPreferenceSummary.preferenceCategoryFocusArea',
                  preferenceFocusAreas.length === 1 ? 'Focus area' : 'Focus areas',
                  { count: preferenceFocusAreas.length }
                )}
                preferenceResponse={preferenceFocusAreas}
              />
            )}
            {preferenceLanguage && (
              <MatchPreferenceRow
                preferenceCategory={tSwitchWizard(
                  'MatchPreferenceSummary.preferenceLanguage',
                  'Language',
                  undefined
                )}
                preferenceResponse={preferenceLanguage}
              />
            )}
            <MatchPreferenceRow
              preferenceCategory={tSwitchWizard(
                'MatchPreferenceSummary.preferenceCategoryState',
                'State you live in',
                undefined
              )}
              preferenceResponse={preferenceClientState}
            />
            <MatchPreferenceRow
              preferenceCategory={tSwitchWizard(
                'MatchPreferenceSummary.preferenceCategoryGender',
                'Provider gender',
                undefined
              )}
              preferenceResponse={preferenceGender}
            />
            {preferenceSessionType !== 'None' && (
              <MatchPreferenceRow
                preferenceCategory={tSwitchWizard(
                  'MatchPreferenceSummary.preferenceCategorySessionType',
                  'Session type',
                  undefined
                )}
                preferenceResponse={preferenceSessionType}
              />
            )}
          </TitlesView>
          <StickyBottomContainer
            noBorder={false}
            isSticky
            contentWrapperStyle={{ padding: '16px 16px' }}
          >
            <Button
              stretch
              sizeDS="large"
              isLoading={false}
              onPress={onPressConfirmPreferences}
              dataQa="MatchPreferenceSummaryPrimaryButton"
              roundedFocusStyle
              aria-describedby="Find new provider"
              text={tSwitchWizard(
                'MatchPreferenceSummary.primaryButtonText',
                'Looks good',
                undefined
              )}
              variant="primary"
              style={{ margin: 0, width: isMobile ? '100%' : 336 }}
            />
            <BaseButton
              onPress={onPressUpdatePreferences}
              dataQa="MatchPreferenceSummarySecondaryButton"
              style={{ padding: 18 }}
            >
              <TextDS variant="headingMd" colorRole="buttonBrandSecondary">
                {tSwitchWizard(
                  'MatchPreferenceSummary.secondaryButtonText',
                  'Update my preferences',
                  undefined
                )}
              </TextDS>
            </BaseButton>
          </StickyBottomContainer>
        </View>
      </ColumnContainer>
    </FullWidthContainer>
  );
};

export default MatchPreferenceSummary;
