import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { AdminConfigs } from './mainReducer';

export type TwoFAStatus = 'on' | 'off' | 'required' | 'suggested' | 'disabled';
export interface ClientAuthState {
  isRefreshing: boolean;
  isLoggedIn: boolean;
  isRevoking: boolean;
  isUpdating: boolean;
  redirectTo?: string;
  isError: boolean;
  isErrorLoginSSOAction: boolean;
  errorMessage: string;
  adminConfigs: AdminConfigs;
  providerInfo?: ETherapistInfo;
  has2FA: boolean;
  hasPhoneNumber: boolean;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  isLoading: boolean;
  isReady: boolean;
  showEnable2FA: boolean;
  showPhoneError: boolean;
  showVerificationCodeError: boolean;
  showCodeExpiredError: boolean;
  enabled2faSuccess: boolean;
  request2faCodeSuccess: boolean;
  loggedInWith2fa: boolean;
  email: string;
  password: string;
  otpToken: string;
  twoFAStatus?: TwoFAStatus;
  phoneNumberLast2Digits: string;
  invitationKey: string;
  isTokenChecked: boolean | null;
}

export interface LoginResponse {
  access: string;
  accessTTLSeconds: number;
  otpToken: string;
  refresh: string;
  refreshTTLSeconds: number;
  userID: number;
  userPhone?: string;
  redirectTo?: string;
  ttlSeconds?: number;
  twoFAStatus: TwoFAStatus;
  phoneNumberLast2Digits?: string;
}

export interface VerifyResponse {
  access: string;
  accessTTLSeconds: number;
  refresh: string;
  refreshTTLSeconds: number;
  userID: number;
  bypassTwoFactorToken?: boolean;
}
export interface LoginOauth2Response {
  code: string;
  redirect: string;
}
export interface RegisterNewUserResponse {
  userID: number;
  roomID: number;
  redirectURL: string;
}

export interface CognitoRegisterNewUserResponse extends RegisterNewUserResponse {
  authToken: string;
}

export interface ChangePasswordResponse {
  email: string;
  redirectLink?: string;
}

export type ClientAuthActionTypes =
  | { type: 'postLogIn' }
  | { type: 'postLogInSuccess' }
  | {
      type: 'postLogInSuccessWithCognito';
      payload: Pick<ClientAuthState, 'loggedInWith2fa' | 'email' | 'password'>;
    }
  | {
      type: 'postLogInSuccessWithEmailVerification';
      payload: Pick<ClientAuthState, 'redirectTo'>;
    }
  | {
      type: 'postLogInSuccessWithOauth';
      payload: Pick<ClientAuthState, 'redirectTo'>;
    }
  | { type: 'postRegister' }
  | { type: 'postLogOut' }
  | { type: 'postRefreshToken' }
  | { type: 'postRefreshTokenSuccess' }
  | { type: 'postForgotPassword' }
  | { type: 'postForgotPasswordSuccess' }
  | { type: 'postChangePassword' }
  | { type: 'loadProvider' }
  | { type: 'loadProviderSuccess'; payload: Pick<ClientAuthState, 'providerInfo'> }
  | { type: 'requestValidateVerificationCode' }
  | {
      type: 'receivedInvalidVerificationCode';
      payload: Pick<ClientAuthState, 'showVerificationCodeError'>;
    }
  | { type: 'request2faCode' }
  | { type: 'request2faVerify' }
  | { type: 'requestVerifyAttributeCode' }
  | { type: 'requestVerifyAttributeCodeSuccess' }
  | {
      type: 'request2faCodeSuccess';
      payload: Pick<ClientAuthState, 'request2faCodeSuccess'>;
    }
  | { type: 'clearErrors' }
  | { type: 'setIsError'; error: Error | string }
  | { type: 'setIsErrorLoginSSOAction'; error: Error | string }
  | { type: 'setPhoneNumber'; payload: Pick<ClientAuthState, 'phoneNumber' | 'showPhoneError'> }
  | {
      type: 'setPhoneNumberLast2Digits';
      payload: Pick<ClientAuthState, 'phoneNumberLast2Digits' | 'showPhoneError'>;
    }
  | { type: 'set2faReminder' }
  | {
      type: 'setPhoneNumberCountryCode';
      payload: Pick<ClientAuthState, 'phoneNumberCountryCode' | 'showPhoneError'>;
    }
  | { type: 'setPhoneNumberError'; payload: Pick<ClientAuthState, 'showPhoneError'> }
  | {
      type: 'setVerificationCodeError';
      payload: Pick<ClientAuthState, 'showVerificationCodeError'>;
    }
  | {
      type: 'setCodeExpiredError';
      payload: Pick<ClientAuthState, 'showCodeExpiredError'>;
    }
  | { type: 'getAdminConfigOptions' }
  | { type: 'receiveAdminConfigOptions'; payload: Pick<ClientAuthState, 'adminConfigs'> }
  | {
      type: 'receivedValidVerificationCode';
      payload: Pick<ClientAuthState, 'enabled2faSuccess' | 'showVerificationCodeError'>;
    }
  | {
      type: 'setTwoFAStatus';
      payload: Pick<ClientAuthState, 'twoFAStatus'>;
    }
  | {
      type: 'setOTPToken';
      payload: Pick<ClientAuthState, 'otpToken' | 'phoneNumberLast2Digits'>;
    }
  | {
      type: 'setInvitationKey';
      payload: Pick<ClientAuthState, 'invitationKey'>;
    }
  | {
      type: 'getTwoFAStatusAndPhone';
      payload: Pick<ClientAuthState, 'twoFAStatus' | 'phoneNumberLast2Digits'>;
    }
  | { type: 'clearOTPToken' }
  | { type: 'setTokenCheck'; payload: Pick<ClientAuthState, 'isTokenChecked'> }
  | {
      type: 'receiveGetClient2faStatus';
      payload: Partial<
        Pick<
          ClientAuthState,
          'hasPhoneNumber' | 'phoneNumber' | 'phoneNumberCountryCode' | 'has2FA'
        >
      >;
    };

export const clientAuthInitialState: ClientAuthState = {
  isRefreshing: false,
  isLoggedIn: false,
  isRevoking: false,
  isUpdating: false,
  redirectTo: '',
  isError: false,
  isErrorLoginSSOAction: false,
  errorMessage: '',
  adminConfigs: {},
  has2FA: false,
  hasPhoneNumber: false,
  phoneNumber: '',
  phoneNumberCountryCode: 'US',
  isReady: false,
  isLoading: false,
  showEnable2FA: true,
  showPhoneError: false,
  showVerificationCodeError: false,
  enabled2faSuccess: false,
  request2faCodeSuccess: false,
  loggedInWith2fa: false,
  email: '',
  password: '',
  otpToken: '',
  phoneNumberLast2Digits: '',
  showCodeExpiredError: false,
  invitationKey: '',
  isTokenChecked: null,
};

export const clientAuthReducer = (
  state = clientAuthInitialState,
  action: ClientAuthActionTypes
): ClientAuthState => {
  switch (action.type) {
    case 'postLogIn':
    case 'postLogOut':
    case 'postRegister':
    case 'postRefreshToken':
    case 'postForgotPassword':
    case 'postChangePassword':
    case 'request2faCode':
    case 'request2faVerify':
    case 'requestValidateVerificationCode':
    case 'requestVerifyAttributeCode':
      return {
        ...state,
        isUpdating: true,
        isError: false,
        errorMessage: '',
      };
    case 'postLogInSuccessWithEmailVerification':
    case 'postLogInSuccessWithOauth':
    case 'postLogInSuccessWithCognito':
    case 'request2faCodeSuccess':
    case 'receivedValidVerificationCode':
      return {
        ...state,
        ...action.payload,
        isUpdating: false,
        isError: false,
        errorMessage: '',
      };
    case 'postLogInSuccess':
    case 'postRefreshTokenSuccess':
    case 'postForgotPasswordSuccess':
    case 'requestVerifyAttributeCodeSuccess':
    case 'set2faReminder':
      return {
        ...state,
        isUpdating: false,
        isError: false,
        errorMessage: '',
      };
    case 'getAdminConfigOptions':
      return {
        ...state,
        isUpdating: true,
        isError: false,
        errorMessage: '',
      };

    case 'receiveAdminConfigOptions':
      return {
        ...state,
        adminConfigs: {
          ...state.adminConfigs,
          ...action.payload.adminConfigs,
        },
        isUpdating: false,
        isError: false,
        errorMessage: '',
      };
    case 'setIsError':
      return {
        ...state,
        isUpdating: false,
        isError: true,
        errorMessage: typeof action.error === 'string' ? action.error : action.error.message,
      };
    case 'setIsErrorLoginSSOAction':
      return {
        ...state,
        isUpdating: false,
        isErrorLoginSSOAction: true,
        errorMessage: typeof action.error === 'string' ? action.error : action.error.message,
      };
    case 'receivedInvalidVerificationCode':
    case 'setCodeExpiredError':
      return {
        ...state,
        ...action.payload,
        isUpdating: false,
        isError: false,
        errorMessage: '',
      };
    case 'clearOTPToken':
      return {
        ...state,
        otpToken: '',
      };
    case 'clearErrors':
      return {
        ...state,
        isError: false,
        errorMessage: '',
      };
    case 'loadProviderSuccess':
    case 'setPhoneNumber':
    case 'setPhoneNumberError':
    case 'setPhoneNumberCountryCode':
    case 'setVerificationCodeError':
    case 'receiveGetClient2faStatus':
    case 'setTwoFAStatus':
    case 'setOTPToken':
    case 'setInvitationKey':
    case 'setPhoneNumberLast2Digits':
    case 'getTwoFAStatusAndPhone':
    case 'setTokenCheck':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
