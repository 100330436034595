import {
  ExtraHuge,
  Big,
  Large,
  View,
  CouplesCircle,
  ChatCircle,
  RxCircle,
  Screen,
  useEmotionTheme,
  COLORS,
} from '@talkspace/react-toolkit';
import { ERoom, RoomStatus, RoomType } from 'ts-frontend/entities/Room';
import { useTranslation } from '@talkspace/i18n';
import { ReactElement, useMemo } from 'react';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { Subscription } from 'ts-frontend/types/Subscription';
import { TFUpdateCoverage } from '@talkspace/i18n/types';
import { isPendingPlan, isInactivePlan, isActivePlan } from '../../utils/subscriptionsHelper';
import CoverageCard from '../../components/CoverageCard';
import { useCloseModal, useIsModal } from '@/utils/ModalsContextProvider';
import styled from '../../core/styled';

const Wrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;

  return {
    ...(isMobile && {
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 32,
    }),
  };
});

const getCardTypeProps = (
  roomType: RoomType,
  colors: typeof COLORS,
  tUpdateCoverage: TFUpdateCoverage
) => {
  const card: Record<
    string,
    {
      label: string;
      headerBgColor: string;
      Icon: () => ReactElement;
    }
  > = {
    private_room: {
      label: tUpdateCoverage(
        'updateCoverage.individualTherapyLabel',
        'Individual Therapy',
        undefined
      ),
      headerBgColor: colors.aquaSqueeze,
      Icon: () => <ChatCircle color={colors.blueStone} />,
    },
    couples_room: {
      label: tUpdateCoverage('updateCoverage.couplesTherapyLabel', 'Couples Therapy', undefined),
      headerBgColor: colors.permaSolitudeNew,
      Icon: () => <CouplesCircle color={colors.matisse} />,
    },
    psychiatry_room: {
      label: tUpdateCoverage('updateCoverage.psychiatryLabel', 'Psychiatry', undefined),
      headerBgColor: colors.bizarre,
      Icon: () => <RxCircle color={colors.redDamask} />,
    },
  };
  return card?.[roomType] || null;
};

export const getLastSession = (room: ERoom, subscriptions: Record<string, Subscription>) => {
  let lastSession;
  if (room.roomType === 'psychiatry_room') {
    lastSession =
      subscriptions?.[room.roomID]?.liveVideoSessionStatus?.psychiatryFollowUpLastCompletedAt || '';
  } else {
    lastSession =
      subscriptions?.[room.roomID]?.liveVideoSessionStatus?.therapySessionLastCompletedAt || '';
  }
  return lastSession;
};

interface Props {
  type: 'pending' | 'active' | 'inactive' | 'custom';
  roomStatusToFilter?: RoomStatus[];
  showClosedDueToIneligibility?: boolean;
  rooms: Record<string, ERoom>;
  providers: Record<string, ETherapistInfo>;
  subscriptions: Record<string, Subscription>;
  closeButtonDataQa: string;
  headline: string;
  isLoading: boolean;
  footerText?: string;
  handlePress: (
    room: ERoom,
    coverageCardParams?: { isInsuranceIneligible?: boolean; isClosedIneligible?: boolean }
  ) => void;
}

const PlansList = ({
  type,
  roomStatusToFilter = [],
  showClosedDueToIneligibility,
  rooms,
  providers,
  subscriptions,
  closeButtonDataQa,
  headline,
  isLoading,
  footerText,
  handlePress,
}: Props) => {
  const closeModal = useCloseModal();
  const isModal = useIsModal();
  const { t: tUpdateCoverage } = useTranslation('updateCoverage');

  const { colors } = useEmotionTheme();

  const filteredRoomPlans = useMemo(
    () =>
      Object.values(rooms).filter(
        ({ status }) =>
          ({
            active: isActivePlan(status),
            pending: isPendingPlan(status),
            inactive: isInactivePlan(status),
            custom: roomStatusToFilter.includes(status),
          }[type])
      ),
    [roomStatusToFilter, rooms, type]
  );

  const roomPlansClosedDueToIneligibility = useMemo(
    () =>
      showClosedDueToIneligibility
        ? Object.values(rooms).filter(
            (room) =>
              Object.values(subscriptions).filter(
                (s) => s.isClosedIneligible && s.id === room.roomID
              ).length === 1
          )
        : [],
    [rooms, showClosedDueToIneligibility, subscriptions]
  );

  const plansToShow = useMemo(
    () =>
      [...filteredRoomPlans, ...roomPlansClosedDueToIneligibility].sort(
        (a, b) => a.roomID - b.roomID
      ),
    [filteredRoomPlans, roomPlansClosedDueToIneligibility]
  );

  const hasCards = Boolean(plansToShow?.length);

  const seeNoPlanStyle = hasCards
    ? {
        paddingTop: 32,
        marginTop: 32,
        marginBottom: 68,
        borderTop: `solid ${colors.permaMischka} 1px`,
        width: '100%',
      }
    : {
        marginTop: 32,
        marginBottom: 68,
      };

  return (
    <Screen
      safeAreaVariant={isModal ? 'none' : 'setHeightAndAddTop'}
      showCloseButton
      onCloseButtonClick={closeModal}
      closeButtonDataQa={closeButtonDataQa}
    >
      <Wrapper align="center">
        <ExtraHuge style={{ marginBottom: hasCards ? 24 : 0 }}>{headline}</ExtraHuge>
        {isLoading ? null : (
          <>
            <View align="center" style={{ width: '100%' }}>
              {plansToShow.map((room) => (
                <CoverageCard
                  key={room.roomID}
                  style={{ marginBottom: 24 }}
                  {...getCardTypeProps(room.roomType, colors, tUpdateCoverage)}
                  lastSession={getLastSession(room, subscriptions)}
                  accountType={room.accountType}
                  roomID={room.roomID}
                  roomStatus={room.status}
                  roomType={room.roomType}
                  provider={providers?.[room.therapistID]}
                  handlePress={(coverageCardParams) => handlePress(room, coverageCardParams)}
                />
              ))}
            </View>
            {footerText && (
              <View style={seeNoPlanStyle} justify="center">
                <Big>
                  {tUpdateCoverage(
                    'updateCoverage.planNotFound',
                    'Don’t see your plan?',
                    undefined
                  )}
                </Big>
                <Large style={{ marginTop: 8 }}>{footerText}</Large>
              </View>
            )}
          </>
        )}
      </Wrapper>
    </Screen>
  );
};

export default PlansList;
