import { FunctionComponent, ElementType } from 'react';
import {
  View,
  TouchableView,
  Large,
  Swap,
  CaringProvider,
  PauseSign,
  StopSign,
} from '@talkspace/react-toolkit';
import { ShieldPlus } from '@talkspace/react-toolkit/src/designSystems/icons';
import { useTranslation } from '@talkspace/i18n';
import { TFNewMemberNav } from '@talkspace/i18n/types';
import { SuperbillsIcon } from '../icons';
import styled from '../../core/styled';

const ActionTouchableView = styled(TouchableView)({
  flexDirection: 'row',
  marginTop: 12,
  marginBottom: 12,
  width: 'fit-content',
  alignItems: 'center',
});

const IconWrapper = styled(View)({
  width: 24,
  alignItems: 'center',
});

const LargeBoldWide = styled(Large)(({ theme: { colors } }) => {
  return { fontWeight: 'bold', color: colors.greenText };
});

interface ClickableActionProps {
  onPress: () => void;
  IconComponent: ElementType;
  label: string;
  dataQa?: string;
}

const ClickableAction: FunctionComponent<ClickableActionProps> = ({
  onPress,
  label,
  IconComponent,
  dataQa,
}) => (
  <ActionTouchableView aria-label={label} onPress={onPress} dataQa={dataQa}>
    <IconWrapper>
      <IconComponent />
    </IconWrapper>
    <LargeBoldWide style={{ marginLeft: 11 }}>{label}</LargeBoldWide>
  </ActionTouchableView>
);

interface ActionButtonsProps {
  canChangeProvider?: boolean;
  canViewSuperbills?: boolean;
  canChangePlan?: boolean;
  canCancelPlan?: boolean;
  canPauseService: boolean;
  isPendingSubscription?: boolean;
  isB2B?: boolean;
  handlePauseButtonPress?: () => void;
  handleViewSuperbills?: () => void;
  handleChangeProviderButtonPress?: () => void;
  handleChangePlanButtonPress?: () => void;
  handleCancelPlanButtonPress?: () => void;
  handleUpdateCoverageButtonPress?: () => void;
}

const getCancelLabel = (
  isB2B: boolean | undefined,
  isPendingSubscription: boolean | undefined,
  tnewMemberNav: TFNewMemberNav
) => {
  if (isB2B) return tnewMemberNav('payment.cancel', 'Cancel my plan', undefined);
  return `${
    isPendingSubscription
      ? tnewMemberNav('payment.cancelSub', 'Cancel subscription renewal', undefined)
      : tnewMemberNav('payment.stopSub', 'Stop subscription renewal', undefined)
  }`;
};

const SubscriptionActionButtons: FunctionComponent<ActionButtonsProps> = ({
  canChangeProvider,
  canViewSuperbills,
  canChangePlan,
  canCancelPlan,
  isPendingSubscription,
  isB2B,
  handlePauseButtonPress,
  handleChangePlanButtonPress,
  handleChangeProviderButtonPress,
  handleViewSuperbills,
  handleCancelPlanButtonPress,
  handleUpdateCoverageButtonPress,
  canPauseService,
}) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return (
    <View style={{ marginBottom: 20 }}>
      {canChangeProvider && handleChangeProviderButtonPress ? (
        <ClickableAction
          label={tnewMemberNav('payment.change', 'Change provider', undefined)}
          onPress={handleChangeProviderButtonPress}
          IconComponent={CaringProvider}
          dataQa="changeProvider"
        />
      ) : null}
      {handleUpdateCoverageButtonPress ? (
        <ClickableAction
          label={tnewMemberNav('payment.updateMy', 'Update my coverage', undefined)}
          onPress={handleUpdateCoverageButtonPress}
          IconComponent={() => <ShieldPlus size="major" colorType="brand" />}
          dataQa="updateCoverage"
        />
      ) : null}
      {canViewSuperbills && handleViewSuperbills ? (
        <ClickableAction
          label={tnewMemberNav('payment.view', 'View superbills', undefined)}
          onPress={handleViewSuperbills}
          IconComponent={SuperbillsIcon}
          dataQa="viewSuperbills"
        />
      ) : null}
      {canPauseService && handlePauseButtonPress ? (
        <ClickableAction
          label={tnewMemberNav('payment.pause', 'Pause therapy', undefined)}
          onPress={handlePauseButtonPress}
          IconComponent={PauseSign}
          dataQa="pauseTherapy"
        />
      ) : null}
      {canChangePlan && handleChangePlanButtonPress ? (
        <ClickableAction
          label={tnewMemberNav('payment.changePlan', 'Change plan', undefined)}
          onPress={handleChangePlanButtonPress}
          IconComponent={Swap}
          dataQa="changePlan"
        />
      ) : null}
      {canCancelPlan && handleCancelPlanButtonPress ? (
        <ClickableAction
          label={getCancelLabel(isB2B, isPendingSubscription, tnewMemberNav)}
          onPress={handleCancelPlanButtonPress}
          IconComponent={StopSign}
          dataQa="cancelSubscriptionButton"
        />
      ) : null}
    </View>
  );
};

export default SubscriptionActionButtons;
