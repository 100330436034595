import Check from '../../icons/Check';
import { useEmotionTheme } from '../../../core/styled';
import { ColorRolesVersion } from '../../types';
import Checkbox, { CheckboxProps } from './Checkbox';
import TextDS from '../typography/TextDS';

interface GetInteractiveStylesProps {
  isChecked: boolean;
  colorRoles: ColorRolesVersion;
  isDisabled?: boolean;
}
const getInteractiveStyles = ({ isChecked, colorRoles, isDisabled }: GetInteractiveStylesProps) => {
  const checkedStyles = {
    backgroundColor: colorRoles.surfaces.interactiveBoldDefault,
    border: 'unset',
    '&:hover': {
      backgroundColor: colorRoles.surfaces.interactiveBoldHovered,
    },
    '&:active': {
      backgroundColor: colorRoles.surfaces.interactiveBoldPressed,
    },
  };

  const notCheckedSytles = {
    backgroundColor: 'unset',
    border: `1.5px solid ${colorRoles.borders.borderInteractiveDefault}`,
    '&:hover': {
      backgroundColor: colorRoles.surfaces.surfaceInteractiveHovered,
    },
    '&:active': {
      backgroundColor: colorRoles.surfaces.surfaceInteractivePressed,
    },
  };

  const disabledStyles = {
    backgroundColor: colorRoles.surfaces.surfaceInteractiveDisabledBold,
    border: 'unset',
  };

  if (isDisabled) {
    return disabledStyles;
  }

  return isChecked ? checkedStyles : notCheckedSytles;
};

const CheckboxV1 = (props: CheckboxProps) => {
  const { isChecked, label, checkComponent, checkboxStyle, containerStyle, isDisabled } = props;
  const { colorRoles } = useEmotionTheme();

  const interactiveStyles = getInteractiveStyles({ isChecked, colorRoles, isDisabled });

  return (
    <Checkbox
      {...props}
      label={
        <TextDS variant="bodySm" colorRole="textDefault">
          {label}
        </TextDS>
      }
      checkComponent={checkComponent || <Check colorType="inverse" state="default" />}
      checkboxStyle={{
        marginRight: 6,
        height: 20,
        width: 20,
        ...interactiveStyles,
        ...checkboxStyle,
      }}
      containerStyle={{
        padding: 2,
        gap: 6,
        ...containerStyle,
      }}
    />
  );
};

export default CheckboxV1;
