import { FunctionComponent } from 'react';
import { useWindowWidthState, View } from '@talkspace/react-toolkit';
import { VerifyEmail } from '../../../components/icons';
import SummaryCardActionBanner from '../SummaryCardActionBanner/SummaryCardActionBanner';
import styled from '../../../core/styled';

const BoldSpan = styled.span({ fontWeight: 'bold' });

interface Props {
  currentEmail: string;
  pendingEmail: string;
  handleResendEmailVerificationPress: () => void;
}

const ManageEmailVerificationCard: FunctionComponent<Props> = ({
  currentEmail,
  pendingEmail,
  handleResendEmailVerificationPress,
}) => {
  const { isMobile } = useWindowWidthState();

  return (
    <SummaryCardActionBanner
      dataQa="myAccountResendVerificationEmailButton"
      isMobile={isMobile}
      title="Verify your email"
      style={{ margin: '20px 0', width: '100%' }}
      subtitle={() =>
        pendingEmail ? (
          <>
            To verify your email address, click the link emailed to{' '}
            <BoldSpan>{pendingEmail}. </BoldSpan>
            Until then, Talkspace will continue to use <BoldSpan>{currentEmail}.</BoldSpan>
          </>
        ) : (
          <>
            To verify your email address, click the link emailed to{' '}
            <BoldSpan>{currentEmail}</BoldSpan>
          </>
        )
      }
      iconComponent={
        <View>
          <VerifyEmail />
        </View>
      }
      buttonTitle="Resend Verification"
      primaryOnPress={handleResendEmailVerificationPress}
    />
  );
};

export default ManageEmailVerificationCard;
