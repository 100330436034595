/* eslint-disable import/prefer-default-export */

export const buildQmFlowPath = ({
  source,
  fromPage,
  updateCoverageRoomID,
  serviceType,
  skipServiceSelection,
  previousTherapistId,
}: {
  source?: string;
  fromPage?: string;
  updateCoverageRoomID?: number;
  serviceType?: string;
  skipServiceSelection?: boolean;
  previousTherapistId?: number;
}) => {
  const params = new URLSearchParams();

  if (source) params.append('source', source);
  if (fromPage) params.append('fromPage', fromPage);
  if (updateCoverageRoomID) params.append('updateCoverageRoomID', updateCoverageRoomID.toString());
  if (serviceType) params.append('serviceType', serviceType);
  if (previousTherapistId) params.append('previousTherapistId', previousTherapistId.toString());
  if (skipServiceSelection !== undefined) {
    params.append('skipServiceSelection', skipServiceSelection.toString());
  }
  return `/dispatcher?${params.toString()}`;
};
