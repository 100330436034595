import {
  ComponentPropsWithoutRef,
  forwardRef,
  useRef,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react';
import View from '../View';
import styled from '../../core/styled';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import useShareForwardedRef from '../../hooks/useShareForwardedRef';

const ScrollViewComponent = styled(View)({
  flex: 1,
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: 10,
    visibility: 'hidden',
  },
  '::-webkit-scrollbar-thumb': {
    margin: 5,
    backgroundColor: '#D8D8E0',
    WebkitBorderRadius: 5,
    visibility: 'hidden',
  },
  '::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#BECBE0',
  },
  '::-webkit-scrollbar-track': {
    margin: 4,
  },
  ':hover': {
    '::-webkit-scrollbar': {
      visibility: 'visible',
    },
    '::-webkit-scrollbar-thumb': {
      visibility: 'visible',
    },
  },
});

interface ScrollViewContextProps {
  scrollTo: (position: number) => void;
  scrollToBottom: () => void;
}

const ScrollViewContext = createContext<ScrollViewContextProps | undefined>(undefined);

interface Props extends ComponentPropsWithoutRef<typeof ScrollViewComponent> {
  onScrollChange?: (scroll: number) => void;
}

const ScrollView = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { onScrollChange = () => null, ...otherProps } = props;

  const scrollViewRef = useShareForwardedRef<HTMLDivElement>(ref);
  const { isMobile } = useWindowWidthState();
  const tickingRef = useRef<boolean>(false);

  useEffect(() => {
    if (!scrollViewRef.current || !onScrollChange) return undefined;
    const { current: scrollView } = scrollViewRef;

    function handleScroll() {
      if (!tickingRef.current) {
        window.requestAnimationFrame(() => {
          if (!scrollView) return;
          if (onScrollChange) onScrollChange(scrollView.scrollTop);
          tickingRef.current = false;
        });
        tickingRef.current = true;
      }
    }

    handleScroll();
    scrollViewRef.current.addEventListener('scroll', handleScroll);

    return () => {
      if (scrollView) scrollView.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, onScrollChange, scrollViewRef]);

  const scrollTo = useCallback(
    (position: number) => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTop = position;
      }
    },
    [scrollViewRef]
  );

  const scrollToBottom = useCallback(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  }, [scrollViewRef]);

  return (
    <ScrollViewContext.Provider value={{ scrollTo, scrollToBottom }}>
      <ScrollViewComponent ref={scrollViewRef} {...otherProps} />
    </ScrollViewContext.Provider>
  );
});

export const useScrollViewActions = () => {
  const context = useContext(ScrollViewContext);
  if (!context) {
    throw new Error('useScrollViewActions must be used within a ScrollViewProvider');
  }
  return context;
};

export default ScrollView;
