import { VoidFunctionComponent, useCallback } from 'react';
import TouchableView from '../TouchableView';
import styled, { useEmotionTheme } from '../../core/styled';
import { Standard, Large } from '../Typography';
import BaseButton from '../BaseButton';
import View from '../View';
import { CaretLeft, CaretRight } from '../icons';
import { useWindowWidthState } from '../../hooks/windowWidthContext';

interface ToolBarProps {
  dateStart: Date;
  dateEnd: Date;
  handleEditAvailabilityPress: () => void;
  handlePreviousPress: () => void;
  handleNextPress: () => void;
  handleTodayPress: () => void;
}

const ToolBarContainer = styled(View)(() => {
  return {
    marginBottom: 16,
  };
});

const TodayButton = styled(BaseButton)<{ isMobile?: boolean; isMedium?: boolean }>(
  ({ theme: { colors }, isMedium, isMobile }) => {
    return {
      width: 100,
      height: 44,
      borderRadius: 7,
      border: `solid ${colors.permaEden} 1px`,
      '&:hover': {
        background: '#F6F8FB',
      },
      '&:active': {
        background: colors.permaSolitude,
      },
      ...(isMobile && {
        width: 40,
        border: 'none',
      }),
      ...(isMedium && {
        width: 77,
      }),
    };
  }
);

const CaretButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    width: 30,
    height: 30,
    paddingTop: 3,
    borderRadius: '50%',
    '&:hover': {
      background: '#F6F8FB',
    },
    '&:active': {
      background: colors.permaSolitude,
    },
  };
});

const EditAvailabilityText = styled(Standard)(({ theme: { colors } }) => {
  return {
    color: colors.permaVeryDarkCyan,
    fontWeight: 700,
  };
});

const MonthYearText = styled(Large)<{ isMobile: boolean }>(({ isMobile }) => {
  const desktopStyle = !isMobile
    ? {
        fontSize: 25,
        maxWidth: 'initial',
        whiteSpace: 'initial',
        overflowX: 'initial',
        overflowY: 'initial',
        textOverFlow: 'initial',
      }
    : {};
  return {
    fontWeight: 700,
    maxWidth: 112,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    ...desktopStyle,
  };
});

const DateNavigatorContainer = styled(View)<{ isMobile?: boolean }>(({ isMobile }) => {
  const mobileStyle = isMobile ? { marginLeft: 5, marginRight: 5 } : {};
  return {
    marginLeft: 30,
    marginRight: 20,
    ...mobileStyle,
  };
});

const ToolBar: VoidFunctionComponent<ToolBarProps> = ({
  dateStart,
  dateEnd,
  handleEditAvailabilityPress,
  handlePreviousPress,
  handleNextPress,
  handleTodayPress,
}) => {
  const { isMobile, isMedium } = useWindowWidthState();
  const { colors } = useEmotionTheme();

  const buildDateRange = useCallback(() => {
    const startMonth = dateStart?.toLocaleString('default', { month: 'short' });
    const endMonth = dateEnd?.toLocaleString('default', { month: 'short' });
    const yearStart = dateStart?.getFullYear().toString();
    const yearEndString = dateEnd?.getFullYear().toString();
    const yearStartString = yearStart < yearEndString ? yearStart : '';
    const dateRangeText =
      startMonth === endMonth
        ? `${startMonth} ${yearEndString}`
        : `${startMonth} ${yearStartString} - ${endMonth} ${yearEndString}`;
    return dateRangeText;
  }, [dateEnd, dateStart]);

  return (
    <ToolBarContainer row justify="space-between" align="center">
      <View row align="center">
        <TodayButton onPress={handleTodayPress} isMedium={isMedium} isMobile={isMobile}>
          <Standard variant="standardBoldDarkCyan">Today</Standard>
        </TodayButton>
        <DateNavigatorContainer row isMobile={isMobile}>
          <CaretButton onPress={handlePreviousPress}>
            <CaretLeft height={14} width={8} color={colors.permaEden} />
          </CaretButton>
          <CaretButton onPress={handleNextPress}>
            <CaretRight height={14} width={8} color={colors.permaEden} />
          </CaretButton>
        </DateNavigatorContainer>
        <MonthYearText isMobile={isMobile}>{buildDateRange()}</MonthYearText>
      </View>
      <TouchableView
        onPress={handleEditAvailabilityPress}
        row
        style={{ position: 'relative' }}
        align="center"
      >
        <EditAvailabilityText>Edit availability</EditAvailabilityText>
        {!isMobile && (
          <CaretRight
            style={{ marginLeft: 6, marginTop: 6 }}
            width={7}
            strokeWidth={2}
            strokeLinecap="round"
            color={colors.permaVeryDarkCyan}
          />
        )}
      </TouchableView>
    </ToolBarContainer>
  );
};

export default ToolBar;
