import { FunctionComponent } from 'react';

import { useEmotionTheme } from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useNewMemberNav } from 'launchDarkly/hooks';
import styled from '@/core/styled';
import BaseAccordion from './Base';
import NotificationTitle from '../NotificationTitle';
import NotificationPanelRow from '../NotificationPanelRow';
import { CellPhone, DailyReminder, EmailEnvelope } from '../../../components/icons';
import constructDescription from './helpers';
import { arrowContainerStyles, bodyStyle, titleContainerStyles } from '../../myAccountStyles';

const getBorderRadius = (isMobile: boolean) => (isMobile ? 0 : 10);

const Accordion = styled(BaseAccordion)<{
  isMobile: boolean;
  useNewNav: boolean;
}>(({ isMobile, useNewNav, theme: { colors } }) => {
  const oldNavStyling = {
    borderTopLeftRadius: getBorderRadius(isMobile),
    borderTopRightRadius: getBorderRadius(isMobile),
    borderTop: isMobile ? 0 : `1px solid ${colors.iconLightGrey}`,
    maxWidth: isMobile ? '100%' : 500,
  };
  const newNavStyling = {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderTop: `1px solid ${colors.iconLightGrey}`,
    maxWidth: 'unset',
  };
  return useNewNav ? newNavStyling : oldNavStyling;
});

const StyledCellPhone = styled(CellPhone)(() => {
  return {
    width: '22px',
  };
});

interface Props {
  isOpen: boolean;
  handlePress: (value: boolean) => void;
  isMobile: boolean;
  accountUpdate: { email: boolean; push: boolean; sms: boolean };
  handlePushToggle: (value: number) => void;
  handleSMSToggle: (value: number) => void;
  style?: {};
}

const AccountUpdatesAccordion: FunctionComponent<Props> = ({
  isOpen,
  handlePress,
  isMobile,
  accountUpdate,
  handlePushToggle,
  handleSMSToggle,
  style,
}) => {
  const { colors, colorRoles } = useEmotionTheme();
  const { matchSmsNotification } = useFlags<{ matchSmsNotification: boolean }>();
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const useNewNav = useNewMemberNav();

  return (
    <Accordion
      bodyStyle={bodyStyle}
      titleContainerStyles={titleContainerStyles}
      isMobile={isMobile}
      useNewNav={useNewNav}
      open={isOpen}
      onPress={handlePress}
      disabled={false}
      style={style}
      titleComponent={() => (
        <NotificationTitle
          title={tnewMemberNav('notifications.account', 'Account updates', undefined)}
          isOpen={isOpen}
          isMobile={isMobile}
          subTitle={
            isOpen
              ? tnewMemberNav(
                  'notifications.important',
                  'Important information about your account, subscription, and live sessions.',
                  undefined
                )
              : constructDescription(accountUpdate, tnewMemberNav)
          }
        />
      )}
      arrowStyles={{
        fill: useNewNav ? colorRoles.icons.iconDefaultDefault : colors.periwinkleGrey,
      }}
      arrowContainerStyles={arrowContainerStyles}
    >
      <NotificationPanelRow
        label={tnewMemberNav('notifications.push', 'Push', undefined)}
        iconComponent={() => <DailyReminder />}
        isOn={accountUpdate.push}
        onToggle={handlePushToggle}
        name="accountUpdatesPush"
      />
      <NotificationPanelRow
        label={tnewMemberNav('notifications.email', 'Email', undefined)}
        iconComponent={() => <EmailEnvelope />}
        isOn
        onToggle={() => undefined}
        isRequired
        name="accountUpdatesEmail"
      />
      {matchSmsNotification && (
        <NotificationPanelRow
          label={tnewMemberNav('notifications.sms', 'SMS', undefined)}
          iconComponent={() => <StyledCellPhone />}
          isOn={accountUpdate.sms}
          onToggle={handleSMSToggle}
          name="accountUpdatesSMS"
        />
      )}
    </Accordion>
  );
};

export default AccountUpdatesAccordion;
