import { FunctionComponent } from 'react';
import { useEmotionTheme } from '@talkspace/react-toolkit';
import { GenericWizardWelcomeScreen } from 'stepWizard';
import { useTranslation } from '@talkspace/i18n';
import peopleImage from '../../assets/peopleImage.png';

const WelcomeScreen: FunctionComponent<{
  handleStartButton: () => void;
}> = ({ handleStartButton }) => {
  const { colors } = useEmotionTheme();
  const { t: tSwitchWizard } = useTranslation('switchWizard');
  return (
    <GenericWizardWelcomeScreen
      firstHeadlineRowText={tSwitchWizard('WelcomeScreen.titleRow1', 'Find a new', undefined)}
      secondHeadlineRowText={tSwitchWizard('WelcomeScreen.titleRow2', 'provider', undefined)}
      subHeadlineText={tSwitchWizard('WelcomeScreen.subtitle', '5 questions - 2min', undefined)}
      description={tSwitchWizard(
        'WelcomeScreen.description',
        "Finding the right fit with a provider is essential to making progress. Let's go over your information and identify what you're looking for in your new provider.",
        undefined
      )}
      onButtonPress={handleStartButton}
      buttonText={tSwitchWizard('WelcomeScreen.begin', 'Begin', undefined)}
      imageURL={peopleImage}
      imageHeight={206}
      imageWidth={226}
      imageXPos={161}
      imageYPos={56}
      imageMobileXPos={34}
      imageMobileYPos={120}
      backgroundColor={colors.welcomeBlue}
    />
  );
};

export default WelcomeScreen;
