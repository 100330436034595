import { VFC } from 'react';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import DetailsRow from '../DetailsRow';
import GlobeWithClock from '../icons/GlobeWithClock';

const getFormattedTimeZone = (timezone: string) => {
  if (!timezone) {
    return null;
  }
  if (timezone.toLowerCase().includes('etc/')) {
    return null;
  }
  if (timezone.includes('/')) {
    const [region, city] = timezone.split('/');
    return `${region} - ${city.replace('_', ' ')}`;
  }
  return null;
};

interface BookingTimeZoneProps {
  timezone: string;
  style?: EmotionStyle;
}

const StyledDetailsRow = styled(DetailsRow)(({ theme: { colors } }) => {
  return {
    margin: '0 auto',
    p: {
      color: colors.slateGrey,
      paddingTop: 2,
      fontSize: 13,
    },
  };
});

const BookingTimeZone: VFC<BookingTimeZoneProps> = ({ timezone, style }) => {
  const { colors } = useEmotionTheme();
  const formattedTimezone = getFormattedTimeZone(timezone);
  if (!formattedTimezone) {
    return null;
  }
  return (
    <StyledDetailsRow
      text={formattedTimezone}
      style={style}
      renderLeftContainerStyle={{ minWidth: 'auto' }}
      renderLeft={() => (
        <GlobeWithClock size={25} style={{ marginRight: 4 }} color={colors.slateGrey} />
      )}
    />
  );
};

export default BookingTimeZone;
