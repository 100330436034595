import { forwardRef, useState, RefCallback } from 'react';
import * as React from 'react';
import View from '../View';
import Label from '../Label';
import Tiny from '../Typography/Tiny';
import MaskedInput from './MaskedInput';
import Tooltip from '../Tooltip';
import styled, { EmotionStyle, useEmotionTheme, EmotionTheme } from '../../core/styled';
import commonStyles from '../../constants/commonStyles';
import useShareForwardedRef from '../../hooks/useShareForwardedRef';
import { useUniqueID } from '../../hooks/a11yHelper';
import { spacing } from '../../designSystems/tokens';
import { CircleExclamation } from '../../designSystems/icons';

const { space100, space400 } = spacing;
const { roundedFocusRing } = commonStyles;

interface InputPropsOverwrite {
  onSubmitEditing?(): void;
  onChangeValue?(value: string): void;
  dataQa?: string;
  value?: string;
  placeholder?: string;
  shouldShowErrorIcon?: boolean;
  maskType?: string;
  isDisabled?: boolean;
  placeHolderStyle?: EmotionStyle;
  ariaDescribedBy?: string;

  /**
   * When wrappedInputProps prop is provided the input renders with a trivial wrapper that allows for a keyboard-specific focus effect
   * that contours with the border of the input itself, as per the new designs. The keys within the prop object allow for
   * a more accessible input to be constructed, with label and error messages properly associated when their respective values are provided
   */
  wrappedInputProps?: {
    label?: string;
    tooltip?: string;
    tooltipLabel?: string;
    isError?: boolean;
    errorMessage?: string;
    labelStyle?: EmotionStyle;
    inputStyle?: EmotionStyle;
    wrapperStyle?: EmotionStyle;
    errorStyle?: EmotionStyle;
    containerStyle?: EmotionStyle;
    tooltipStyle?: EmotionStyle;
    tooltipMessageStyle?: EmotionStyle;
    tooltipTextColor?: string;
    tooltipBackgroundColor?: string;
    tooltipBorder?: boolean;
    tooltipBorderColor?: string;
    isErrorAlert?: boolean;
    hint?: string;
  };
  id?: string;
  ariaRequired?: boolean;
  inputRef?: RefCallback<HTMLInputElement>;
}

// this styling is line with the new QM styles
const wrappedInputStyles = (
  colors: EmotionTheme['colors'],
  isError?: boolean,
  placeHolderStyle?: EmotionStyle,
  isDisabled?: boolean,
  borderColor?: string,
  errorBorderColor?: string
) => {
  return {
    border: `1px solid ${
      isError ? errorBorderColor || colors.permaErrorRed : borderColor || colors.permaWildBlueYonder
    }`,
    height: 55,
    margin: 0,
    padding: 0,
    borderRadius: 9,
    color: isDisabled ? colors.periwinkleGreyText : undefined,
    backgroundColor: isDisabled ? colors.permaLinkWaterGrey : undefined,
    fontSize: 16,
    fontWeight: 400,
    font: 'roboto',
    caretColor: colors.green,
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    // padding added to line up the input with the placeholder
    paddingLeft: 17,
    '::placeholder': {
      color: colors.placeholderGrey,
      ...placeHolderStyle,
    },
  };
};

const StyledInputComponent = styled.input<{ inputStyle?: EmotionStyle }>(({ inputStyle }) => {
  return { fontFamily: "'Roboto', sans-serif", '&:focus': { outline: 'none' }, ...inputStyle };
});

const Error = styled(Tiny)(({ theme: { colors } }) => {
  return {
    fontWeight: 400,
    color: colors.permaErrorRed,
    textAlign: 'left',
  };
});

const StyledLabel = styled(Label)({
  marginBottom: 7,
});

const Hint = styled(Label)({
  alignSelf: 'flex-start',
  paddingTop: 4,
  paddingLeft: 0,
});

const StyledLabelContainer = styled(View)(() => {
  return {
    alignItems: 'center',
  };
});

const InputWrapper = styled(View)<{
  isError?: boolean;
  isKeyboardFocused?: boolean;
  isAutoFocused?: boolean;
}>(({ isError, isKeyboardFocused, isAutoFocused, theme: { colors } }) => {
  return {
    borderRadius: 9,
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    ...roundedFocusRing(
      colors.permaWildBlueYonder,
      !!(isKeyboardFocused && !isAutoFocused),
      isError
    ),
  };
});

type InputProps = React.ComponentPropsWithoutRef<'input'> & InputPropsOverwrite;

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      dataQa,
      value,
      onChangeValue,
      onSubmitEditing,
      onChange: onChangeProp,
      type,
      onBlur,
      onFocus,
      onMouseDown,
      maskType,
      wrappedInputProps,
      id,
      ariaRequired,
      placeHolderStyle,
      ariaDescribedBy,
      inputRef,
      isDisabled,
      shouldShowErrorIcon,
      ...otherProps
    },
    ref
  ) => {
    const { colors } = useEmotionTheme();
    let InputComponent;
    if (maskType) {
      InputComponent = MaskedInput;
    } else {
      InputComponent = StyledInputComponent;
    }
    const [isFocused, setIsFocused] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const innerRef = useShareForwardedRef(ref);
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChangeProp) onChangeProp(e);
      if (onChangeValue) onChangeValue(e.target.value);
    };
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      onFocus?.(e);
      setIsFocused(true);
    };
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.(e);
      setIsClicked(false);
      setIsFocused(false);
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
      onMouseDown?.(e);
      setIsClicked(true);
    };
    const onKeyPress = (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
      const code = keyEvent.charCode || keyEvent.keyCode;
      if (code === 13) {
        if (onSubmitEditing) onSubmitEditing();
        innerRef.current?.blur?.();
      }
    };
    const inputId = useUniqueID('inputId');
    const errorId = useUniqueID('errorId');

    let finalAriaDescribedBy = ariaDescribedBy;
    if (wrappedInputProps?.isError && wrappedInputProps?.errorMessage) {
      finalAriaDescribedBy = finalAriaDescribedBy ? `${errorId} ${finalAriaDescribedBy}` : errorId;
    }
    return wrappedInputProps ? (
      <View style={{ width: 335, ...wrappedInputProps?.containerStyle }}>
        {wrappedInputProps?.label && (
          <StyledLabelContainer row>
            <StyledLabel isDefaultStyle style={wrappedInputProps?.labelStyle} htmlFor={inputId}>
              {wrappedInputProps.label}
            </StyledLabel>
            {wrappedInputProps?.tooltip && (
              <Tooltip
                tip={wrappedInputProps.tooltip}
                label={wrappedInputProps.tooltipLabel || 'Tooltip'}
                dataQa={`${dataQa}Tooltip`}
                roundedFocusStyle
                questionMarkSize={15}
                buttonStyle={{
                  marginTop: 2,
                  marginBottom: 7,
                  marginLeft: 2,
                  display: 'flex',
                  borderRadius: 5,
                  color: colors.permaWildBlueYonder,
                  ...wrappedInputProps?.tooltipStyle,
                }}
                textColor={wrappedInputProps.tooltipTextColor}
                backgroundColor={wrappedInputProps.tooltipBackgroundColor}
                messageStyle={wrappedInputProps.tooltipMessageStyle}
                border={wrappedInputProps.tooltipBorder}
                borderColor={wrappedInputProps.tooltipBorderColor}
              />
            )}
          </StyledLabelContainer>
        )}
        <InputWrapper
          style={{
            flexDirection: wrappedInputProps?.hint ? 'column' : 'row',
            ...wrappedInputProps?.wrapperStyle,
            position: shouldShowErrorIcon ? 'relative' : null,
          }}
          isKeyboardFocused={isFocused && !isClicked}
          isError={wrappedInputProps.isError}
        >
          <InputComponent
            id={id || inputId}
            aria-describedby={finalAriaDescribedBy}
            aria-required={ariaRequired}
            aria-invalid={wrappedInputProps.isError}
            data-qa={dataQa}
            {...otherProps}
            ref={innerRef}
            // MaskedInput looks for inputRef prop
            inputRef={inputRef}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onMouseDown={handleMouseDown}
            type={type || 'text'}
            onKeyPress={onKeyPress || undefined}
            maskType={maskType}
            inputStyle={{
              ...wrappedInputStyles(
                colors,
                wrappedInputProps.isError,
                !maskType && placeHolderStyle ? placeHolderStyle : undefined,
                isDisabled,
                // TODO: Temporary solution until we solve EmotionStyle = object
                wrappedInputProps.inputStyle && 'borderColor' in wrappedInputProps.inputStyle
                  ? (wrappedInputProps.inputStyle as Record<string, string>).borderColor
                  : undefined,
                wrappedInputProps.errorStyle && 'borderColor' in wrappedInputProps.errorStyle
                  ? (wrappedInputProps.errorStyle as Record<string, string>).borderColor
                  : undefined
              ),
              ...wrappedInputProps?.inputStyle,
            }}
            value={value}
            // placeholderStyle carries to masked input if maskType prop provided
            placeHolderStyle={maskType && placeHolderStyle ? placeHolderStyle : undefined}
            disabled={isDisabled}
          />
          {shouldShowErrorIcon && wrappedInputProps.isError && (
            <View style={{ position: 'absolute', right: `${space100}px`, top: `${space400}%` }}>
              <CircleExclamation colorType="critical" />
            </View>
          )}
          {!wrappedInputProps.isError && wrappedInputProps.hint && (
            <Hint>{wrappedInputProps.hint}</Hint>
          )}
        </InputWrapper>
        <View
          aria-live={wrappedInputProps.isErrorAlert ? 'polite' : undefined}
          style={{ display: 'fit-content', margin: 0, padding: 0 }}
        >
          {wrappedInputProps?.isError && (
            <Error id={errorId} style={wrappedInputProps?.errorStyle} dataQa={`${dataQa}-error`}>
              {wrappedInputProps?.errorMessage}
            </Error>
          )}
        </View>
      </View>
    ) : (
      <InputComponent
        id={id || inputId}
        data-qa={dataQa}
        aria-required={ariaRequired}
        {...otherProps}
        ref={innerRef}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        type={type || 'text'}
        onKeyPress={onKeyPress || undefined}
        maskType={maskType}
        value={value}
        placeHolderStyle={maskType && placeHolderStyle ? placeHolderStyle : undefined}
      />
    );
  }
);

export default Input;
