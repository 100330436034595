import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M15 8.89648C15 10.3309 14.5344 11.6559 13.75 12.7309L17.7063 16.6902C18.0969 17.0809 18.0969 17.7152 17.7063 18.1059C17.3156 18.4965 16.6812 18.4965 16.2906 18.1059L12.3344 14.1465C11.2594 14.934 9.93437 15.3965 8.5 15.3965C4.90937 15.3965 2 12.4871 2 8.89648C2 5.30586 4.90937 2.39648 8.5 2.39648C12.0906 2.39648 15 5.30586 15 8.89648ZM8.5 13.3965C9.09095 13.3965 9.67611 13.2801 10.2221 13.0539C10.768 12.8278 11.2641 12.4963 11.682 12.0785C12.0998 11.6606 12.4313 11.1645 12.6575 10.6186C12.8836 10.0726 13 9.48743 13 8.89648C13 8.30554 12.8836 7.72037 12.6575 7.17441C12.4313 6.62844 12.0998 6.13237 11.682 5.7145C11.2641 5.29664 10.768 4.96517 10.2221 4.73903C9.67611 4.51288 9.09095 4.39648 8.5 4.39648C7.90905 4.39648 7.32389 4.51288 6.77792 4.73903C6.23196 4.96517 5.73588 5.29664 5.31802 5.7145C4.90016 6.13237 4.56869 6.62844 4.34254 7.17441C4.1164 7.72037 4 8.30554 4 8.89648C4 9.48743 4.1164 10.0726 4.34254 10.6186C4.56869 11.1645 4.90016 11.6606 5.31802 12.0785C5.73588 12.4963 6.23196 12.8278 6.77792 13.0539C7.32389 13.2801 7.90905 13.3965 8.5 13.3965Z';
const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
};

const MagnifyingGlass = withDSIconMaker(pathConfig, 'magnifying glass');

export default MagnifyingGlass;
