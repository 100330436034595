import { TFNewMemberNav } from '@talkspace/i18n/types';

type Description =
  | 'Off'
  | 'Push only'
  | 'Email only'
  | 'SMS only'
  | 'Push, email'
  | 'Push, SMS'
  | 'Email, SMS'
  | 'Push, email, SMS'
  | 'On';

export default function constructDescription(
  {
    email,
    push,
    sms,
  }: {
    email: boolean;
    push: boolean;
    sms?: boolean;
  },
  tnewMemberNav: TFNewMemberNav
): Description {
  // Add only truthy elements to array to get count
  const truthyPrefs = [email, push, sms].filter(Boolean);

  if (truthyPrefs.length === 0)
    return tnewMemberNav('notifications.off', 'Off', undefined) as Description;
  if (truthyPrefs.length === 1) {
    if (email)
      return tnewMemberNav('notifications.emailOnly', 'Email only', undefined) as Description;
    if (push) return tnewMemberNav('notifications.pushOnly', 'Push only', undefined) as Description;
    if (sms) return tnewMemberNav('notifications.smsOnly', 'SMS only', undefined) as Description;
  } else if (truthyPrefs.length === 2) {
    if (email && push)
      return tnewMemberNav('notifications.pushEmail', 'Push, email', undefined) as Description;
    if (email && sms)
      return tnewMemberNav('notifications.emailSMS', 'Email, SMS', undefined) as Description;
    if (push && sms)
      return tnewMemberNav('notifications.pushSMS', 'Push, SMS', undefined) as Description;
  }
  return tnewMemberNav('notifications.on', 'On', undefined) as Description;
}
