import { FunctionComponent } from 'react';

import { useEmotionTheme } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useTranslation } from '@talkspace/i18n';
import BaseAccordion from './Base';
import NotificationTitle from '../NotificationTitle';
import NotificationPanelRow from '../NotificationPanelRow';
import { DailyReminder, EmailEnvelope } from '../../../components/icons';
import constructDescription from './helpers';
import { arrowContainerStyles, bodyStyle, titleContainerStyles } from '../../myAccountStyles';

interface Props {
  isOpen: boolean;
  handlePress: (value: boolean) => void;
  isMobile: boolean;
  superbills: { email: boolean; push: boolean };
  handlePushToggle: (value: number) => void;
  handleEmailToggle: (value: number) => void;
}

const SuperbillsAccordion: FunctionComponent<Props> = ({
  isOpen,
  handlePress,
  isMobile,
  superbills,
  handlePushToggle,
  handleEmailToggle,
}) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const { colors, colorRoles } = useEmotionTheme();
  const useNewNav = useNewMemberNav();

  return (
    <BaseAccordion
      bodyStyle={bodyStyle}
      titleContainerStyles={titleContainerStyles}
      open={isOpen}
      onPress={handlePress}
      disabled={false}
      titleComponent={() => (
        <NotificationTitle
          title={tnewMemberNav('notifications.superbills', 'Superbills', undefined)}
          isOpen={isOpen}
          isMobile={isMobile}
          subTitle={
            isOpen
              ? tnewMemberNav('notifications.notification', 'Notifications', undefined)
              : constructDescription(superbills, tnewMemberNav)
          }
        />
      )}
      arrowStyles={{
        fill: useNewNav ? colorRoles.icons.iconDefaultDefault : colors.periwinkleGrey,
      }}
      arrowContainerStyles={arrowContainerStyles}
      useNewNav={useNewNav}
    >
      <NotificationPanelRow
        label={tnewMemberNav('notifications.push', 'Push', undefined)}
        iconComponent={() => <DailyReminder />}
        isOn={superbills.push}
        onToggle={handlePushToggle}
        name="superbillsPush"
      />
      <NotificationPanelRow
        label={tnewMemberNav('notifications.email', 'Email', undefined)}
        iconComponent={() => <EmailEnvelope />}
        isOn={superbills.email}
        onToggle={handleEmailToggle}
        name="superbillsEmail"
      />
    </BaseAccordion>
  );
};

export default SuperbillsAccordion;
