import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  RefObject,
  useMemo,
  useRef,
} from 'react';
import moment from 'moment';
import {
  View,
  Label,
  Large,
  SelectRounded,
  Button,
  ProgressIndicator,
  OptionType,
  Input,
  RatingStars,
  RatingNumbers,
  Huge,
  TSInput,
  TherapistReviewForm,
  useEmotionTheme,
  BrickSelectorState,
  SimpleQuestions,
  useUniqueID,
  ValueTypeSingleSelect,
  HiddenText,
  BackNextButton,
  AddressInput,
  SchoolSearch,
  A11yPhoneInput,
  AddressInputValue,
  isPhonePhoneNumberEmpty,
  DatePickerInput,
  useThemeVersion,
  useWindowWidthState,
  EmotionStyle,
  TextDS,
  spacing,
} from '@talkspace/react-toolkit';
import {
  parseVersionKey,
  SemanticVersion,
} from '@talkspace/react-toolkit/src/utils/semanticVersioning';
import InputMask from 'react-input-mask';
import { TherapistReviewData } from 'ts-frontend/types';
import { ValueType, OptionTypeBase, OptionsType } from 'react-select';
import camelCase from 'lodash/camelCase';
import SelectRoundedV1 from '@talkspace/react-toolkit/src/designSystems/components/SelectRounded/SelectRoundedV1';
import CreditOffer from '../CreditOffer';
import StateAndCountrySelector from '../StateAndCountrySelector/StateAndCountrySelector';
import {
  getMaxInputLength,
  getValidMomentRangeFromValidationOptions,
  populateAdditionalInfoIntoElement,
  validateInput,
  validateOptionValue,
} from '../../utils/wizardUtils';
import CheckIn from '../CheckIn';
import PCPSearch, { PCPSearchSchema } from '../PCPSearch';
import { WizardStep, InputField } from '../../types';
import {
  MultiLineTextArea,
  TitlesView,
  SecondaryButton,
  RightCol,
  LeftCol,
  TopRow,
  FullWidthContainer,
  ColumnContainer,
  RightColPlaceholder,
  DynamicSpacingView,
  MultiSelectContainer,
  MultiSelectSubtitle,
  StickyBottomContainer,
} from './StepWizardStyles';
import { useWizardActions } from '../../hooks/wizardContext';
import FormInput from '../FormInput';

const { space400 } = spacing;

interface StepWizardProps {
  step: WizardStep;
  stepInputValue?: OptionType | OptionType[] | TherapistReviewData | AddressInputValue | string;
  setStepFinalValue: (value: any) => void;
  setShouldAdvance: (value: boolean) => void;
  stepInputOptions?: BrickSelectorState[];
  setCurrentStepOptions: (value: BrickSelectorState[]) => void;
  stepNumber: number;
  progress: number;
  handleBackPress?: () => void;
  stepAdditionalInfo?: Record<string, any>;
  setSecondaryButtonClicked: (value: any) => void;
  roomID?: number;
  hideProgressIndicator?: boolean;
  setDisableButtonAction: (value: boolean) => void;
  disableButtonAction: boolean;
  wrapperRef?: RefObject<HTMLDivElement> | undefined;
  stepsLength?: number;
  automaticSpacing?: boolean;
  wizardType: string;
  wizardContext: any;
  schemeThemeVersion?: SemanticVersion;
  setStepValueCallback: ((value: any) => void) | null;
}

interface DynamicTitleProps {
  titleID: string;
  step: WizardStep;
  stepAdditionalInfo?: Record<string, unknown>;
  dataQa?: string;
  wizardContext?: any;
  style?: EmotionStyle;
  themeMajorVersion?: number;
}

interface GetFieldParams {
  titleID?: string;
  inputLabelID?: string;
  customStep?: InputField;
  customValue?: unknown;
  customOnChange?: (newValue: unknown) => void;
}

const DynamicTitle: FunctionComponent<DynamicTitleProps> = ({
  titleID,
  step,
  stepAdditionalInfo,
  dataQa,
  wizardContext,
  style,
  themeMajorVersion = 0,
}) => {
  const Title = step?.titleSize || Huge;

  return themeMajorVersion >= 1 ? (
    <TextDS variant="headingXl" style={{ marginBottom: space400, ...style }}>
      {populateAdditionalInfoIntoElement(step.title, stepAdditionalInfo, wizardContext)}
    </TextDS>
  ) : (
    <Title id={titleID} as="h1" style={{ marginBottom: 12, ...style }} dataQa={dataQa}>
      {populateAdditionalInfoIntoElement(step.title, stepAdditionalInfo, wizardContext)}
    </Title>
  );
};

const StepWizard: FunctionComponent<StepWizardProps> = ({
  step,
  stepInputValue,
  setStepFinalValue,
  setShouldAdvance,
  stepInputOptions,
  setCurrentStepOptions,
  stepNumber = 1,
  progress = 0.1,
  handleBackPress,
  stepAdditionalInfo,
  setSecondaryButtonClicked,
  hideProgressIndicator,
  roomID,
  setDisableButtonAction,
  disableButtonAction,
  wrapperRef,
  stepsLength,
  automaticSpacing,
  wizardType,
  wizardContext,
  schemeThemeVersion,
  setStepValueCallback,
}) => {
  const { setState } = useWizardActions();
  const [showNextButton, setShowNextButton] = useState(false);
  const [showSecondaryButton, setShowSecondaryButton] = useState(false);
  const [hideStickyContainer, setHideStickyContainer] = useState(false);
  const { isMobile } = useWindowWidthState();
  // TODO: @jkredo - @levfish: I'm sorry I had to do this, but the usages of `currentValue` here and all of the other `any`s
  // confused me as to what the proper type of `currentValue` could be. At first it looked like it could be `string | string[]` but
  // this is also being used in `CheckIn` component and I don't want to break the code. Can one of you please fix the types in this file please?
  // TODO: @ERIC as steps update and new fields render the current value is carrying over on initial render. This needs to be fixed
  const [currentValue, setCurrentValue] = useState<any>();
  const { colors } = useEmotionTheme();
  const titlesView = useRef<HTMLDivElement>(null);
  const maxInputLength = getMaxInputLength(step);

  const onMenuOpen = useCallback(() => {
    if (isMobile) {
      setHideStickyContainer(true);
    }
  }, [isMobile]);
  const onMenuClose = useCallback(() => {
    setHideStickyContainer(false);
  }, []);

  const stepNameNotCapitalised = camelCase(step.name);
  const dataQaNamePerStep = `${wizardType}${stepNameNotCapitalised
    .charAt(0)
    .toUpperCase()}${stepNameNotCapitalised.substring(1)}`;

  const selectSetOption = useCallback(
    (selectValue: any) => {
      if (Array.isArray(selectValue)) {
        return (
          stepInputOptions &&
          selectValue
            .map((selectVal) => {
              const foundInStepInputOptions = stepInputOptions.find(
                (option) => option.value === selectVal
              );

              if (
                step.inputType === 'creatableSearchableMultiSelect' &&
                foundInStepInputOptions === undefined
              ) {
                const newOption = {
                  label: selectVal,
                  value: selectVal,
                  isSelected: true,
                };
                setCurrentStepOptions([...stepInputOptions, newOption]);
                return newOption;
              }
              return foundInStepInputOptions;
            })
            .filter((val) => val !== undefined)
        );
      }
      return (
        (stepInputOptions && stepInputOptions.find((option) => option.value === selectValue)) || ''
      );
    },
    [setCurrentStepOptions, step.inputType, stepInputOptions]
  );

  const isAddressInputValue = (candidate: any): candidate is AddressInputValue =>
    typeof candidate === 'object' && 'addressFromApi' in candidate;

  // TODO: @jkredo - This file needs some TypeScript love, please take a look
  const isInputValueEmpty = useCallback(
    (value?: unknown, inputType?: string): boolean => {
      if (value === null || value === undefined) {
        return true;
      }
      const currentInputType = inputType || step.inputType;
      switch (currentInputType) {
        case 'form':
          return step.inputFields
            ? step.inputFields.some(
                (field) =>
                  field.validationRequired &&
                  value &&
                  typeof value === 'object' &&
                  isInputValueEmpty(value[field.inputState], field.inputType)
              )
            : true;
        case 'multiSelect':
        case 'searchableMultiSelect':
        case 'creatableSearchableMultiSelect':
          return (
            (!!value && Array.isArray(value) && value.length === 0) ||
            (selectSetOption(value) as OptionType[]).length === 0
          );
        case 'pcpSearch':
          return !(value as PCPSearchSchema | null)?.firstName;
        case 'select':
        case 'searchableSelect':
          return value === '' || selectSetOption(value) === '';
        case 'stateCountrySelector':
          return value === 'US';
        case 'therapistReview':
          return !value;
        case 'text':
        case 'multilineText':
        case 'us-phone':
        case 'starsRating':
        case 'numbersRating':
        case 'date':
          return value === '';
        case 'datePicker':
          return !moment.isMoment(value);
        case 'school':
        case 'year':
          return !value;
        case 'phone':
          return isPhonePhoneNumberEmpty(value);
        case 'creditOffer':
          return false;
        case 'address':
          if (value && isAddressInputValue(value)) {
            return (
              value.addressFromApi.address === '' ||
              value.addressFromApi.city === '' ||
              value.addressFromApi.state === ''
            );
          }
          return true;
        case 'custom':
          return !value;
        default:
          return true;
      }
    },
    [selectSetOption, step.inputFields, step.inputType]
  );

  const handleNextButtonClicked = useCallback(() => {
    if (step.nextButton && step.nextButton.defaultValue) {
      setStepFinalValue(step.nextButton.defaultValue);
    }
    setShouldAdvance(true);
    setCurrentValue('');
  }, [setShouldAdvance, setStepFinalValue, step.nextButton]);
  const options = stepInputOptions;

  const handleInputValueChange = useCallback(
    (value?: any) => {
      if (disableButtonAction) return;
      if (maxInputLength && value.length > maxInputLength) return;
      setCurrentValue(value);
      setStepFinalValue(value);
      let isValidated = false;
      const isSecondaryHideOnSelect =
        step.secondaryButton && step.secondaryButton.whenVisible === 'hideOnSelect';
      if (step.validationRequired) {
        isValidated = validateInput(value, step.inputType, step.validationOptions);
      } else isValidated = true;
      if (isValidated && step.nextButton.whenVisible === 'autoAdvance') {
        setDisableButtonAction(true);
        setTimeout(handleNextButtonClicked, step.nextButton.autoAdvanceDelay || 500);
      } else if (isValidated && !isInputValueEmpty(value)) {
        setShowNextButton(true);
        if (isSecondaryHideOnSelect) {
          setShowSecondaryButton(false);
        }
      } else {
        setShowNextButton(false);
        if (isSecondaryHideOnSelect) {
          setShowSecondaryButton(true);
        }
      }
    },
    [
      disableButtonAction,
      maxInputLength,
      setStepFinalValue,
      step.secondaryButton,
      step.validationRequired,
      step.nextButton.whenVisible,
      step.nextButton.autoAdvanceDelay,
      step.inputType,
      step.validationOptions,
      isInputValueEmpty,
      setDisableButtonAction,
      handleNextButtonClicked,
    ]
  );

  useEffect(() => {
    // TODO: setStepValueCallback should be updated ever time handleInputValueChange updates
    // but this was causing a render loop so its disabled after the first update.
    if (!setStepValueCallback) {
      setState({
        setStepValueCallback: handleInputValueChange,
      });
    }
  }, [handleInputValueChange, setState, setStepValueCallback]);

  const formatDate = (dateStr: string) => {
    if (dateStr) return moment(dateStr).format('MM/DD/YYYY');
    return undefined;
  };

  const getFormattedDateValue = (value: string | undefined) => {
    let formattedValue = value;

    if (!value) {
      formattedValue = '';
    } else if (value.length < 10 || formatDate(value) === 'Invalid date') {
      formattedValue = value;
    } else {
      formattedValue = formatDate(value);
    }

    return formattedValue;
  };

  const handleDateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    handleInputValueChange(value);
    if (value) {
      if (validateInput(value, 'date', step.validationOptions)) {
        handleInputValueChange(moment(value).format('YYYY-MM-DD'));
      } else setShowNextButton(false);
    }
  };

  const handleSecondaryBtnClick = () => {
    if (step.secondaryButton) {
      setStepFinalValue(step.secondaryButton.defaultValue);
    }
    setSecondaryButtonClicked(true);
    setShouldAdvance(true);
    setCurrentValue('');
  };

  const handleSelection = <T extends Omit<BrickSelectorState, 'isSelected'>>(selection: T) => {
    handleInputValueChange(selection.value);
  };

  const selectGetValue = (option: ValueType<OptionTypeBase, false>) => {
    let result: OptionType['value'] | Array<OptionType['value'] | null> | null;
    if (Array.isArray(option)) {
      result = option.map(validateOptionValue);
    } else {
      result = validateOptionValue(option as ValueTypeSingleSelect<OptionType>);
    }
    handleInputValueChange(result);
  };

  const getContentField = (stepToUse: WizardStep, titleID?: string, dataQa?: string) => {
    switch (stepToUse.contentType) {
      case 'creditOffer':
        return <CreditOffer stepAdditionalInfo={stepAdditionalInfo} dataQa={dataQa} />;
      default:
        return null;
    }
  };

  const getInputField = ({
    stepToUse,
    valueToUse,
    titleID,
    inputLabelID,
    customOnChange,
  }: {
    stepToUse: WizardStep | InputField;
    valueToUse: any;
    titleID?: string;
    inputLabelID?: string;
    customOnChange?: (newValue: unknown) => void;
  }) => {
    // TODO: form type uses this function to generate his own fields. but for now it only support text. if you need a certein input field please make sure custom value and customOnChange works well
    switch (stepToUse.inputType) {
      case 'pcpSearch':
        return (
          <View>
            <PCPSearch
              onSelect={handleInputValueChange}
              formerValue={currentValue?.firstName ? currentValue : undefined}
            />
          </View>
        );
      case 'form':
        return (
          <View>
            <FormInput
              value={currentValue}
              onChange={handleInputValueChange}
              /* getField calls getInputField which calls getField recursively , one of them has to be used before its declared */
              /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
              getField={getField}
              inputFields={step.inputFields}
            />
          </View>
        );
      case 'select':
      case 'searchableSelect':
        return wizardContext.isV2 ? (
          <SelectRoundedV1
            aria-labelledby={titleID}
            options={
              stepToUse.includeDefaultOption
                ? [
                    { label: stepToUse.inputTypePlaceholder || 'Select', value: '' },
                    ...(options as OptionsType<any>),
                  ]
                : (options as OptionsType<any>)
            }
            placeholder={stepToUse.inputTypePlaceholder || 'Select'}
            value={selectSetOption(valueToUse) as OptionType}
            onChange={selectGetValue}
            isMulti={false}
            isInputReadOnly={stepToUse.inputType !== 'searchableSelect'}
            isCreatable={false}
            dataQa={`${dataQaNamePerStep}SelectRoundedV1`}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
          />
        ) : (
          <SelectRounded
            aria-labelledby={titleID}
            options={
              stepToUse.includeDefaultOption
                ? [
                    { label: stepToUse.inputTypePlaceholder || 'Select', value: '' },
                    ...(options as OptionsType<any>),
                  ]
                : (options as OptionsType<any>)
            }
            placeholder={stepToUse.inputTypePlaceholder || 'Select'}
            value={selectSetOption(valueToUse) as OptionType}
            onChange={selectGetValue}
            isMulti={false}
            isInputReadOnly={stepToUse.inputType !== 'searchableSelect'}
            isCreatable={false}
            dataQa={`${dataQaNamePerStep}SelectRounded`}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
          />
        );
      case 'multiSelect':
      case 'searchableMultiSelect':
      case 'creatableSearchableMultiSelect':
        return (
          <MultiSelectContainer align="center">
            <MultiSelectSubtitle>
              {stepToUse.inputTypeDescription || 'Select all that apply'}
            </MultiSelectSubtitle>
            {wizardContext.isV2 ? (
              <SelectRoundedV1
                aria-labelledby={titleID}
                options={options as OptionsType<any>}
                placeholder={stepToUse.inputTypePlaceholder || undefined}
                value={selectSetOption(valueToUse) as OptionType}
                onChange={selectGetValue}
                isMulti
                isInputReadOnly={
                  !(
                    stepToUse.inputType === 'searchableMultiSelect' ||
                    stepToUse.inputType === 'creatableSearchableMultiSelect'
                  )
                }
                isCreatable={stepToUse.inputType === 'creatableSearchableMultiSelect'}
                dataQa={`${dataQaNamePerStep}SelectRoundedV1`}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
              />
            ) : (
              <SelectRounded
                aria-labelledby={titleID}
                options={options as OptionsType<any>}
                placeholder={stepToUse.inputTypePlaceholder || undefined}
                value={selectSetOption(valueToUse) as OptionType}
                onChange={selectGetValue}
                isMulti
                isInputReadOnly={
                  !(
                    stepToUse.inputType === 'searchableMultiSelect' ||
                    stepToUse.inputType === 'creatableSearchableMultiSelect'
                  )
                }
                isCreatable={stepToUse.inputType === 'creatableSearchableMultiSelect'}
                dataQa={`${dataQaNamePerStep}SelectRounded`}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
              />
            )}
          </MultiSelectContainer>
        );
      case 'text':
        return (
          <>
            {stepToUse.inputLabel && <Label id={inputLabelID}>{stepToUse.inputLabel}</Label>}
            <TSInput
              maxLength={64}
              aria-labelledby={stepToUse.inputLabel ? inputLabelID : titleID}
              value={valueToUse}
              onChange={customOnChange || handleInputValueChange}
              placeholder={stepToUse.inputTypePlaceholder}
            />
          </>
        );
      case 'multilineText':
        return (
          <MultiLineTextArea
            maxLength={1500}
            aria-labelledby={titleID}
            value={typeof valueToUse === 'string' ? valueToUse : ''}
            placeholder={stepToUse.inputTypePlaceholder}
            onChangeText={handleInputValueChange}
            dataQa={`${dataQaNamePerStep}MultiLineTextArea`}
          />
        );
      case 'us-phone':
        return (
          <InputMask
            placeholder={stepToUse.inputTypePlaceholder}
            mask="999 999 9999" // Extra digit at the end for validation
            alwaysShowMask={false}
            maskChar={null}
            onChange={handleInputValueChange}
            value={valueToUse || ''}
            style={{ width: '100%' }}
            maxLength={12}
          >
            {(inputProps) => (
              <TSInput
                {...inputProps}
                aria-labelledby={titleID}
                onChange={undefined}
                onBlur={undefined}
                placeholder={stepToUse.inputTypePlaceholder}
                inputMode="numeric"
                maxLength={12}
              />
            )}
          </InputMask>
        );
      case 'therapistReview':
        return (
          stepAdditionalInfo &&
          stepAdditionalInfo.therapistInfo && (
            <TherapistReviewForm
              therapistId={stepAdditionalInfo.therapistInfo.id}
              submitButtonText="Next"
              handleOnSubmit={handleInputValueChange}
              isReviewTextRequired={false}
              isLoading={disableButtonAction}
              formerRating={
                (stepInputValue as TherapistReviewData | undefined) &&
                (stepInputValue as TherapistReviewData).ratingValue
              }
              formerReview={
                (stepInputValue as TherapistReviewData | undefined) &&
                (stepInputValue as TherapistReviewData).reviewText
              }
              roomID={roomID}
              dataQa={dataQaNamePerStep}
            />
          )
        );
      case 'stateCountrySelector':
        return (
          <StateAndCountrySelector
            aria-labelledby={titleID}
            onSelect={handleInputValueChange}
            formerValue={typeof currentValue === 'string' ? currentValue : ''}
          />
        );
      case 'stateCountrySelectorPsych':
        return (
          <StateAndCountrySelector
            aria-labelledby={titleID}
            onSelect={handleInputValueChange}
            formerValue={typeof currentValue === 'string' ? currentValue : ''}
            isPsych
          />
        );
      case 'datePicker':
        return (
          <DatePickerInput
            minDate={stepToUse.minDate}
            maxDate={stepToUse.maxDate}
            currentValue={moment.isMoment(currentValue) ? currentValue : moment()}
            handleInputValueChange={handleInputValueChange}
          />
        );
      case 'date':
        return (
          <Input
            dataQa="DOBInput"
            ariaRequired
            date-format="MM/dd/yyyy"
            placeholder="MM/DD/YYYY"
            maskType="date"
            inputMode="numeric"
            value={getFormattedDateValue(valueToUse)}
            onChange={handleDateInputChange}
            onBlur={() => validateInput(formatDate(valueToUse), 'date', step.validationOptions)}
            wrappedInputProps={{
              isError:
                valueToUse &&
                valueToUse.length === 10 &&
                !validateInput(formatDate(valueToUse), 'date', step.validationOptions),
              errorMessage: 'Invalid date',
            }}
          />
        );
      case 'school':
        return <SchoolSearch onSelectCallback={handleInputValueChange} />;
      case 'year':
        return (
          <TSInput
            dataQa="yearInput"
            inputMode="numeric"
            type="number"
            value={currentValue}
            placeholder={stepToUse.inputTypePlaceholder}
            onChange={customOnChange || handleInputValueChange}
            min={String(
              getValidMomentRangeFromValidationOptions(step.validationOptions).minMoment.year()
            )}
            max={String(
              getValidMomentRangeFromValidationOptions(step.validationOptions).maxMoment.year()
            )}
          />
        );
      case 'starsRating':
        return (
          <View style={{ textAlign: 'center' }}>
            <RatingStars initialRating={valueToUse} getRatingValue={handleInputValueChange} />
          </View>
        );
      case 'numbersRating':
        return (
          <View style={{ textAlign: 'center' }}>
            <RatingNumbers
              initialRating={valueToUse}
              maxRating={10}
              onChange={handleInputValueChange}
              dataQa="stepWizardRatingNumbers"
            />
          </View>
        );
      case 'checkIn':
        return (
          <CheckIn
            brickOptions={stepInputOptions}
            formerValues={valueToUse}
            handleOnSubmit={handleInputValueChange}
          />
        );
      case 'question':
        return (
          <SimpleQuestions
            radioGroupLegend={`Choose one answer for the following prompt: ${step.title}`}
            value={valueToUse}
            onPress={handleSelection}
            questions={stepInputOptions as Omit<BrickSelectorState, 'isSelected'>[]}
            dataQa={dataQaNamePerStep}
          />
        );
      case 'address':
        return (
          <View>
            <AddressInput value={valueToUse} setValue={handleInputValueChange} />
          </View>
        );
      case 'phone':
        return (
          <View>
            <A11yPhoneInput
              width="100%"
              showLabel={false}
              placeholder={stepToUse.inputTypePlaceholder || 'Enter phone number'}
              value={typeof valueToUse === 'string' ? valueToUse : undefined}
              handleInputValueChange={handleInputValueChange}
            />
          </View>
        );
      case 'buttonsOnly':
        return <></>;
      default:
        return null;
    }
  };

  const getField: (params: GetFieldParams) => JSX.Element | null = ({
    titleID,
    inputLabelID,
    customStep,
    customValue,
    customOnChange,
  }) => {
    const stepToUse = customStep || step;
    const valueToUse = customValue ?? currentValue;

    return (
      getInputField({
        stepToUse,
        valueToUse,
        titleID,
        inputLabelID,
        customOnChange,
      }) || getContentField(step, titleID, `${dataQaNamePerStep}CreditOffer`)
    );
  };

  const controlledShowNextButton = useMemo(
    () => step.nextButton.whenVisible === 'controlled' && step.nextButton.visibilityControlKey,
    [step.nextButton.whenVisible, step.nextButton.visibilityControlKey]
  );

  useEffect(() => {
    // NOTE: this allows step to control it's next button visibility
    if (controlledShowNextButton) {
      const shouldShowNextButton = !!wizardContext[step.nextButton.visibilityControlKey!];
      setShowNextButton(shouldShowNextButton);
    }
  }, [controlledShowNextButton, step.nextButton.visibilityControlKey, wizardContext]);

  useEffect(() => {
    // NOTE: if next button is controlled, don't run this effect
    if (controlledShowNextButton) {
      return;
    }

    setCurrentValue(stepInputValue);
    const isSecondaryHideOnSelect =
      step.secondaryButton && step.secondaryButton.whenVisible === 'hideOnSelect';
    setShowSecondaryButton(!!step.secondaryButton);
    if (
      step.nextButton.whenVisible === 'always' ||
      (!isInputValueEmpty(stepInputValue) && step.nextButton.whenVisible === 'onSelect')
    ) {
      setStepFinalValue(stepInputValue);
      setShowNextButton(true);
      if (isSecondaryHideOnSelect) {
        setShowSecondaryButton(false);
      }
    } else {
      setShowNextButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, setStepFinalValue, stepInputValue]);

  const { IconComponent, titleStyle } = step;
  const inputLabelID = useUniqueID('inputLabelID');
  const stepInfoID = useUniqueID('stepInfoID');
  const titleID = useUniqueID('titleID');
  let stepInfo = '';
  if (stepNumber) {
    stepInfo += `step ${stepNumber} `;
    if (stepsLength) {
      stepInfo += `of ${stepsLength}`;
    }
  }

  const { major: themeVersion } = useThemeVersion();

  let themeMajorVersion;
  if (schemeThemeVersion) {
    ({ major: themeMajorVersion } = parseVersionKey(schemeThemeVersion));
  }

  const bodyComponent = step.bodyComponent && step.bodyComponent();
  const marginBottomTopRow = IconComponent ? 0 : 52;
  const showBackButton = !!(handleBackPress && stepNumber > 1);
  const showTopRow = !step.hideTopRow;
  const showButtonsInStickyDrawer = !!(
    themeVersion ||
    (themeMajorVersion >= 1 && isMobile && showNextButton)
  );

  const fieldComponent = getField({
    titleID: titleID && step.title ? titleID : undefined,
    inputLabelID,
  });
  return (
    <FullWidthContainer
      align="center"
      justify="center"
      tabIndex={-1}
      as="main"
      aria-labelledby={`${stepInfoID} ${titleID}`}
      ref={wrapperRef}
    >
      {stepInfo && <HiddenText id={stepInfoID}>{stepInfo}</HiddenText>}
      {showTopRow && (
        <TopRow marginBottom={marginBottomTopRow} justify="space-between">
          <LeftCol isVisible={showBackButton}>
            <BackNextButton
              width={13}
              height={23}
              onPress={handleBackPress}
              caretColor={colors.permaTalkspaceDarkGreen}
              style={{ padding: '16px 23px 10px 10px', marginLeft: 8 }}
            />
          </LeftCol>
          <RightCol>
            {hideProgressIndicator || (
              <ProgressIndicator
                size={39}
                numberStyle={{ fontSize: 18 }}
                progressDecimal={progress}
                progressDisplay={stepNumber}
                containerStyle={{ marginTop: 40 }}
              />
            )}
          </RightCol>
          <RightColPlaceholder />
        </TopRow>
      )}
      <ColumnContainer align="center">
        {IconComponent && <IconComponent />}
        <View
          style={{
            position: 'relative',
            paddingBottom: 20,
          }}
        >
          {step.title && (
            <TitlesView ref={titlesView}>
              <DynamicTitle
                titleID={titleID}
                step={step}
                stepAdditionalInfo={stepAdditionalInfo}
                dataQa={`${dataQaNamePerStep}Title`}
                wizardContext={wizardContext}
                style={titleStyle}
                themeMajorVersion={themeMajorVersion}
              />
              {step.subtitle && (
                <Large variant="largeDarkGrey">
                  {populateAdditionalInfoIntoElement(
                    step.subtitle,
                    stepAdditionalInfo,
                    wizardContext
                  )}
                </Large>
              )}
            </TitlesView>
          )}
          <DynamicSpacingView
            automaticSpacing={automaticSpacing}
            titlesView={titlesView}
            maxTopHeight={(step.title && step.subtitle ? 220 : 156) - marginBottomTopRow}
          >
            {bodyComponent && (
              <View
                style={{
                  width: 320,
                  marginTop: themeMajorVersion >= 1 ? 0 : 32,
                }}
              >
                {bodyComponent}
              </View>
            )}
            {fieldComponent && (
              <View key={step.name} style={{ width: 320, marginTop: 32 }}>
                {fieldComponent}
              </View>
            )}
            <StickyBottomContainer
              isSticky={showButtonsInStickyDrawer}
              hideStickyContainer={hideStickyContainer}
            >
              {showNextButton && (
                <Button
                  isLoading={disableButtonAction}
                  onPress={handleNextButtonClicked}
                  style={{
                    width: 320,
                    backgroundColor: colors.permaTalkspaceDarkGreen,
                    marginTop: 0,
                  }}
                  dataQa={`${dataQaNamePerStep}PrimaryButton`}
                  roundedFocusStyle
                  aria-describedby={titleID}
                >
                  <Large variant="largeBoldWhite">
                    {populateAdditionalInfoIntoElement(
                      step.nextButton.displayText,
                      stepAdditionalInfo,
                      wizardContext
                    )}
                  </Large>
                </Button>
              )}
              {showSecondaryButton && (
                <SecondaryButton
                  isLoading={disableButtonAction}
                  onPress={handleSecondaryBtnClick}
                  displayStyle={step?.secondaryButton?.displayStyle}
                  dataQa={`${dataQaNamePerStep}SecondaryButton`}
                  style={
                    step?.secondaryButton?.displayStyle === 'secondaryFooter'
                      ? { marginTop: 90 }
                      : { marginTop: 16 }
                  }
                >
                  <Large
                    variant="largeMediumDarkGreen"
                    style={{
                      // necessary to override default text color of bigMedium variant
                      color:
                        step?.secondaryButton?.displayStyle === 'primary'
                          ? colors.white
                          : colors.accessibilityGreenDark,
                    }}
                  >
                    {populateAdditionalInfoIntoElement(
                      step?.secondaryButton?.displayText,
                      stepAdditionalInfo,
                      wizardContext
                    )}
                  </Large>
                </SecondaryButton>
              )}
              {step.footer && step.footer()}
            </StickyBottomContainer>
          </DynamicSpacingView>
        </View>
      </ColumnContainer>
    </FullWidthContainer>
  );
};

export default StepWizard;
