import { Card, SpacingView, TextDS, View } from '@talkspace/react-toolkit';
import ButtonV2 from '@talkspace/react-toolkit/src/designSystems/components/Button/ButtonV2';
import { XMark } from '@talkspace/react-toolkit/src/designSystems/icons';

interface PCPInfoCardProps {
  pcp: {
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    address?: string | null;
  };
  onRemove: () => void;
}

const PCPInfoCard = ({ pcp, onRemove }: PCPInfoCardProps) => (
  <Card>
    <SpacingView fullWidth flex={1} itemSpacing="space050">
      <View row flex={1} align="center" style={{ justifyContent: 'space-between' }}>
        <TextDS variant="headingMd">
          {pcp.firstName} {pcp.lastName}
        </TextDS>
        <ButtonV2
          onPress={onRemove}
          variant="tertiary"
          sizeDS="slim"
          text="Remove"
          IconRight={XMark}
          stretch={false}
          dataQa="PCPSearchRemoveSelectionButton"
        />
      </View>
      <View>
        <TextDS colorRole="textSubtlest" variant="bodySm">
          {pcp.phoneNumber}
        </TextDS>
        <TextDS colorRole="textSubtlest" variant="bodySm">
          {pcp.address}
        </TextDS>
      </View>
    </SpacingView>
  </Card>
);

export default PCPInfoCard;
