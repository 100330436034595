import { VoidFunctionComponent } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  View,
  Button,
  Checkbox,
  Huge,
  Large,
  Tiny,
  XCheckbox,
  styled,
  useEmotionTheme,
  Big,
  useRepeatingTimeslotsCopy,
  BookingTimeZone,
} from '@talkspace/react-toolkit';
import { TherapistTimeslot } from 'ts-frontend/types';

import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';
import BookingSummaryDropdown from '../BookingSummaryDropdown';
import BottomButtonContainer from '../BottomButtonContainer';
import BookingSummaryList from '../BookingSummaryList';

const BottomButtonContainerFullWidth = styled(View)(({ theme: { colors } }) => {
  return {
    width: 374,
    position: 'absolute',
    paddingTop: 15,
    paddingBottom: 30,
    paddingRight: 19.5,
    paddingLeft: 19.5,
    height: 'auto',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'left',
    backgroundColor: 'white',
    borderTop: `1px solid ${colors.permaCornflowerBlueSolid}`,
  };
});

const NoTimeForBreak = () => {
  const { colors } = useEmotionTheme();

  return (
    <View row>
      <XCheckbox />
      <View>
        <Large
          style={{
            marginLeft: 8,
          }}
          variant="largeSlateGreyStrikethrough"
        >
          Add a 15 minute break after this session
        </Large>
        <Tiny style={{ marginLeft: 8, color: colors.slateGrey }}>
          Not enough time available after this session
        </Tiny>
      </View>
    </View>
  );
};

const Subtitle = styled(Huge)({
  textAlign: 'center',
  paddingTop: 16,
  paddingBottom: 8,
});

const TimeslotListWrapper = styled(View)({
  width: '100%',
  marginTop: 16,
});

interface Props {
  timezone: string;
  clientDisplayName?: string;
  timeslots: TherapistTimeslot[];
  conflictingTimeslots: TherapistTimeslot[] | null;
  availableTimeslots: TherapistTimeslot[];
  onAdd15MinuteBreakCheck: (hasBreakAfterSession: boolean) => void;
  onScheduleSessionClick: () => void;
}

const ConfirmBookingRecurring: VoidFunctionComponent<Props> = ({
  timezone,
  clientDisplayName,
  timeslots,
  conflictingTimeslots,
  availableTimeslots,
  onAdd15MinuteBreakCheck,
  onScheduleSessionClick,
}) => {
  const { colorRoles } = useEmotionTheme();
  const { repeatingSessionsFull2 } = useFlags();
  const { isLoading, hasBreakAfterSession, selectedCreditOption, hasTimeForBreak } =
    useInRoomSchedulingState();

  const { everyWeek, dayName, startTimeString, endTimeString, startDateString, endDateString } =
    useRepeatingTimeslotsCopy(timeslots);

  return repeatingSessionsFull2 ? (
    <>
      <View flex={1} align="center">
        {selectedCreditOption && (
          <>
            <Subtitle>
              {everyWeek ? 'Weekly' : 'Every other week'} on {dayName}s<br />
              {startTimeString} – {endTimeString} with {clientDisplayName}
            </Subtitle>
          </>
        )}
        <Big variant="lividBlueMedium" style={{ marginBottom: 8 }}>
          from {startDateString} – {endDateString}
        </Big>
        <BookingTimeZone timezone={timezone} />
      </View>
      <TimeslotListWrapper style={{ width: '100%' }}>
        <BookingSummaryDropdown
          timeslots={timeslots}
          recurringAvailable={availableTimeslots}
          recurringConflicting={conflictingTimeslots}
        />
      </TimeslotListWrapper>
      <BottomButtonContainerFullWidth>
        {hasTimeForBreak ? (
          <Checkbox
            label="Add a 15 min break after when available"
            isChecked={!!hasBreakAfterSession}
            containerStyle={{ flex: 1 }}
            isDisabled={false}
            isRequired={false}
            setIsChecked={onAdd15MinuteBreakCheck}
            isLabelOnRight
            labelStyle={{ fontSize: 15, fontWeight: 500 }}
            dataQa="15MinuteBreakCheckbox"
          />
        ) : (
          <NoTimeForBreak />
        )}
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onPress={onScheduleSessionClick}
          text="Schedule sessions"
          dataQa="inRoomSchedulingConfirmBookingRecurringSchedule"
          style={{ backgroundColor: colorRoles.system.actionPrimaryDefault }}
          stretch
        />
      </BottomButtonContainerFullWidth>
    </>
  ) : (
    <>
      <View flex={1} align="center">
        {selectedCreditOption && (
          <Subtitle>You’re about to schedule recurring sessions with {clientDisplayName}</Subtitle>
        )}
        <BookingTimeZone timezone={timezone} />
      </View>
      <TimeslotListWrapper style={{ width: '100%' }}>
        <BookingSummaryList timeslots={timeslots} />
      </TimeslotListWrapper>
      <BottomButtonContainer>
        {hasTimeForBreak ? (
          <Checkbox
            label="Add a 15 min break after when available"
            isChecked={!!hasBreakAfterSession}
            containerStyle={{ flex: 1 }}
            isDisabled={false}
            isRequired={false}
            setIsChecked={onAdd15MinuteBreakCheck}
            isLabelOnRight
            labelStyle={{ fontSize: 15, fontWeight: 500 }}
            dataQa="15MinuteBreakCheckbox"
          />
        ) : (
          <NoTimeForBreak />
        )}
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onPress={onScheduleSessionClick}
          text="Schedule sessions"
          dataQa="inRoomSchedulingConfirmBookingRecurringSchedule"
          style={{ backgroundColor: colorRoles.system.actionPrimaryDefault }}
          stretch
        />
      </BottomButtonContainer>
    </>
  );
};

export default ConfirmBookingRecurring;
