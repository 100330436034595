import { FunctionComponent } from 'react';
import {
  View,
  Button,
  Large,
  ExtraHuge,
  useWindowWidthState,
  StepTip,
  AvatarWithFloatingShapes,
  TextDS,
  SpacingView,
  BannerCard,
  spacing,
  BookingAvatars,
} from '@talkspace/react-toolkit';
import { CircleInfo } from '@talkspace/react-toolkit/src/designSystems/icons';
import styled from '../../../core/styled';
import ErrorModal from '../ErrorModal/ErrorModal';
import apiHelper from '@/core/api/apiHelper';

const { space300, space200 } = spacing;

interface InPlatformMatchConfirmProps {
  therapistName: string;
  therapistImageURL: string;
  therapistIsUnavailable?: boolean;
  numberOfMatches: number;
  switchTherapist: () => void;
  handleSecondaryClick: () => void;
  goBackToMatches: () => void;
  shouldExplanationBlockAppear?: boolean;
  canBookIntroSession?: boolean;
  therapistID: number | null;
  isB2B?: boolean;
  isV2?: boolean;
}

const BoldSpan = styled.span({ fontWeight: 'bold' });

const TherapistReadyText = styled(Large)({
  maxWidth: 320,
  textAlign: 'center',
  marginTop: 13,
  paddingLeft: 5,
  paddingRight: 5,
});

const SelectButton = styled(Button)(({ theme: { colors } }) => {
  return {
    marginTop: 36,
    marginBottom: 42,
    width: 335,
    backgroundColor: colors.permaTalkspaceDarkGreen,
  };
});

const InPlatformMatchConfirm: FunctionComponent<InPlatformMatchConfirmProps> = ({
  therapistName,
  therapistImageURL,
  therapistID,
  switchTherapist,
  therapistIsUnavailable,
  numberOfMatches,
  handleSecondaryClick,
  goBackToMatches,
  shouldExplanationBlockAppear = true,
  canBookIntroSession = false,
  isB2B = false,
  isV2,
}) => {
  const getUnavailableText = () => (isB2B ? 'Contact support' : 'Talk to a matching agent');

  // note: if unavailable, the therapist is removed from the array
  // that is why we look for 0 or greater than 0 here
  const onTherapistUnavailableButtonPress =
    numberOfMatches > 0 ? goBackToMatches : handleSecondaryClick;
  const onTherapistUnavailableButtonText =
    numberOfMatches > 0 ? 'Choose a different therapist' : getUnavailableText();
  const { isMobile } = useWindowWidthState();

  if (therapistIsUnavailable) {
    return (
      <View flex={1} align="center">
        <ErrorModal
          error="Sorry, therapist is unavailable"
          errorMessage="Unfortunately the therapist you've chosen has just become unavailable."
          onButtonPress={onTherapistUnavailableButtonPress}
          buttonText={onTherapistUnavailableButtonText}
        />
      </View>
    );
  }

  return isV2 ? (
    <View flex={1} align="center">
      <View
        flex={isMobile ? 1 : 0}
        style={{
          marginBottom: isMobile ? 0 : 45,
          paddingTop: space300,
          paddingLeft: space200,
          paddingRight: space200,
        }}
      >
        <SpacingView itemSpacing="space300" centerAlign flexGrow="unset">
          <TextDS variant="headingXl">
            You’re about to change your provider to {therapistName}
          </TextDS>
          {therapistID ? (
            <View style={{ height: 64, paddingLeft: isMobile ? 93 : 0 }}>
              <BookingAvatars
                therapistUserID={therapistID}
                shouldShowBookingSuccess={false}
                bookingSuccessSmall
                selectedTimeslot={null}
                isCouples={false}
                isSmallAvatars
                autoHeight
                marginTop={0}
                cdnBaseUrl={apiHelper().cdnEndpoint}
              />
            </View>
          ) : null}

          <TextDS colorRole="textSubtle">
            After changing your provider, you’ll be able to coordinate with them to book a first
            session
          </TextDS>
        </SpacingView>

        {canBookIntroSession && (
          <TherapistReadyText variant="largeDarkGrey">
            To break the ice, Talkspace would like to offer you a{' '}
            <BoldSpan>10 minute live intro session.</BoldSpan>
          </TherapistReadyText>
        )}
      </View>
      <View flex={isMobile ? 0 : 1} align="center" style={{ padding: 20, flexGrow: 'unset' }}>
        {shouldExplanationBlockAppear && (
          <StepTip tip="switchExplanation" style={{ maxWidth: 365 }} />
        )}
        <BannerCard
          title="Please take note"
          subtitle="After changing providers, services with your previous provider will be unavailable"
          icon={<CircleInfo colorType="info" />}
          variant="info"
          size="small"
          isMobile
        />

        <SelectButton
          text="Change provider"
          onPress={switchTherapist}
          dataQa="inPlatformMatchContinueWithTherapist"
          style={{ marginTop: space300, marginBottom: 0 }}
        />
      </View>
    </View>
  ) : (
    <View flex={1} align="center">
      <View flex={isMobile ? 1 : 0} align="center" style={{ marginBottom: isMobile ? 0 : 45 }}>
        <AvatarWithFloatingShapes
          imageURL={therapistImageURL}
          showBookingIcon={canBookIntroSession}
        />

        <ExtraHuge style={{ maxWidth: 326, textAlign: 'center' }}>
          You've selected {therapistName}!
        </ExtraHuge>
        <TherapistReadyText variant="largeDarkGrey">
          {canBookIntroSession ? (
            <>
              To break the ice, Talkspace would like to offer you a{' '}
              <BoldSpan>10 minute live intro session.</BoldSpan>
            </>
          ) : (
            `${therapistName} is ready to help you on your journey towards feeling better.`
          )}
        </TherapistReadyText>
      </View>
      <View flex={isMobile ? 0 : 1} align="center">
        {shouldExplanationBlockAppear && (
          <StepTip tip="switchExplanation" style={{ maxWidth: 365 }} />
        )}
        <SelectButton
          text={`Continue with ${therapistName}`}
          onPress={switchTherapist}
          dataQa="inPlatformMatchContinueWithTherapist"
        />
      </View>
    </View>
  );
};

export default InPlatformMatchConfirm;
