import { FunctionComponent, useEffect, useState, useRef, RefObject } from 'react';
import {
  useUniqueID,
  View,
  Text,
  Avatar,
  Button,
  useEmotionTheme,
  useWindowWidthState,
  MessageStar,
} from '@talkspace/react-toolkit';
import moment from 'moment';

import { jsonParseSafe } from 'ts-frontend/utils';
import styled from '@/core/styled/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import { StarredMessage } from '../../entities/StarredMessage';
import { messageTypeToBodyType } from '../../entities/EMessage';
import Media from '../Media';

const getViewportSizeWordLimit = (width: number) => {
  if (width <= 1440) return 18;
  return 34;
};

const sliceByNumberOfWords = (input: string, width: number) => {
  const wordCount = getViewportSizeWordLimit(width);
  return input.split(' ').splice(0, wordCount).join(' ');
};

const Container = styled(View)({
  paddingTop: 30,
  paddingBottom: 30,
});

const getAvatarStyles = () => {
  return {
    width: 30,
    height: 30,
    marginLeft: 8,
    marginRight: 8,
    ...webOnlyStyle({
      '@media (min-width: 376px)': {
        width: 35,
        height: 35,
        marginLeft: 10,
        marginRight: 10,
      },
      '@media (min-width: 1126px)': {
        width: 60,
        height: 60,
        marginLeft: 30,
        marginRight: 30,
      },
    }),
  };
};

const ResponsiveAvatar = styled(Avatar)({
  ...getAvatarStyles(),
});

const ShortNameIcon = styled(View)({
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 50,
  backgroundColor: '#D2D8E0',
  ...getAvatarStyles(),
});

const ShadowedText = styled(Text)(({ theme: { colors } }) => {
  return {
    color: colors.white,
    fontSize: 16,
    ...webOnlyStyle({
      textShadow: '0px 2px 2px #bbc1c9',
      '@media (min-width: 1126px)': {
        fontSize: 30,
      },
    }),
  };
});

const MediaWrapper = styled(View)<{ backgroundColor: string }>(({ backgroundColor }) => {
  return {
    position: 'relative',
    marginBottom: 10,
    padding: 0,
    outline: 0,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    backgroundColor,
  };
});

const Message = styled(Text)({
  width: 270,
  lineHeight: 2,
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  ...webOnlyStyle({
    '@media (min-width: 1170px)': {
      width: 400,
    },
    '@media (min-width: 1600px)': {
      width: 715,
    },
  }),
});

const AuthorAndDate = styled(Text)(({ theme: { colors } }) => {
  return { fontSize: 12, color: colors.grey };
});

const FlatButton = styled(Button)({
  border: 'none',
  outline: 'none',
  marginLeft: 'auto',
  padding: 0,
});

const ButtonText = styled(Text)(({ theme: { colors } }) => {
  return { color: colors.green, fontSize: 16 };
});

const MessageStarWrapper = styled(View)({
  marginLeft: 'auto',
  marginRight: '4vw',
});

const Hr = styled(View)({
  height: 1,
  backgroundColor: '#f5f5f5',
});

interface Props {
  message: StarredMessage;
  shouldShowStar: boolean;
  handleStarClick: (message: StarredMessage) => void;
  isSelf: boolean;
  isPeer: boolean;
  hideBottomBorder: boolean;
  containerWidth: number;
  firstStarRef?: RefObject<HTMLDivElement>;
}

const getAvatarDimensions = (isDesktop: boolean, isMedium: boolean, isLarge: boolean) => {
  let height = 30;
  let width = 30;
  if (isDesktop) {
    height = 60;
    width = 60;
  } else if (isLarge || isMedium) {
    height = 35;
    width = 35;
  }
  return { height, width };
};

const StarredMessageItem: FunctionComponent<Props> = ({
  message,
  shouldShowStar,
  handleStarClick,
  isSelf,
  isPeer,
  hideBottomBorder,
  containerWidth,
  firstStarRef,
}) => {
  const { isMedium, isLarge, isDesktop } = useWindowWidthState();
  const avatarDimensions = getAvatarDimensions(isDesktop, isMedium, isLarge);
  const shouldMessageExpand = useRef(false);
  const { colors } = useEmotionTheme();
  const [isMessageExpanded, setMessageExpanded] = useState(false);
  const [displayedMessage, setDisplayedMessage] = useState('');

  useEffect(() => {
    const messageJSON = jsonParseSafe(message.message);
    const messageText = (
      messageJSON && 'messageContent' in messageJSON ? messageJSON.messageContent : message.message
    ) as string;
    const wordsInMessage = messageText.split(' ').length;
    const viewportSizeWordLimit = getViewportSizeWordLimit(window.innerWidth);

    if (wordsInMessage > viewportSizeWordLimit) {
      shouldMessageExpand.current = true;

      if (!isMessageExpanded) {
        const truncatedMessage = sliceByNumberOfWords(messageText, window.innerWidth);
        setDisplayedMessage(`${truncatedMessage}...`);
        return;
      }
    }
    setDisplayedMessage(messageText);
  }, [message.message, isMessageExpanded]);

  function getBackgroundColor() {
    if (message.messageType === 40) return colors.white;
    if (isSelf) return colors.green;
    if (isPeer) return colors.purple;
    return '#EBEEF2';
  }
  const messageTextID = useUniqueID('messageTextID');
  const authorAndDateID = useUniqueID('authorAndDateID');
  const starDescribedBy = message.signedMediaURL
    ? authorAndDateID
    : `${messageTextID} ${authorAndDateID}`;
  return (
    <>
      <Container row align="start" role="listitem">
        {message.avatarImage ? (
          <ResponsiveAvatar {...avatarDimensions} image={message.avatarImage} />
        ) : (
          <ShortNameIcon>
            <ShadowedText>{message.displayName.slice(0, 2)}</ShadowedText>
          </ShortNameIcon>
        )}
        <View>
          {message.signedMediaURL ? (
            <MediaWrapper backgroundColor={getBackgroundColor()}>
              <Media
                messageID={message.messageID}
                bodyType={messageTypeToBodyType(message.messageType)}
                color={isSelf || isPeer ? colors.white : colors.baliHaiGrey}
                signedMediaURL={message.signedMediaURL}
                fileName={message.mediaName}
                fileSize={message.mediaSize}
                senderName={message.displayName}
                createdAt={message.createdAt}
                containerWidth={containerWidth}
              />
            </MediaWrapper>
          ) : (
            <Message id={messageTextID}>{displayedMessage}</Message>
          )}

          <View row align="baseline">
            <AuthorAndDate id={authorAndDateID}>
              {isSelf ? 'You' : message.displayName},{' '}
              {moment(message.createdAt).format('MMM D, YYYY')}
            </AuthorAndDate>
            {shouldMessageExpand.current && (
              <FlatButton
                isSecondary
                size="small"
                onPress={() => setMessageExpanded(!isMessageExpanded)}
              >
                <ButtonText>{isMessageExpanded ? 'Show less' : 'Read more'}</ButtonText>
              </FlatButton>
            )}
          </View>
        </View>
        {shouldShowStar && (
          <MessageStarWrapper>
            <MessageStar
              firstStarRef={firstStarRef}
              ariaDescribedBy={starDescribedBy}
              isActive={message.isStarred}
              onPress={() => handleStarClick(message)}
            />
          </MessageStarWrapper>
        )}
      </Container>
      {!hideBottomBorder && <Hr />}
    </>
  );
};

export default StarredMessageItem;
