import { useMutation } from 'react-query';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

export interface SavePCPMutationVariables {
  roomID: number;
  firstName: string;
  lastName: string;
  address?: string | null;
  organization?: string | null;
  phoneNumber?: string | null;
  consentShareInfo: boolean;
  npiNumber?: number | null;
}

const savePCP = async (params: SavePCPMutationVariables): Promise<void> => {
  const {
    roomID,
    firstName,
    lastName,
    address,
    organization,
    phoneNumber,
    consentShareInfo,
    npiNumber,
  } = params;

  const data = await apiWrapper.put(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/primary-care-provider`,
    {
      firstName,
      lastName,
      address,
      organization,
      phoneNumber,
      consentShareInfo,
      npiNumber,
    }
  );
  return data.data;
};

const useMutationSavePCP = () => useMutation<void, Error, SavePCPMutationVariables>(savePCP);

export default useMutationSavePCP;
