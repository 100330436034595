import { FunctionComponent } from 'react';

import { ExtraTiny, Link, useEmotionTheme } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useTranslation, Trans } from '@talkspace/i18n';
import styled from '@/core/styled';
import BaseAccordion from './Base';
import NotificationTitle from '../NotificationTitle';
import NotificationPanelRow from '../NotificationPanelRow';
import { EmailEnvelope, CellPhone, DailyReminder } from '../../../components/icons';
import { useClientAuthState } from '../../../hooks/clientAuthContext';
import { arrowContainerStyles, bodyStyle, titleContainerStyles } from '../../myAccountStyles';

const Accordion = styled(BaseAccordion)<{
  isMobile: boolean;
  useNewNav: boolean;
}>(({ isMobile, useNewNav, theme: { colors } }) => {
  const oldNavStyling = {
    borderBottomLeftRadius: isMobile ? 0 : 10,
    borderBottomRightRadius: isMobile ? 0 : 10,
    borderBottom: `1px solid ${colors.iconLightGrey}`,
  };
  const newNavStyling = {
    borderBottom: 0,
  };
  return useNewNav ? newNavStyling : oldNavStyling;
});

interface Props {
  isOpen: boolean;
  handlePress: (value: boolean) => void;
  isMobile: boolean;
  marketingPromotions: { email: boolean; push: boolean; sms: boolean };
  marketingNews: { email: boolean; push: boolean };
  marketingEducation: { email: boolean; push: boolean };
  marketingBlog: { email: boolean; push: boolean };
  handleEmailToggle: (value: number) => void;
  handleSMSToggle: (value: number) => void;
  handlePushToggle: (value: number) => void;
  handlePromotionsEmailToggle: (value: number) => void;
  handleNewsEmailToggle: (value: number) => void;
  handleEducationEmailToggle: (value: number) => void;
  handleBlogEmailToggle: (value: number) => void;
}

const MarketingCommunicationsAccordion: FunctionComponent<Props> = ({
  isOpen,
  handlePress,
  isMobile,
  marketingPromotions,
  marketingNews,
  marketingEducation,
  marketingBlog,
  handleEmailToggle,
  handleSMSToggle,
  handlePushToggle,
  handlePromotionsEmailToggle,
  handleNewsEmailToggle,
  handleEducationEmailToggle,
  handleBlogEmailToggle,
}) => {
  const { colors, colorRoles } = useEmotionTheme();
  const {
    adminConfigs: { isCognitoLoginEnabled },
  } = useClientAuthState();
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const marketingDescription = [
    marketingNews,
    marketingPromotions,
    marketingBlog,
    marketingEducation,
  ].some(({ email }) => email)
    ? tnewMemberNav('notifications.on', 'On', undefined)
    : tnewMemberNav('notifications.off', 'Off', undefined);
  const useNewNav = useNewMemberNav();

  return (
    <Accordion
      bodyStyle={bodyStyle}
      titleContainerStyles={titleContainerStyles}
      isMobile={isMobile}
      useNewNav={useNewNav}
      open={isOpen}
      onPress={handlePress}
      disabled={false}
      titleComponent={() => (
        <NotificationTitle
          title={tnewMemberNav('notifications.marketing', 'Marketing communications', undefined)}
          isOpen={isOpen}
          isMobile={isMobile}
          subTitle={
            isOpen
              ? tnewMemberNav(
                  'notifications.receive',
                  'Receive promotions, feature news, and content.',
                  undefined
                )
              : marketingDescription
          }
        />
      )}
      arrowStyles={{
        fill: useNewNav ? colorRoles.icons.iconDefaultDefault : colors.periwinkleGrey,
      }}
      arrowContainerStyles={arrowContainerStyles}
    >
      {isCognitoLoginEnabled ? (
        <>
          <NotificationPanelRow
            label={tnewMemberNav('notifications.push', 'Push', undefined)}
            iconComponent={() => <DailyReminder />}
            isOn={marketingPromotions.push}
            onToggle={handlePushToggle}
            name="sessionRemindersPush"
          />
          <NotificationPanelRow
            label={tnewMemberNav('notifications.email', 'Email', undefined)}
            iconComponent={() => <EmailEnvelope />}
            isOn={marketingPromotions.email}
            onToggle={handleEmailToggle}
            name="news"
          />
          <NotificationPanelRow
            label={tnewMemberNav('notifications.sms', 'SMS', undefined)}
            iconComponent={() => <CellPhone style={{ marginRight: 7 }} />}
            isOn={marketingPromotions.sms}
            onToggle={handleSMSToggle}
            name="promotions"
          />
          <ExtraTiny style={{ marginLeft: 34, marginBottom: 16 }} inline>
            <Trans>
              By enabling SMS, I agree to receive recurring marketing and promotional text messages
              (SMS and MMS) from Talkspace up to 5msgs/mo. Message and data rates may apply. For
              more information, see our
              <Link
                target="_blank"
                href="https://www.talkspace.com/public/terms"
                style={{ color: colors.green, fontWeight: 700 }}
                roundedFocusStyle
                primaryColor={colors.green}
              >
                Terms of Use
              </Link>
              and
              <Link
                target="_blank"
                href="https://www.talkspace.com/public/privacy-policy"
                style={{ color: colors.green, fontWeight: 700 }}
                roundedFocusStyle
                primaryColor={colors.green}
              >
                Privacy Policy
              </Link>
              .
            </Trans>
          </ExtraTiny>
        </>
      ) : (
        <>
          <NotificationPanelRow
            label={tnewMemberNav('notifications.promotions', 'Promotions and events', undefined)}
            isOn={marketingPromotions.email}
            onToggle={handlePromotionsEmailToggle}
            name="promotions"
          />
          <NotificationPanelRow
            label={tnewMemberNav('notifications.product', 'Product news', undefined)}
            isOn={marketingNews.email}
            onToggle={handleNewsEmailToggle}
            name="news"
          />
          <NotificationPanelRow
            label={tnewMemberNav('notifications.therapy', 'Therapy tips', undefined)}
            isOn={marketingEducation.email}
            onToggle={handleEducationEmailToggle}
            name="tips"
          />
          <NotificationPanelRow
            label={tnewMemberNav('notifications.blog', 'Blog updates', undefined)}
            isOn={marketingBlog.email}
            onToggle={handleBlogEmailToggle}
            name="updates"
          />
        </>
      )}
    </Accordion>
  );
};

export default MarketingCommunicationsAccordion;
