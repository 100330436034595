import { FunctionComponent } from 'react';
import {
  View,
  TextDS,
  Button,
  SpacingView,
  StickyDrawer,
  StickyDrawerContextProvider,
  TSAvatar,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { useWizardState } from 'stepWizard';
import { State } from '../reducers/switchWizardState';
import styled from '@/core/styled';

const ViewWrapper = styled(View)(({ theme: { spacing } }) => {
  return {
    padding: `${spacing('space300')}px ${spacing('space200')}px`,
  };
});

const SwitchSuccess: FunctionComponent<{
  onPress: () => void;
}> = ({ onPress }) => {
  const { t: tSwitchWizard } = useTranslation('switchWizard');
  const { newTherapistMatch } = useWizardState<State>();
  return (
    <StickyDrawerContextProvider>
      <ViewWrapper align="center" justify="center">
        <SpacingView itemSpacing="space150" centerAlign>
          <TSAvatar image={newTherapistMatch?.therapistImage} />
          <TextDS variant="heading2xl">
            {tSwitchWizard('SwitchSuccess.title', 'You’re all set!', undefined)}
          </TextDS>
          <TextDS colorRole="textSubtle">
            {tSwitchWizard(
              'SwitchSuccess.subtitle',
              `Message ${newTherapistMatch?.therapistFirstName} to book your first session and start
            your journey towards feeling better`,
              { firstName: newTherapistMatch?.therapistFirstName }
            )}
          </TextDS>
        </SpacingView>
      </ViewWrapper>
      <StickyDrawer>
        <Button text="Continue" onPress={onPress} dataQa="switchSuccessContinue" />
      </StickyDrawer>
    </StickyDrawerContextProvider>
  );
};

export default SwitchSuccess;
