import React from 'react';
import { Trans, useTranslation } from '@talkspace/i18n';
import { TextDS } from '../index';
import Styled from '../../core/styled';

const Paragraph = Styled(TextDS)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      textAlign: 'center',
      maxWidth: isMobile ? 335 : 340,
    };
  }
);

const BookingPolicyMessages = () => {
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  return (
    <>
      <Paragraph variant="body" style={{ marginTop: 20, marginBottom: 20 }}>
        {tBookingScreen(
          'available.reschedule',
          'If you need to reschedule or cancel session, you may do so for free until 24 hours before your appointment’s start time.',
          undefined
        )}
      </Paragraph>
      <Trans i18nKey="available.late" t={tBookingScreen}>
        <Paragraph variant="body" style={{ marginTop: 20 }}>
          Late cancellations or no-shows will result in a fee for the cost of service which can be
        </Paragraph>{' '}
      </Trans>
      <Trans i18nKey="available.potentialCost" t={tBookingScreen}>
        <TextDS variant="body" style={{ fontWeight: 700 }} inline>
          up to $175.
        </TextDS>
      </Trans>
    </>
  );
};

export default BookingPolicyMessages;
