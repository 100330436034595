import { trackCTAClick } from 'ts-analytics/mixpanel/events';
import { useTranslation } from '@talkspace/i18n';
import { Col, TextDS, spacing } from '@talkspace/react-toolkit';
import LiveWorkshopsCard from './LiveWorkshopsCard';
import MentalHealthResourcesCard from './MentalHealthResourcesCard';
import PreSessionQuestionsCard from './PreSessionQuestionsCard';
import ForYouCardLoader from './ForYouCardLoader';

const { space100 } = spacing;

interface Props {
  showLiveWorkshopsCard: boolean | undefined;
  isLoading: boolean;
}

const ForYouSection = ({ showLiveWorkshopsCard, isLoading }: Props) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return !isLoading ? (
    <Col sm={8} lg={4}>
      <TextDS style={{ marginBottom: space100 }} variant="headingLg" tabIndex={0}>
        {tnewMemberNav('home.for', 'For you', undefined)}
      </TextDS>
      {showLiveWorkshopsCard && (
        <LiveWorkshopsCard
          dataQa="homePageCardLiveWorkshops"
          onPress={() => {
            trackCTAClick('Live workshops', 'home');
            window.open('https://www.talkspace.com/live-workshops', '_blank');
          }}
        />
      )}
      <MentalHealthResourcesCard
        dataQa="homePageCardMHResource"
        onPress={() => {
          trackCTAClick('Mental health resources', 'home');
          window.open('https://www.talkspace.com/blog/', '_blank');
        }}
      />
      <PreSessionQuestionsCard
        dataQa="homePageCardPreSessionQuestions"
        onPress={() => {
          trackCTAClick('Pre-session questions', 'home');
          window.open(
            'https://assets.dev.talkspace.com/web-app/how_to_prepare_for_your_session.pdf',
            '_blank'
          );
        }}
      />
    </Col>
  ) : (
    <ForYouCardLoader />
  );
};
export default ForYouSection;
