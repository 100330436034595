import { FunctionComponent, ReactNode } from 'react';
import {
  View,
  CaretLeft,
  Big,
  TouchableView,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import styled from '../../core/styled';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';
import ArrowLeftAndroid from '../icons/ArrowLeftAndroid';

const CaretLeftWrapper = styled(TouchableView)({
  justifyContent: 'center',
  marginRight: 20,
  lineHeight: 0, // fixes bootstrap lineHeight 20px causing this to render at 26px height
});

interface Props {
  title: string;
  mobileTitle?: string;
  onBackButtonPress: () => void;
  centerTitle?: boolean;
}

const HeaderWithCenterTitle = ({
  title,
  left,
  right,
}: Partial<Props & { left?: ReactNode; right?: ReactNode }>) => (
  <View row align="center" flex={1}>
    <View flex={1}>{left}</View>
    <Big style={{ flex: 10, textAlign: 'center' }}>{title}</Big>
    <View flex={1}>{right}</View>
  </View>
);

const MobileHeader: FunctionComponent<Props> = ({
  title,
  mobileTitle,
  centerTitle,
  onBackButtonPress,
}) => {
  const isInFrame = ReactFrameService.instance().isInFrame();
  const isAndroidWebView = isInFrame && ReactFrameService.isAndroid();
  const { isMobile } = useWindowWidthState();
  const { colors, colorRoles } = useEmotionTheme();
  const useNewNav = useNewMemberNav();
  if (centerTitle)
    return (
      <HeaderWithCenterTitle
        onBackButtonPress={onBackButtonPress}
        title={isMobile && mobileTitle ? mobileTitle : title}
        left={
          (isMobile || isInFrame) && (
            <CaretLeftWrapper onPress={onBackButtonPress} aria-label="back">
              {isAndroidWebView ? <ArrowLeftAndroid /> : <CaretLeft color={colors.green} />}
            </CaretLeftWrapper>
          )
        }
      />
    );
  return (
    <View row align="center">
      {isMobile || isInFrame ? (
        <>
          <CaretLeftWrapper onPress={onBackButtonPress} aria-label="back">
            {isAndroidWebView ? (
              <ArrowLeftAndroid />
            ) : (
              <CaretLeft color={useNewNav ? colorRoles.icons.iconDefaultDefault : colors.green} />
            )}
          </CaretLeftWrapper>
          <Big as="h1">{mobileTitle}</Big>
        </>
      ) : (
        <Big as="h1">{title}</Big>
      )}
    </View>
  );
};

export default MobileHeader;
