import { FunctionComponent, useEffect, useRef } from 'react';
import { useEmotionTheme, View, AddressInputValue } from '@talkspace/react-toolkit';
import { medications as medicationsOptions } from '@talkspace/configs';
import { useWizardActions, useWizardState } from 'stepWizard';
import { useTSAdminConfig } from 'ts-frontend/hooks/useTSAdminConfig';
import { AdminConfigName } from 'ts-frontend/types';
import useMutationSavePCP from 'ts-frontend/hooks/useMutationSavePCP';
import { useFlags } from 'launchDarkly/FlagsProvider';
import ActionStatus from '@/components/ActionStatus';
import styled from '@/core/styled';

import useMedicalHealth from '../hooks/useMedicalHealth';
import { MedicalHealthWizardState } from '../reducers/medicalHealthWizardState';
import { RouteComponentProps } from '../../../core/routerLib/routerLib';
import { MedicalHealthPayload } from '../../treatmentIntakeApiHelper';

const Wrapper = styled(View)({
  alignSelf: 'center',
  width: 340,
});

const formatPharmacyAddress = (pharmacyAddress: AddressInputValue) => {
  const fullAddressFromGoogleApi = pharmacyAddress?.addressFromApi?.fullAddress;
  const addressFromInput = pharmacyAddress?.addressString;

  const middle = fullAddressFromGoogleApi && addressFromInput ? ', ' : '';

  if (fullAddressFromGoogleApi || addressFromInput) {
    return `${fullAddressFromGoogleApi || ''}${middle}${addressFromInput || ''}`;
  }
  return null;
};

export const SubmitMedicalHealth: FunctionComponent<RouteComponentProps<{}>> = ({ history }) => {
  const isOnboarding = history.location.pathname.includes('/onboarding');
  const [{ error, isSuccess, isLoading }, { submitIntakeResults }] = useMedicalHealth();
  const requestSent = useRef(false);
  const { colors } = useEmotionTheme();
  const { mutate: savePCP } = useMutationSavePCP();
  const { pcpInIntake } = useFlags();

  const {
    roomID,
    clientUserID,
    selectedIsPregnant,
    medicalIssues,
    selectedHasChronicPain,
    medications,
    medicationsCustom,
    otcMedications,
    drugAllergies,
    pharmacyAddress,
    controlledSubstances,
    primaryCarePractitioner,
  } = useWizardState<MedicalHealthWizardState>();
  const { onExitClick } = useWizardActions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const payload: MedicalHealthPayload = {
    isPregnant: selectedIsPregnant,
    medicalIssues: medicalIssues || [],
    hasChronicPain: selectedHasChronicPain,
    medications: medications || [],
    medicationsCustom: medicationsCustom || [],
    otcMedications: otcMedications || null,
    drugAllergies: drugAllergies || null,
    pharmacyAddress: formatPharmacyAddress(pharmacyAddress),
    controlledSubstances: controlledSubstances || [],
    isOnboarding,
  };

  useEffect(() => {
    if (!requestSent.current && roomID) {
      requestSent.current = true;

      const medicationsAfterFilter: (string | number)[] = [];
      const customMedicationsAfterFilter: (string | number)[] = [];

      medications &&
        medications.forEach((medication) => {
          const foundInStepInputOptions = medicationsOptions[medication];

          if (!foundInStepInputOptions) {
            customMedicationsAfterFilter.push(medication);
          } else {
            medicationsAfterFilter.push(medication);
          }
        });

      payload.medications = medicationsAfterFilter;
      payload.medicationsCustom = customMedicationsAfterFilter;

      if (pcpInIntake && primaryCarePractitioner) {
        const {
          firstName,
          lastName,
          address,
          organization,
          phoneNumber,
          consentShareInfo,
          npiNumber,
        } = primaryCarePractitioner;

        savePCP(
          {
            roomID,
            firstName,
            lastName,
            address,
            organization,
            phoneNumber,
            consentShareInfo,
            npiNumber,
          },
          { onSettled: () => submitIntakeResults(roomID, clientUserID, payload) }
        );
      } else {
        submitIntakeResults(roomID, clientUserID, payload);
      }
    }
  }, [
    submitIntakeResults,
    roomID,
    clientUserID,
    payload,
    medications,
    savePCP,
    pcpInIntake,
    primaryCarePractitioner,
  ]);

  const { data: treatmentIntakeInOnboardingActive } = useTSAdminConfig(
    AdminConfigName.TREATMENT_INTAKE_IN_ONBOARDING
  );

  useEffect(() => {
    if (isSuccess) {
      if (treatmentIntakeInOnboardingActive && isOnboarding) {
        history.push(`/room/${roomID}/onboarding/next`);
      } else {
        history.push(`/treatment-intake-launcher/room/${roomID}/source/chat`, { initStep: 2 });
      }
    }
  }, [treatmentIntakeInOnboardingActive, history, isSuccess, roomID, isOnboarding]);

  return (
    <Wrapper>
      {error || isLoading ? (
        <ActionStatus
          isLoading={isLoading}
          isError={!!error}
          errorTitle="Something Went Wrong"
          errorSubTitle="Please check your internet connection and try again."
          errorButtonText="Close"
          errorButtonAction={onExitClick}
          titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
          subTitleStyles={{
            fontSize: 16,
            color: colors,
            marginTop: 13,
            marginBottom: 42,
            width: 200,
            textAlign: 'center',
          }}
        />
      ) : null}
    </Wrapper>
  );
};

export default SubmitMedicalHealth;
