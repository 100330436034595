import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const smallPath =
  'M6 8C6 7.46957 6.21071 6.96086 6.58579 6.58579C6.96086 6.21071 7.46957 6 8 6C8.53043 6 9.03914 6.21071 9.41421 6.58579C9.78929 6.96086 10 7.46957 10 8C10 8.53043 9.78929 9.03914 9.41421 9.41421C9.03914 9.78929 8.53043 10 8 10C7.46957 10 6.96086 9.78929 6.58579 9.41421C6.21071 9.03914 6 8.53043 6 8Z';

const pathConfig: IconConfig = {
  small: {
    default: {
      path: smallPath,
    },
  },
  standard: {
    default: {
      path: smallPath, // No standard variant
    },
  },
};

const CircleXSmall = withDSIconMaker(pathConfig, 'small circle');

export default CircleXSmall;
