import { useEffect, useMemo, useState } from 'react';
import {
  OptionType,
  SelectRounded,
  spacing,
  TextDS,
  TouchableView,
  useEmotionTheme,
  useScrollViewActions,
  useWindowWidthState,
  ValueTypeSingleSelect,
  View,
} from '@talkspace/react-toolkit';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { components, ControlProps, MenuListComponentProps, OptionProps } from 'react-select';
import { NoticeProps } from 'react-select/src/components/Menu';
import Button from '@talkspace/react-toolkit/src/designSystems/components/Button';
import { CircleXSmall, MagnifyingGlass } from '@talkspace/react-toolkit/src/designSystems/icons';
import useQueryPCPSearch, { PCPResult } from 'ts-frontend/hooks/useQueryPCPSearch';
import debounce from 'lodash/debounce';
import PCPManualForm from './PCPManualForm';
import PCPCheckBox from './PCPCheckBox';
import PCPInfoCard from './PCPInfoCard';
import { OptionTypeWithResults, PCPSearchSchema } from './types';

interface Props {
  onSelect: (value: PCPSearchSchema) => void;
  formerValue: PCPSearchSchema | undefined;
}

const pcpSearchSchema: yup.SchemaOf<PCPSearchSchema> = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  organization: yup.string().test({
    name: 'organization-test',
    test: async (value, context) => {
      let schema = yup.string().optional().nullable();
      if (context.options.context?.isManualEntry) {
        schema = yup.string().required('Organization is required');
      }
      return (
        (await schema.isValid(value)) ||
        context.createError({
          message: 'Organization is required',
        })
      );
    },
  }),
  phoneNumber: yup.string().optional().nullable(),
  consentShareInfo: yup.boolean().default(false).required(),
  npiNumber: yup.number().optional().nullable(),
  address: yup.string().optional().nullable(),
});

const NoOptionsMessage = (props: NoticeProps<OptionType, false>) => (
  <components.NoOptionsMessage {...props}>
    <span>No results.</span>
  </components.NoOptionsMessage>
);

const Option = (props: OptionProps<OptionType, false>) => {
  const { data } = props;
  const {
    basic: { first_name: firstName, last_name: lastName },
    addresses,
  } = data as PCPResult;
  const { address_1: address, telephone_number: telephoneNumber } = addresses[0];
  return (
    <components.Option {...props}>
      <View style={{ gap: 4, width: '100%' }}>
        <TextDS variant="body">{`${firstName} ${lastName}`}</TextDS>
        <View row>
          <TextDS
            variant="body"
            colorRole="textSubtlest"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flex: 10,
              flexShrink: 0,
              textTransform: 'capitalize',
            }}
          >
            {address}
          </TextDS>
          {telephoneNumber && (
            <>
              <CircleXSmall colorType="subtlest" />
              <TextDS variant="body" colorRole="textSubtlest">
                {telephoneNumber}
              </TextDS>
            </>
          )}
        </View>
      </View>
    </components.Option>
  );
};

const MenuList = (props: MenuListComponentProps<OptionType, false>) => {
  const { children } = props;
  const { colorRoles } = useEmotionTheme();
  const { selectProps } = props;
  const { onManualEntryPress } = selectProps;
  return (
    <components.MenuList {...props}>
      {children}
      <TouchableView
        style={{
          paddingTop: spacing.space200,
          paddingBottom: spacing.space200,
          minHeight: 54,
          alignItems: 'center',
          display: 'flex',
        }}
        onPress={onManualEntryPress}
      >
        {/* TODO: RegularButton + clickHandler props.selectOption */}
        <TextDS
          variant="headingMd"
          style={{
            color: colorRoles.button.brandTertiaryTextDefault,
          }}
        >
          Enter Manually
        </TextDS>
      </TouchableView>
    </components.MenuList>
  );
};

const Control = (props: ControlProps<OptionType, false>) => (
  <components.Control {...props}>
    <MagnifyingGlass colorType="subtlest" />
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.children}
  </components.Control>
);

const usePrimaryCareProviderOptions = ({ query }: { query: string }) => {
  const { data, isLoading, ...rest } = useQueryPCPSearch(query);

  return useMemo(() => {
    if (isLoading) {
      return {
        loading: true,
        options: [] as OptionTypeWithResults[],
        ...rest,
      };
    }

    return {
      loading: false,
      options:
        data?.results.map<OptionTypeWithResults>((result) => {
          return {
            label: `${result.basic.last_name}, ${result.basic.first_name}`,
            value: result.number,
            ...result,
          };
        }) || [],
      ...rest,
    };
  }, [data?.results, isLoading, rest]);
};

const getValue = (formValues: PCPSearchSchema): OptionTypeWithResults | null => {
  if (!formValues?.firstName) return null;
  const label = `${formValues.lastName} ${formValues.firstName}`;
  return {
    label,
    value: formValues.npiNumber?.toString() || label,
    basic: {
      first_name: formValues.firstName,
      last_name: formValues.lastName,
    } as OptionTypeWithResults['basic'],
    addresses: [
      {
        address_1: formValues.address || '',
        telephone_number: formValues.phoneNumber || '',
      },
    ] as OptionTypeWithResults['addresses'],
    // Default values for fields unnecessary fields
    number: formValues.npiNumber?.toString() || '000000000',
    taxonomies: [],
    identifiers: [],
    endpoints: [],
    other_names: [],
    created_epoch: '0',
    enumeration_type: 'NPI-1',
    last_updated_epoch: '0',
    practiceLocations: [],
  };
};

const PCPSearch = ({ onSelect, formerValue }: Props) => {
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [query, setQuery] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const methods = useForm<PCPSearchSchema>({
    resolver: yupResolver(pcpSearchSchema),
    context: {
      isManualEntry,
    },
    defaultValues: {
      firstName: '',
      lastName: '',
      organization: undefined,
      phoneNumber: undefined,
      consentShareInfo: false,
      npiNumber: undefined,
      address: undefined,
    },
  });

  const { getValues, reset, handleSubmit } = methods;
  useEffect(() => {
    if (formerValue) {
      reset({
        ...formerValue,
      });
    }
  }, [formerValue, reset]);

  const { colorRoles } = useEmotionTheme();
  const { loading, options } = usePrimaryCareProviderOptions({ query });
  const { scrollToBottom } = useScrollViewActions();
  const onChange = (value: ValueTypeSingleSelect<OptionTypeWithResults>) => {
    if (!value) {
      methods.reset({}, { keepDefaultValues: true });
      return;
    }
    methods.setValue('firstName', value.basic.first_name);
    methods.setValue('lastName', value.basic.last_name);
    methods.setValue('phoneNumber', value.addresses[0].telephone_number);
    methods.setValue('npiNumber', +value.number);
    methods.setValue('address', value.addresses[0].address_1);
  };

  const onModeSwitch = () => {
    setIsManualEntry(!isManualEntry);
  };

  const formValues = getValues();
  const selectValue = useMemo(() => getValue(formValues), [formValues]);
  const shouldShowContinueButton = isManualEntry ? true : !!selectValue;

  const onSubmit = handleSubmit((data) => {
    onSelect(data);
  });

  const { isMobile } = useWindowWidthState();

  useEffect(() => {
    // If select is unmounted by rendering of the manual entry, onMenuClosed is not fired.
    // This effect ensures that if select is not mounted, the state variable of menu open is reset
    if (isManualEntry) setIsMenuOpen(false);
  }, [isManualEntry]);
  useEffect(() => {
    if (isMenuOpen && isMobile) scrollToBottom();
  }, [options, isMenuOpen, scrollToBottom, isMobile]);

  return (
    <FormProvider {...methods}>
      <View align="center" style={{ marginBottom: isMenuOpen && isMobile ? spacing.space900 : 0 }}>
        {!isManualEntry && (
          <TextDS
            variant="bodySm"
            style={{ alignSelf: 'flex-start', marginBottom: spacing.space050 }}
          >
            Your primary care doctor
          </TextDS>
        )}
        {(() => {
          if (isManualEntry) {
            return (
              <>
                <PCPManualForm />
                <PCPCheckBox />
              </>
            );
          }
          if (selectValue) {
            return (
              <View align="stretch" style={{ gap: spacing.space300 }}>
                <PCPInfoCard pcp={formValues} onRemove={() => onChange(null)} />
                <PCPCheckBox />
              </View>
            );
          }

          return (
            <SelectRounded
              value={selectValue}
              dataQa="PCPSearchSelect"
              placeholder="Search for your doctor"
              filterOption={null}
              isInputReadOnly={false}
              onInputChange={debounce((value) => {
                setQuery(value);
              }, 400)}
              onMenuOpen={() => setIsMenuOpen(true)}
              onMenuClose={() => setIsMenuOpen(false)}
              options={options}
              isLoading={loading}
              menuBorderColor={colorRoles.borders.borderInteractiveDefault}
              onManualEntryPress={onModeSwitch}
              styles={{
                control: (base, { isFocused }) => {
                  return {
                    ...base,
                    paddingRight: 3,
                    paddingTop: 4,
                    border: `1px solid ${
                      isFocused
                        ? colorRoles.borders.borderInteractiveSelectedBold
                        : colorRoles.borders.borderInteractiveDefault
                    }`,
                  };
                },
                input: (base) => {
                  return {
                    ...base,
                    caretColor: colorRoles.typography.textDefault,
                    fontSize: 14,
                  };
                },
                noOptionsMessage: (base) => {
                  return {
                    ...base,
                    // Hide "No Options" if user hasn't typed
                    ...(query === '' ? { display: 'none' } : {}),
                    color: colorRoles.typography.textSubtlest,
                    justifyContent: 'center',
                  };
                },
                option: (base, { data, isSelected, isFocused, selectProps }) => {
                  const {
                    value,
                    isMulti,
                    selectProps: { isMouseUser },
                  } = selectProps;
                  let activeStyles = {};

                  if (!isMouseUser && isFocused) {
                    activeStyles = {
                      backgroundColor: colorRoles.surfaces.surfaceInteractivePressed,
                    };
                  }
                  const showSelectedStyling = isMulti
                    ? (isSelected && !isFocused) || (isSelected && isFocused && isMouseUser)
                    : (data === value && !isFocused) ||
                      (data === value && isFocused && isMouseUser);
                  return {
                    ...base,
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: showSelectedStyling
                      ? colorRoles.surfaces.surfaceInteractivePressed
                      : 'transparent',
                    '&:hover': isMouseUser
                      ? {
                          backgroundColor: colorRoles.surfaces.surfaceInteractiveHovered,
                        }
                      : {},
                    ...activeStyles,
                  };
                },
              }}
              components={{
                NoOptionsMessage,
                MenuList,
                Option,
                Control,
              }}
              wrapperStyle={{ marginTop: 0 }}
              onChange={onChange}
            />
          );
        })()}
        {shouldShowContinueButton && (
          <Button dataQa="PCPSearchContinueButton" onPress={onSubmit} text="Continue" />
        )}
      </View>
    </FormProvider>
  );
};

export default PCPSearch;
