import { useEffect, useMemo, useState, useRef } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { FormProvider, useForm } from 'react-hook-form';
import validateEmail from 'ts-frontend/utils/validateEmail';
import { useTranslation } from '@talkspace/i18n';
import { TFTeenOnboarding } from '@talkspace/i18n/types';

import * as yup from 'yup';
import {
  View,
  Button,
  Large,
  Huge,
  A11Y_COLORS,
  Link,
  PanelHeader,
  Big,
  HeaderBackWrapper,
  useWindowWidthState,
  TSLogo,
} from '@talkspace/react-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { parseFullName } from 'ts-frontend/utils/parseString';
import localStorage from '@/core/storage/localStorage';
import useQueryEmergencyContact from '@/hooks/useQueryEmergencyContact';
import BackButton from '../components/BackButton';
import useScreenSize, { isResponsive } from '../../core/hooks/useScreenSize';
import styled from '../../core/styled';
import useMutationParentalConsentDetails from '../hooks/useMutationSubmitParentalConsentDetails';
import useQueryCustomerInformation from '../../hooks/useQueryCustomerInformation';
import RadioAccordionOption from '@/components/RadioAccordionOption';
import RadioAccordion from '@/components/RadioAccordion';
import { getUserData } from '../../auth/helpers/token';
import { useLocation } from '@/core/routerLib';
import ConsenterDetailsForm from '../components/ConsenterDetailsForm';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

const TEEN_CONSENT_EXCEPTIONS_ARTICLE_LINK =
  'https://help.talkspace.com/hc/en-us/articles/18167691776027';

interface ParentalConsentFields {
  consenterRelationship?: string;
  consenterFirstName?: string;
  consenterLastName?: string;
  consenterEmail?: string;
  consenterLanguage?: string;
  isNYCTeen?: boolean;
  consentDetailsFormSkipped?: boolean;
  shouldSendEmailImmediately?: boolean;
}

interface ConsenterDetailsFields {
  firstName: string;
  lastName: string;
  email: string;
}

type ConsenterOptionType = 'parent' | 'guardian' | 'exemption';

const StyledExceptionsLink = styled(Link)({
  marginTop: 24,
  display: 'flex',
  padding: '14px 24px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  alignSelf: 'stretch',
  color: A11Y_COLORS.accessibilityGreenDark,
  fontSize: 17,
  fontWeight: 700,
  letterSpacing: 0.5,
});

const StyledSubmitButtonContainer = styled(View)({
  display: 'inline-flex',
  width: '100%',
  paddingTop: 24,
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
});

const StyledWrapper = styled(View)(({ theme: { safeAreaInsets } }) => {
  return {
    paddingBottom: safeAreaInsets.bottom,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: 'white',
  };
});

const StyledStickyFooter = styled('footer')({
  borderTop: `1px solid ${A11Y_COLORS.permaLondonGray}`,
  backgroundColor: A11Y_COLORS.white,
  position: 'sticky',
  bottom: 0,
  overflow: 'hidden',
  transition: 'all 0.25s ease',
});

const StyledStickyHeader = styled('header')<{ noSafeArea: boolean }>(
  ({ theme: { safeAreaInsets }, noSafeArea }) => {
    return {
      borderBottom: `1px solid ${A11Y_COLORS.permaLondonGray}`,
      backgroundColor: A11Y_COLORS.white,
      position: 'sticky',
      paddingTop: noSafeArea ? 0 : safeAreaInsets.top,
      top: 0,
      height: noSafeArea ? 67 : 67 + safeAreaInsets.top,
      display: 'flex',
      alignItems: 'center',
    };
  }
);

const StyledContent = styled(View)<{ isRoomModal: boolean }>(({ isRoomModal }) => {
  return {
    flex: '1',
    maxHeight: isRoomModal ? `calc(100dvh - 200px)` : undefined,
    overflowY: isRoomModal ? 'auto' : undefined,
  };
});

const StyledScrollPadding = styled(View)<{ isRoomModal: boolean }>(({ isRoomModal }) => {
  return {
    paddingBottom: isRoomModal ? 200 : undefined,
  };
});

const StyledBottomBarWrapper = styled(View)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 25,
});

const StyledButton = styled(Button)(({ theme: { colors } }) => {
  return {
    position: 'sticky',
    top: 100,
    fontFamily: 'Roboto',
    backgroundColor: colors.green,
    fontSize: 19,
    fontWeight: 'bold',
    width: 335,
    minHeight: 55,
    borderRadius: 10,
    display: 'flex',
    marginTop: 0,
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
    letterSpacing: 0.74,
    textAlign: 'center',
  };
});

const getDetailsSchema = (
  relation: string,
  userEmail: string | undefined,
  tTeenOnboarding: TFTeenOnboarding
): yup.SchemaOf<ConsenterDetailsFields> =>
  yup.object().shape({
    firstName: yup
      .string()
      .test(
        'has-entered-first-name',
        relation === 'parent'
          ? tTeenOnboarding(
              'sendConsentFormTo.parentFirst',
              'Please enter parent first name.',
              undefined
            )
          : tTeenOnboarding(
              'sendConsentFormTo.guardianFirst',
              'Please enter guardian first name.',
              undefined
            ),
        (userInput: ConsenterDetailsFields['firstName']) => !!userInput
      )
      .required(`Please enter ${relation} first name.`),
    lastName: yup
      .string()
      .test(
        'has-entered-last-name',
        relation === 'parent'
          ? tTeenOnboarding(
              'sendConsentFormTo.parentLast',
              'Please enter parent last name.',
              undefined
            )
          : tTeenOnboarding(
              'sendConsentFormTo.guardianLast',
              'Please enter guardian last name.',
              undefined
            ),
        (userInput: ConsenterDetailsFields['lastName']) => !!userInput
      )
      .required(`Please enter ${relation} last name.`),
    email: yup
      .string()
      .test(
        'has-entered-email',
        relation === 'parent'
          ? tTeenOnboarding(
              'sendConsentFormTo.parentEmail',
              'Please enter parent email.',
              undefined
            )
          : tTeenOnboarding(
              'sendConsentFormTo.guardianEmail',
              'Please enter guardian email.',
              undefined
            ),
        (userInput: ConsenterDetailsFields['email']) => !!userInput
      )
      .test(
        'has-entered-valid-email',
        tTeenOnboarding('sendConsentFormTo.notValidEmail', 'This is not a valid email.', undefined),
        (userInput: ConsenterDetailsFields['email']) => !userInput || validateEmail(userInput)
      )
      .test(
        'has-set-different-email-than-own',
        relation === 'parent'
          ? tTeenOnboarding(
              'sendConsentFormTo.parentDiffEmail',
              'Different email required for parent.',
              undefined
            )
          : tTeenOnboarding(
              'sendConsentFormTo.guardianDiffEmail',
              'Different email required for guardian.',
              undefined
            ),
        (userInput: ConsenterDetailsFields['email']) => !userInput || userInput !== userEmail
      )
      .required(`Please enter ${relation} email.`),
    language: yup.string().optional(),
  });

interface ParentalConsentDetailsInnerContainerProps {
  onComplete: () => void;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  title;
  onCloseButtonClick?: () => void;
  userEmail: string | undefined;
  isResubmit: boolean;
}

const ParentalConsentDetailsContainerInner = ({
  onComplete,
  showBackButton = true,
  showCloseButton = false,
  title,
  onCloseButtonClick,
  userEmail,
  isResubmit,
}: ParentalConsentDetailsInnerContainerProps) => {
  const location = useLocation();
  const { t: tTeenOnboarding } = useTranslation('teenOnboarding');
  const {
    mutate: postDetails,
    isLoading: isPostDetailsLoading,
    isSuccess: isPostDetailsSuccess,
    isError: isPostDetailsError,
    error: postDetailsError,
  } = useMutationParentalConsentDetails();
  const parentRadioButtonRef = useRef<HTMLInputElement>(null);
  const guardianRadioButtonRef = useRef<HTMLInputElement>(null);
  const { id: userID } = getUserData();
  const { data: { contactName, relationship } = {} } = useQueryEmergencyContact({ userID });

  const { firstName, lastName } = parseFullName(contactName || '');

  const defaultConsenter = (
    ['parent', 'guardian'].includes(relationship || '') ? relationship : undefined
  ) as Extract<ConsenterOptionType, 'parent' | 'guardian'> | undefined;
  const [consenterOption, setConsenterOption] = useState<ConsenterOptionType | undefined>(
    defaultConsenter ?? undefined
  );
  const screenSize = useScreenSize();
  const { isMobile } = useWindowWidthState();
  const { data: customerInformation } = useQueryCustomerInformation({ userID });
  const { teenUserCanSkipParentalConsent, localizationParentalConsent, brandRefreshChanges } =
    useFlags();
  const guardianDetailsRef = useRef<HTMLInputElement>(null);
  const parentDetailsRef = useRef<HTMLInputElement>(null);
  const parentDetailsSchema = getDetailsSchema('parent', userEmail, tTeenOnboarding);
  const guardianDetailsSchema = getDetailsSchema('guardian', userEmail, tTeenOnboarding);

  const allowSkip = useMemo(
    () => teenUserCanSkipParentalConsent && !isResubmit,
    [isResubmit, teenUserCanSkipParentalConsent]
  );

  const parentMethods = useForm<ConsenterDetailsFields>({
    resolver: yupResolver(parentDetailsSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      language: localizationParentalConsent ? 'en' : undefined,
    } as ConsenterDetailsFields,
  });
  const { handleSubmit: handleParentSubmit, formState: parentFormState } = parentMethods;

  const handleCloseButtonClick = () => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
  };

  const guardianMethods = useForm<ConsenterDetailsFields>({
    resolver: yupResolver(guardianDetailsSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      language: localizationParentalConsent ? 'en' : undefined,
    } as ConsenterDetailsFields,
  });
  const { handleSubmit: handleGuardianSubmit, formState: guardianFormState } = guardianMethods;

  useEffect(() => {
    if (isPostDetailsSuccess) {
      onComplete();
    }
  }, [isPostDetailsSuccess, onComplete]);

  const additionalHeightForParentFormErrors = useMemo(
    () => Object.keys(parentFormState.errors).length * 18,
    [parentFormState]
  );

  const resubmitExtraHeight = 34;
  const initialOptionsHeight = localizationParentalConsent ? 400 : 264;
  const baseOptionsHeight = useMemo(
    () => (isResubmit ? initialOptionsHeight : initialOptionsHeight + resubmitExtraHeight),
    [isResubmit, initialOptionsHeight]
  );

  const additionalHeightForGuardianFormErrors = useMemo(
    () => Object.keys(guardianFormState.errors).length * 18,
    [guardianFormState]
  );
  const sendFormNoticeCopy = tTeenOnboarding(
    'sendConsentFormTo.shortDescription',
    'We’ll send a form to your parent or guardian, but we won’t share why you’re seeking therapy.',
    undefined
  );
  const stickyFooterHeight = useMemo(() => {
    if (!consenterOption) {
      return 0;
    }
    return isMobile ? 120 : 104;
  }, [consenterOption, isMobile]);

  const submitFormData = (data: ParentalConsentFields) => {
    postDetails({
      userID,
      data,
    });
  };

  const onSubmit = (data) => {
    localStorage.setItem(
      'parentalConsentLanguage',
      JSON.stringify({ consenterLanguage: data.language })
    );
    localStorage.setItem('isNYCTeen', String(customerInformation?.state === 'NY'));
    submitFormData({
      consenterRelationship: consenterOption,
      consenterFirstName: data.firstName,
      consenterLastName: data.lastName,
      consenterEmail: data.email,
      consenterLanguage: data.language,
      consentDetailsFormSkipped: false,
      isNYCTeen: customerInformation?.state === 'NY',
      shouldSendEmailImmediately: true,
    });
  };

  const onSubmitExemption = () => {
    submitFormData({
      consentDetailsFormSkipped: true,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    switch (consenterOption) {
      case 'parent':
        handleParentSubmit(onSubmit)(e);
        break;
      case 'guardian':
        handleGuardianSubmit(onSubmit)(e);
        break;
      case 'exemption':
        onSubmitExemption();
        break;
    }
  };

  const handleRadioClick = (inputRef) => {
    if (inputRef?.current) {
      const element = inputRef.current.querySelector('[name="firstName"]');
      if (element) {
        setTimeout(() => {
          element.focus();
        }, 300);
      }
    }
  };

  // prefill the parent details from emergency contact
  useEffect(() => {
    if (['parent', 'guardian'].includes(defaultConsenter || '')) {
      const methods = defaultConsenter === 'parent' ? parentMethods : guardianMethods;
      const radioRef =
        defaultConsenter === 'parent' ? parentRadioButtonRef : guardianRadioButtonRef;
      // click on the corresponding radio button
      radioRef.current?.click();
      // prefill corresponding form values
      methods.setValue('firstName', firstName);
      methods.setValue('lastName', lastName);
    }
  }, [defaultConsenter, firstName, guardianMethods, lastName, parentMethods]);

  const isRoomModal = location.pathname === '/parental-consent-resubmit';

  return (
    <StyledWrapper>
      <StyledStickyHeader noSafeArea={isRoomModal}>
        <PanelHeader
          renderLeft={() => (
            <>
              {isMobile ? (
                <>
                  <View style={{ width: 13 }}>
                    {showBackButton && (
                      <HeaderBackWrapper>
                        <BackButton />
                      </HeaderBackWrapper>
                    )}
                  </View>
                  <View flex={1} align={isMobile ? 'center' : 'start'}>
                    <Big>{!isResubmit && title}</Big>
                  </View>
                </>
              ) : (
                <TSLogo variant={brandRefreshChanges ? '2024' : 'old'} />
              )}
            </>
          )}
          onRightPress={showCloseButton ? () => handleCloseButtonClick() : undefined}
          dataQa="parentalConsentDetailsCloseButtonDataQa"
        />
      </StyledStickyHeader>
      <StyledContent isRoomModal={isRoomModal}>
        <StyledScrollPadding isRoomModal={isRoomModal}>
          {!isMobile && (
            <View style={{ paddingTop: 52, paddingLeft: 32 }}>
              {showBackButton && <BackButton />}
            </View>
          )}
          <View align="center">
            <View
              align="center"
              style={{
                marginTop: isResponsive(screenSize) ? 30 : 60,
                marginBottom: 29,
                maxWidth: 335,
              }}
            >
              <Huge style={{ marginBottom: 10, textAlign: 'center' }}>
                {tTeenOnboarding(
                  'sendConsentFormTo.title',
                  'Who should we send a consent form to?',
                  undefined
                )}
              </Huge>
              <View
                style={{
                  textAlign: 'center',
                  width: 335,
                }}
              >
                <Large style={{ color: A11Y_COLORS.permaGrey }}>{sendFormNoticeCopy}</Large>
              </View>
            </View>
            {isPostDetailsError && (
              <View style={{ color: 'red' }}>{postDetailsError?.message} </View>
            )}
            <RadioAccordion
              onChange={(value) => {
                setConsenterOption(value as ConsenterOptionType);
              }}
            >
              <RadioAccordionOption
                ref={parentRadioButtonRef}
                title={tTeenOnboarding(
                  'sendConsentFormTo.parentUpperRelation',
                  'Parent',
                  undefined
                )}
                value="parent"
                contentHeight={baseOptionsHeight + additionalHeightForParentFormErrors}
                color={A11Y_COLORS.accessibilityGreenDark}
                dataQa="consenterRadioOptionParent"
                onRadioChange={() => handleRadioClick(parentDetailsRef)}
              >
                <FormProvider {...parentMethods}>
                  <ConsenterDetailsForm relation="parent" detailsRef={parentDetailsRef} />
                </FormProvider>
              </RadioAccordionOption>
              <RadioAccordionOption
                ref={guardianRadioButtonRef}
                title={tTeenOnboarding(
                  'sendConsentFormTo.guardianUpperRelation',
                  'Guardian',
                  undefined
                )}
                value="guardian"
                contentHeight={baseOptionsHeight + additionalHeightForGuardianFormErrors}
                color={A11Y_COLORS.accessibilityGreenDark}
                dataQa="consenterRadioOptionGuardian"
                onRadioChange={() => handleRadioClick(guardianDetailsRef)}
              >
                <FormProvider {...guardianMethods}>
                  <ConsenterDetailsForm relation="guardian" detailsRef={guardianDetailsRef} />
                </FormProvider>
              </RadioAccordionOption>
              <RadioAccordionOption
                title={tTeenOnboarding(
                  'sendConsentFormTo.exemptTitle',
                  'Discuss with my therapist',
                  undefined
                )}
                description={tTeenOnboarding(
                  'sendConsentFormTo.exemptSubtitle',
                  'I want to see if I might be exempt',
                  undefined
                )}
                value="exemption"
                noContent
                hidden={!allowSkip}
                contentHeight={0}
                color={A11Y_COLORS.accessibilityGreenDark}
                dataQa="consenterRadioOptionExemption"
              />
            </RadioAccordion>
            {allowSkip && (
              <StyledExceptionsLink
                dataQa="teenConsentExceptionArticleLink"
                href={TEEN_CONSENT_EXCEPTIONS_ARTICLE_LINK}
                target="_blank"
              >
                {tTeenOnboarding(
                  'sendConsentFormTo.seeConsent',
                  'See consent exceptions',
                  undefined
                )}
              </StyledExceptionsLink>
            )}
          </View>
        </StyledScrollPadding>
      </StyledContent>
      <StyledStickyFooter style={{ height: stickyFooterHeight }}>
        <StyledSubmitButtonContainer>
          <form onSubmit={handleSubmit}>
            <StyledButton
              type="submit"
              style={{ width: 335, backgroundColor: A11Y_COLORS.green, padding: '14px 24px' }}
              text={consenterOption === 'exemption' ? 'Continue' : 'Send consent form'}
              isLoading={isPostDetailsLoading}
              disabled={!consenterOption}
              dataQa="submitParentalConsentFormButton"
            />
          </form>
        </StyledSubmitButtonContainer>
        {isMobile ? <StyledBottomBarWrapper /> : <View style={{ height: 50 }} />}
      </StyledStickyFooter>
    </StyledWrapper>
  );
};

interface ParentalConsentDetailsContainerProps {
  onComplete: () => void;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  title;
  onCloseButtonClick?: () => void;
  isResubmit?: boolean;
}

const ParentalConsentDetailsContainer = ({
  onComplete,
  showBackButton = true,
  showCloseButton = false,
  title,
  onCloseButtonClick,
  isResubmit = false,
}: ParentalConsentDetailsContainerProps) => {
  const { id: userID } = getUserData();
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getUserEmail = async () => {
      const userEmailRes = await apiWrapper.get(
        `${apiHelper().apiEndpoint}/v2/users/${userID}/email`
      );
      setUserEmail(userEmailRes?.data?.data?.email || 'email_not_found');
    };

    getUserEmail();
  }, [isResubmit, userID]);

  return userEmail ? (
    <ParentalConsentDetailsContainerInner
      onComplete={onComplete}
      title={title}
      userEmail={userEmail}
      showBackButton={showBackButton}
      showCloseButton={showCloseButton}
      onCloseButtonClick={onCloseButtonClick}
      isResubmit={isResubmit}
    />
  ) : (
    <></>
  );
};

export default ParentalConsentDetailsContainer;
