import { VoidFunctionComponent } from 'react';
import { TherapistTimeslot } from 'ts-frontend/types';
import useRepeatingTimeslotsCopy from './useRepeatingTimeslotsCopy';
import { Large } from '../Typography';
import styled from '../../core/styled';

const LargeLight = styled(Large)({
  fontWeight: 400,
});

interface Props {
  repeatingTimeslots?: TherapistTimeslot[] | null;
}

const RepeatingBookingDates: VoidFunctionComponent<Props> = ({ repeatingTimeslots }) => {
  const { everyWeek, dayName, startTimeString, endTimeString, startDateString, endDateString } =
    useRepeatingTimeslotsCopy(repeatingTimeslots);
  return (
    <>
      <LargeLight variant="largeMediumGrey">
        {!everyWeek ? 'Every other' : 'Every'} {dayName}
      </LargeLight>
      <LargeLight variant="largeMediumGrey">
        {startTimeString} {' – '}
        {endTimeString}
      </LargeLight>
      <LargeLight variant="largeMediumGrey">
        {startDateString} {' – '}
        {endDateString}
      </LargeLight>
    </>
  );
};

export default RepeatingBookingDates;
