import { FunctionComponent, useMemo } from 'react';
import { View, Spinner, spacing, TextDS, useWindowWidthState } from '@talkspace/react-toolkit';
import { FilePDF } from '@talkspace/react-toolkit/src/designSystems/icons';

import styled from '@/core/styled';

import FileErrorIcon from '../Icons/FileError';
import './style.css'; // hack to solve bootstrap global * { box-sizing: border-box }

const Wrapper = styled(View)(({ theme: { colorRoles } }) => {
  return {
    flexDirection: 'row',
    maxHeight: 56,
    height: 40,
    borderRadius: spacing.space150,
    padding: spacing.space100,
    gap: spacing.space100,
    backgroundColor: colorRoles.surfaces.brandSubtleDefault,
    alignItems: 'center',
  };
});

interface Props {
  isLoading?: boolean;
  isError?: boolean;
  fileName: string;
  fileSize: number;
  fileType: string;
  dataQa?: string;
}

const FileBubble: FunctionComponent<Props> = ({
  isLoading,
  isError,
  fileName,
  fileSize,
  fileType,
  dataQa,
}) => {
  const window = useWindowWidthState();
  const charLimit = window.isSmall || window.isMedium ? 22 : 27;

  const displayedFileName = useMemo(
    () => (fileName.length > charLimit ? `${fileName.slice(0, charLimit)}...` : fileName),
    [fileName, charLimit]
  );

  const displayedFileSizeMB = useMemo(() => Number((fileSize / 1000000).toFixed(2)), [fileSize]);

  const displayedFileSizeKB = useMemo(() => Number((fileSize / 1000).toFixed(2)), [fileSize]);

  const displayedFileType = useMemo(
    () => fileType.split('/')[1]?.toUpperCase() || fileType,
    [fileType]
  );

  const fileDetails = `${
    displayedFileSizeMB > 1 ? `${displayedFileSizeMB}MB` : `${displayedFileSizeKB}KB`
  }, ${displayedFileType}`;

  return (
    <View className="file-bubble-container" data-qa={dataQa}>
      <Wrapper>
        <View>
          {(() => {
            if (isLoading) {
              return (
                <Spinner
                  style={{ width: 30, height: 30 }}
                  containerStyle={{ paddingTop: 2, width: 35, height: 35 }}
                />
              );
            }
            if (isError) {
              return <FileErrorIcon />;
            }
            return <FilePDF size="huge" />;
          })()}
        </View>
        <View flex={1}>
          <TextDS variant="headingMd" colorRole="textDefault">
            {displayedFileName}
          </TextDS>
          <TextDS variant="bodyXs" colorRole="textSubtle">
            {fileDetails}
          </TextDS>
        </View>
      </Wrapper>
    </View>
  );
};

export default FileBubble;
