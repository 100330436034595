import { FunctionComponent } from 'react';
import {
  useUniqueID,
  TouchableView,
  FocusTrap,
  View,
  useWindowWidthState,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import styled, { EmotionStyle } from '../../core/styled';
import { webOnlyStyle } from '../../core/styled/styleHelpers';

interface DropdownMenuModalProps {
  hide?: boolean;
  style?: EmotionStyle;
  onBackdropPress(): void;
  ariaLabelledby?: string;
  id?: string;
}

const fullScreenStyles = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  paddingBottom: 10,
  ...webOnlyStyle({
    position: 'absolute',
    width: 'initial',
    height: 'initial',
    borderWidth: 0,
    borderRadius: 0,
  }),
};

const ContentsWrapper = styled(View)({
  left: 67,
  top: 58,
  height: 632,
  width: 293,
  borderWidth: 1,
  display: 'flex',
  borderRadius: 10,
  position: 'absolute',
  flexDirection: 'column',
  backgroundColor: 'white',
  overflow: 'hidden',
  ':focus': {
    outline: 'none',
  },
});

const Overlay = styled(TouchableView)({
  ...fullScreenStyles,
  backgroundColor: 'transparent',
});

const DropdownMenuModal: FunctionComponent<DropdownMenuModalProps> = ({
  onBackdropPress,
  children,
  hide,
  ariaLabelledby,
  style = {},
  id,
}) => {
  const contentWrapperID = useUniqueID('contentWrapperID');
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const contentWrapperStyle = {
    borderColor: colors.extraLightGrey,
    ...webOnlyStyle({
      border: `1px solid ${colors.extraLightGrey}`,
      boxShadow: '2px 11px 13px 0 rgba(0,0,27,0.15)',
      right: 'auto',
    }),
    ...(isMobile ? { ...fullScreenStyles, zIndex: 999 } : {}),
    ...style,
  };
  return hide ? null : (
    <>
      <FocusTrap
        escapeCloses
        onDeactivate={onBackdropPress}
        wrapper={id || contentWrapperID}
        titleText="account menu"
      >
        <Overlay onPress={onBackdropPress} tabIndex={-1} />
        <ContentsWrapper
          role="region"
          id={id || contentWrapperID}
          aria-labelledby={ariaLabelledby}
          style={contentWrapperStyle}
          tabIndex={-1}
        >
          {children}
        </ContentsWrapper>
      </FocusTrap>
    </>
  );
};

export default DropdownMenuModal;
