import * as LiveUpdates from '@capacitor/live-updates';
import { safeIonicWrapper } from '../../ionicUtils';
import { liveUpdatesLogger } from '../../loggers';
import { APP_LOADED, CHECK_PASSCODE } from '../../modules/passcode/constants';
import { SplashScreenHide, SplashScreenShow } from '../splashScreen';

let currentLiveUpdate: LiveUpdates.SyncResult | null = null;

type Config = {
  showSplash?: boolean;
  /**
   * Amount of milliseconds to show the splash screen for before hiding it.
   * This might take longer due to Live Updates
   */
  splashDuration?: number;
};

export const updateWebVersion = safeIonicWrapper(
  async ({ showSplash = false, splashDuration = 2000 }: Config = {}) => {
    const now = new Date();
    if (showSplash)
      await SplashScreenShow({
        autoHide: true,
        showDuration: 5 * 1000, // Default timeout for app to never become unresponsive
      });
    const result = await LiveUpdates.sync().catch((error) => {
      liveUpdatesLogger.error('Live update failed', error);
      return null;
    });
    currentLiveUpdate = result;
    if (result?.activeApplicationPathChanged) {
      liveUpdatesLogger.info('Live update success');
      sessionStorage.removeItem(APP_LOADED);
      sessionStorage.removeItem(CHECK_PASSCODE);
      await LiveUpdates.reload();
      liveUpdatesLogger.info('Live update reload');
      // No need to hide splash here. When the app reloads, it will run this function again and it will go to the `else` block
    } else {
      // If _splashDuration_ mil have passed, immediately hide, otherwise hide for the remaining duration
      const timePassed = new Date().getTime() - now.getTime();
      const fadeOutDuration = showSplash ? splashDuration - timePassed : 0;
      await SplashScreenHide({
        fadeOutDuration: fadeOutDuration <= 0 ? undefined : fadeOutDuration,
      });
      liveUpdatesLogger.info('Splash screen hidden');
    }
  },
  Promise.resolve(undefined)
);

export const getLiveUpdateStatus = () => currentLiveUpdate;

export { liveUpdatesLogger };
