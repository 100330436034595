import { useEffect } from 'react';
import {
  ExtraHuge,
  Large,
  Modal,
  View,
  Button,
  useEmotionTheme,
  BaseButton,
  Standard,
  Tiny,
  Link,
  TwoFactorAuthenticationIcon,
  Spinner,
} from '@talkspace/react-toolkit';
import { useHistory } from 'core/routerLib';
import { Trans, useTranslation } from '@talkspace/i18n';
import { getUserData } from '@/auth/helpers/token';
import useIsTeenFunnelUser from '../hooks/useIsTeenFunnelUser';
import { trackClient2FAVerification } from '../utils/analytics';
import TwoFactorAuthenticationWrapper from '../containers/TwoFactorAuthenticationWrapper';
import { useClientAuthActions, useClientAuthState } from '../../hooks/clientAuthContext';

import useMutationVerifyNumber from '../hooks/useMutationVerifyPhone';

const TwoFactorAuthenticationVerifyNumber = () => {
  const { colors } = useEmotionTheme();
  const history = useHistory();
  const { id: userID } = getUserData();
  const { isTeenFunnelUser, isLoadingTeenFunnelUser } = useIsTeenFunnelUser(userID);

  const { isUpdating, phoneNumberLast2Digits, twoFAStatus } = useClientAuthState();
  const { update2faReminderAction, setOTPTokenAction, setErrorAction } = useClientAuthActions();

  const {
    mutate: verifyPhoneNumber,
    isLoading: isVerifyNumberLoading,
    isSuccess: sendingCodeSuccess,
  } = useMutationVerifyNumber();

  useEffect(() => {
    trackClient2FAVerification('Verify number flow');
  }, []);

  useEffect(() => {
    if (sendingCodeSuccess) history.push('/2fa/verify');
  }, [sendingCodeSuccess, history]);

  const handleVerifyNumber = () => {
    trackClient2FAVerification('Continue enable 2FA');
    verifyPhoneNumber(null, {
      onSuccess: (res) => {
        setOTPTokenAction(res.otpToken, res.phoneNumber);
      },
      onError: (err) => setErrorAction('Error verifying number'),
    });
  };

  const handleReminderLater = () => {
    trackClient2FAVerification('I’ll do this later verify 2FA');
    update2faReminderAction();
  };
  const { t: tTwoFactor } = useTranslation('twoFactor');

  return (
    <Modal isVisible isMobileFullscreen titleText="Enable two-factor authentication">
      <TwoFactorAuthenticationWrapper>
        <View flex={1} align="center" style={{ marginTop: 47, maxWidth: 335, textAlign: 'center' }}>
          <TwoFactorAuthenticationIcon />
          <ExtraHuge>
            {tTwoFactor('twoFactor.title', 'Enable two-factor authentication', undefined)}
          </ExtraHuge>
          <Large variant="largeDarkGrey" style={{ maxWidth: 309, marginTop: 10 }}>
            <Trans t={tTwoFactor} key="twoFactor.description">
              To keep your account extra secure, we’ll send a 6-digit verification code to your
              phone number ending in {phoneNumberLast2Digits} when you log in to make sure it’s you.
            </Trans>
          </Large>

          <View style={{ width: '100%' }}>
            <Button
              dataQa="2faReminderContinueButton"
              disabled={isUpdating}
              isLoading={isUpdating || isVerifyNumberLoading}
              text={tTwoFactor('twoFactor.continue', 'Continue', undefined)}
              onPress={handleVerifyNumber}
              primaryColor={colors.green}
              style={{ width: '100%', marginTop: 30 }}
              roundedFocusStyle
            />
            {isLoadingTeenFunnelUser ? (
              <Spinner containerStyle={{ height: 'auto', padding: 20 }} />
            ) : (
              <>
                {twoFAStatus === 'suggested' && (
                  <BaseButton
                    data-qa="2faReminderLaterButton"
                    onPress={handleReminderLater}
                    style={{ marginTop: 20, borderRadius: 5 }}
                    primaryColor={colors.green}
                    roundedFocusStyle
                  >
                    <Standard variant="standardBoldGreen">
                      {isTeenFunnelUser
                        ? tTwoFactor('twoFactor.skip', 'Skip', undefined)
                        : tTwoFactor('twoFactor.later', "I'll do this later", undefined)}
                    </Standard>
                  </BaseButton>
                )}
              </>
            )}
          </View>
          <Trans t={tTwoFactor} key="twoFactor.rates">
            <Tiny style={{ marginTop: 'auto', marginBottom: 60 }}>
              Message and data rates may apply. For more information, see our{' '}
              <Link
                dataQa="2faReminderTermsLink"
                stopPropagation
                target="_blank"
                href="https://www.talkspace.com/public/terms"
                style={{ marginLeft: 0, marginRight: 0 }}
                primaryColor={colors.green}
                roundedFocusStyle
              >
                <Tiny variant="tinyBoldGreen" inline>
                  Terms of use{' '}
                </Tiny>
              </Link>
              and{' '}
              <Link
                dataQa="2faReminderPrivacyLink"
                stopPropagation
                target="_blank"
                href="https://www.talkspace.com/public/privacy-policy"
                style={{ marginLeft: 0, marginRight: 0 }}
                primaryColor={colors.green}
                roundedFocusStyle
              >
                <Tiny variant="tinyBoldGreen" inline>
                  Privacy policy.
                </Tiny>
              </Link>
            </Tiny>
          </Trans>
        </View>
      </TwoFactorAuthenticationWrapper>
    </Modal>
  );
};

export default TwoFactorAuthenticationVerifyNumber;
