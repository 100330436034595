import { useTranslation } from '@talkspace/i18n';
import { RouteComponentProps, withRouter } from '@/core/routerLib';

import OnboardingPage from './OnboardingPage';
import ParentalConsent from '../assets/ParentalConsent';

const OnboardingPageTeenEmergencyContact = ({
  match: {
    params: { roomID },
  },
  totalSteps,
  currentStep,
  history,
}: RouteComponentProps<{ roomID: number }> & { totalSteps: number; currentStep: number }) => {
  const { t: tTeenOnboarding } = useTranslation('teenOnboarding');
  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={currentStep}
      illustration={<ParentalConsent />}
      title={tTeenOnboarding('letsCompleteProfile.title', "Let's complete your profile", undefined)}
      description={tTeenOnboarding(
        'letsCompleteProfile.description',
        'We’ll start by getting some contact information, then we’ll ask a few more questions so you’ll be ready for therapy.',
        undefined
      )}
      ctaText={tTeenOnboarding('letsCompleteProfile.ctaText', 'Start now', undefined)}
      onPress={() => {
        history.push(`/room/${roomID}/onboarding/teen-emergency-contact/source/onboarding`);
      }}
    />
  );
};

export default withRouter(OnboardingPageTeenEmergencyContact);
