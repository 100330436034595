import { FunctionComponent, useState, useEffect } from 'react';
import moment from 'moment';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  Avatar,
  View,
  TouchableView,
  Modal,
  Small,
  Huge,
  Image,
  CouplesDefaultAvatar,
  TherapistSearch,
  useEmotionTheme,
  RoundRectExclaimationMark,
} from '@talkspace/react-toolkit';
import { ERoom } from 'ts-frontend/entities/Room';
import {
  ETherapistInfo,
  Availability,
  checkIfPsychiatrist,
  INACTIVE_PHASE_VALUES,
} from 'ts-frontend/entities/Therapist';
import { useTranslation } from '@talkspace/i18n';
import { TFNewMemberNav } from '@talkspace/i18n/types';
import { COLORS } from '@/utils/design';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import { WorkingTodayIcon, RightCaretIcon, TimeOffIcon, OffTodayIcon } from '../icons';
import PlayButtonIcon from '../../assets/icons/PlayButtonIcon.png';
import { RouteComponentProps, withRouter } from '../../core/routerLib/index';
import { trackCTAClick } from '../../utils/analytics/events';
import appConfig from '../../utils/configs';

interface PanelTherapistBlockProps extends RouteComponentProps<{ roomID: number }> {
  therapist: ETherapistInfo;
  room: ERoom;
  clickable?: boolean;
  avatarSize?: number;
  playButton?: boolean;
  licenses?: boolean;
  startDate?: boolean;
  isNoMatchesState?: boolean;
}

const showAvailability = (
  availability: Availability,
  phase: number,
  tnewMemberNav: TFNewMemberNav
) => {
  if (INACTIVE_PHASE_VALUES.includes(phase)) {
    return (
      <>
        <RoundRectExclaimationMark width={15} height={15} />
        <Small style={{ marginLeft: 5 }}>
          {tnewMemberNav('providerProfile.unavailable', 'Unavailable', undefined)}
        </Small>
      </>
    );
  }

  if (availability === 'time-off') {
    return (
      <>
        <TimeOffIcon style={{ paddingBottom: 2 }} />
        <Small style={{ marginLeft: 5 }}>
          {tnewMemberNav('providerProfile.on', 'On time off', undefined)}
        </Small>
      </>
    );
  }

  if (availability === 'unavailable') {
    return (
      <>
        <OffTodayIcon style={{ paddingBottom: 2 }} />
        <Small style={{ marginLeft: 5 }}>
          {tnewMemberNav('providerProfile.off', 'Off today', undefined)}
        </Small>
      </>
    );
  }
  if (availability === 'available') {
    return (
      <>
        <WorkingTodayIcon style={{ paddingBottom: 2 }} />
        <Small style={{ marginLeft: 5 }}>
          {tnewMemberNav('providerProfile.working', 'Working today', undefined)}
        </Small>
      </>
    );
  }
  return null;
};

const convertAvatarToCouples = (imageURL) => (
  <View flex={1} row style={{ marginTop: 30, marginBottom: 6 }}>
    <Avatar
      image={imageURL}
      width={85}
      height={85}
      style={{
        boxSizing: 'content-box',
        borderRadius: '50%',
        border: `6px solid ${COLORS.white}`,
        position: 'relative',
      }}
    />
    <CouplesDefaultAvatar size={85} style={{ marginTop: 6, marginLeft: -20 }} />
  </View>
);

const PanelTherapistBlockTitleName: FunctionComponent<{
  title: string;
  firstName: string;
  lastName: string;
  clickable?: boolean;
  isNoMatchesState?: boolean;
}> = ({ title, firstName, lastName, clickable, isNoMatchesState }) => {
  if (isNoMatchesState) return <Huge style={{ marginTop: 3 }}>{firstName}</Huge>;

  return (
    <Huge style={{ marginTop: 3 }}>
      {title} {firstName} {lastName}
      {clickable && (
        <RightCaretIcon
          style={{
            paddingLeft: 4,
            marginBottom: 3,
            ...webOnlyStyle({
              transform: 'scale(1.6)',
            }),
          }}
        />
      )}
    </Huge>
  );
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const PanelTherapistBlock: FunctionComponent<PanelTherapistBlockProps> = ({
  therapist,
  room,
  avatarSize = 135,
  history,
  match,
  startDate,
  isNoMatchesState = false,
  clickable,
  playButton,
  licenses,
}) => {
  const { colors } = useEmotionTheme();
  const shouldShowAvatarIfCouples =
    room.roomType === 'couples_room' && !playButton && !isNoMatchesState;
  const isTherapistPT = therapist.type === 'primary' && startDate;
  const [isVisible, setIsVisible] = useState(false);
  const [therapistVideoUrl, setTherapistVideoUrl] = useState<string>();
  const { memberNewNav } = useFlags();

  useEffect(() => {
    if (playButton) {
      const therapistVideoUrlTemp = `${appConfig.endpoints.cdnEndpoint}/therapist-intro-videos/${therapist.id}.mp4`;
      fetch(therapistVideoUrlTemp, {
        method: 'HEAD',
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            setTherapistVideoUrl(therapistVideoUrlTemp);
          }
        })
        .catch(() => undefined);
    }
  }, [playButton, therapist.id]);

  const handlePress = () => {
    if (memberNewNav && memberNewNav !== 'disabled') {
      trackCTAClick('Therapist details', 'messages', { roomID: room.roomID });
    }
    history.push({
      pathname: `/room/${match.params.roomID}/therapist-details`,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const shouldShowAvailability = !checkIfPsychiatrist(therapist.type) && !isNoMatchesState;
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return (
    <ConditionalWrapper
      condition={clickable}
      wrapper={(children) => (
        <TouchableView
          onPress={handlePress}
          aria-label={`open ${therapist.firstName} ${therapist.lastName}'s profile`}
        >
          {children}
        </TouchableView>
      )}
    >
      <View align="center">
        {shouldShowAvatarIfCouples ? (
          convertAvatarToCouples(therapist.imageURL)
        ) : (
          <View flex={1} style={{ marginBottom: 8, position: 'relative' }}>
            <Modal isVisible={isVisible} onBackdropPress={() => setIsVisible(false)}>
              <TouchableView>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video src={therapistVideoUrl} style={{ maxWidth: '84vw' }} autoPlay controls />
              </TouchableView>
            </Modal>
            {isNoMatchesState ? (
              <View
                justify="center"
                align="center"
                style={{
                  width: avatarSize,
                  height: avatarSize,
                  borderRadius: '50%',
                  overflow: 'hidden',
                  backgroundColor: colors.permaHawkesBlue,
                }}
              >
                <TherapistSearch width={46} height={44} />
              </View>
            ) : (
              <Avatar
                image={therapist.imageURL}
                width={avatarSize}
                height={avatarSize}
                style={{ alignSelf: 'center' }}
              />
            )}
            {therapistVideoUrl && (
              <View
                style={{
                  position: 'absolute',
                  bottom: '-13px',
                  right: '-20px',
                }}
              >
                <TouchableView
                  aria-label="play provider intro video"
                  onPress={() => setIsVisible(true)}
                >
                  <Image source={PlayButtonIcon} alt="play" width={68} height={68} />
                </TouchableView>
              </View>
            )}
          </View>
        )}
        {isTherapistPT && !isNoMatchesState ? (
          <Small>
            Your provider since {moment(room.lastTherapistChange || room.createdAt).format('LL')}
          </Small>
        ) : null}

        <PanelTherapistBlockTitleName
          title={therapist.title}
          firstName={therapist.firstName}
          lastName={therapist.lastName}
          clickable={clickable}
          isNoMatchesState={isNoMatchesState}
        />

        {licenses && (
          <View flex={1} style={{ marginTop: 3 }} row align="center">
            <Small>{therapist.licenses.join(', ')}</Small>
          </View>
        )}
        {shouldShowAvailability && (
          <View flex={1} style={{ marginTop: 7 }} row align="center">
            {showAvailability(therapist.availability, therapist.phase, tnewMemberNav)}
          </View>
        )}
      </View>
    </ConditionalWrapper>
  );
};

export default withRouter(PanelTherapistBlock);
