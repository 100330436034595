import { useTranslation } from '@talkspace/i18n';
import { Lightbulb } from '../../components/icons';
import ForYouCard, { ForYouCardProps } from './ForYouCard';

interface LiveWorkshopsCardProps extends Pick<ForYouCardProps, 'onPress' | 'disabled' | 'dataQa'> {}

const LiveWorkshopsCard = ({ onPress, disabled, dataQa }: LiveWorkshopsCardProps) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return (
    <ForYouCard
      dataQa={dataQa}
      onPress={onPress}
      disabled={disabled}
      Icon={<Lightbulb />}
      titleText={tnewMemberNav('home.live', 'Live workshops', undefined)}
      bodyText={tnewMemberNav(
        'home.explore',
        'Explore group classes led by expert Talkspace therapists',
        undefined
      )}
    />
  );
};

export default LiveWorkshopsCard;
