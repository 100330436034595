import { useQuery } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '@/core/api/apiWrapper';
import { parentalConsentQueryKey } from './queryKeys';

export type ConsentStatusEnum =
  | 'consentSkippedByTeen'
  | 'consentExemptInState'
  | 'consentGranted'
  | 'emailSent'
  | 'providerApprovedExemption'
  | 'providerApprovedManualConsent';

export type ConsentExemptionData = {
  ageOfConsent: number;
  clientAge: number;
  clientState: string;
};
export interface ParentalConsentQueryResponse {
  id: number;
  userId: number;
  consenterRelationship: string;
  consenterFirstName: string;
  consenterLastName: string;
  consenterEmail: string;
  consentStatus: ConsentStatusEnum;
  exemptionData?: ConsentExemptionData;
  consentGrantedAt: Date | null;
  emailSentAt: Date;
  providerApprovedManualConsentAt: Date | null;
  providerApprovedExemptionAt: Date | null;
  previouslySentTimes: number;
}

const fetchParentalConsent = (clientUserID?: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/users/${clientUserID}/parental-consent`
  );
  return data.data;
};

const useQueryParentalConsent = (clientUserID?: number, enabled: boolean = true) =>
  useQuery<ParentalConsentQueryResponse, Error>({
    queryKey: clientUserID?.toString() && parentalConsentQueryKey(clientUserID),
    queryFn: fetchParentalConsent(clientUserID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!clientUserID && enabled,
  });

export default useQueryParentalConsent;
