import {
  User,
  LockKeyhole,
  CreditCard,
  ShieldPlus,
  CirclePlus,
  UserPen,
  BellOutline,
  MessagePlus,
  UniversalAccess,
  SquarePlus,
  CircleQuestion,
  ShieldHalved,
  ArrowRightFromBracket,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import { useTranslation } from '@talkspace/i18n';
import { AccountCard } from '../../types';

export const useProfileCards = (): AccountCard[] => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');

  return [
    {
      titleText: tnewMemberNav('accountSettings.personal', 'Personal information', undefined),
      Icon: <User size="major" colorType="brand" />,
      hideCaret: false,
      route: '/home/account/personal-information',
      dataQa: 'accountProfilePersonalInformation',
      trackUserSettingsEvent: {
        CTA: 'View Personal Information',
        source: 'Personal Information',
      },
    },
    {
      titleText: tnewMemberNav('accountSettings.login', 'Login and security', undefined),
      Icon: <LockKeyhole size="major" colorType="brand" />,
      hideCaret: false,
      route: '/home/account/account-details',
      dataQa: 'accountProfileLoginSecurity',
      trackUserSettingsEvent: {
        CTA: 'View Login Security',
        source: 'Personal Information',
      },
    },
  ];
};

export const usePlanAndCoverageCards = (): AccountCard[] => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');

  return [
    {
      titleText: tnewMemberNav('accountSettings.payment', 'Payment and plan', undefined),
      bodyText: tnewMemberNav(
        'accountSettings.check',
        'Check your billing details and plan information or change providers',
        undefined
      ),
      Icon: <CreditCard size="major" colorType="brand" />,
      hideCaret: false,
      route: '/home/account/manage-subscription',
      dataQa: 'accountPlanPaymentAndPlan',
      trackUserSettingsEvent: {
        CTA: 'View Payment Plan',
        source: 'Personal Information',
      },
    },
    {
      titleText: tnewMemberNav('accountSettings.update', 'Update coverage', undefined),
      bodyText: tnewMemberNav(
        'accountSettings.add',
        'Add, change, or update insurance and EAP details or switch to self-pay',
        undefined
      ),
      Icon: <ShieldPlus size="major" colorType="brand" />,
      hideCaret: false,
      hideIfHasPendingMatchRoom: true,
      route: '/home/account/update-coverage',
      dataQa: 'accountPlanUpdateCoverage',
      trackUserSettingsEvent: {
        CTA: 'Update Coverage',
        source: 'Account Dropdown',
      },
    },
    {
      titleText: tnewMemberNav('accountSettings.addService', 'Add a service', undefined),
      bodyText: tnewMemberNav(
        'accountSettings.we',
        'We offer therapy, psychiatry, and couples therapy',
        undefined
      ),
      Icon: <CirclePlus size="major" colorType="brand" />,
      hideCaret: false,
      hideIfHasPendingMatchRoom: true,
      isAddService: true,
      dataQa: 'accountPlanAddAService',
      trackUserSettingsEvent: {
        CTA: 'Add Service',
        source: 'Account Dropdown',
      },
    },
    {
      titleText: tnewMemberNav('accountSettings.change', 'Change provider', undefined),
      bodyText: tnewMemberNav(
        'accountSettings.its',
        "It's common to try a few providers before finding the right fit",
        undefined
      ),
      Icon: <UserPen size="major" colorType="brand" />,
      hideCaret: false,
      hideIfHasPendingMatchRoom: true,
      route: '/home/account/change-provider',
      dataQa: 'accountPlanChangeProvider',
      trackUserSettingsEvent: {
        CTA: 'Change Provider',
        source: 'Account Dropdown',
      },
    },
  ];
};

export const useSettingsCards = (): AccountCard[] => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');

  return [
    {
      titleText: tnewMemberNav('accountSettings.notifications', 'Notifications', undefined),
      Icon: <BellOutline size="major" colorType="brand" />,
      hideCaret: false,
      route: '/home/account/notifications',
      dataQa: 'accountSettingsNotifications',
      trackUserSettingsEvent: {
        CTA: 'View Notifications',
        source: 'Personal Information',
      },
    },
    {
      titleText: tnewMemberNav('accountSettings.read', 'Read receipts', undefined),
      bodyText: tnewMemberNav(
        'accountSettings.if',
        "If you turn off read receipts, you won't see when someone has read your message",
        undefined
      ),
      Icon: <MessagePlus size="major" colorType="brand" />,
      dataQa: 'accountSettingsReadReceipts',
    },
    {
      titleText: tnewMemberNav('accountSettings.high', 'High contrast mode', undefined),
      Icon: <UniversalAccess size="major" colorType="brand" />,
      dataQa: 'accountSettingsHighContrast',
    },
  ];
};

export const useMoreCards = (): AccountCard[] => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');

  return [
    {
      titleText: tnewMemberNav('accountSettings.emergency', 'Emergency resources', undefined),
      Icon: <SquarePlus size="major" colorType="brand" />,
      hideCaret: false,
      windowRoute: 'https://helpnow.talkspace.com/',
      dataQa: 'accountMoreEmergencyResources',
    },
    {
      titleText: tnewMemberNav('accountSettings.customer', 'Customer support', undefined),
      Icon: <CircleQuestion size="major" colorType="brand" />,
      hideCaret: false,
      route: '/home/account/customer-support',
      dataQa: 'accountMoreCustomerSupport',
      trackUserSettingsEvent: {
        CTA: 'Customer Support',
        source: 'Account Dropdown',
      },
    },
    {
      titleText: tnewMemberNav('accountSettings.privacy', 'Privacy policy', undefined),
      Icon: <ShieldHalved size="major" colorType="brand" />,
      hideCaret: false,
      windowRoute: 'https://www.talkspace.com/public/privacy-policy',
      dataQa: 'accountMorePrivacyPolicy',
    },
    {
      titleText: tnewMemberNav('accountSettings.logOut', 'Log out', undefined),
      Icon: <ArrowRightFromBracket size="major" colorType="brand" />,
      hideCaret: true,
      dataQa: 'accountMoreLogOut',
      isLogout: true,
    },
  ];
};
