import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from '@talkspace/i18n';
import { TFKeysPrefixedQuickmatchCommon } from '@talkspace/i18n/types';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import View from '../View';
import BaseButton, { BaseButtonProps } from '../BaseButton';
import TabRadioGroup from '../TabRadioGroup';
import { withHover, WithHoverProps } from '../../hoc';
import { getButtonStyles } from '../../utils';
import CheckV2 from '../icons/CheckV2';
import Big from '../Typography/Big';
import Small from '../Typography/Small';

export interface SurveyQuestion {
  id?: number;
  label: string;
  labelTranslationKey?: TFKeysPrefixedQuickmatchCommon;
  value: string;
  description?: string;
}

export interface SimpleSurveyQuestionsProps {
  onPress: (question: SurveyQuestion | SurveyQuestion[]) => void;
  questions: SurveyQuestion[];
  style?: EmotionStyle;
  value?: string;
  radioGroupLegend?: string;
  dataQa?: string;
  wrapperStyle?: EmotionStyle;
  fieldSetStyle?: EmotionStyle;
  fieldStyle?: EmotionStyle;
  isMulti?: boolean;
  defaultSelection?: SurveyQuestion[];
}

type MultiButtonProps = {
  isMulti?: boolean;
  selected?: boolean;
} & BaseButtonProps;

const MultiButton = ({ isMulti, selected, children, ...props }: MultiButtonProps) => {
  const { colors } = useEmotionTheme();
  if (isMulti) {
    return (
      <BaseButton {...props}>
        <View row align="center" justify="space-between">
          {children}
          {selected && (
            <CheckV2 width={15} height={13} color={colors.green} style={{ marginRight: 5 }} />
          )}
        </View>
      </BaseButton>
    );
  }
  return <BaseButton {...props}>{children}</BaseButton>;
};

const Question = withHover(
  styled(MultiButton)<
    {
      selected: boolean;
      removeTransition?: boolean;
      isMulti?: boolean;
      isClicked?: boolean;
    } & WithHoverProps
  >(
    ({
      selected,
      isHovering,
      removeTransition,
      isMulti,
      hoverType,
      theme: { colors, colorRoles, isHighContrast },
    }) => {
      let bgColor = colors.white;
      let txtColor = colors.black;
      if (selected && !isMulti) bgColor = colors.green;
      if (selected && isHighContrast && !isMulti) txtColor = colors.white;
      if (selected && isMulti) bgColor = colors.lightGreen;
      return {
        fontSize: '1.4rem',
        textAlign: 'left',
        padding: '14px 20px',
        width: '100%', // for some Android browsers where flex does not properly fill width
        border: `1px solid ${colorRoles.borders.borderDefault}`,
        borderRadius: '1rem',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease-in-out',
        color: txtColor,
        backgroundColor: bgColor,
        '&:not(:last-child)': {
          borderBottom: '0',
        },
        '&:first-of-type': {
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
        },
        '&:last-child': {
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
        },
        '&:not(:first-of-type):not(:last-child)': {
          borderRadius: '0',
        },
        ...getButtonStyles(
          {
            activeColors: {
              color: isMulti ? colors.black : colors.white,
              backgroundColor: isMulti ? colors.lightGreen : colors.green,
            },
            hoverColors: {
              color: colors.black,
              backgroundColor: colors.lightGreen,
            },
          },
          { isSelected: selected, isHovering, isClicked: hoverType === 'mouse', removeTransition }
        ),
      };
    }
  )
);

const findPredicate = (question: SurveyQuestion) => (s: SurveyQuestion) =>
  s.label === question.label;

const SimpleQuestions = ({
  value,
  onPress,
  questions,
  style,
  radioGroupLegend = 'Select one of the following answers',
  dataQa,
  wrapperStyle,
  fieldSetStyle,
  fieldStyle,
  isMulti,
  defaultSelection,
  ...otherProps
}: SimpleSurveyQuestionsProps) => {
  const [selected, setSelected] = useState<SurveyQuestion[]>([]);
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');

  useEffect(() => {
    if (defaultSelection) {
      setSelected(defaultSelection);
    }
  }, [defaultSelection]);

  const { colorRoles } = useEmotionTheme();
  const onQuestionSelection = useCallback(
    (question: SurveyQuestion) => {
      if (isMulti) {
        const findIndex = selected.findIndex(findPredicate(question));
        const selectedCopy = [...selected];
        const newSelected =
          findIndex > -1
            ? selectedCopy.splice(findIndex, 1) && selectedCopy
            : [...selectedCopy, question];
        setSelected(newSelected);
        onPress(newSelected);
      } else {
        onPress(question);
      }
    },
    [isMulti, onPress, selected]
  );

  return (
    <View style={{ ...wrapperStyle }}>
      <TabRadioGroup
        legendText={radioGroupLegend}
        initialSelection={!!value}
        style={{ ...fieldSetStyle }}
      >
        {questions.map((question, index) => {
          const isSelected = isMulti
            ? !!selected.find(findPredicate(question))
            : value === question.value || value === question.label;
          return (
            <Question
              data-qa={dataQa ? `${dataQa}RadioButton${index}` : `radioButton${index}`}
              role="radio"
              removeWrapper
              removeTransition
              // Using `value` as part of the key servers as a good reset variable
              key={String(question.label + value + index)}
              aria-checked={isSelected}
              tabIndex={isSelected ? 0 : -1}
              selected={isSelected}
              onPress={() => onQuestionSelection(question)}
              isMulti={isMulti}
              forwardHoverType
              style={{ ...fieldStyle }}
              {...otherProps}
            >
              {question.description && (
                <>
                  <Big style={{ color: colorRoles.typography.textDefault }}>{question.label}</Big>
                  <Small style={{ marginTop: 4, color: colorRoles.typography.textSubtle }}>
                    {question.description}
                  </Small>
                </>
              )}
              {!question.description && question.labelTranslationKey && (
                <Trans<TFKeysPrefixedQuickmatchCommon>
                  t={tQuickmatchCommon}
                  i18nKey={question.labelTranslationKey}
                  tOptions={undefined}
                >
                  {question.label}
                </Trans>
              )}
              {!question.description && !question.labelTranslationKey && question.label}
            </Question>
          );
        })}
      </TabRadioGroup>
    </View>
  );
};
export default SimpleQuestions;
