import { FunctionComponent } from 'react';
import {
  View,
  Large,
  Standard,
  BaseButton,
  Tiny,
  Spinner,
  ResponsiveLayoutWithHeader,
  useEmotionTheme,
  PanelHeader,
  Mini,
  Link,
  TextDS,
  spacing,
} from '@talkspace/react-toolkit';
import { shouldShowNativeFeature, NativeFeaturesEnum } from 'ts-ionic';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { formatPhoneNumber, parsePhoneNumber } from '@talkspace/react-toolkit/src/utils';
import { PaymentDetails } from 'ts-frontend/types';
import { useTranslation, Trans } from '@talkspace/i18n';
import { TFNewMemberNav } from '@talkspace/i18n/types';
import styled from '../../../core/styled';
import getPanelWidth from '../../utils/design';
import { COLORS } from '../../../utils/design';
import InfoPanel from '../../../components/InfoPanel';
import IncompleteInfoTooltip from '../IncompleteInfoTooltip';
import MyAccountPanelWrapper from '../MyAccountPanelWrapper';
import { PasswordDots } from '../../../components/icons';
import MobileHeader from '../../../components/MobileLeftHeader';
import ManageEmailVerificationCard from '../ManageEmailVerificationCard';
import ReactFrameService from '../../../auth/reactFrame/ReactFrameService';
import { useMainState } from '../../../hooks/mainContext';
import NotificationToggle from '../NotificationToggle';
import { useClientAuthState } from '../../../hooks/clientAuthContext';

const { space400 } = spacing;

const getBreadcrumbRoutes = (tnewMemberNav: TFNewMemberNav) => [
  {
    label: tnewMemberNav('accountSettings.account', 'Account', undefined),
    path: '/home/account',
  },
  {
    label: tnewMemberNav('login.login', 'Login and security', undefined),
    path: '',
  },
];

const ClearButton = styled(BaseButton)({
  padding: 0,
  margin: 0,
  backgroundColor: COLORS.white,
});

const StyledInfoPanel = styled(InfoPanel)<{ inNavShell?: boolean; useNewNav: boolean }>(
  ({ theme: { window, colorRoles }, inNavShell, useNewNav }) => {
    const { isMobile } = window;
    const borderColor = useNewNav ? 'solid' : 'transparent';

    return {
      borderRadius: isMobile ? 0 : 10,
      borderTopColor: isMobile ? borderColor : colorRoles.borders.borderDefault,
      borderRightColor: isMobile ? borderColor : colorRoles.borders.borderDefault,
      borderLeftColor: isMobile ? borderColor : colorRoles.borders.borderDefault,
      marginBottom: isMobile ? 30 : 15,
      minWidth: 335,
      marginTop: inNavShell ? space400 : 0,
      maxWidth: useNewNav ? 'unset' : getPanelWidth(window),
    };
  }
);

const LoadingPanel = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    backgroundColor: COLORS.a11yLinkWaterGrey,
    borderRadius: isMobile ? 0 : 10,
    marginBottom: isMobile ? 30 : 15,
    minWidth: 335,
    maxWidth: getPanelWidth(window),
  };
});

const DynamicLoadingPanel = styled(LoadingPanel)<{ panelHeight: number }>((props) => {
  return {
    height: props.panelHeight,
  };
});

const PhoneNumberSection = styled(View)({
  marginBottom: 12,
});

const PasscodeChangeSection = styled(View)({
  marginBottom: 12,
  marginTop: 30,
});

const TwoFactorAuthSection = styled(View)({
  marginTop: 30,
});

const ToggleContainer = styled(View)(() => {
  return {
    paddingTop: 12,
    maxWidth: 'calc(100% - 5px)',
    marginLeft: 20,
    paddingBottom: 12,
    flexDirection: 'row',
    alignItems: 'center',
    width: 112,
  };
});

const EmailSection = styled(View)({
  marginBottom: 30,
});

const EmailWrapper = styled(View)({
  width: 240,
});

const Email = styled(Large)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const PasswordSection = styled(View)({
  marginBottom: 30,
});

const NicknameSection = styled(View)({
  marginBottom: 30,
});

const ChangeLinkText: FunctionComponent<{ text: string; isNewNav: boolean }> = ({
  text,
  isNewNav,
}) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return isNewNav ? (
    <TextDS variant="headingMd" colorRole="textBrandDefault">
      {tnewMemberNav('login.change', 'Change', undefined)}
    </TextDS>
  ) : (
    <Large variant="largeBoldWideGreen">{text}</Large>
  );
};

interface Props {
  isLoading: boolean;
  isPhoneNumberLoading: boolean;
  phoneNumber: string;
  disable2FA: boolean;
  nickname: string;
  currentEmail: string;
  pendingEmail: string;
  displayedEmail: string;
  paymentDetails?: PaymentDetails;
  emailIsVerified: boolean;
  emailVerificationFlagIsActive: boolean;
  isPasscodeEnabled: boolean;
  isPasscodeValueSet: boolean;
  handleBackButtonPress: () => void;
  handleCloseButtonPress: () => void;
  handleChangeNicknamePress: () => void;
  handleChangeEmailPress: () => void;
  handleChangePasswordPress: () => void;
  handleResendEmailVerificationPress: () => void;
  handleChangePhoneNumberPress: () => void;
  handleSMSTogglePress: () => void;
  handleChangePasscodeEnabled: () => void;
  handleChangePasscodeValue: () => void;
  inNavShell?: boolean;
}

const AccountDetails: FunctionComponent<Props> = ({
  isLoading,
  isPhoneNumberLoading,
  phoneNumber,
  disable2FA,
  nickname,
  displayedEmail,
  currentEmail,
  pendingEmail,
  emailIsVerified,
  emailVerificationFlagIsActive,
  isPasscodeEnabled,
  isPasscodeValueSet,
  handleBackButtonPress,
  handleCloseButtonPress,
  handleChangeNicknamePress,
  handleChangeEmailPress,
  handleChangePasswordPress,
  handleResendEmailVerificationPress,
  handleChangePhoneNumberPress,
  handleSMSTogglePress,
  handleChangePasscodeEnabled,
  handleChangePasscodeValue,
  inNavShell,
}) => {
  const panelHeight = emailIsVerified || !emailVerificationFlagIsActive ? 236 : 410;

  const isInFrame = ReactFrameService.instance().isInFrame();
  const isIOS = ReactFrameService.isiOS();
  const { colors, colorRoles } = useEmotionTheme();
  const { me } = useMainState();
  const {
    adminConfigs: { isCognitoLoginEnabled },
  } = useClientAuthState();
  const hasAuthProvider = Boolean(me?.authProvider);

  const isSecurityDetailNotFilled: boolean =
    (!phoneNumber && isCognitoLoginEnabled) || !displayedEmail;

  const rawNumber = phoneNumber && parsePhoneNumber(phoneNumber)?.number;

  const displayPhoneNumber = rawNumber ? formatPhoneNumber(rawNumber) : '-';
  const useNewNav = useNewMemberNav();

  let textMessageToggleText;
  if (!useNewNav) {
    textMessageToggleText = disable2FA ? 'Enable' : 'Disable';
  } else {
    textMessageToggleText = null;
  }
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const breadcrumbRoutes = getBreadcrumbRoutes(tnewMemberNav);
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={
        inNavShell
          ? null
          : () => (
              <PanelHeader
                renderLeft={() => (
                  <MobileHeader
                    title="My Account"
                    mobileTitle={
                      useNewNav ? '' : tnewMemberNav('login.login', 'Login and security', undefined)
                    }
                    centerTitle={isInFrame && isIOS}
                    onBackButtonPress={handleBackButtonPress}
                  />
                )}
                onRightPress={isInFrame ? undefined : handleCloseButtonPress}
                isNewNav={useNewNav}
              />
            )
      }
      noHeaderLine={useNewNav ? true : inNavShell}
    >
      <MyAccountPanelWrapper
        alwaysRow={isInFrame}
        title={tnewMemberNav('login.login', 'Login and security', undefined)}
        hideMenu={inNavShell}
        breadcrumbRoutes={breadcrumbRoutes}
        inNavShell={inNavShell}
      >
        {isSecurityDetailNotFilled && !isLoading && !isPhoneNumberLoading && (
          <IncompleteInfoTooltip
            text="Please fill in all your login and security details."
            style={{ marginBottom: 15, maxWidth: 500 }}
          />
        )}
        {isLoading ? (
          <DynamicLoadingPanel panelHeight={panelHeight}>
            <Spinner primaryColor={COLORS.white} />
          </DynamicLoadingPanel>
        ) : (
          <StyledInfoPanel inNavShell={inNavShell} useNewNav={useNewNav}>
            <NicknameSection>
              <View row justify="space-between">
                <Standard>{tnewMemberNav('login.nickname', 'Nickname', undefined)}</Standard>
                <ClearButton
                  onPress={handleChangeNicknamePress}
                  data-qa="myAccountChangeNicknameButton"
                >
                  <ChangeLinkText isNewNav={useNewNav} text="Change nickname" />
                </ClearButton>
              </View>
              <Large dataQa="myAccountNickname" variant="largeDarkGrey">
                {nickname}
              </Large>
            </NicknameSection>
            <EmailSection>
              <View row justify="space-between">
                <Standard>{tnewMemberNav('login.email', 'Email', undefined)}</Standard>
                {!hasAuthProvider && (
                  <ClearButton
                    onPress={handleChangeEmailPress}
                    data-qa="myAccountChangeEmailButton"
                  >
                    <ChangeLinkText isNewNav={useNewNav} text="Change email" />
                  </ClearButton>
                )}
              </View>
              <EmailWrapper>
                <Email dataQa="myAccountEmail" variant="largeDarkGrey">
                  {displayedEmail}
                </Email>
                {emailVerificationFlagIsActive && (
                  <Tiny
                    dataQa="myAccountVerifiedOrUnverified"
                    style={{
                      color: emailIsVerified ? colors.emerald : colors.red,
                      fontWeight: 'bold',
                    }}
                  >
                    {emailIsVerified
                      ? tnewMemberNav('login.verified', 'Verified', undefined)
                      : tnewMemberNav('login.unverified', 'Unverified', undefined)}
                  </Tiny>
                )}
              </EmailWrapper>
              {hasAuthProvider && (
                <Tiny>
                  {tnewMemberNav(
                    'login.your',
                    'Your email and password are managed by a Single Sign On provider. Reach out to them for any change/reset request.',
                    undefined
                  )}
                </Tiny>
              )}
              {!emailIsVerified && emailVerificationFlagIsActive && (
                <ManageEmailVerificationCard
                  currentEmail={currentEmail}
                  pendingEmail={pendingEmail}
                  handleResendEmailVerificationPress={handleResendEmailVerificationPress}
                />
              )}
            </EmailSection>
            {!hasAuthProvider && (
              <PasswordSection>
                <View row justify="space-between" style={{ marginBottom: 10 }}>
                  <Standard>{tnewMemberNav('login.password', 'Password', undefined)}</Standard>
                  <ClearButton
                    onPress={handleChangePasswordPress}
                    data-qa="myAccountChangePasswordeButton"
                  >
                    <ChangeLinkText isNewNav={useNewNav} text="Change password" />
                  </ClearButton>
                </View>
                <PasswordDots />
              </PasswordSection>
            )}
            {!hasAuthProvider && (
              <PhoneNumberSection>
                <View row justify="space-between">
                  <Standard>{tnewMemberNav('login.phone', 'Phone number', undefined)}</Standard>
                  <ClearButton
                    onPress={handleChangePhoneNumberPress}
                    data-qa="myAccountChangePhoneNumberButton"
                  >
                    <ChangeLinkText isNewNav={useNewNav} text="Change phone number" />
                  </ClearButton>
                </View>
                <Large dataQa="myAccountPhoneNumber" variant="largeDarkGrey">
                  {displayPhoneNumber}
                </Large>
              </PhoneNumberSection>
            )}
          </StyledInfoPanel>
        )}
        {shouldShowNativeFeature(NativeFeaturesEnum.PASSCODE) && !isLoading && (
          <StyledInfoPanel inNavShell={inNavShell} useNewNav={useNewNav} style={{ marginTop: 30 }}>
            <View row justify="space-between">
              <View style={{ flexShrink: 1, maxWidth: 275 }}>
                <Standard variant="standardBlack">Passcode</Standard>
                <Mini>Extra security with a 4-digit code to access your account.</Mini>
              </View>
              <ToggleContainer>
                <Tiny style={{ marginRight: 10 }}>{isPasscodeEnabled ? 'Disable' : 'Enable'}</Tiny>
                <NotificationToggle
                  isOn={isPasscodeEnabled}
                  onChange={handleChangePasscodeEnabled}
                  name="passcodeToggle"
                  label="passcode toggle"
                  info="passcode toggle"
                  controlled
                />
              </ToggleContainer>
            </View>
            {isPasscodeEnabled && (
              <PasscodeChangeSection row justify="space-between">
                <View>{isPasscodeValueSet ? <Standard>* * * *</Standard> : 'Not set'}</View>
                <View>
                  <ClearButton
                    onPress={handleChangePasscodeValue}
                    data-qa="myAccountChangePhoneNumberButton"
                  >
                    <ChangeLinkText isNewNav={useNewNav} text="Change passcode" />
                  </ClearButton>
                </View>
              </PasscodeChangeSection>
            )}
          </StyledInfoPanel>
        )}
        {isLoading ? (
          <LoadingPanel style={{ height: 113 }} />
        ) : (
          <>
            {me?.show2FAFeatures && !hasAuthProvider && (
              <TwoFactorAuthSection>
                <StyledInfoPanel
                  style={{ borderTopColor: colorRoles.borders.borderDefault }}
                  useNewNav={useNewNav}
                >
                  <View style={{ marginBottom: 20 }}>
                    <Large variant="largeMediumTSBlack">
                      {tnewMemberNav('login.two', 'Two-factor authentication', undefined)}
                    </Large>
                    <Tiny>
                      <Trans>
                        To keep your account extra secure, we’ll send a 6-digit verification code to
                        your phone number{' '}
                        {phoneNumber && `ending in ${phoneNumber.slice(phoneNumber.length - 2)}`}{' '}
                        when you log in to make sure it’s you.
                      </Trans>
                    </Tiny>
                  </View>
                  <View row justify="space-between">
                    <View style={{ flexShrink: 1, maxWidth: 275 }}>
                      <Standard variant="standardBlack">
                        {tnewMemberNav('login.text', 'Text message (SMS)', undefined)}
                      </Standard>
                      <Mini>
                        <Trans>
                          Message and data rates may apply. For more information, see our{' '}
                          <Link
                            href="https://www.talkspace.com/public/terms"
                            target="_blank"
                            style={{
                              textDecoration: 'none',
                              margin: 0,
                              fontWeight: 700,
                              color: colors.green,
                            }}
                            text="Terms of use"
                          />{' '}
                          and{' '}
                          <Link
                            href="https://www.talkspace.com/public/privacy-policy"
                            target="_blank"
                            style={{
                              textDecoration: 'none',
                              margin: 0,
                              fontWeight: 700,
                              color: colors.green,
                            }}
                            text="Privacy policy"
                          />
                          .
                        </Trans>
                      </Mini>
                    </View>
                    <ToggleContainer>
                      <Tiny
                        {...(!disable2FA && { variant: 'tinyRed' })}
                        style={{ marginRight: 10 }}
                      >
                        {textMessageToggleText}
                      </Tiny>
                      <NotificationToggle
                        isOn={!disable2FA}
                        onChange={handleSMSTogglePress}
                        name="SMSToggle"
                        label="2fa sms toggle"
                        info="2fa sms toggle"
                        controlled
                      />
                    </ToggleContainer>
                  </View>
                </StyledInfoPanel>
              </TwoFactorAuthSection>
            )}
          </>
        )}
      </MyAccountPanelWrapper>
    </ResponsiveLayoutWithHeader>
  );
};

export default AccountDetails;
