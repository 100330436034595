import { useMemo } from 'react';
import TouchableView from '../../../components/TouchableView';
import { ColorRolesVersion } from '../../types';
import styled, { EmotionStyle } from '../../../core/styled';
import TextDS, { TextDSVariant } from '../typography/TextDS';

export type ChipSize = 'slim' | 'medium' | 'large';

const sizeStyles: Record<ChipSize, EmotionStyle> = {
  slim: { height: 24, borderRadius: 6, padding: '4px 8px' },
  medium: { height: 32, borderRadius: 8, padding: '6px 12px' },
  large: { height: 48, borderRadius: 12, padding: '6px 12px' },
};

const textVariants: Record<ChipSize, TextDSVariant> = {
  slim: 'bodySm',
  medium: 'body',
  large: 'body',
};

type ChipVariant = 'primary' | 'secondary';
type ChipState = 'default' | 'hovered' | 'pressed';

const textColors: Record<ChipVariant, Record<ChipState, keyof ColorRolesVersion['typography']>> = {
  primary: {
    default: 'textInverse',
    hovered: 'textInverse',
    pressed: 'textInverse',
  },
  secondary: {
    default: 'textInteractiveDefault',
    hovered: 'textInteractiveHovered',
    pressed: 'textInteractivePressed',
  },
};

interface ChipViewProps {
  variant: ChipVariant;
  isSelected: boolean;
}

interface StyleHelperParams {
  variant: ChipVariant;
  isSelected: boolean;
  colorRoles: ColorRolesVersion;
}

function getBackgroundColor({ variant, isSelected, colorRoles }: StyleHelperParams) {
  if (variant === 'primary') {
    return isSelected
      ? colorRoles.surfaces.interactiveBoldPressed
      : colorRoles.surfaces.interactiveBoldDefault;
  }
  return isSelected
    ? colorRoles.surfaces.surfaceInteractivePressed
    : colorRoles.surfaces.surfaceInteractiveDefault;
}

function getBorder({ variant, isSelected, colorRoles }: StyleHelperParams) {
  if (variant === 'primary') {
    return 'none';
  }

  const borderColor = isSelected
    ? colorRoles.borders.interactiveBoldPressed
    : colorRoles.borders.interactiveBoldDefault;

  return `1px ${borderColor} solid`;
}

const ChipView = styled(TouchableView)<ChipViewProps>(
  ({ variant, isSelected, theme: { colorRoles } }) => {
    return {
      minWidth: 94,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: getBackgroundColor({ variant, isSelected, colorRoles }),
      border: getBorder({ variant, isSelected, colorRoles }),
      transition: 'all 0.2s ease',

      '&:hover': {
        backgroundColor:
          variant === 'primary'
            ? colorRoles.surfaces.interactiveBoldHovered
            : colorRoles.surfaces.surfaceInteractiveHovered,
        border:
          variant === 'primary' ? 'none' : `1px ${colorRoles.borders.interactiveBoldHovered} solid`,
      },

      '&:active': {
        backgroundColor:
          variant === 'primary'
            ? colorRoles.surfaces.interactiveBoldPressed
            : colorRoles.surfaces.surfaceInteractivePressed,
        border:
          variant === 'primary'
            ? 'none'
            : `1px ${colorRoles.borders.interactiveBoldPressed} solid !important`,
      },
    };
  }
);

interface Props {
  size: ChipSize;
  variant: ChipVariant;
  label: string;
  isSelected: boolean;
  onPress: () => void;
  dataQa: string;
}

export default function Chip({ size, variant, label, isSelected, onPress, dataQa }: Props) {
  const chipState: ChipState = isSelected ? 'pressed' : 'default';

  const textColor = textColors[variant][chipState];

  const chipStyle = useMemo(() => sizeStyles[size], [size]);

  return (
    <ChipView
      variant={variant}
      isSelected={isSelected}
      style={chipStyle}
      onPress={onPress}
      dataQa={dataQa}
    >
      <TextDS variant={textVariants[size]} colorRole={textColor}>
        {label}
      </TextDS>
    </ChipView>
  );
}
