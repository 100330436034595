import { useEffect, useCallback } from 'react';
import { Col, Grid, Row, TextDS, spacing, useWindowWidthState } from '@talkspace/react-toolkit';
import { useAppReviewCheckStorage } from 'ts-ionic/plugins/appReview';
import { useTranslation } from '@talkspace/i18n';

import BookingsListController from '../controllers/BookingsListController';
import BookSessionCardController from '../controllers/BookSessionCardController';
import useNavShellData from '../../hooks/useNavShellData';
import BookingsListEmpty from '../components/BookingsListEmpty';
import useRedirectOnboarding from '../../hooks/useRedirectOnboarding';

import { trackPageView, trackInAppReview } from '../../utils/analytics/events';

const { space200, space400 } = spacing;

const ScheduleScreen = () => {
  const {
    activeSessionData,
    bookings,
    subscriptions,
    dataByRoomID,
    clientUsageStats,
    queries: { isLoadingBookings, isLoadingRoomsList, isLoadingSubscriptions },
  } = useNavShellData();

  useEffect(() => {
    trackPageView('schedule');
  }, []);

  useRedirectOnboarding();
  const appReviewCallback = useCallback(() => {
    trackInAppReview();
  }, []);
  useAppReviewCheckStorage(appReviewCallback);

  const isEmpty = bookings?.length === 0;
  const isLoading =
    isLoadingSubscriptions ||
    isLoadingBookings ||
    isLoadingRoomsList ||
    bookings === undefined ||
    dataByRoomID === undefined;
  const showBookSessionCard = isLoading || (!isLoading && !isEmpty);
  const { t: tnewMemberNav } = useTranslation('newMemberNav');

  const { isMobile } = useWindowWidthState();
  return (
    <Grid fluid rowSpacing="space200">
      <Row>
        <Col>
          <TextDS
            tabIndex={0}
            variant="heading2xl"
            style={{ marginTop: isMobile ? space200 : space400 }}
          >
            {tnewMemberNav('schedule.schedule', 'Schedule', undefined)}
          </TextDS>
        </Col>
      </Row>
      <Row>
        {isEmpty ? (
          <Col>
            <BookingsListEmpty
              clientUsageStats={clientUsageStats}
              subscriptions={subscriptions || []}
              isLoading={isLoading}
            />
          </Col>
        ) : (
          <Col xs={4} sm={8}>
            {showBookSessionCard && (
              <BookSessionCardController
                clientUsageStats={clientUsageStats}
                subscriptions={subscriptions || []}
                isLoading={isLoading}
              />
            )}
            <BookingsListController
              isLoading={isLoading}
              activeSessionData={activeSessionData}
              bookings={bookings}
              dataByRoomID={dataByRoomID}
            />
          </Col>
        )}
      </Row>
    </Grid>
  );
};

export default ScheduleScreen;
