import { suicideIdeationOrPlanning } from '@talkspace/configs';
import { WizardScheme } from 'stepWizard';
import { TFAdultOnboarding } from '@talkspace/i18n/types';
import EmergencyResourcesBodyText from '../components/EmergencyResourcesBodyText';

const getTeenIntakeNewSteps: (tAdultOnboarding: TFAdultOnboarding) => WizardScheme['steps'] = (
  tAdultOnboarding: TFAdultOnboarding
) => [
  {
    title: tAdultOnboarding(
      'crisisSuppScreen.title',
      'If you’re in immediate danger, don’t use this app. Instead, use these resources:',
      undefined
    ),
    bodyComponent: () => <EmergencyResourcesBodyText />,
    nextButton: {
      displayText: tAdultOnboarding('crisisSuppScreen.next', 'Next', undefined),
      actionDispatch: 'next',
      whenVisible: 'never',
    },
    secondaryButton: {
      displayText: tAdultOnboarding('crisisSuppScreen.next', 'Next', undefined),
      actionDispatch: 'next',
      whenVisible: 'always',
    },
    name: 'emergencyResources',
    backOption: 'back',
    displayCondition: 'selectedSuicidePlanning',
  },
  {
    title: tAdultOnboarding(
      'thoughtKillingOthers.title',
      'Have you ever had thoughts about harming or killing others?',
      undefined
    ),
    inputType: 'select',
    inputTypePlaceholder: tAdultOnboarding('thoughtKillingOthers.select', 'Select', undefined),
    inputState: 'homicidalIdeation',
    nextButton: {
      displayText: tAdultOnboarding('thoughtKillingOthers.next', 'Next', undefined),
      actionDispatch: 'next',
      whenVisible: 'onSelect',
    },
    inputOptions: 'homicidalIdeationOrPlanningOptions',
    name: 'homicidalIdeation',
    backOption: 'back',
    postAction: 'setHomicidalIdeation',
  },
  {
    title: tAdultOnboarding(
      'thoughtMightDo.title',
      'Have you ever thought about how you might do this?',
      undefined
    ),
    inputType: 'select',
    inputTypePlaceholder: tAdultOnboarding('thoughtMightDo.select', 'Select', undefined),
    inputState: 'homicidalPlanning',
    nextButton: {
      displayText: tAdultOnboarding('thoughtMightDo.next', 'Next', undefined),
      actionDispatch: 'next',
      whenVisible: 'onSelect',
    },
    inputOptions: 'homicidalIdeationOrPlanningOptions',
    name: 'homicidalPlanningPlanning',
    backOption: 'back',
    displayCondition: 'selectedHomicidalIdeation',
    postAction: 'setHomicidalPlanning',
  },

  {
    title: tAdultOnboarding(
      'excessiveAnger.title',
      'Do you have excessive anger, aggression, and/or violent behaviors?',
      undefined
    ),
    inputType: 'select',
    inputTypePlaceholder: tAdultOnboarding('excessiveAnger.select', 'Select', undefined),
    inputState: 'angerAggression',
    nextButton: {
      displayText: tAdultOnboarding('excessiveAnger.next', 'Next', undefined),
      actionDispatch: (value, wizardContext) => {
        if (wizardContext.source === 'onboarding')
          return `/room/${wizardContext.roomID}/onboarding/mental-health/source/onboarding/submit`;
        return '/mental-health-wizard/submit';
      },
      whenVisible: 'onSelect',
    },
    inputOptions: 'angerAggressionOptions',
    name: 'angerAggression',
    backOption: 'back',
    postAction: 'setAngerAggression',
  },
];
const getMentalHealthScheme: (
  tAdultOnboarding: TFAdultOnboarding,
  featureFlag: boolean
) => WizardScheme = (tAdultOnboarding, featureFlag) => {
  const teenIntakeSteps: WizardScheme['steps'] = featureFlag
    ? getTeenIntakeNewSteps(tAdultOnboarding)
    : [];
  return {
    wizardModalTitle: 'mental intake',
    wizardVersion: 1,
    wizardType: 'mentalIntake',
    disablePersist: true,
    steps: [
      {
        title: tAdultOnboarding(
          'sleepQuality.title',
          'How would you describe your sleep quality?',
          undefined
        ),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('sleepQuality.select', 'Select', undefined),
        inputState: 'sleepQuality',
        nextButton: {
          displayText: tAdultOnboarding('sleepQuality.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'sleepQualityOptions',
        name: 'sleepQuality',
      },
      {
        title: tAdultOnboarding(
          'sleepAids.title',
          'Do you use any sleep aids at night?',
          undefined
        ),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('sleepAids.select', 'Select', undefined),
        inputState: 'sleepAidUse',
        nextButton: {
          displayText: tAdultOnboarding('sleepAids.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'sleepAidUseOptions',
        name: 'sleepAidUse',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'socialSupport.title',
          'How would you describe your social support system?',
          undefined
        ),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('socialSupport.select', 'Select', undefined),
        inputState: 'socialSupportSystem',
        nextButton: {
          displayText: tAdultOnboarding('socialSupport.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'socialSupportSystemOptions',
        name: 'socialSupportSystem',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'prevSeen.title',
          'Are you seeing or have you previously seen a mental health professional?',
          undefined
        ),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('prevSeen.select', 'Select', undefined),
        inputState: 'previousMentalHealthTreatment',
        nextButton: {
          displayText: tAdultOnboarding('prevSeen.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'previousMentalHealthTreatmentOptions',
        name: 'previousMentalHealthTreatment',
        backOption: 'back',
        postAction: 'setPreviousMentalHealthTreatment',
      },
      {
        title: tAdultOnboarding(
          'mentalHealthDiagnosis.title',
          'Which (if any) of the following mental health conditions have you been diagnosed with?',
          undefined
        ),
        inputTypePlaceholder: tAdultOnboarding('mentalHealthDiagnosis.select', 'Select', undefined),
        inputType: 'searchableMultiSelect',
        inputState: 'previousMentalHealthIssues',
        nextButton: {
          displayText: tAdultOnboarding('mentalHealthDiagnosis.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('mentalHealthDiagnosis.skip', 'Skip', undefined),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        inputOptions: 'previousMentalHealthIssuesOptions',
        name: 'previousMentalHealthIssues',
        displayCondition: 'selectedPreviousMentalHealthTreatment',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'haveHospitalized.title',
          'Have you ever been hospitalized for psychiatric care?',
          undefined
        ),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('haveHospitalized.select', 'Select', undefined),
        inputState: 'hospitalizationHistory',
        nextButton: {
          displayText: tAdultOnboarding('haveHospitalized.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'hospitalizationHistoryOptions',
        name: 'hospitalizationHistory',
        displayCondition: 'selectedPreviousMentalHealthTreatment',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'immediateRelatives.title',
          'Have any immediate relatives been diagnosed with or treated for any of the following mental health conditions?',
          undefined
        ),
        inputType: 'searchableMultiSelect',
        inputState: 'previousRelativesMentalHealthIssues',
        inputTypePlaceholder: tAdultOnboarding('immediateRelatives.select', 'Select', undefined),
        nextButton: {
          displayText: tAdultOnboarding('immediateRelatives.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('immediateRelatives.skip', 'Skip', undefined),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        inputOptions: 'previousRelativesMentalHealthIssuesOptions',
        name: 'previousRelativesMentalHealthIssues',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'traumaticEvents.title',
          'Have you ever experienced an especially frightening, horrible, or traumatic event?',
          undefined
        ),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('haveHospitalized.select', 'Select', undefined),
        inputState: 'traumaticExperience',
        nextButton: {
          displayText: tAdultOnboarding('traumaticEvents.next', 'Next', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'traumaticExperienceOptions',
        name: 'traumaticExperience',
        backOption: 'back',
        postAction: 'setTraumaticExperience',
      },
      {
        title:
          'In the past month, have you struggled with situations, reminders, dreams and/or thoughts of the event?',
        inputType: 'select',
        inputState: 'traumaticFlashbacks',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'traumaticFlashbacksOptions',
        name: 'traumaticFlashbacks',
        backOption: 'back',
        postAction: 'setTraumaticFlashbacks',
        displayCondition: 'selectedTraumaticExperience',
      },
      {
        title:
          'In the past month, have you felt guilty or inclined to blame yourself for what happened? ',
        inputType: 'select',
        inputState: 'guilt',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'guiltOptions',
        name: 'guilt',
        backOption: 'back',
        postAction: 'setGuilt',
        displayCondition: 'selectedTraumaticExperience',
      },
      {
        title:
          'In the past month, have you felt more distant from those close to you or indifferent toward activities you used to enjoy?',
        inputType: 'select',
        inputState: 'isolation',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'isolationOptions',
        name: 'isolation',
        backOption: 'back',
        postAction: 'setIsolation',
        displayCondition: 'selectedTraumaticExperience',
      },
      {
        title: tAdultOnboarding(
          'thoughtKillingYourself.title',
          'Have you ever had thoughts of killing yourself?',
          undefined
        ),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('haveHospitalized.select', 'Select', undefined),
        inputState: 'suicideIdeation',
        nextButton: {
          displayText: tAdultOnboarding('haveHospitalized.next', 'Next', undefined),
          actionDispatch: (value, wizardContext) => {
            if (featureFlag) {
              return 'next';
            }
            if (suicideIdeationOrPlanning[value] !== suicideIdeationOrPlanning.never) {
              return 'next';
            }
            if (wizardContext.source === 'onboarding')
              return `/room/${wizardContext.roomID}/onboarding/mental-health/source/onboarding/submit`;
            return '/mental-health-wizard/submit';
          },
          whenVisible: 'onSelect',
        },
        inputOptions: 'suicideIdeationOptions',
        name: 'suicideIdeation',
        backOption: 'back',
        postAction: 'setSuicideIdeation',
      },
      {
        title: tAdultOnboarding(
          'thoughtMightDo.title',
          'Have you ever thought about how you might do this?',
          undefined
        ),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('haveHospitalized.select', 'Select', undefined),
        inputState: 'suicidePlanning',
        nextButton: {
          displayText: tAdultOnboarding('haveHospitalized.next', 'Next', undefined),
          actionDispatch: (_, wizardContext) => {
            if (featureFlag) {
              return 'next';
            }
            if (wizardContext.source === 'onboarding') {
              return `/room/${wizardContext.roomID}/onboarding/mental-health/source/onboarding/submit`;
            }
            return '/mental-health-wizard/submit';
          },
          whenVisible: 'onSelect',
        },
        inputOptions: 'suicidePlanningOptions',
        name: 'suicidePlanning',
        backOption: 'back',
        displayCondition: 'selectedSuicideIdeation',
        postAction: 'setSuicidePlanning',
      },
      ...teenIntakeSteps,
    ],
  };
};

export default getMentalHealthScheme;
