import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { A11yPhoneInput, RHFInput, RHFSelect, isPhoneNumberValid } from '@talkspace/react-toolkit';
import { useWizardActions, useWizardState } from 'stepWizard/hooks/wizardContext';
import { TeenEmergencyContactWizardState } from '../reducers/teenEmergencyContactWizardState';

const MISSING_ERROR = 'Missing field';

type FormData = {
  contactName: string;
  contactPhone: string;
  relationship: string;
};
const schema: yup.SchemaOf<FormData> = yup.object().shape({
  contactName: yup
    .string()
    .required(MISSING_ERROR)
    .nullable()
    .min(4, 'Name is too short')
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/, 'Invalid name'),
  contactPhone: yup
    .string()
    .required(MISSING_ERROR)
    .test(
      'is-valid-phone-number',
      'Phone number is missing or invalid.',
      (userInput) => !!(userInput && isPhoneNumberValid(userInput))
    )
    .nullable(),
  relationship: yup.string().required(MISSING_ERROR).nullable(),
});
const EmergencyContactForm = () => {
  const { contactName, contactPhone, relationship, relationshipOptions } =
    useWizardState<TeenEmergencyContactWizardState>();
  const { setState: setWizardState } = useWizardActions();
  const form = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      contactName,
      contactPhone,
      relationship,
    },
  });
  const {
    formState: { errors, isValid },
  } = form;

  useEffect(() => {
    if (
      Object.keys(errors).length === 0 &&
      contactName?.length &&
      contactPhone?.length &&
      relationship?.length
    ) {
      setWizardState({
        isValidEmergencyContact: true,
      });
    } else {
      setWizardState({
        isValidEmergencyContact: false,
      });
    }
  }, [setWizardState, contactName, contactPhone, relationship, errors, form, isValid]);

  return (
    <FormProvider {...form}>
      <form>
        <RHFInput
          inputType="text"
          fieldName="contactName"
          label="Their full name"
          data-qa="contactNameInput"
          onChange={(e) => {
            setWizardState({ contactName: e.target.value });
          }}
        />

        <RHFSelect
          fieldName="relationship"
          label="Relationship to you"
          options={relationshipOptions}
          registerOptionValue
          dataQa="relationshipSelect"
          onChange={(value) => {
            setWizardState({ relationship: value });
          }}
        />
        <A11yPhoneInput
          isReactHookForm
          name="contactPhone"
          inputType="tel"
          value={contactPhone}
          label="Their phone number"
          dataQa="contactPhoneInput"
          onChange={(value) => {
            setWizardState({ contactPhone: value });
          }}
        />
      </form>
    </FormProvider>
  );
};

export default EmergencyContactForm;
