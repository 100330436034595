import { FunctionComponent } from 'react';

import { useEmotionTheme } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useTranslation } from '@talkspace/i18n';
import BaseAccordion from './Base';
import NotificationTitle from '../NotificationTitle';
import NotificationPanelRow from '../NotificationPanelRow';
import { DailyReminder, EmailEnvelope } from '../../../components/icons';
import constructDescription from './helpers';
import { arrowContainerStyles, bodyStyle, titleContainerStyles } from '../../myAccountStyles';

interface Props {
  isOpen: boolean;
  handlePress: (value: boolean) => void;
  isMobile: boolean;
  roomActivity: { email: boolean; push: boolean; sms: boolean };
  handlePushToggle: (value: number) => void;
  handleEmailToggle: (value: number) => void;
}

const RoomActivityAccordion: FunctionComponent<Props> = ({
  isOpen,
  handlePress,
  isMobile,
  roomActivity,
  handlePushToggle,
  handleEmailToggle,
}) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const { colors, colorRoles } = useEmotionTheme();
  const useNewNav = useNewMemberNav();
  return (
    <BaseAccordion
      bodyStyle={bodyStyle}
      titleContainerStyles={titleContainerStyles}
      open={isOpen}
      onPress={handlePress}
      disabled={false}
      titleComponent={() => (
        <NotificationTitle
          title={tnewMemberNav('notifications.room', 'Room activity', undefined)}
          isOpen={isOpen}
          isMobile={isMobile}
          subTitle={
            isOpen
              ? tnewMemberNav(
                  'notifications.get',
                  'Get alerted when you have unread messages waiting for you in the room.',
                  undefined
                )
              : constructDescription(roomActivity, tnewMemberNav)
          }
        />
      )}
      arrowStyles={{
        fill: useNewNav ? colorRoles.icons.iconDefaultDefault : colors.periwinkleGrey,
      }}
      arrowContainerStyles={arrowContainerStyles}
      useNewNav={useNewNav}
    >
      <NotificationPanelRow
        label={tnewMemberNav('notifications.push', 'Push', undefined)}
        iconComponent={() => <DailyReminder />}
        isOn={roomActivity.push}
        onToggle={handlePushToggle}
        name="roomActivityPush"
      />
      <NotificationPanelRow
        label={tnewMemberNav('notifications.email', 'Email', undefined)}
        iconComponent={() => <EmailEnvelope />}
        isOn={roomActivity.email}
        onToggle={handleEmailToggle}
        name="roomActivityEmail"
      />
    </BaseAccordion>
  );
};

export default RoomActivityAccordion;
