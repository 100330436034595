import { FunctionComponent, useEffect, useCallback, useState } from 'react';
import { History } from 'history';
import { StepWizardProvider } from 'stepWizard';
import { useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { RouteComponentProps } from '@/core/routerLib';
import { MainContextProvider } from '@/hooks/mainContext';
import { useGenericWizardActions } from '@/utils/genericWizardActions';
import { getUserData } from '@/auth/helpers/token';
import ApiHelper from '@/utils/ApiHelper';
import Routes from './Routes';
import { getInitialState, MedicalHealthWizardState } from './reducers/medicalHealthWizardState';
import medicalHealthActions from './hooks/medicalHealthActions';

interface Props {
  roomID: number;
  source: string;
  contextID?: string;
  history: History;
}

const MedicalHealthWizard: FunctionComponent<RouteComponentProps<Props>> = ({
  match: {
    params: { roomID, source, contextID = '' },
  },
}) => {
  const { id } = getUserData();
  const { pcpInIntake } = useFlags();

  const getFirstState = useCallback(getInitialState, [roomID, source, contextID]);

  const [initialState, setInitialState] = useState<MedicalHealthWizardState | undefined>();

  const genericMatchingIntakeActions = useGenericWizardActions(medicalHealthActions);

  const { t: tAdultOnboarding } = useTranslation('adultOnboarding');

  useEffect(() => {
    setInitialState(getFirstState(id, roomID, source, contextID, tAdultOnboarding, pcpInIntake));
  }, [contextID, getFirstState, id, roomID, source, tAdultOnboarding, pcpInIntake]);

  return (
    <MainContextProvider>
      {initialState ? (
        <StepWizardProvider
          initialState={initialState}
          ApiHelperOverride={ApiHelper}
          genericActions={genericMatchingIntakeActions}
        >
          <Routes />
        </StepWizardProvider>
      ) : null}
    </MainContextProvider>
  );
};

export default MedicalHealthWizard;
