import {
  View,
  Tiny,
  Standard,
  TouchableView,
  EmotionStyle,
  CloseIcon,
  TextDS,
  ColorRolesVersion,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { VFC } from 'react';
import { ChevronRight } from '@talkspace/react-toolkit/src/designSystems/icons';
import styled from '@/core/styled';

export interface Props {
  title: string;
  titleColorRole?: keyof ColorRolesVersion['typography'];
  titleStyle?: EmotionStyle;
  subtitle?: string;
  subtitleStyle?: EmotionStyle;
  onPress?: () => void;
  boldTitle?: boolean;
  showCancel?: boolean;
  alwaysShowArrow?: boolean;
  onCancelPress?: () => void;
  Icon?: VFC<{ style?: EmotionStyle }>;
  iconStyle?: EmotionStyle;
  overrideRightIcon?: JSX.Element;
  dataQa?: string;
  isLargeText?: boolean;
  tinyTitle?: boolean;
  bannerColorRole?: keyof ColorRolesVersion['surfaces'];
  bannerStyles?: EmotionStyle;
  isIconNextToTitle?: boolean;
}

const ChatBannerWrapper = styled(TouchableView)({
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-around',
});

const LeftWrapper = styled(TouchableView)<{ hasChildren?: boolean; isLargeText?: boolean }>(
  ({
    hasChildren,
    isLargeText,
    theme: {
      window: { isSmall, isMobile },
    },
  }) => {
    let paddingLeft = 30;
    if (isSmall && hasChildren) {
      paddingLeft = 20;
    } else if (isLargeText || isMobile) {
      paddingLeft = 0;
    }
    return {
      flex: 1,
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft,
    };
  }
);
const CenterWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      flex: isMobile && 10,
      flexDirection: 'row',
      alignItems: 'center',
    };
  }
);
const RightWrapper = styled(View)<{ isLargeText?: boolean }>(
  ({
    isLargeText,
    theme: {
      window: { isSmall },
    },
  }) => {
    let paddingRight = 30;
    if (isSmall) {
      paddingRight = 21;
    } else if (isLargeText) {
      paddingRight = 0;
    }
    return {
      flex: 1,
      alignItems: 'flex-end',
      justifyContent: 'center',
      paddingRight,
    };
  }
);

const MobileOnly = styled(View)<{ showAlways: boolean }>(
  ({
    showAlways = false,
    theme: {
      window: { isSmall },
    },
  }) => {
    return {
      display: isSmall || showAlways ? 'flex' : 'none',
    };
  }
);

const BannerText = styled(View)<{ isIconNextToTitle?: boolean }>(
  ({
    theme: {
      window: { isMobile },
    },
    isIconNextToTitle,
  }) => {
    if (isIconNextToTitle && !isMobile) {
      return { textAlign: 'center' };
    }
    return { flexGrow: 0, flexShrink: 1, alignItems: 'flex-start' };
  }
);

export const ChatBanner = ({
  Icon,
  iconStyle = {},
  title,
  onPress,
  subtitle,
  boldTitle,
  showCancel,
  onCancelPress,
  alwaysShowArrow,
  overrideRightIcon,
  dataQa,
  isLargeText = false,
  tinyTitle = false,
  titleColorRole,
  titleStyle,
  subtitleStyle,
  bannerColorRole,
  bannerStyles,
  isIconNextToTitle,
}: Props) => {
  const { isMobile } = useWindowWidthState();
  const renderRightElement = onPress || alwaysShowArrow || overrideRightIcon;
  const { colorRoles } = useEmotionTheme();

  return (
    <ChatBannerWrapper
      onPress={onPress}
      dataQa={dataQa}
      style={{
        background: bannerColorRole && colorRoles.surfaces[bannerColorRole],
        ...bannerStyles,
      }}
    >
      <LeftWrapper
        hasChildren={showCancel}
        isLargeText={isLargeText}
        onPress={onCancelPress}
        tabIndex={showCancel ? 0 : -1}
        aria-label={showCancel ? 'Cancel' : undefined}
      >
        {showCancel && <CloseIcon />}
      </LeftWrapper>
      <CenterWrapper>
        {(!isIconNextToTitle || isMobile) && (
          <View style={{ marginRight: 10 }}>{Icon && <Icon style={iconStyle} />}</View>
        )}
        <BannerText isIconNextToTitle={isIconNextToTitle}>
          <View row justify="center">
            {isIconNextToTitle && !isMobile && (
              <View style={{ marginRight: 10 }}>{Icon && <Icon style={iconStyle} />}</View>
            )}
            {boldTitle && (
              <TextDS inline variant="headingMd" colorRole={titleColorRole ?? 'textBrandDefault'}>
                {title}
              </TextDS>
            )}
            {tinyTitle && <Tiny style={titleStyle}>{title}</Tiny>}
            {!boldTitle && !tinyTitle && <Standard style={titleStyle}>{title}</Standard>}
          </View>

          {subtitle && tinyTitle ? (
            <Standard style={subtitleStyle}>{subtitle}</Standard>
          ) : (
            <Tiny>{subtitle}</Tiny>
          )}
        </BannerText>
      </CenterWrapper>
      <RightWrapper isLargeText={isLargeText}>
        {renderRightElement &&
          (overrideRightIcon || (
            <MobileOnly showAlways={!!alwaysShowArrow}>
              <ChevronRight />
            </MobileOnly>
          ))}
      </RightWrapper>
    </ChatBannerWrapper>
  );
};

export default ChatBanner;
