import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M14.7063 7.10264C15.0969 6.71201 15.0969 6.07764 14.7063 5.68701C14.3156 5.29639 13.6813 5.29639 13.2906 5.68701L10 8.98076L6.70627 5.69014C6.31565 5.29951 5.68127 5.29951 5.29065 5.69014C4.90002 6.08076 4.90002 6.71514 5.29065 7.10576L8.5844 10.3964L5.29377 13.6901C4.90315 14.0808 4.90315 14.7151 5.29377 15.1058C5.6844 15.4964 6.31877 15.4964 6.7094 15.1058L10 11.812L13.2938 15.1026C13.6844 15.4933 14.3188 15.4933 14.7094 15.1026C15.1 14.712 15.1 14.0776 14.7094 13.687L11.4156 10.3964L14.7063 7.10264Z';

const pathConfig: IconConfig = {
  standard: { default: { path: standardPath } },
};

const XMarkLarge = withDSIconMaker(pathConfig, 'x mark');

export default XMarkLarge;
