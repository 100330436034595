import { useCallback, useMemo } from 'react';
import {
  Tray,
  View,
  Small,
  ExtraHuge,
  Large,
  Button,
  BaseButton,
  TextDS,
} from '@talkspace/react-toolkit';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { ERoom } from 'ts-frontend/entities/Room';
import useIsClientDischargeV3OnForPlan from 'ts-frontend/hooks/useIsClientDischargeV3OnForPlan';
import { useTranslation, Trans } from '@talkspace/i18n';
import styled from '@/core/styled';
import { trackEvent } from '@/utils/analytics/eventTracker';
import TherapistAvatarV2 from '../TherapistAvatarV2';

interface Props {
  therapist: ETherapistInfo;
  room: ERoom;
  isProviderAvailable: boolean;
  handleMatchProvider: () => void;
  handleReactivation: () => void;
  dismissReactivationTray: () => void;
  roomID: number;
}

const Styled = {
  HeaderWrapper: styled(View)({
    marginTop: 24,
  }),
  SubTitle: styled(Large)(({ theme: { colorRoles } }) => {
    return {
      marginTop: 16,
      color: colorRoles.typography.textSubtle,
    };
  }),
  ButtonContainer: styled(View)({
    gap: 24,
    marginTop: 12,
    paddingLeft: 10,
    paddingRight: 10,
  }),
};

const ReactivationTray = ({
  therapist,
  room,
  isProviderAvailable,
  handleMatchProvider,
  handleReactivation,
  dismissReactivationTray,
  roomID,
}: Props) => {
  const isClientDischargeV3OnForPlan = useIsClientDischargeV3OnForPlan(roomID);
  const { t: tDteRevalidation } = useTranslation('dte');
  const handleMatchProviderAndClose = useCallback(() => {
    trackEvent(
      'Inactivity Options Selected',
      {
        actionName: 'inactivityPromptInteraction',
        optionSelected: 'Switch provider',
        isProviderAvailable,
        roomID,
      },
      ['tsAnalytics']
    );
    handleMatchProvider();
    dismissReactivationTray();
  }, [handleMatchProvider, dismissReactivationTray, roomID, isProviderAvailable]);
  const handleReactivationAndClose = useCallback(() => {
    trackEvent(
      'Inactivity Options Selected',
      {
        actionName: 'inactivityPromptInteraction',
        optionSelected: 'Message provider',
        isProviderAvailable,
        roomID,
      },
      ['tsAnalytics']
    );
    handleReactivation();
    dismissReactivationTray();
  }, [handleReactivation, dismissReactivationTray, roomID, isProviderAvailable]);

  const handleClose = useCallback(() => {
    trackEvent(
      'Inactivity Prompt Dismissed',
      {
        actionName: 'inactivityPromptInteraction',
        isProviderAvailable,
        roomID,
      },
      ['tsAnalytics']
    );
    dismissReactivationTray();
  }, [isProviderAvailable, roomID, dismissReactivationTray]);

  const providerAvailabilityText = useMemo(() => {
    const { availability } = therapist || {};

    if (!isProviderAvailable) {
      return tDteRevalidation(
        'dteReactivationUnavailable.providerUnavailableText',
        'Your provider is unavailable',
        undefined
      );
    }

    if (availability === 'time-off') {
      return tDteRevalidation(
        'dteReactivationUnavailable.providerTimeOffText',
        'Your provider is on time-off',
        undefined
      );
    }

    if (availability === 'unavailable') {
      return tDteRevalidation(
        'dteReactivationUnavailable.unavailable',
        'Your provider is off today',
        undefined
      );
    }

    return '';
  }, [isProviderAvailable, therapist, tDteRevalidation]);
  const subtitle = isProviderAvailable
    ? tDteRevalidation(
        'dteReactivationAvailable.subtitle',
        'If you want to continue therapy, your provider is ready to hear from you',
        undefined
      )
    : tDteRevalidation(
        'dteReactivationUnavailable.subtitle',
        "It looks like your provider is unavailable. If you're ready to continue therapy, we'll match you to a new provider to keep making progress.",
        undefined
      );
  return (
    <Tray handleClose={handleClose} isReactivationTray>
      <TherapistAvatarV2
        therapist={therapist}
        room={room}
        isProviderAvailable={isProviderAvailable}
        isReactivationTray
      />
      <Small style={{ paddingTop: 8 }}>{!isProviderAvailable && providerAvailabilityText}</Small>
      <Styled.HeaderWrapper>
        <ExtraHuge>
          {tDteRevalidation('dteReactivationAvailable.header', 'Welcome back', undefined)}
        </ExtraHuge>
        <Styled.SubTitle>{subtitle}</Styled.SubTitle>
      </Styled.HeaderWrapper>
      <Styled.ButtonContainer>
        {isProviderAvailable ? (
          <>
            <Button onPress={handleReactivationAndClose} dataQa="messageProviderPress">
              {isClientDischargeV3OnForPlan ? (
                <Large variant="largeBoldWhite">
                  <Trans t={tDteRevalidation} key="dteReactivationAvailable.restartTherapy">
                    Restart therapy
                  </Trans>
                </Large>
              ) : (
                <TextDS variant="headingMd" colorRole="textInverse">
                  <Trans t={tDteRevalidation} key="dteReactivationAvailable.message">
                    Message
                  </Trans>{' '}
                  {therapist.firstName}
                </TextDS>
              )}
            </Button>
            <BaseButton onPress={handleMatchProviderAndClose} dataQa="matchWithNewProviderPress">
              <TextDS variant="headingMd" colorRole="textBrandDefault">
                <Trans t={tDteRevalidation} key="dteReactivationAvailable.changeProvider">
                  Change provider
                </Trans>
              </TextDS>
            </BaseButton>
          </>
        ) : (
          <>
            <Button onPress={handleMatchProviderAndClose} dataQa="continueWithNewProviderPress">
              <TextDS variant="headingMd" colorRole="textInverse">
                <Trans t={tDteRevalidation} key="dteReactivationUnavailable.continueWithProvider">
                  Continue with a new provider
                </Trans>
              </TextDS>
            </Button>
          </>
        )}
      </Styled.ButtonContainer>
    </Tray>
  );
};

export default ReactivationTray;
