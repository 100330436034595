import { ReactElement } from 'react';
import styled, { useEmotionTheme } from '../../core/styled';
import View from '../../components/View';
import { AnimatedSwipeDownModal, Modal, Tag, TextDS } from '../../components';
import { ColorRolesVersion } from '../types';
import { useWindowWidthState } from '../../index.next';
import ButtonV2 from '../components/Button/ButtonV2';
import CloseButtonV2 from '../components/CloseButton/CloseButtonV2';

export interface AnnouncementProps {
  tagPosition?: 'top' | 'right';
  isHorizontal?: boolean;
  title: string;
  subtitle?: string;
  tagText?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  backgroundColor?: ColorRolesVersion;
  isOpen: boolean;
  illustration: ReactElement;
  onPrimaryButtonClick?: () => void;
  onSecondaryButton?: () => void;
  onClose: () => void;
}

const AnnouncementContainer = styled(View)<{
  isHorizontal?: boolean;
}>(
  ({
    isHorizontal,
    theme: {
      window: { isMobile },
      spacing,
    },
  }) => {
    return {
      backgroundColor: 'white',
      borderRadius: spacing('space200'),
      boxShadow: !isMobile && '0px 8px 32px 0px rgba(43, 42, 46, 0.25)',
      width: isMobile && '100%',
      ...(!isMobile && { maxWidth: isHorizontal ? 700 : 368 }),
    };
  }
);

const ActionsContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: isMobile ? 'center' : 'flex-end',
      flexDirection: isMobile ? 'column-reverse' : 'row',
    };
  }
);

const CloseButtonContainer = styled(View)(() => {
  return {
    position: 'absolute',
    right: 16,
    top: 10,
  };
});

const SlotContainer = styled(View)<{
  backgroundColor?: ColorRolesVersion;
  isHorizontal?: boolean;
}>(({ isHorizontal, backgroundColor, theme: { colorRoles, spacing } }) => {
  return {
    backgroundColor: backgroundColor || colorRoles.surfaces.decorativeBalanceBlue,
    padding: isHorizontal ? 0 : spacing('space200'),
    borderRadius: isHorizontal ? '16px 0 0 16px' : '16px 16px 0 0',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: isHorizontal && 208,
  };
});

const Announcement = ({
  tagPosition = 'right',
  title,
  subtitle,
  primaryButtonText,
  secondaryButtonText,
  isHorizontal,
  backgroundColor,
  tagText,
  isOpen,
  illustration,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButton,
}: AnnouncementProps) => {
  const { isMobile } = useWindowWidthState();
  const { spacing } = useEmotionTheme();

  const content = (
    <AnnouncementContainer isHorizontal={isHorizontal} row={isHorizontal} align="stretch">
      <SlotContainer isHorizontal={isHorizontal} backgroundColor={backgroundColor}>
        {!isHorizontal && (
          <CloseButtonContainer>
            <CloseButtonV2 onPress={onClose} xColorType="brand" />
          </CloseButtonContainer>
        )}

        {illustration}
      </SlotContainer>

      <View style={{ padding: spacing('space200'), flex: 1 }}>
        <View style={{ marginBottom: spacing('space300') }}>
          <View
            style={{
              flexDirection: tagPosition === 'top' ? 'column' : 'row-reverse',
              gap: tagPosition === 'top' ? 16 : 0,
            }}
          >
            {tagText && <Tag text={tagText} color="decorativeGratitudeGreen" />}
            <TextDS style={{ marginRight: spacing('space100') }} variant="headingLg">
              {title}
            </TextDS>
          </View>

          <TextDS style={{ marginTop: spacing('space100') }} variant="bodySm">
            {subtitle}
          </TextDS>
        </View>

        <ActionsContainer>
          {secondaryButtonText && (
            <ButtonV2
              style={{ marginRight: !isMobile && spacing('space200') }}
              text={secondaryButtonText}
              variant="tertiary"
              sizeDS={isMobile ? 'large' : 'slim'}
              onPress={onSecondaryButton}
            />
          )}
          {primaryButtonText && (
            <ButtonV2
              style={{ ...(isMobile && { width: '100%' }) }}
              text={primaryButtonText}
              variant="primary"
              sizeDS={isMobile ? 'large' : 'slim'}
              onPress={onPrimaryButtonClick}
            />
          )}
        </ActionsContainer>
      </View>
      {isHorizontal && (
        <View alignSelf="start" style={{ marginTop: 10, marginRight: 10 }}>
          <CloseButtonV2 onPress={onClose} xColorType="brand" />
        </View>
      )}
    </AnnouncementContainer>
  );

  if (isMobile) {
    return (
      <AnimatedSwipeDownModal isOpen={isOpen} onClose={onClose} containerStyles={{ padding: 0 }}>
        {content}
      </AnimatedSwipeDownModal>
    );
  }

  return (
    <Modal onBackdropPress={onClose} isVisible={isOpen}>
      {content}
    </Modal>
  );
};

export default Announcement;
