import type { EntrypointQueryProps } from '../hooks/useQueryEntrypoint';

export const onboardingQueryKey = ({
  userID,
  roomID,
}: {
  userID?: number | string;
  roomID?: number | string;
}) => [userID?.toString(), roomID?.toString(), 'onboarding'];

export const onboardingV3QueryKey = ({
  userID,
  roomID,
}: {
  userID?: number | string;
  roomID?: number | string;
}) => [userID?.toString(), roomID?.toString(), 'onboardingV3'];

export const entrypointQueryKey = ({
  attribution,
  distinctID,
  nativeAttribution,
}: Partial<EntrypointQueryProps>) => [
  distinctID,
  'funnelVariation',
  attribution?.funnelVariation,
  'roomType',
  attribution?.roomType,
  'nativeAttribution',
  JSON.stringify(nativeAttribution),
];

export const clientBookingQueryKey = ({
  roomID,
  bookingID,
}: {
  roomID: string;
  bookingID: string;
}) => [roomID, bookingID, 'clientBooking'];

export const clientBookingsAllRoomsQueryKey = ({
  clientUserID,
  includeAsync,
}: {
  clientUserID: string | number;
  includeAsync?: boolean;
}) => [clientUserID, includeAsync ? 'includeAsyncTrue' : 'includeAsyncFalse', 'bookingsAllRooms'];

export const therapistInfoQueryKey = ({ roomID }: { roomID: string }) => [roomID, 'therapistInfo'];

export const lastMessagesQueryKey = ({ clientUserID }: { clientUserID: string | number }) => [
  clientUserID,
  'lastMessages',
];

export const getAllRoomsQueryKey = ({ clientUserID }: { clientUserID: string | number }) => [
  clientUserID,
  'getAllRooms',
];

export const getClientQueryKey = ({ clientUserID }: { clientUserID: string | number }) => [
  clientUserID,
  'getClient',
];
export const getReturningUserKey = ({ clientUserID }: { clientUserID: string | number }) => [
  clientUserID,
  'returningUser',
];

export const getCommunityUserDataQueryKey = ({
  clientUserID,
}: {
  clientUserID: string | number;
}) => [clientUserID, 'getCommunityUserData'];

export const getNotificationPreferencesDataQueryKey = ({
  clientUserID,
}: {
  clientUserID: string | number;
}) => [clientUserID, 'getNotificationPreferences'];

export const getDailyPromptQueryKey = ({ clientUserID }: { clientUserID: string | number }) => [
  clientUserID,
  'getDailyPrompt',
];

export const customerInformationQueryKey = ({ userID }: { userID: number }) => [
  `${userID}`,
  'customerInformation',
];

export const getUserSettingsQueryKey = ({ userID }: { userID: number }) => [
  `${userID}`,
  'userSettings',
];

export const getClientSurveysQueryKey = () => ['clientSurveys'];

export const getCommunityFilteredPostCountQueryKey = ({
  communityID,
  tags,
}: {
  communityID: string | undefined;
  tags: string[];
}) => [communityID, ...tags];

export const getUserPostsQueryKey = ({
  communityID,
  userID,
}: {
  communityID: string | undefined;
  userID: string | undefined;
}) => [communityID, userID, 'userPosts'];

export const emergencyContactQueryKey = ({ userID }: { userID: number }) => [
  userID,
  'emergencyContact',
];
