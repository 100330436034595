import { FunctionComponent } from 'react';
import { View, Large } from '@talkspace/react-toolkit';

import { useTranslation } from '@talkspace/i18n';
import styled from '../../../core/styled';
import NotificationToggle from '../NotificationToggle';

const Wrapper = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '17px 0',
});

const ExtraSmall = styled(View)(({ theme: { colors } }) => {
  return { fontSize: 11, paddingRight: 3, color: colors.slateGrey };
});

interface Props {
  label: string;
  isOn: boolean;
  iconComponent?: () => JSX.Element;
  isRequired?: boolean;
  onToggle: (value: number) => void;
  name: string;
}

const NotificationPanelRow: FunctionComponent<Props> = ({
  label,
  iconComponent,
  isOn,
  onToggle,
  isRequired = false,
  name,
}) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return (
    <Wrapper>
      <View row>
        {iconComponent ? iconComponent() : <View style={{ marginRight: -11 }} />}
        <Large style={{ marginLeft: 11 }}>{label}</Large>
      </View>
      {isRequired ? (
        <ExtraSmall>{tnewMemberNav('notifications.required', 'Required', undefined)}</ExtraSmall>
      ) : (
        <NotificationToggle
          label={label}
          isOn={isOn}
          onChange={(value: boolean) => onToggle(Number(value))}
          name={name}
        />
      )}
    </Wrapper>
  );
};

export default NotificationPanelRow;
