import {
  View,
  SpacingView,
  Card,
  CardContent,
  TextDS,
  SwitchCard,
  Spinner,
} from '@talkspace/react-toolkit';
import AppDetails from 'ts-ionic/components/AppDetails';
import { useTranslation } from '@talkspace/i18n';
import { NativeFeaturesEnum, shouldShowNativeFeature } from 'ts-ionic';
import { useProfileCards, usePlanAndCoverageCards, useSettingsCards, useMoreCards } from './cards';
import { AccountScreenProps } from '../../types';
import { trackCTAClick } from '../../../utils/analytics/events';
import AccountScreenBannerPrompt from './AccountScreenBannerPrompt';
import AccountPageWrapper from '@/myAccount/components/AccountPageWrapper';
import { detectEnv } from '@/utils/configs';

const DEBUG = detectEnv() !== 'prod';

const AccountScreen: React.VFC<AccountScreenProps> = ({
  hasPendingMatchRoom,
  isHighContrast,
  isMobile,
  isLoadingUserSettings,
  userSettings,
  onPressReadReceipts,
  onPressColorContrast,
  onPress,
}) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const profileCards = useProfileCards();
  const planAndCoverageCards = usePlanAndCoverageCards();
  const settingsCards = useSettingsCards();
  const moreCards = useMoreCards();

  if (isLoadingUserSettings) return <Spinner />;
  return (
    <AccountPageWrapper title={tnewMemberNav('accountSettings.account', 'Account', undefined)}>
      {shouldShowNativeFeature(NativeFeaturesEnum.PUSH_NOTIFICATIONS) && (
        <AccountScreenBannerPrompt isMobile={isMobile} />
      )}
      <SpacingView itemSpacing="space500" row={false}>
        <SpacingView itemSpacing="space200" row={false}>
          <TextDS variant="headingLg">
            {tnewMemberNav('accountSettings.profile', 'Profile', undefined)}
          </TextDS>
          {profileCards.map((card) => (
            <Card variant="noBorder" padding="slim" onPress={() => onPress(card)} {...card}>
              <CardContent variant="slim" {...card} />
            </Card>
          ))}
        </SpacingView>
        <View>
          <SpacingView itemSpacing="space200" row={false}>
            <TextDS variant="headingLg">
              {tnewMemberNav('accountSettings.plan', 'Plan and coverage', undefined)}
            </TextDS>
            {planAndCoverageCards.map((card) => {
              if (card.hideIfHasPendingMatchRoom && hasPendingMatchRoom) return null;
              return (
                <Card variant="noBorder" padding="slim" onPress={() => onPress(card)} {...card}>
                  <CardContent variant="slim" {...card} />
                </Card>
              );
            })}
          </SpacingView>
        </View>
        <SpacingView itemSpacing="space200" row={false}>
          <TextDS variant="headingLg">
            {tnewMemberNav('accountSettings.settings', 'Settings', undefined)}
          </TextDS>
          <Card
            variant="noBorder"
            padding="slim"
            onPress={() => onPress(settingsCards[0])}
            {...settingsCards[0]}
          >
            <CardContent variant="slim" hideCaret={false} {...settingsCards[0]} />
          </Card>
          <SwitchCard
            cardVariant="noBorder"
            contentVariant="slim"
            isOn={!!userSettings?.sendMessagesReceipts}
            handleOnPress={onPressReadReceipts}
            forceColumn
            {...settingsCards[1]}
          />
          <SwitchCard
            cardVariant="noBorder"
            contentVariant="slim"
            isOn={isHighContrast}
            handleOnPress={onPressColorContrast}
            forceColumn
            {...settingsCards[2]}
          />
        </SpacingView>
        <SpacingView itemSpacing="space200" row={false}>
          <TextDS variant="headingLg">More</TextDS>
          {moreCards.map((card) => (
            <Card
              variant="noBorder"
              padding="slim"
              onPress={() => {
                trackCTAClick(card.titleText, 'account');
                onPress(card);
              }}
              {...card}
            >
              <TextDS variant="headingLg">
                <CardContent variant="slim" {...card} />
              </TextDS>
            </Card>
          ))}
        </SpacingView>
      </SpacingView>
      <AppDetails showLessData={!DEBUG} />
    </AccountPageWrapper>
  );
};

export default AccountScreen;
