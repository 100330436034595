import { useTranslation } from '@talkspace/i18n';
import { Notepad } from '../../components/icons';
import ForYouCard, { ForYouCardProps } from './ForYouCard';

interface PreSessionQuestionsCardProps
  extends Pick<ForYouCardProps, 'onPress' | 'disabled' | 'dataQa'> {}

const PreSessionQuestionsCard = ({ onPress, disabled, dataQa }: PreSessionQuestionsCardProps) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return (
    <ForYouCard
      dataQa={dataQa}
      onPress={onPress}
      disabled={disabled}
      Icon={<Notepad />}
      titleText={tnewMemberNav('home.pre', 'Pre-session questions', undefined)}
      bodyText={tnewMemberNav(
        'home.a',
        'A few prompts to help you prepare for your session',
        undefined
      )}
    />
  );
};

export default PreSessionQuestionsCard;
