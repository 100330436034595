import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from '@/core/routerLib';

type propsType = RouteComponentProps<{ roomID: number }> & {
  totalSteps: number;
  currentStep: number;
};
const OnboardingPageTeenParentalConsent = (props: propsType) => {
  const {
    match: {
      params: { roomID },
    },
    history,
  } = props;
  // NOTE: no intro page for this step, redirect to /parental-consent right away
  useEffect(() => {
    history.push(`/room/${roomID}/onboarding/parental-consent`);
  }, [history, roomID]);
  return null;
};

export default withRouter(OnboardingPageTeenParentalConsent);
