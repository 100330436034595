import { useQuery } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { getReturningUserKey } from '../utils/queryKeys';

interface QueryProps {
  clientUserID: string | number;
}

type ReturningUserData = {
  integrationPartnerName: string;
  therapist: {
    firstName: string;
    lastName: string;
  };
};

const fetchReturningUser =
  ({ clientUserID }: QueryProps) =>
  async () => {
    const {
      data: { data },
    } = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/returning-user`
    );
    return data;
  };

const useQueryReturningUser = ({ clientUserID }: QueryProps) =>
  useQuery<ReturningUserData, Error>({
    queryKey: getReturningUserKey({ clientUserID }),
    queryFn: fetchReturningUser({ clientUserID }),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(clientUserID),
  });

export default useQueryReturningUser;
