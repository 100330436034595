import { RHFInput, RHFPhoneInput, styled, View } from '@talkspace/react-toolkit';

interface PCPManualFormProps {}

const Form = styled.form({
  flex: 1,
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  marginLeft: 0,
  marginRight: 0,
});

const DoubleInputParentContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  maxWidth: 320,
});

const DoubleInputContainer = styled(View)({
  maxWidth: 155,
});

const PCPManualForm = (props: PCPManualFormProps) => (
  <Form>
    <DoubleInputParentContainer>
      <DoubleInputContainer>
        <RHFInput fieldName="firstName" label="First Name" isRequired />
      </DoubleInputContainer>
      <DoubleInputContainer>
        <RHFInput fieldName="lastName" label="Last Name" isRequired />
      </DoubleInputContainer>
    </DoubleInputParentContainer>
    <View>
      <RHFInput fieldName="organization" label="Organization" isRequired />
    </View>
    <View>
      <RHFPhoneInput fieldName="phoneNumber" />
    </View>
  </Form>
);

export default PCPManualForm;
