import {
  View,
  Link,
  A11Y_COLORS,
  TextDS,
  spacing,
  styled,
  VerificationCodeInput,
  BaseButton,
} from '@talkspace/react-toolkit';
import { Email } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { Trans, useTranslation } from '@talkspace/i18n';
import { useHistory } from '@/core/routerLib';
import OTPRequestStatus from './OTPRequestStatus';
import { trackUpdateEmailCTAClicked } from '@/utils/analytics/events';

const VERIFICATION_CODE_LENGTH = 6;

const { space300, space200 } = spacing;

const EmailWrapper = styled(View)({
  height: 64,
  width: 64,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 8,
});

const ButtonWrapper = styled(View)({
  marginTop: space200,
});

interface OTPInputViewProps {
  userEmail: string;
  onPress: () => void;
  onClearError: () => void;
  token: string | null;
  isError: boolean;
  isSuccess: boolean;
  emailSent: boolean;
  isLoading: boolean;
  verificationCode: string;
  setVerificationCode: (x: string) => void;
  onResendToken: () => void;
}

const OTPInputView = ({
  userEmail,
  token,
  isError,
  isSuccess,
  emailSent,
  isLoading,
  verificationCode,
  setVerificationCode,
  onPress,
  onClearError,
  onResendToken,
}: OTPInputViewProps) => {
  const { t: tEmailVerification } = useTranslation('emailVerification');
  const history = useHistory();

  return (
    <View justify="center" align="center" style={{ padding: `${space300}px ${space200}px` }}>
      <View justify="center" align="center" style={{ textAlign: 'center' }}>
        <EmailWrapper>
          <Email />
        </EmailWrapper>
        <TextDS variant="heading2xl" style={{ paddingBottom: 32 }}>
          {tEmailVerification(
            'emailVerification.titleAlt',
            'Before matching with a provider, verify your email',
            undefined
          )}
        </TextDS>
        <TextDS variant="body" style={{ textAlign: 'center' }}>
          <Trans
            t={tEmailVerification}
            i18nKey="emailVerification.descriptionOtp"
            options={{ userEmail }}
          >
            We sent a one-time code to <strong>{{ userEmail }}</strong>
          </Trans>
        </TextDS>
        <TextDS variant="body" style={{ textAlign: 'center', marginTop: space300 }}>
          <Trans t={tEmailVerification} i18nKey="emailVerification.inputCodeHere">
            Verify your email by inputting the code here:
          </Trans>
        </TextDS>

        <OTPRequestStatus isError={isError} isLoading={isLoading} isSuccess={isSuccess} />

        {!isLoading && !isSuccess && (
          <VerificationCodeInput
            isError={isError}
            style={{ marginTop: space300 }}
            onChange={setVerificationCode}
            onSubmit={onPress}
            clearVerificationCodeError={onClearError}
            clearVerificationCode={false}
            isLoading={isLoading}
            autoFocus
            isDisabled={isLoading && verificationCode.length === VERIFICATION_CODE_LENGTH}
          />
        )}

        {isError && (
          <View style={{ paddingTop: space300 }}>
            <TextDS variant="body" colorRole="textCriticalDefault" style={{ textAlign: 'center' }}>
              <Trans t={tEmailVerification} i18nKey="emailVerification.errorOtp">
                Error validating OTP
              </Trans>
            </TextDS>
          </View>
        )}

        <ButtonWrapper>
          <BaseButton
            onPress={onResendToken}
            style={{ padding: space300 }}
            dataQa="otpEmailVerificationResendCodeButton"
          >
            <TextDS variant="headingMd" colorRole="buttonBrandSecondary">
              {tEmailVerification('emailVerification.resendCode', 'Resend Code', undefined)}
            </TextDS>
          </BaseButton>
        </ButtonWrapper>

        {token && userEmail && (
          <TextDS variant="body" style={{ marginTop: 32 }}>
            {tEmailVerification('emailVerification.typo', 'Typo in your email?', undefined)}{' '}
            <BaseButton
              onPress={() => {
                trackUpdateEmailCTAClicked();
                history.push(`/email-verification/change-email?email=${userEmail}&token=${token}`);
              }}
              style={{ textDecoration: 'none', fontWeight: 'bold', color: A11Y_COLORS.greenText }}
              dataQa="update-email-link"
            >
              {tEmailVerification('emailVerification.update', 'Update email', undefined)}
            </BaseButton>
          </TextDS>
        )}
        <TextDS variant="bodySm" style={{ paddingTop: 12 }}>
          {tEmailVerification('emailVerification.need', 'Need help?', undefined)}{' '}
          <Link
            href="https://help.talkspace.com/hc/en-us"
            text={tEmailVerification('emailVerification.contact', 'Contact support', undefined)}
            target="_blank"
            style={{
              textDecoration: 'none',
              fontWeight: 'bold',
              color: A11Y_COLORS.greenText,
              fontSize: 14,
            }}
            dataQa="otpEmailVerificationContactSupportLink"
          />
        </TextDS>
      </View>
    </View>
  );
};

export default OTPInputView;
