import { FunctionComponent, useState, useRef } from 'react';
import * as React from 'react';
import {
  Button,
  Big,
  toast,
  Input,
  useErrorFocus,
  View,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import { useTranslation } from '@talkspace/i18n';

import { Provider, PaymentDetails, CardType } from 'ts-frontend/types';
import { checkEmailValidity } from 'ts-frontend/helpers';
import CreditCardInput from '../CreditCardInput';

interface NewCardProps {
  stripe: Stripe;
  email?: string;
  isEmailRequired?: boolean;
  provider: Provider;
  onSubmit: (token: string, paymentDetails?: PaymentDetails, email?: string) => void;
  submitText: string;
  errorMessage?: string;
  isProcessing: boolean;
  buttonDescribedBy?: string;
  isEmailDisabled?: boolean;
  aboveButtonDisclaimer?: JSX.Element;
}

const NewCard: FunctionComponent<NewCardProps> = ({
  stripe,
  email,
  isEmailRequired,
  provider,
  onSubmit,
  submitText,
  errorMessage,
  isProcessing,
  buttonDescribedBy,
  isEmailDisabled,
  aboveButtonDisclaimer,
}) => {
  const [stripeError, setStripeError] = useState('');
  const cardElementRef = useRef<StripeCardElement | null>(null);
  const [creatingToken, setCreatingToken] = useState(false);
  const [clientEmail, setClientEmail] = useState(email || '');
  const [emailError, setEmailError] = useState('');
  const [fullName, setFullName] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const { formContainerRef, setShouldFocusFirstInvalidField } = useErrorFocus();
  const { colors } = useEmotionTheme();
  const { t: tBookingScreen } = useTranslation('bookingScreen');

  function validateEmail() {
    if (isEmailRequired) {
      if (clientEmail?.length === 0) {
        setEmailError(tBookingScreen('payment.requireEmail', 'Email is required.', undefined));
        return false;
      }

      if (!checkEmailValidity(clientEmail)) {
        setEmailError(
          tBookingScreen('payment.validEmail', 'Please enter a valid email address.', undefined)
        );
        return false;
      }
    }

    setEmailError('');
    return true;
  }

  const handleSubmit = () => {
    if (!validateEmail()) {
      setShouldFocusFirstInvalidField(true);
      return;
    }
    if (!fullName) {
      setEmailError(tBookingScreen('payment.requireFullName', 'Full name is required', undefined));
      return;
    }
    setShouldFocusFirstInvalidField(false);
    if (cardElementRef.current) {
      setStripeError('');
      setCreatingToken(true);
      stripe
        .createToken(cardElementRef.current, { name: fullName })
        .then((result) => {
          if (result.error) {
            setStripeError(
              result.error && result.error.message
                ? result.error.message
                : tBookingScreen(
                    'payment.genericErrorMessage',
                    'Something went wrong. Please Try again',
                    undefined
                  )
            );
          } else if (result.token && result.token.card) {
            onSubmit(
              result.token.id,
              {
                cardType: result.token.card.brand as CardType,
                fourLastDigits: result.token.card.last4,
                expirationMonth: result.token.card.exp_month,
                expirationYear: result.token.card.exp_year,
                provider,
              },
              clientEmail
            );
          }
        })
        .catch((error) => {
          toast(error);
        })
        .finally(() => {
          setCreatingToken(false);
        });
    }
  };

  const isLoading = creatingToken || isProcessing;

  return (
    <View ref={formContainerRef}>
      <Input
        dataQa="paymentFullNameInput"
        placeholder={tBookingScreen('payment.enterFull', 'Enter full name', undefined)}
        wrappedInputProps={{
          label: tBookingScreen('payment.cardholder', 'Cardholder full name', undefined),
          errorMessage: fullNameError,
          isError: !!fullNameError,
          containerStyle: { width: '100%', marginBottom: 10 },
          inputStyle: { width: '100%', height: 60, color: 'black' },
          labelStyle: { marginBottom: 0 },
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFullName(e.target.value);
          setFullNameError('');
        }}
        value={fullName}
        ariaRequired
      />
      {isEmailRequired && (
        <Input
          dataQa="paymentEmailInput"
          placeholder={tBookingScreen('payment.enterEmail', 'Enter email address', undefined)}
          wrappedInputProps={{
            label: tBookingScreen('payment.email', 'Email', undefined),
            errorMessage: emailError,
            isError: !!emailError,
            containerStyle: { width: '100%', marginBottom: 10 },
            inputStyle: { width: '100%', height: 60 },
            labelStyle: { marginBottom: 0 },
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setClientEmail(e.target.value);
            setEmailError('');
          }}
          value={clientEmail}
          ariaRequired
          isDisabled={isEmailDisabled}
        />
      )}
      <CreditCardInput
        stripe={stripe}
        cardElementRef={cardElementRef}
        serverError={stripeError || errorMessage || ''}
      />
      {aboveButtonDisclaimer || null}
      <Button
        aria-describedby={buttonDescribedBy}
        dataQa="submitNewCCButton"
        type="submit"
        isLoading={isLoading}
        disabled={isLoading}
        onPress={handleSubmit}
        style={{ width: '100%' }}
        primaryColor={colors.green}
        roundedFocusStyle
      >
        <Big variant="bigWide">{submitText}</Big>
      </Button>
    </View>
  );
};

export default NewCard;
