import { ServiceType } from 'ts-frontend/types';
import { useTranslation } from '@talkspace/i18n';
import { useEffect, useCallback, useState } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { isLDExperimentTreatment } from 'launchDarkly/util';
import { ROOM_TYPE_TO_SERVICE_TYPE } from '../../whatToExpect/constants';
import PlansList from '../components/PlansList';
import { getUserData } from '@/auth/helpers/token';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import useUpdateCoverageParams from '../../hooks/useUpdateCoverageParams';
import { useParams, Redirect, useHistory } from '@/core/routerLib';
import { trackUpdateCoverageEvent } from '../../utils/analytics/events';
import { trackEvent } from '../../utils/analytics/eventTracker';

const TEMP_ALLOW_SKIP_SCREEN = false;

const UpdateCoverageContainer = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { id } = getUserData();
  const { t: tUpdateCoverage } = useTranslation('updateCoverage');
  const { roomID } = useParams<{ roomID: string }>();
  const { getAllRooms, getLVSSubscriptions } = useMainActions();
  const { roomsByID, therapistsByID, subscriptionsByID } = useMainState();
  const flags = useFlags();
  const { addCoverageKeepProvider: flagACKP } = flags;
  const isInsuranceConfirmationStepTreatment = isLDExperimentTreatment(
    flags,
    'insuranceConfirmationStep',
    {
      isUpdateCoverage: true,
    }
  );
  const history = useHistory();
  const updateCoverageParams = useUpdateCoverageParams();

  useEffect(() => {
    const getInitialData = async () => {
      setIsLoading(true);
      await Promise.all([getAllRooms(id), getLVSSubscriptions()]);
      setIsLoading(false);
    };
    getInitialData();
  }, [getAllRooms, getLVSSubscriptions, id]);

  const handlePress = useCallback(
    ({ roomID: coverageRoomID, roomType, therapistID }, coverageCardParams) => {
      const { isInsuranceIneligible, isClosedIneligible } = coverageCardParams || {};

      const serviceType: ServiceType = ROOM_TYPE_TO_SERVICE_TYPE[roomType];
      trackUpdateCoverageEvent(+coverageRoomID, 'Update Coverage Room');

      const params = {
        updateCoverageRoomID: coverageRoomID.toString(),
        source:
          isInsuranceIneligible || isClosedIneligible ? 'mbh-ineligibility' : 'update-coverage',
        serviceType,
        previousTherapistId: therapistID.toString(),
        fromPage: 'account-dropdown',
      };

      if (flags.insuranceConfirmationStep.experimentActive) {
        trackEvent('TS Experiment Session', {
          experimentName: flags.insuranceConfirmationStep.experimentName,
          variantName: flags.insuranceConfirmationStep.variant,
        });
      }

      if (isInsuranceConfirmationStepTreatment && TEMP_ALLOW_SKIP_SCREEN) {
        const combinedParams = {
          ...updateCoverageParams,
          ...params,
        };
        const eligibilityQueryString = new URLSearchParams(combinedParams).toString();
        const eligibilityRoute = coverageRoomID
          ? `/eligibility-widget/room/${coverageRoomID}?${eligibilityQueryString.toString()}`
          : `/eligibility-widget/?${eligibilityQueryString.toString()}`;

        history.push(eligibilityRoute);
      } else {
        const whatToExpectQueryString = new URLSearchParams(params).toString();

        history.push(`/what-to-expect?${whatToExpectQueryString.toString()}`);
      }
    },
    [
      flags.insuranceConfirmationStep.experimentActive,
      flags.insuranceConfirmationStep.experimentName,
      flags.insuranceConfirmationStep.variant,
      isInsuranceConfirmationStepTreatment,
      updateCoverageParams,
      history,
    ]
  );

  if (!flagACKP) {
    const path = roomID ? `/eligibility-widget/room/${roomID}` : '/eligibility-widget';
    return <Redirect to={path} />;
  }
  const headline = tUpdateCoverage(
    'updateCoverage.headline',
    'Please choose which plan you’d like to update your coverage for',
    undefined
  );
  const footer = tUpdateCoverage(
    'updateCoverage.footer',
    'That’s because you can only update coverage for active rooms. To see your inactive rooms, go to the Payment and plans section in Settings.',
    undefined
  );
  return (
    <PlansList
      type="active"
      isLoading={isLoading}
      showClosedDueToIneligibility
      closeButtonDataQa="updateCoverageCloseButton"
      headline={headline}
      footerText={footer}
      rooms={
        roomID in roomsByID
          ? {
              [roomID]: roomsByID[roomID],
            }
          : roomsByID
      }
      providers={therapistsByID}
      subscriptions={subscriptionsByID}
      handlePress={handlePress}
    />
  );
};

export default UpdateCoverageContainer;
