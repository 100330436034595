import SelectRounded, { SelectRoundedProps } from '../../../components/SelectRounded/SelectRounded';
import { View } from '../../../components';
import PasswordX from '../../../components/Svgs/PasswordX';
import { useEmotionTheme } from '../../../core/styled';
import { SelectRoundedVariant } from '../../types';
import { getSelectStyles } from '../../styles/selectStyles';

const MultiValueRemoveComponent = (props) => {
  const { colors } = useEmotionTheme();
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        cursor: 'pointer',
      }}
      {...props}
    >
      <PasswordX color={colors.white} height={10} width={10} />
    </View>
  );
};

const MultiValueRemoveComponentSlim = (props) => {
  const { colors } = useEmotionTheme();
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        cursor: 'pointer',
      }}
      {...props}
    >
      <PasswordX color={colors.white} height={8} width={8} />
    </View>
  );
};

interface SelectV1Props extends SelectRoundedProps {
  variant?: SelectRoundedVariant;
}

const SelectRoundedV1 = (props: SelectV1Props) => {
  const { colorRoles } = useEmotionTheme();
  const { variant } = props;
  return (
    <SelectRounded
      {...props}
      styles={getSelectStyles(colorRoles, variant || 'medium')}
      components={{
        MultiValueRemove:
          variant === 'slim' ? MultiValueRemoveComponentSlim : MultiValueRemoveComponent,
      }}
      preferThemeStyles
    />
  );
};
export default SelectRoundedV1;
