import { useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router';
import { Spinner, View } from '@talkspace/react-toolkit';
import useNYCTeenRoomToRenew from '../DTERenewal/hooks/useNYCTeenRoomToRenew';
import { useOpenModal } from '../utils/ModalsContextProvider';
import { DeepLinkAction } from '../hooks/useDeepLink';
import useNavShellData from '../hooks/useNavShellData';

type PopupConfig = {
  id: 'nycTeenspaceDTERenewal' | 'switchApp' | 'checkReactivation' | 'returningUser';
  callback: () => void;
  isActive: boolean;
};

const AfterLoginPopupManager = () => {
  const openModal = useOpenModal();
  const history = useHistory<{ planID: number }>();

  const {
    subscriptions,
    queries: { isLoadingSubscriptions },
    clientInfo,
  } = useNavShellData();

  const { roomID: nycTeenRoomToRenew, isLoading: isLoadingNYCTeenRoomToRenew } =
    useNYCTeenRoomToRenew({ subscriptions });

  const isLoadingRequiredData = isLoadingSubscriptions || isLoadingNYCTeenRoomToRenew;

  const [currentPopupIndex, setCurrentPopupIndex] = useState<number>(0);
  const nextPopup = () => setCurrentPopupIndex((prev) => prev + 1);

  // NOTE:
  // * Only add callbacks that call openModal() and close without navigateTo
  // * Always pass onClose=nextPopup into openModal for it to advance correctly
  // * Last action does history.replace leaving the popup manager
  const popupQueue = useMemo<PopupConfig[]>(
    () => [
      {
        id: 'nycTeenspaceDTERenewal',
        isActive: Boolean(nycTeenRoomToRenew),
        callback: () => {
          openModal(
            `/dte-renewal/room/${nycTeenRoomToRenew}/nyc-teenspace`,
            undefined,
            undefined,
            undefined,
            nextPopup
          );
        },
      },
      {
        id: 'returningUser',
        isActive: !!clientInfo?.shouldTriggerReturningUserFlow,
        callback: () => {
          history.replace('/welcome-back-returning-user');
        },
      },
      // NOTE: Last action. Can't add anything after this because this one is doing history.replace
      {
        id: 'checkReactivation',
        isActive: true,
        callback: () => {
          history.replace(`/home?action=${DeepLinkAction.CHECK_REACTIVATION}`);
        },
      },
    ],
    [history, nycTeenRoomToRenew, openModal, clientInfo?.shouldTriggerReturningUserFlow]
  );

  useEffect(() => {
    if (!isLoadingRequiredData && currentPopupIndex < popupQueue.length) {
      const { isActive, callback } = popupQueue[currentPopupIndex];
      if (isActive) {
        callback();
      } else {
        nextPopup();
      }
    }
  }, [currentPopupIndex, isLoadingRequiredData, popupQueue]);

  return (
    <View align="center" justify="center" style={{ height: '100vh' }}>
      <Spinner />
    </View>
  );
};

export default AfterLoginPopupManager;
