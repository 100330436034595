/* eslint-disable camelcase */
import { useQuery } from 'react-query';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import { searchPCPQueryKey } from './queryKeys';

export interface PCPSearchResults {
  result_count: number;
  results: PCPResult[];
}

export interface PCPResult {
  created_epoch: string;
  enumeration_type: string;
  last_updated_epoch: string;
  number: string;
  addresses: Address[];
  practiceLocations: Address[];
  basic: Basic;
  taxonomies: Taxonomy[];
  identifiers: Identifier[];
  endpoints: Endpoint[];
  other_names: OtherName[];
}

interface Address {
  country_code: string;
  country_name: string;
  address_purpose: string;
  address_type: string;
  address_1: string;
  address_2?: string;
  city: string;
  state: string;
  postal_code: string;
  telephone_number: string;
}

interface Basic {
  first_name: string;
  last_name: string;
  middle_name: string;
  credential: string;
  sole_proprietor: string;
  gender: string;
  enumeration_date: string;
  last_updated: string;
  certification_date: string;
  status: string;
}

interface Taxonomy {
  code: string;
  taxonomy_group: string;
  desc: string;
  state: string;
  license: string;
  primary: boolean;
}

interface Identifier {
  // TBD
}

interface Endpoint {
  // TBD
}

interface OtherName {
  type: string;
  code: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  prefix: string;
  suffix: string;
}

const searchPCP = async (firstName: string, lastName: string): Promise<PCPSearchResults> => {
  const response = await apiWrapper.get(
    `${
      apiHelper().publicAPIEndpoint
    }/public/nppes?version=2.1&limit=30&first_name=${firstName}&last_name=${lastName}`
  );
  return response.data;
};

const mixAndMatchResults = (
  results1: PCPSearchResults,
  results2: PCPSearchResults
): PCPSearchResults => {
  const combinedResults = [...results1.results, ...results2.results];
  const uniqueResults = combinedResults.filter(
    (result, index, self) => index === self.findIndex((r) => r.number === result.number)
  );

  return {
    result_count: uniqueResults.length,
    results: uniqueResults.sort((a, b) => a.basic.last_name.localeCompare(b.basic.last_name)),
  };
};

const useQueryPCPSearch = (query: string) => {
  const [firstName, ...rest] = query.split(' ');
  const lastName = rest.join(' ');

  const queryFn = async (): Promise<PCPSearchResults> => {
    const [results1, results2] = await Promise.all([
      searchPCP(firstName, lastName),
      searchPCP(lastName, firstName),
    ]);
    return mixAndMatchResults(results1, results2);
  };

  return useQuery<PCPSearchResults, Error>({
    queryKey: searchPCPQueryKey(query),
    queryFn,
    enabled: !!query,
  });
};

export default useQueryPCPSearch;
