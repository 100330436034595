/* eslint-disable import/prefer-default-export */
import { ToastType } from '@talkspace/react-toolkit/src/hooks/ToastProvider';
import { ReactionsEnum } from './types';

export const POST_MAX_LENGTH = 2200;
export const COMMENT_MAX_LENGTH = 2200;
export const TEENSPACE_DAY_END_SHIFT_HOURS = 3;

export const ReactionsOrder: ReactionsEnum[] = [
  ReactionsEnum.CRYING_FACE,
  ReactionsEnum.RAISING_HANDS,
  ReactionsEnum.MENDING_HEART,
  ReactionsEnum.SMILING_FACE_WITH_OPEN_HANDS,
  ReactionsEnum.FACE_HOLDING_BACK_TEARS,
];

export const DISPLAY_NAME_ERRORS = {
  409: 'That display name is already taken. Please try another',
  undefined: 'Enter a display name',
  400: 'Please use only letters, numbers, hyphens, and underscores in your display name',
};

export const GENERIC_ERROR = 'Something went wrong. Please try again.';

export const DISPLAY_NAME_MAX_LENGTH_LIMIT = 20;

export const LOCAL_STORAGE_LAST_CHECKED_DAILY_PROMPT_TIMESTAMP = 'lastCheckedDailyPromptTimestamp';

export const LOCAL_STORAGE_TEENSPACE_COMMUNITY = 'teenspaceCommunity';

export const SESSION_STORAGE_IGNORE_EAP_TO_BH_MODAL = 'ignoreEapToBHModal';

export const LOCAL_STORAGE_UPDATE_COVERAGE_COMPLETE = 'updateCoverageComplete';

export const COMMUNITY_TOASTS: { [key: string]: { type: ToastType; text: string } } = {
  POST_SHARED_WITH_THERAPIST: { type: 'success', text: 'Your post was shared with your therapist' },
  POST_DELETED: { type: 'success', text: 'Your post has been deleted' },
  POST_REPORTED: { type: 'success', text: 'Post reported' },
  COMMENT_DELETED: { type: 'success', text: 'Your comment has been deleted' },
  COMMENT_REPORTED: { type: 'success', text: 'Comment reported' },
  GENERIC_ERROR: { type: 'error', text: 'Something went wrong. Please try again.' },
};

export const TEENSPACE = 'teenspace';
