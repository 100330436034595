/* eslint-disable import/prefer-default-export */

// Extracts the first and last name from a full name string
// NOTE: Assumes the first name is the first word and the rest is the last name
// e.g. parseFullName('John Doe the Second') => { firstName: 'John', lastName: 'Doe the Second' }
export const parseFullName = (fullName: string): { firstName: string; lastName: string } => {
  const [firstName, ...rest] = fullName.split(' ');
  const lastName = rest.join(' ');
  return { firstName, lastName };
};
