import {
  createContext,
  useState,
  useMemo,
  useContext,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
} from 'react';
import styled, { EmotionStyle } from '../../core/styled';
import { COLORS } from '../../constants/commonStyles';
import Modal from '../Modal';
import View from '../View';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import TouchableView from '../TouchableView';

interface MenuContextInterface {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const fullScreenStyles = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  paddingBottom: 10,
  width: 'initial',
  height: 'initial',
  borderWidth: 0,
  borderRadius: 0,
  zIndex: 999,
};

const FloatingWrapper = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    left: 67,
    top: 58,
    height: 538,
    width: 293,
    borderWidth: 1,
    display: 'flex',
    borderRadius: 10,
    position: 'absolute',
    borderColor: COLORS.extraLightGrey,
    flexDirection: 'column',
    backgroundColor: 'white',
    overflow: 'hidden',
    border: `1px solid ${COLORS.extraLightGrey}`,
    boxShadow: '2px 11px 13px 0 rgba(0,0,27,0.15)',
    right: 'auto',
    ...(isMobile ? fullScreenStyles : {}),
  };
});

const MenuContext = createContext<MenuContextInterface | null>(null);

// eslint-disable-next-line @typescript-eslint/ban-types
const MenuProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const value = useMemo(() => {
    return { isOpen, setIsOpen };
  }, [isOpen, setIsOpen]);

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

function useMenuContext(): MenuContextInterface {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error(`Menu compound components cannot be rendered outside the Menu component`);
  }
  return context;
}

const MenuWrapper = ({
  children,
  style = {},
  ...props
}: PropsWithChildren<{ style?: EmotionStyle }>) => {
  const { isMobile } = useWindowWidthState();
  const { isOpen, setIsOpen } = useMenuContext();
  const closeMenu = () => setIsOpen(false);

  return isOpen ? (
    <Modal isVisible onBackdropPress={closeMenu}>
      <FloatingWrapper style={style} isMobile={isMobile} {...props}>
        {children}
      </FloatingWrapper>
    </Modal>
  ) : null;
};

const MenuToggle = ({
  children,
  style = {},
  ...props
}: PropsWithChildren<{ style?: EmotionStyle }>) => {
  const { setIsOpen, isOpen } = useMenuContext();
  const toggleMenu = () => setIsOpen(!isOpen);
  return (
    <TouchableView onPress={toggleMenu} style={style} {...props}>
      {children}
    </TouchableView>
  );
};

const Menu = ({ children, style = {}, ...props }: PropsWithChildren<{ style?: EmotionStyle }>) => (
  <MenuProvider>
    <View style={{ position: 'relative', ...style }} {...props}>
      {children}
    </View>
  </MenuProvider>
);

Menu.Toggle = MenuToggle;
Menu.Wrapper = MenuWrapper;

export default Menu;
