import { FunctionComponent, useRef, useState, useEffect } from 'react';
import {
  Button,
  ExtraHuge,
  Modal,
  StepTip,
  styled,
  TherapistCard,
  useWindowWidthState,
  View,
} from '@talkspace/react-toolkit';
import ScrollViewComponent from 'chat/components/ScrollViewComponent';
import { setFunnelName } from 'ts-frontend/hooks/funnelName';
import { RouteComponentProps } from '../../core/routerLib';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import MeetYourProviderWrapper from '../components/MeetYourProviderWrapper';
import { useMeetYourProviderState } from '../hooks/meetYourProviderContext';

const ScrollView = styled(ScrollViewComponent)({
  paddingLeft: 14,
  alignItems: 'center',
  height: (11 * window.innerHeight) / 16,
});

const FadeOut = styled(View)({
  backgroundImage: 'linear-gradient(to top, rgba(255,255,255,1),rgba(255,255,255,0))',
  backgroundRepeat: 'repeat-x',
  height: 100,
  pointerEvents: 'none',
  width: '90%',
  marginTop: -98,
  zIndex: 1,
});

const ProfileScreen: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const { showAvailability, therapist } = useMeetYourProviderState();
  const [hasVisitedBottom, setHasVisitedBottom] = useState<boolean>(false);
  const scrollViewRef = useRef<HTMLDivElement | null>(null);

  const { isMobile } = useWindowWidthState();
  const closeModal = useCloseModal();

  const IS_AT_BOTTOM_GRACE_PERCENTAGE = 0.1;
  const mainButtonText = isMobile && hasVisitedBottom ? 'Continue' : 'Get Started';

  useEffect(() => {
    setFunnelName('Onboarding');
  }, []);

  const handleOnScroll = (e) => {
    const { scrollHeight: targetScrollHeight, scrollTop, clientHeight } = e.target;
    const isAtBottom =
      targetScrollHeight - Math.abs(scrollTop) <=
      clientHeight + targetScrollHeight * IS_AT_BOTTOM_GRACE_PERCENTAGE;
    if (isAtBottom) {
      setHasVisitedBottom(true);
    }
  };

  const handleMainPress = () => {
    history.push('/meet-your-provider/informed-consent');
  };

  if (!therapist) {
    closeModal();
    return null;
  }

  return (
    <Modal isVisible titleText="You’ve been matched">
      <MeetYourProviderWrapper titleText="You’ve been matched">
        <ScrollView ref={scrollViewRef} onScroll={handleOnScroll}>
          <ExtraHuge style={{ textAlign: 'center' }}>Meet your provider</ExtraHuge>
          {therapist && (
            <TherapistCard
              availability={therapist.availability}
              businessDays={therapist.info.businessDays}
              buttonText={mainButtonText}
              firstName={therapist.info.first_name}
              imageURL={therapist.imageURL}
              implicitBusinessHoursByDay={therapist.implicitBusinessHoursByDay}
              joinedYearsAgo={therapist.info.joinedYearsAgo}
              lastName={therapist.info.last_name}
              licenses={therapist.licenses}
              focus={therapist.expertise.focus}
              publicDetails={therapist.info.public_details}
              timeOff={therapist.info.businessHours.businessHoursAvailability.timeOff}
              therapistTimezone={therapist.info.businessHours.therapistTimezone}
              videoURL={therapist.videoURL}
              yearsInPractice={therapist.info.yearsInPractice}
              onMainPress={handleMainPress}
              showAvailability={showAvailability}
              hideMainButton={isMobile}
            />
          )}
          <StepTip
            tip="switchExplanation"
            style={{ maxWidth: 343, paddingTop: 30, ...(isMobile && { paddingBottom: 81 }) }}
          />
        </ScrollView>
        {isMobile && (
          <>
            <FadeOut />
            <Button
              text={mainButtonText}
              onPress={handleMainPress}
              dataQa="therapistCardMobileMainButtonPress"
            />
          </>
        )}
      </MeetYourProviderWrapper>
    </Modal>
  );
};

export default ProfileScreen;
