import { useMemo } from 'react';
import {
  CardWithContent,
  CardWithContentProps,
  TSAvatar,
  StatusTag,
  StatusTagVariant,
  ColorRolesVersion,
  useEmotionTheme,
  TextDS,
  OverflowMenu,
  View,
  StatusAlertDot,
  spacing,
} from '@talkspace/react-toolkit';
import { Booking } from 'ts-frontend/types';
import { useTranslation } from '@talkspace/i18n';
import useQueryFeatureViews from 'ts-frontend/hooks/featureViews/useQueryFeatureViews';
import { RoomType } from '@/entities/Room';
import YourSupportTeamBookingCard from './YourSupportTeamBookingCard';
import useEligibilityWarning from '@/EligibilityWarning/hooks/useEligibilityWarning';
import { useOpenModal } from '@/utils/ModalsContextProvider';
import { trackCTAClick } from '../../utils/analytics/events';

const { space0, space100 } = spacing;

const getAvatarProps = (colorRoles: ColorRolesVersion, roomType?: RoomType) => {
  if (roomType === 'psychiatry_room')
    return {
      backgroundColor: colorRoles.surfaces.decorativePsych,
    };
  if (roomType === 'couples_room')
    return {
      backgroundColor: colorRoles.surfaces.decorativeCouples,
    };
  return { backgroundColor: colorRoles.surfaces.brandSubtleDefault };
};

const getTags = ({
  roomType,
  roomClosed,
  liveSessionStarting,
  messagingSessionActive,
  coverageExpired,
  noEapSessionsLeft,
}: {
  roomType?: RoomType;
  roomClosed?: boolean;
  liveSessionStarting?: boolean;
  messagingSessionActive?: boolean;
  coverageExpired?: boolean;
  noEapSessionsLeft?: boolean;
}) => {
  const tagVariants: StatusTagVariant[] = [];
  // Neutral tags
  if (roomType === 'psychiatry_room') tagVariants.push('psychiatry');
  if (roomType === 'private_room') tagVariants.push('psychotherapy');
  if (roomType === 'couples_room') tagVariants.push('therapyCouples');
  if (roomClosed) tagVariants.push('roomClosed');

  // Negative tags
  if (coverageExpired) tagVariants.push('coverageExpired');
  if (noEapSessionsLeft) tagVariants.push('noSessionsLeft');

  // Positive tags
  if (liveSessionStarting) tagVariants.push('liveSessionStarting');
  if (messagingSessionActive && !liveSessionStarting) tagVariants.push('messagingSessionActive');
  return tagVariants.map((tagVariant) => <StatusTag key={tagVariant} variant={tagVariant} />);
};

interface YourSupportTeamCardProps extends Pick<CardWithContentProps, 'disabled'> {
  isDisabled?: boolean;
  isLoading?: boolean;
  onPress?: () => void;
  onBookingPress?: () => void;
  roomID: number;
  roomType?: RoomType;
  clientUserID: number;
  therapistName: string;
  therapistImage: string | undefined;
  messageTime: string;
  messageSender: string;
  messageText: string;
  roomClosed?: boolean;
  liveSessionStarting?: boolean;
  messagingSessionActive?: boolean;
  hasAlert?: boolean;
  nextBooking?: Booking;
  BottomRowButton?: React.ReactNode;
  shouldBookAsyncSession?: boolean;
  dataQa?: string;
  showMenu?: boolean;
  noEapSessionsLeft?: boolean;
}

const YourSupportTeamCard = ({
  roomID,
  roomType,
  clientUserID,
  nextBooking,
  therapistName,
  therapistImage,
  disabled,
  messageTime,
  messageSender,
  messageText,
  roomClosed,
  liveSessionStarting,
  messagingSessionActive,
  isLoading,
  hasAlert,
  BottomRowButton,
  onPress,
  onBookingPress,
  shouldBookAsyncSession,
  showMenu,
  noEapSessionsLeft,
}: YourSupportTeamCardProps) => {
  const { isInsuranceIneligible } = useEligibilityWarning({ roomID });

  const { isLoading: featureViewsLoading, data: featureViewsData } = useQueryFeatureViews(
    `${clientUserID}`
  );
  const isUpdateCoverageCompleted =
    !featureViewsLoading && !!featureViewsData && featureViewsData.eapUpdateCoverageCompleted;

  const { colorRoles } = useEmotionTheme();
  const openModal = useOpenModal();
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const avatarProps = getAvatarProps(colorRoles, roomType);
  const tags = useMemo(
    () =>
      getTags({
        roomType,
        roomClosed,
        liveSessionStarting,
        messagingSessionActive,
        coverageExpired: isInsuranceIneligible,
        noEapSessionsLeft,
      }),
    [
      roomType,
      roomClosed,
      liveSessionStarting,
      messagingSessionActive,
      isInsuranceIneligible,
      noEapSessionsLeft,
    ]
  );

  const BottomRow = useMemo(() => {
    const showUpdateCoverage = noEapSessionsLeft && !isUpdateCoverageCompleted;
    if (liveSessionStarting || shouldBookAsyncSession || showUpdateCoverage) return BottomRowButton;
    if (nextBooking)
      return (
        <YourSupportTeamBookingCard
          dataQa={`homePageYourSupportTeamBookingCard-${roomID}`}
          booking={nextBooking}
          isLoading={isLoading}
          isDisabled={disabled}
          onPress={onBookingPress}
          BottomRowButton={BottomRowButton}
        />
      );
    return null;
  }, [
    isLoading,
    disabled,
    nextBooking,
    liveSessionStarting,
    BottomRowButton,
    shouldBookAsyncSession,
    onBookingPress,
    roomID,
    noEapSessionsLeft,
    isUpdateCoverageCompleted,
  ]);

  const menuOptions = useMemo(
    () => [
      {
        callback: () => {
          trackCTAClick('Provider profile', 'home', { roomID });
          openModal(`/provider-profile/room/${roomID}`);
        },
        label: tnewMemberNav('providerActions.provider', 'Provider profile', undefined),
        dataQa: `providerProfile-${roomID}`,
      },
      {
        callback: () => {
          trackCTAClick('Change provider', 'home', { roomID });
          openModal(`/switch-provider/room/${roomID}`, { source: 'home' });
        },
        label: tnewMemberNav('providerActions.change', 'Change provider', undefined),
        dataQa: `changeProvider-${roomID}`,
      },
    ],
    [roomID, openModal, tnewMemberNav]
  );

  const bottomRowFullWidth = !!nextBooking || !BottomRow;

  return (
    <CardWithContent
      dataQa={`homePageYourSupportTeamCard-${roomID}`}
      a11yLabel={`Link to your provider ${therapistName}`}
      isLoading={isLoading}
      enableBottomRowHover
      enableRightColHover
      centerContent
      disabled={disabled}
      onPress={onPress}
      TopRow={<>{tags}</>}
      titleText={therapistName}
      allowOnKeyDownPropagation
      Menu={
        showMenu && (
          <OverflowMenu
            dataQa={`overflowMenuButton-${roomID}`}
            a11yLabel="Options for room"
            hasTrayBackdrop
            options={menuOptions}
          />
        )
      }
      Icon={
        <TSAvatar
          avatarColor={colorRoles.element.iconNonInteractiveDefault}
          borderColor="none"
          {...avatarProps}
          size={64}
          image={therapistImage}
          titleText={therapistName}
          alt={therapistName}
        />
      }
      bodyText={
        <View flex={1} row columnGap={hasAlert ? space100 : space0}>
          <View flex={1}>
            <TextDS>
              <TextDS variant="headingSm" colorRole="textSubtle" inline>
                {messageSender}
              </TextDS>{' '}
              <TextDS
                variant="bodySm"
                colorRole="textSubtle"
                inline
                style={{
                  overflowWrap: 'anywhere',
                }}
              >
                {messageText}
              </TextDS>{' '}
              <TextDS variant="headingSm" colorRole="textSubtle" inline>
                {messageTime}
              </TextDS>
            </TextDS>
          </View>
          {hasAlert && (
            <View justify="center">
              <StatusAlertDot />
            </View>
          )}
        </View>
      }
      BottomRow={BottomRow}
      bottomRowFullWidth={bottomRowFullWidth}
      bottomRowLeftMargin={bottomRowFullWidth ? undefined : 76}
    />
  );
};
export default YourSupportTeamCard;
