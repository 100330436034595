import { FunctionComponent, useEffect, useCallback, useState } from 'react';
import { History } from 'history';
import { StepWizardProvider } from 'stepWizard';
import { useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { RouteComponentProps } from '@/core/routerLib';
import { MainContextProvider } from '@/hooks/mainContext';
import { useGenericWizardActions } from '@/utils/genericWizardActions';
import { getUserData } from '@/auth/helpers/token';
import ApiHelper from '@/utils/ApiHelper';
import Routes from './Routes';
import {
  getInitialState,
  TeenEmergencyContactWizardState,
} from './reducers/teenEmergencyContactWizardState';
import emergencyContactActions from './hooks/teenEmergencyContactActions';

interface TeenEmergencyContactRouteParams {
  roomID: number;
  source: string;
  contextID?: string;
  history: History;
}

const TeenEmergencyContactWizard: FunctionComponent<
  RouteComponentProps<TeenEmergencyContactRouteParams>
> = ({
  match: {
    params: { roomID, source, contextID = '' },
  },
}) => {
  const { id } = getUserData();
  const { localizationClientWeb } = useFlags();
  const { t: tTeenOnboarding, i18n } = useTranslation('teenOnboarding');
  const getFirstState = useCallback(getInitialState, [roomID, source, contextID]);
  const [initialState, setInitialState] = useState<TeenEmergencyContactWizardState | undefined>();
  const genericMatchingIntakeActions = useGenericWizardActions(emergencyContactActions);

  useEffect(() => {
    setInitialState(getFirstState(id, roomID, source, contextID, tTeenOnboarding));
  }, [contextID, id, roomID, source, getFirstState, i18n, tTeenOnboarding, localizationClientWeb]);

  return (
    <MainContextProvider>
      {initialState ? (
        <StepWizardProvider
          initialState={initialState}
          ApiHelperOverride={ApiHelper}
          genericActions={genericMatchingIntakeActions}
        >
          <Routes />
        </StepWizardProvider>
      ) : null}
    </MainContextProvider>
  );
};

export default TeenEmergencyContactWizard;
