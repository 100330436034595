import { ConsoleLogger } from 'ts-frontend/types';

export const logError = (error: Error | { message: string }, clientLogger: ConsoleLogger) => {
  const errorMessage = error instanceof Error ? error.message : error.message;
  clientLogger.error(errorMessage);
  // TODO: Send log to crashlytics or other error reporting service
};

export const logWarning = (errorMessage: string, clientLogger: ConsoleLogger) => {
  clientLogger.warn(errorMessage);
  // TODO: Send log to crashlytics or other error reporting service
};
