import { useEffect, VoidFunctionComponent } from 'react';
import {
  View,
  Button,
  Tiny,
  useEmotionTheme,
  StickyDrawer,
  BookingTimeZone,
  BookingPolicyMessages,
} from '@talkspace/react-toolkit';
import { trackCancellationPolicyPrompt } from 'ts-analytics/mixpanel/events';
import { useTranslation, Trans } from '@talkspace/i18n';
import { TFBookingScreen } from '@talkspace/i18n/types';

import { getUserData } from '@/auth/helpers/token';
import { TherapistInfo } from '../../types';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';
import BottomButtonContainer from '../BottomButtonContainer';
import { FlowVariant } from '../../../ts-frontend/types';

interface Props {
  therapist?: TherapistInfo;
  timezone: string;
  handleReserveClick: () => void;
  onClickCancellationPolicy: () => void;
  flowVariant?: FlowVariant;
}

const BottomButton = ({
  isLoading,
  handleReserveClick,
  onClickCancellationPolicy,
  tBookingScreen,
  flowVariant,
}: {
  isLoading: boolean;
  handleReserveClick: () => void;
  onClickCancellationPolicy: () => void;
  tBookingScreen: TFBookingScreen;
  flowVariant?: FlowVariant;
}) => {
  const { colorRoles } = useEmotionTheme();

  if (flowVariant === 'switchWizard') {
    return (
      <StickyDrawer noBorder autoPositioning>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onPress={handleReserveClick}
          text={tBookingScreen('available.continue', 'Continue', undefined)}
          dataQa="inRoomSchedulingConfirmBookingIAgree"
          stretch
        />
      </StickyDrawer>
    );
  }

  return (
    <BottomButtonContainer
      autoPositioning
      propsV0={{
        style: {
          width: 335,
          position: 'absolute',
          height: 65,
          bottom: 69,
          alignItems: 'center',
          justifyContent: 'left',
          backgroundColor: 'white',
        },
      }}
    >
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        onPress={handleReserveClick}
        text={tBookingScreen('available.agree', 'I agree', undefined)}
        dataQa="inRoomSchedulingConfirmBookingIAgree"
        stretch
      />
      <Tiny
        style={{ textAlign: 'center', marginTop: 16, color: colorRoles.typography.textSubtlest }}
      >
        <Trans key="available.byClicking">
          By clicking “I agree” you are agreeing to
          <Tiny
            inline
            variant="tinyBoldGreen"
            style={{ cursor: 'pointer', color: colorRoles.typography.textBrandDefault }}
            onPress={onClickCancellationPolicy}
          >
            {' '}
            Talkspace Cancellation Policy
          </Tiny>
        </Trans>
      </Tiny>
    </BottomButtonContainer>
  );
};

const ConfirmBookingWithPolicy: VoidFunctionComponent<Props> = ({
  therapist,
  timezone,
  flowVariant,
  handleReserveClick,
  onClickCancellationPolicy,
}) => {
  const {
    isLoading,
    selectedCreditOption,
    modality,
    room,
    selectedConfirmBooking,
    selectedBookingToReschedule,
    schedulerMode,
  } = useInRoomSchedulingState();
  const { id: userID } = getUserData();
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  useEffect(() => {
    if (
      schedulerMode === 'clientScheduled' ||
      selectedConfirmBooking?.modality ||
      selectedBookingToReschedule?.modality
    ) {
      trackCancellationPolicyPrompt({
        userID,
        roomID: room?.roomID!,
        providerID: therapist?.id!,
        planID: selectedCreditOption?.planID!,
        sessionModality:
          selectedConfirmBooking?.modality || selectedBookingToReschedule?.modality || modality,
        sessionLength: selectedCreditOption?.creditMinutes || null,
      });
    }
  }, [
    modality,
    room?.roomID,
    schedulerMode,
    selectedBookingToReschedule?.modality,
    selectedConfirmBooking,
    selectedCreditOption?.creditMinutes,
    selectedCreditOption?.planID,
    therapist?.id,
    userID,
  ]);

  return (
    <>
      <View flex={1} align="center">
        <BookingTimeZone timezone={timezone} />
        <BookingPolicyMessages />
      </View>
      <BottomButton
        isLoading={isLoading}
        handleReserveClick={handleReserveClick}
        onClickCancellationPolicy={onClickCancellationPolicy}
        tBookingScreen={tBookingScreen}
        flowVariant={flowVariant}
      />
    </>
  );
};

export default ConfirmBookingWithPolicy;
