import { VoidFunctionComponent } from 'react';
import moment from 'moment';
import { SessionModality, CreditMinutes } from 'ts-frontend/types';
import { useTranslation } from '@talkspace/i18n';

import { Big, Huge } from '../Typography';
import { styled } from '../../index';

const StyledBig = styled(Big)(({ theme: { colors } }) => {
  return {
    color: colors.permaSlateGrayApprox,
    marginTop: 4,
    marginBottom: 4,
  };
});

interface Props {
  modality: SessionModality;
  sessionStart?: string;
  sessionDuration: CreditMinutes | undefined;
  isTherapist: boolean;
}

const SingleBookingDates: VoidFunctionComponent<Props> = ({
  modality,
  sessionStart,
  sessionDuration,
  isTherapist,
}) => {
  const DateComponent = isTherapist ? Big : Huge;
  const { t: tBookingScreen } = useTranslation('bookingScreen');

  if (modality === 'messaging') {
    return (
      <>
        <DateComponent style={{ marginTop: 16 }}>
          {tBookingScreen('cost.messaging', 'Messaging session', undefined)}
        </DateComponent>
        <StyledBig>{`Starting ${moment().format('MMMM D, YYYY')}`}</StyledBig>
      </>
    );
  }
  return (
    <>
      <DateComponent style={{ marginTop: 16 }}>
        {moment(sessionStart).format('dddd, MMMM D')}
      </DateComponent>
      <StyledBig>
        {moment(sessionStart).format('h:mma')} {' – '}
        {moment(sessionStart).add(sessionDuration, 'minutes').format('h:mma')}
      </StyledBig>
    </>
  );
};

export default SingleBookingDates;
