import { useState } from 'react';
import { useHistory } from 'react-router';
import {
  Grid,
  Col,
  ResponsiveLayoutWithHeader,
  PanelHeader,
  SpacingView,
  Card,
  TextDS,
  Breadcrumb,
  useWindowWidthState,
  View,
  spacing,
  ChevronRight,
} from '@talkspace/react-toolkit';
import { CircleQuestion, PenToSquare } from '@talkspace/react-toolkit/src/designSystems/icons';
import { useTranslation } from '@talkspace/i18n';
import ssoHelper, { ZendeskPath } from '@/utils/sso';
import MobileHeader from '../../components/MobileLeftHeader';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '@/core/styled';

const { space200 } = spacing;

const getBreadcrumbRoutes = (tnewMemberNav) => [
  {
    label: tnewMemberNav('accountSettings.account', 'Account', undefined),
    path: '/home/account',
  },
  {
    label: tnewMemberNav('customer.customer', 'Customer support', undefined),
    path: '',
  },
];

const LeftColumn = styled(View)({
  width: 'fit-content',
  marginRight: space200,
});

interface CustomerSupportRowProps {
  bodyText: string;
  icon: React.ReactNode;
}
const CustomerSupportRow = ({ bodyText, icon }: CustomerSupportRowProps) => (
  <View row flex={1}>
    <LeftColumn align="center" justify="center">
      {icon}
    </LeftColumn>
    <View row align="center" justify="space-between" flex={1}>
      <SpacingView itemSpacing="space0" centerContent>
        <TextDS>{bodyText}</TextDS>
      </SpacingView>
      <View justify="center">
        <ChevronRight colorType="brand" />
      </View>
    </View>
  </View>
);

const CustomerSupportScreen = () => {
  const { isMobile } = useWindowWidthState();

  const closeModal = useCloseModal();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const goToZendesk = (path: ZendeskPath) => {
    setIsLoading(true);
    ssoHelper.openZendesk(path).finally(() => {
      setIsLoading(false);
    });
  };

  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return (
    <ResponsiveLayoutWithHeader
      headerHeight={isMobile ? undefined : 0}
      renderHeader={() => (
        <PanelHeader
          renderLeft={() => (
            <MobileHeader
              centerTitle={isMobile}
              title=""
              onBackButtonPress={() => history.goBack()}
            />
          )}
          onRightPress={isMobile ? closeModal : undefined}
        />
      )}
      noHeaderLine
    >
      <Grid fluid rowSpacing={isMobile ? 'space0' : 'space400'}>
        <Col sm={8} lg={8}>
          <SpacingView itemSpacing="space100" row={false}>
            {!isMobile && <Breadcrumb routes={getBreadcrumbRoutes(tnewMemberNav)} />}
            <SpacingView itemSpacing="space400" row={false}>
              <TextDS variant="heading2xl">
                {tnewMemberNav('customer.customer', 'Customer support', undefined)}
              </TextDS>
              <SpacingView itemSpacing="space0" row={false}>
                <Card
                  variant="noBorder"
                  padding="slim"
                  disabled={isLoading}
                  onPress={() => goToZendesk(ssoHelper.ZendeskPath.KNOWLEDGE_BASE as ZendeskPath)}
                >
                  <CustomerSupportRow
                    bodyText={tnewMemberNav('customer.help', 'Help center', undefined)}
                    icon={<CircleQuestion size="major" colorType="brand" />}
                  />
                </Card>
                <Card
                  variant="noBorder"
                  padding="slim"
                  disabled={isLoading}
                  onPress={() => goToZendesk(ssoHelper.ZendeskPath.CONTACT_US as ZendeskPath)}
                >
                  <CustomerSupportRow
                    bodyText={tnewMemberNav('customer.contact', 'Contact us', undefined)}
                    icon={<PenToSquare size="major" colorType="brand" />}
                  />
                </Card>
              </SpacingView>
            </SpacingView>
          </SpacingView>
        </Col>
      </Grid>
    </ResponsiveLayoutWithHeader>
  );
};

export default CustomerSupportScreen;
