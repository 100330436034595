import { Large, useRepeatingTimeslotsCopy } from '@talkspace/react-toolkit';
import { TherapistTimeslot } from 'ts-frontend/types';
import styled from '@/core/styled';

const SummaryText = styled(Large)(({ theme: { colors } }) => {
  return {
    width: 327,
    textAlign: 'center',
    color: colors.permaRiverBed,
  };
});

interface RecurringTimeslotsSummaryProps {
  timeslots: TherapistTimeslot[] | null;
}

const BookingSummaryDatetimes = ({ timeslots }: RecurringTimeslotsSummaryProps) => {
  const { everyWeek, dayName, startTimeString, endTimeString, startDateString, endDateString } =
    useRepeatingTimeslotsCopy(timeslots);

  if (!timeslots || !timeslots.length) {
    return null;
  }
  return (
    <SummaryText>
      {everyWeek ? 'Every' : 'Every other'} {dayName}
      <br />
      {startTimeString} – {endTimeString}
      <br />
      {startDateString} – {endDateString}
    </SummaryText>
  );
};

export default BookingSummaryDatetimes;
