import { useCallback } from 'react';
import {
  View,
  TouchableView,
  TSLogo,
  TSLogoCollapsed,
  Image,
  ExtraHuge,
  Button,
  Tiny,
  Large,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { isApple, isAndroid } from 'ts-frontend/utils/device';
import { getIsIonic } from 'ts-ionic';
import { withRouter } from '../../core/routerLib/routerLib';
import appOnAndroidBanner from '../../assets/appOnAndroidBanner.png';
import appOnIOSBanner from '../../assets/appOnIOSBanner.png';
import starRating from '../../assets/starRating.png';
import appConfig from '../../utils/configs';
import { COLORS } from '../../utils/design';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import styled from '../../core/styled';

const ScrollViewComponent = styled(View)({
  flex: 1,
  overflowY: 'auto',
  paddingBottom: 20,
});

const SwitchApp = () => {
  const windowWidth = useWindowWidthState();
  const closeModal = useCloseModal();

  const isBannerSizeOrBigger = windowWidth.width >= 633;

  const onComplete = useCallback(() => {
    closeModal();
  }, [closeModal]);

  if ((!isApple && !isAndroid) || getIsIonic()) {
    onComplete();
    return null;
  }
  return (
    <ScrollViewComponent>
      <View align="center">
        {(isAndroid || isApple) && (
          <View
            style={{
              backgroundImage: `url(${isApple ? appOnIOSBanner : appOnAndroidBanner})`,
              backgroundSize: 'cover',
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
              height: 262,
              width: '100%',
            }}
          >
            <TSLogo
              variant="2024"
              width={isBannerSizeOrBigger ? 198 : 110}
              color={COLORS.white}
              style={{
                position: 'relative',
                top: isBannerSizeOrBigger ? 104 : 216,
                left: isBannerSizeOrBigger ? 41 : 19,
              }}
            />
          </View>
        )}
        <ExtraHuge
          style={{
            fontSize: 27,
            marginTop: 17,
            marginLeft: 18,
            marginRight: 18,
          }}
        >
          Talkspace works best if you switch to our {isApple ? 'iOS' : 'Android'} app
        </ExtraHuge>
        <View
          row
          style={{
            alignSelf: 'center',
            marginTop: 25,
            width: 300,
          }}
        >
          <TSLogoCollapsed height={40} width={40} variant="2024" noMargin />
          <View justify="center" style={{ marginLeft: 16 }}>
            <Large style={{ fontSize: 18, fontWeight: 300 }}>
              Talkspace for {isApple ? 'iOS' : 'Android'}
            </Large>
            <Image width={96} height={16} src={starRating} style={{ marginTop: 5 }} />
          </View>
        </View>
        <TouchableView
          onPress={() => {
            const appStoreURL = isApple
              ? appConfig.deepLinks.iOS.storeDirectLink
              : appConfig.deepLinks.android.storeDirectLink;
            window.open(appStoreURL, '_blank');
            onComplete();
          }}
        >
          <Button text="Continue in App" style={{ marginTop: 37 }} />
        </TouchableView>
        <TouchableView onPress={onComplete} style={{ marginTop: 16 }}>
          <Tiny>Continue to mobile site</Tiny>
        </TouchableView>
      </View>
    </ScrollViewComponent>
  );
};

export default withRouter(SwitchApp);
