import { FunctionComponent } from 'react';
import {
  Large,
  Huge,
  BaseButton,
  Modal,
  useWindowWidthState,
  useModalActions,
} from '@talkspace/react-toolkit';
import { HeadWithArrowBrain } from '../icons';
import { useCloseModal } from '../../../utils/ModalsContextProvider';

const MoreInfoModal: FunctionComponent<{
  moreInfoText: string;
  assessmentLabel: string;
}> = ({ moreInfoText, assessmentLabel }) => {
  const closeModal = useCloseModal();
  const isMobile = useWindowWidthState();
  const { setModalTitle } = useModalActions();
  setModalTitle(assessmentLabel);
  return (
    <Modal.Panel
      onBackdropPress={() => closeModal()}
      contentViewStyle={{
        marginLeft: isMobile ? 40 : 83,
        marginRight: isMobile ? 40 : 83,
        alignItems: 'center',
      }}
    >
      <HeadWithArrowBrain />
      <Huge style={{ marginTop: 18, marginBottom: 16 }}>{assessmentLabel}</Huge>
      <Large variant="largeDarkGrey" style={{ textAlign: 'center' }}>
        {moreInfoText}
      </Large>
      <BaseButton style={{ marginTop: 39, marginBottom: 43 }} onPress={() => closeModal()}>
        <Large variant="largeBoldWideGreen">Done</Large>
      </BaseButton>
    </Modal.Panel>
  );
};

export default MoreInfoModal;
