import { useTranslation } from '@talkspace/i18n';
import { useEffect } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { trackEvent } from '@/utils/analytics/eventTracker';

import OnboardingPage from './OnboardingPage';
import MedicalHistory from '../assets/MedicalHistory';

const OnboardingPageMedicalHistory = ({
  match: {
    params: { roomID },
  },
  history,
  totalSteps,
  currentStep,
}: RouteComponentProps<{ roomID: number }> & {
  totalSteps: number;
  currentStep: number;
}) => {
  const { t: tAdultOnboarding } = useTranslation('adultOnboarding');
  const { emailVerificationUiCopyChanges } = useFlags();

  useEffect(() => {
    trackEvent('View Complete Your Intake Screen', { roomID }, ['mixpanel']);
  }, [roomID]);

  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={currentStep}
      illustration={<MedicalHistory />}
      title={
        emailVerificationUiCopyChanges
          ? tAdultOnboarding(
              'completeIntake.titleAlt',
              'Answer some questions to match with a provider',
              undefined
            )
          : tAdultOnboarding('completeIntake.title', 'Complete your intake', undefined)
      }
      description={
        emailVerificationUiCopyChanges
          ? tAdultOnboarding(
              'completeIntake.descriptionAlt',
              'To match you with the best provider for you, we’ll ask some questions about your physical, mental, and emotional health.',
              undefined
            )
          : tAdultOnboarding(
              'completeIntake.description',
              'To give you the best support we need to get to know you. We’ll ask you questions about your physical, mental, and emotional health.',
              undefined
            )
      }
      timeEstimate={
        emailVerificationUiCopyChanges
          ? tAdultOnboarding('completeIntake.timeEstimate', '(about 5 minutes)', undefined)
          : undefined
      }
      ctaText={tAdultOnboarding('completeIntake.startNow', 'Start now', undefined)}
      onPress={() => {
        trackEvent(
          'Start Medical History',
          {
            actionName: 'onboardingStep',
            roomID,
          },
          ['tsAnalytics']
        );
        trackEvent('Start Treatment Intake CTA clicked', {}, ['mixpanel']);
        history.push(`/room/${roomID}/onboarding/mental-health/source/onboarding`);
      }}
      renderCopyUpdate={emailVerificationUiCopyChanges}
    />
  );
};

export default withRouter(OnboardingPageMedicalHistory);
