import { WizardScheme } from 'stepWizard';
import { TFTeenOnboarding } from '@talkspace/i18n/types';
import EmergencyResourcesBodyText from '../components/EmergencyResourcesBodyText';

const mentalHealthScheme: (tTeenOnboarding: TFTeenOnboarding) => WizardScheme = (
  tTeenOnboarding
) => {
  return {
    wizardModalTitle: 'mental intake',
    wizardVersion: 1,
    wizardType: 'mentalIntake',
    disablePersist: true,
    steps: [
      {
        title: tTeenOnboarding(
          'haveHospitalized.title',
          'Have you ever been hospitalized for psychiatric care?',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('haveHospitalized.select', 'Select', undefined),
        inputType: 'select',
        inputState: 'hospitalizationHistory',
        nextButton: {
          displayText: tTeenOnboarding('haveHospitalized.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'hospitalizationHistoryOptions',
        name: 'hospitalizationHistory',
        backOption: 'back',
      },
      {
        title: tTeenOnboarding(
          'mentalHealthDiagnosis.title',
          'Which (if any) of the following mental health conditions have you been diagnosed with?',
          undefined
        ),
        inputTypeDescription: tTeenOnboarding(
          'mentalHealthDiagnosis.selectAll',
          'Select all that apply',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('mentalHealthDiagnosis.select', 'Select', undefined),
        inputType: 'searchableMultiSelect',
        inputState: 'previousMentalHealthIssues',
        nextButton: {
          displayText: tTeenOnboarding('mentalHealthDiagnosis.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tTeenOnboarding('mentalHealthDiagnosis.skip', 'Skip', undefined),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        inputOptions: 'previousMentalHealthIssuesOptions',
        name: 'previousMentalHealthIssues',
        backOption: 'back',
      },
      {
        title: tTeenOnboarding(
          'thoughtKillingYourself.title',
          'Have you ever had thoughts of killing yourself?',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('thoughtKillingYourself.select', 'Select', undefined),
        inputType: 'select',
        inputState: 'suicideIdeation',
        nextButton: {
          displayText: tTeenOnboarding('thoughtKillingYourself.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'suicideIdeationOptions',
        name: 'suicideIdeation',
        backOption: 'back',
        postAction: 'setSuicideIdeation',
      },
      {
        title: tTeenOnboarding(
          'thoughtMightDo.title',
          'Have you ever thought about how you might do this?',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('thoughtMightDo.select', 'Select', undefined),
        inputType: 'select',
        inputState: 'suicidePlanning',
        nextButton: {
          displayText: tTeenOnboarding('thoughtMightDo.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'suicidePlanningOptions',
        name: 'suicidePlanning',
        backOption: 'back',
        displayCondition: 'selectedSuicideIdeation',
        postAction: 'setSuicidePlanning',
      },
      {
        title: tTeenOnboarding(
          'crisisSuppScreen.title',
          'If you’re in immediate danger, don’t use this app. Instead, use these resources:',
          undefined
        ),
        bodyComponent: () => <EmergencyResourcesBodyText />,
        nextButton: {
          displayText: tTeenOnboarding('crisisSuppScreen.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'never',
        },
        secondaryButton: {
          displayText: tTeenOnboarding('crisisSuppScreen.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        name: 'emergencyResources',
        backOption: 'back',
        displayCondition: 'selectedSuicidePlanning',
      },
      {
        title: tTeenOnboarding(
          'thoughtKillingOthers.title',
          'Have you ever had thoughts about harming or killing others?',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('thoughtKillingOthers.select', 'Select', undefined),
        inputType: 'select',
        inputState: 'homicidalIdeation',
        nextButton: {
          displayText: tTeenOnboarding('thoughtKillingOthers.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'homicidalIdeationOrPlanningOptions',
        name: 'homicidalIdeation',
        backOption: 'back',
        postAction: 'setHomicidalIdeation',
      },
      {
        title: tTeenOnboarding(
          'thoughtMightDo.title',
          'Have you ever thought about how you might do this?',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('thoughtMightDo.select', 'Select', undefined),
        inputType: 'select',
        inputState: 'homicidalPlanning',
        nextButton: {
          displayText: tTeenOnboarding('thoughtMightDo.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'homicidalIdeationOrPlanningOptions',
        name: 'homicidalPlanningPlanning',
        backOption: 'back',
        displayCondition: 'selectedHomicidalIdeation',
        postAction: 'setHomicidalPlanning',
      },

      {
        title: tTeenOnboarding(
          'excessiveAnger.title',
          'Do you have excessive anger, aggression, and/or violent behaviors?',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('excessiveAnger.select', 'Select', undefined),
        inputType: 'select',
        inputState: 'angerAggression',
        nextButton: {
          displayText: tTeenOnboarding('excessiveAnger.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'angerAggressionOptions',
        name: 'angerAggression',
        backOption: 'back',
        postAction: 'setAngerAggression',
      },

      {
        title: tTeenOnboarding(
          'traumaticEvents.title',
          'Have you ever experienced an especially frightening, horrible, or traumatic event?',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('traumaticEvents.select', 'Select', undefined),
        inputType: 'select',
        inputState: 'traumaticExperience',
        nextButton: {
          displayText: tTeenOnboarding('traumaticEvents.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'traumaticExperienceOptions',
        name: 'traumaticExperience',
        backOption: 'back',
        postAction: 'setTraumaticExperience',
      },
      {
        title: tTeenOnboarding(
          'dreamsEvent.title',
          'In the past 30 days, have you struggled with thoughts or dreams of the event?',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('dreamsEvent.select', 'Select', undefined),
        inputType: 'select',
        inputState: 'traumaticFlashbacks',
        nextButton: {
          displayText: tTeenOnboarding('dreamsEvent.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'traumaticFlashbacksOptions',
        name: 'traumaticFlashbacks',
        backOption: 'back',
        postAction: 'setTraumaticFlashbacks',
        displayCondition: 'selectedTraumaticExperience',
      },
      {
        title: tTeenOnboarding(
          'blamingYourself.title',
          'In the past 30 days, have you felt guilty or blamed yourself for what happened?',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('blamingYourself.select', 'Select', undefined),
        inputType: 'select',
        inputState: 'guilt',
        nextButton: {
          displayText: tTeenOnboarding('blamingYourself.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'guiltOptions',
        name: 'guilt',
        backOption: 'back',
        postAction: 'setGuilt',
        displayCondition: 'selectedTraumaticExperience',
      },
      {
        title: tTeenOnboarding(
          'distanceFromOthers.title',
          'In the past 30 days, have you felt distant from others or stopped enjoying your usual activities?',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('distanceFromOthers.select', 'Select', undefined),
        inputType: 'select',
        inputState: 'isolation',
        nextButton: {
          displayText: tTeenOnboarding('distanceFromOthers.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'isolationOptions',
        name: 'isolation',
        backOption: 'back',
        postAction: 'setIsolation',
        displayCondition: 'selectedTraumaticExperience',
      },

      {
        title: tTeenOnboarding(
          'substance.title',
          'Do you currently use or have you used any of the following substances?',
          undefined
        ),
        inputTypeDescription: tTeenOnboarding(
          'substance.selectAll',
          'Select all that apply',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('substance.select', 'Select', undefined),
        inputType: 'searchableMultiSelect',
        inputState: 'controlledSubstances',
        nextButton: {
          displayText: tTeenOnboarding('substance.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tTeenOnboarding('substance.skip', 'Skip', undefined),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        inputOptions: 'controlledSubstancesOptions',
        name: 'controlledSubstances',
        backOption: 'back',
      },
      {
        title: tTeenOnboarding(
          'sleepQuality.title',
          'How would you describe your sleep quality?',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('sleepQuality.select', 'Select', undefined),
        inputType: 'select',
        inputState: 'sleepQuality',
        nextButton: {
          displayText: tTeenOnboarding('sleepQuality.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'sleepQualityOptions',
        name: 'sleepQuality',
        backOption: 'back',
      },
      {
        title: tTeenOnboarding(
          'schoolExperiences.title',
          'Which of the following are you experiencing at school or in your community?',
          undefined
        ),
        inputTypeDescription: tTeenOnboarding(
          'schoolExperiences.selectAll',
          'Select all that apply',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('schoolExperiences.select', 'Select', undefined),
        inputType: 'searchableMultiSelect',
        inputState: 'experiencingSchoolCommunity',
        nextButton: {
          displayText: tTeenOnboarding('schoolExperiences.continue', 'Continue', undefined),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'experiencingSchoolCommunityOptions',
        name: 'experiencingSchoolCommunity',
        backOption: 'back',
      },
      {
        title: tTeenOnboarding(
          'socialMedia.title',
          'Which of the following have you experienced through social media?',
          undefined
        ),
        inputTypeDescription: tTeenOnboarding(
          'socialMedia.selectAll',
          'Select all that apply',
          undefined
        ),
        inputTypePlaceholder: tTeenOnboarding('socialMedia.select', 'Select', undefined),
        inputType: 'searchableMultiSelect',
        inputState: 'experiencingSocialMedia',
        nextButton: {
          displayText: tTeenOnboarding('socialMedia.continue', 'Continue', undefined),
          actionDispatch: (_, wizardContext) => {
            if (wizardContext.source === 'onboarding') {
              return `/room/${wizardContext.roomID}/onboarding/teen-mental-health/source/onboarding/submit`;
            }
            return '/teen-mental-health-wizard/submit';
          },
          whenVisible: 'onSelect',
        },
        inputOptions: 'experiencingSocialMediaOptions',
        name: 'experiencingSocialMedia',
        backOption: 'back',
      },
    ],
  };
};

export default mentalHealthScheme;
