import { useHistory, useParams } from 'core/routerLib';
import { VFC, useEffect } from 'react';
import { setFunnelName } from 'ts-frontend/hooks/funnelName';
import { useTranslation } from '@talkspace/i18n';
import OnboardingPage from './OnboardingPage';
import BookFirstSession from '../assets/BookFirstSession';
import CameraSparkles from '../assets/CameraSparkles';
import { useOnboardingState } from '../hooks/onboardingContext';
import { trackBookFirstSessionPrompt } from '../../utils/analytics/events';

interface OnboardingPageBookAndActivateProps {
  totalSteps: number;
  currentStep: number;
}

const OnboardingPageBookAndActivate: VFC<OnboardingPageBookAndActivateProps> = ({
  totalSteps,
  currentStep,
}) => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string | undefined }>();

  const { steps } = useOnboardingState();
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  const { metadata: { isMessagingFirstVideoRequired = true } = {} } = steps
    ? steps[currentStep]
    : {};

  useEffect(() => {
    setFunnelName('Onboarding');
  }, []);

  useEffect(() => {
    if (roomID) {
      trackBookFirstSessionPrompt({
        roomID: Number(roomID),
        modalityNudge: 'Match Based on Availability',
      });
    }
  }, [roomID]);

  let illustration = <BookFirstSession />;
  let title = tBookingScreen('book.title', 'Book your first session', undefined);
  let description = tBookingScreen(
    'book.choose',
    "Choose a day and time that works for you and we'll match you with an available provider",
    undefined
  );
  // for messaging only plans in states that require video show different intro information
  if (isMessagingFirstVideoRequired) {
    illustration = <CameraSparkles />;
    title = tBookingScreen('book.book', 'Book your intro video call', undefined);
    description = tBookingScreen(
      'book.your',
      'Your state requires you to meet your provider over video before messaging therapy begins. Direct interaction with your provider is clinically proven to help you quickly form a meaningful bond with them.',
      undefined
    );
  }
  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={currentStep}
      illustration={illustration}
      title={title}
      description={description}
      ctaText={tBookingScreen('book.continue', 'Continue', undefined)}
      onPress={() => history.push(`/room/${roomID}/onboarding/book-and-activate`)}
    />
  );
};

export default OnboardingPageBookAndActivate;
