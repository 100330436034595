import { useEffect } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { Trans, useTranslation } from '@talkspace/i18n';
import {
  Large,
  Massive,
  View,
  SuccessCheckmark,
  TextDS,
  spacing,
  AnimatedT,
  useToastify,
} from '@talkspace/react-toolkit';
import { trackEmailVerificationSuccess } from '@/utils/analytics/events';

const EmailVerified = ({ firstName, isOTP }: { firstName?: string; isOTP: boolean }) => {
  const { emailVerificationUiCopyChanges } = useFlags();
  const { t: emailVerificationT } = useTranslation('emailVerification');

  const { toast } = useToastify();

  useEffect(() => {
    trackEmailVerificationSuccess({ usedOTP: isOTP });
    if (emailVerificationUiCopyChanges) {
      toast(
        emailVerificationT('toast.successToast', 'Your email has been verified', undefined),
        'success'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return emailVerificationUiCopyChanges ? (
    <View
      style={{
        paddingTop: 153,
        paddingLeft: 16,
        paddingRight: 16,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TextDS variant="decorative/heading2Xl" style={{ marginBottom: spacing.space200 }}>
        {firstName ? (
          <Trans t={emailVerificationT} i18nKey="welcome.title" options={{ firstName }}>
            Welcome to Talkspace, {{ firstName }}!
          </Trans>
        ) : (
          emailVerificationT('welcome.titleWithNoName', 'Welcome to Talkspace!', undefined)
        )}
      </TextDS>
      <TextDS variant="body" colorRole="textSubtle" style={{ marginBottom: spacing.space400 }}>
        {emailVerificationT(
          'welcome.description',
          'Let’s start the process of getting you matched to a provider',
          undefined
        )}
      </TextDS>
      <AnimatedT />
    </View>
  ) : (
    <View justify="center" align="center" style={{ paddingTop: 68, textAlign: 'center' }}>
      <SuccessCheckmark width={65} height={65} />
      <Massive style={{ paddingTop: 25 }}>Your email has been verified</Massive>
      <Large variant="largeDarkGrey" style={{ paddingTop: 20 }}>
        Thank you!
      </Large>
    </View>
  );
};

export default EmailVerified;
