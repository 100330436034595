import { Dispatch, FunctionComponent, SetStateAction, useEffect } from 'react';
import {
  View,
  TouchableView,
  TextDS,
  Button,
  SpacingView,
  StickyDrawer,
  StickyDrawerContextProvider,
  TabRadioGroup,
  RadioButton,
  Grid,
  Col,
  Row,
  useWindowWidthState,
  spacing,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { SpeechBubbles } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import CircleUnderlay from '@talkspace/react-toolkit/src/designSystems/components/CircleUnderlay';
import styled from '@/core/styled';
import { trackPageView } from '@/utils/analytics/events';

const RadioButtonWrapper = styled(TouchableView)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const StyledRadioButton = styled(RadioButton)({
  padding: 5,
});

const SharingPreferencesV2: FunctionComponent<{
  onPress: () => void;
  isSharing: boolean | null;
  setIsSharing: Dispatch<SetStateAction<boolean | null>>;
}> = ({ onPress, isSharing, setIsSharing }) => {
  const { t: tSwitchWizard } = useTranslation('switchWizard');
  const { isSmall } = useWindowWidthState();
  useEffect(() => {
    trackPageView('chat-history-sharing-preferences');
  }, []);
  return (
    <StickyDrawerContextProvider>
      <Grid style={{ marginTop: spacing.space400 }}>
        <Row>
          {!isSmall && (
            <Col xs={2} sm={1} md={2} lg={4}>
              <></>
            </Col>
          )}
          <Col xs={4} sm={6} md={4} lg={4}>
            <SpacingView itemSpacing="space150" centerAlign>
              <CircleUnderlay size={136} colorRole="decorativeBalanceBlueTint">
                <SpeechBubbles />
              </CircleUnderlay>
              <TextDS variant="headingXl">
                {tSwitchWizard(
                  'SharingPreferencesV2.title',
                  'Would you like to share your chat history?',
                  undefined
                )}
              </TextDS>
              <TextDS colorRole="textSubtle">
                {tSwitchWizard(
                  'SharingPreferencesV2.subtitle',
                  'Sharing your history with your new provider helps them understand the progress you’ve made',
                  undefined
                )}
              </TextDS>
            </SpacingView>
          </Col>
        </Row>
        <Row>
          {!isSmall && (
            <Col xs={2} sm={1} md={2} lg={4}>
              <></>
            </Col>
          )}
          <Col xs={4} sm={6} md={4} lg={4}>
            <TabRadioGroup
              style={{
                width: '100%',
                minInlineSize: 'fit-content',
                marginTop: 26,
              }}
            >
              <RadioButtonWrapper
                style={{ marginBottom: 15 }}
                onPress={() => {
                  setIsSharing(true);
                }}
                dataQa="sharingPreferencesRadioWrapperTrue"
              >
                <View style={{ width: 'auto' }}>
                  <TextDS>
                    {tSwitchWizard(
                      'SharingPreferencesV2.radioSelectTrue',
                      'Share my chat history',
                      undefined
                    )}
                  </TextDS>
                </View>
                <StyledRadioButton
                  tabFirstOption
                  isActive={!!isSharing}
                  onPress={() => {
                    setIsSharing(true);
                  }}
                  dataQa="sharingPreferencesRadioTrue"
                />
              </RadioButtonWrapper>
              <RadioButtonWrapper
                style={{ marginBottom: 30 }}
                onPress={() => {
                  setIsSharing(false);
                }}
                dataQa="sharingPreferencesRadioWrapperFalse"
              >
                <View style={{ width: 'auto' }}>
                  <TextDS>
                    {tSwitchWizard(
                      'SharingPreferencesV2.radioSelectFalse',
                      'Don’t share my chat history',
                      undefined
                    )}
                  </TextDS>
                </View>
                <StyledRadioButton
                  isActive={!isSharing}
                  onPress={() => {
                    setIsSharing(false);
                  }}
                  dataQa="sharingPreferencesRadioFalse"
                />
              </RadioButtonWrapper>
            </TabRadioGroup>
          </Col>
        </Row>
      </Grid>
      <StickyDrawer>
        <Button
          text="Continue"
          onPress={onPress}
          dataQa="SharingPreferencesV2Continue"
          sizeDS="large"
          fixedWidth
        />
      </StickyDrawer>
    </StickyDrawerContextProvider>
  );
};

export default SharingPreferencesV2;
