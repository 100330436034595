import { useState } from 'react';
import * as React from 'react';
import {
  View,
  Button,
  ExtraHuge,
  isPhoneNumberValid,
  TwoFactorAuthenticationIcon,
  Large,
  A11yPhoneInput,
  Tiny,
  styled,
  spacing,
  SpacingView,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { trackClient2FAVerification } from '@/TwoFactorAuthentication/utils/analytics';

const { space400 } = spacing;
const Container = styled(View)({ width: 336 });

const HeaderWrapper = styled(View)({
  textAlign: 'left',
  marginBottom: 30,
  paddingLeft: 4,
});

interface Props {
  onCancelPress: () => void;
  updatePhoneNumber: (
    phoneNumber: string,
    phoneNumberCountryCode: string,
    is2faModal?: boolean
  ) => void;
}

const ChangePhoneNumber2FAModal: React.VFC<Props> = ({ onCancelPress, updatePhoneNumber }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState<string>('US');
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);

  const onChangePhoneNumber = (newPhoneNumber: string) => {
    const phoneNumberValidity = newPhoneNumber ? isPhoneNumberValid(newPhoneNumber) : false;
    setPhoneNumber(newPhoneNumber);
    setIsValidPhone(phoneNumberValidity);
  };

  const onChangePhoneNumberCountryCode = (countryCode: string) =>
    setPhoneNumberCountryCode(countryCode);

  const handleContinuePhonePress = () => {
    if (isValidPhone && phoneNumber) updatePhoneNumber(phoneNumber, phoneNumberCountryCode);
  };

  const handleCancel = () => {
    trackClient2FAVerification('I’ll do this later enable 2FA - Login and Security');
    onCancelPress();
  };

  const { t: tTwoFactor } = useTranslation('twoFactor');

  return (
    <Container>
      <HeaderWrapper align="center">
        <TwoFactorAuthenticationIcon />
        <ExtraHuge style={{ textAlign: 'center' }}>
          {tTwoFactor('settings.title', 'Enable two-factor authentication', undefined)}
        </ExtraHuge>
      </HeaderWrapper>
      <Large variant="largeDarkGrey" style={{ maxWidth: 309, marginTop: 10 }}>
        {tTwoFactor(
          'settings.description',
          'To keep your account extra secure, we’ll send a 6-digit verification code to your phone number ending in 06 when you log in to make sure it’s you.',
          undefined
        )}
      </Large>
      <A11yPhoneInput
        containerStyle={{ marginTop: 30 }}
        style={{ width: 335 }}
        value={phoneNumber}
        label={tTwoFactor('settings.phone', 'Phone number', undefined)}
        errorMessage={tTwoFactor('settings.error', 'Phone number missing or invalid.', undefined)}
        handleInputValueChange={onChangePhoneNumber}
        handleCountryChange={onChangePhoneNumberCountryCode}
        dataQa="myAccountChange2faPhoneInput"
        isError={!isValidPhone}
      />
      <SpacingView>
        <Button
          data-qa="myAccountChange2faContinuePhoneButton"
          onPress={handleContinuePhonePress}
          text={tTwoFactor('settings.continue', 'Continue', undefined)}
          style={{ marginTop: space400, flex: 1 }}
        />
        <Button
          variant="secondary"
          onPress={handleCancel}
          text={tTwoFactor('settings.later', "I'll do this later", undefined)}
          data-qa="myAccountChange2faPhoneDoThisLaterButton"
          style={{ flex: 1 }}
        />
      </SpacingView>
      <Tiny style={{ marginTop: 49, marginBottom: 60, textAlign: 'left' }}>
        {tTwoFactor(
          'settings.rates',
          'Message and data rates may apply. For more information, see our Terms of use and Privacy policy.',
          undefined
        )}
      </Tiny>
    </Container>
  );
};

export default ChangePhoneNumber2FAModal;
