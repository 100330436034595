import { useState, useEffect, VoidFunctionComponent, useCallback } from 'react';
import {
  useUniqueID,
  View,
  Button,
  BaseButton,
  Standard,
  Big,
  ExtraHuge,
  TSInput,
  Label,
  AnimatedError,
  useEmotionTheme,
  styled,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { checkEmailValidity } from 'ts-frontend/helpers';
import { useTranslation } from '@talkspace/i18n';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import ModalClosingScreen from '../../2storybook/components/ModalClosingScreen';
import useEmailVerification from '../hooks/useEmailVerification';
import getParamByName from '../../utils/queryString';
import parseJwt from '@/utils/jwt';
import { useCloseModal } from '../../utils/ModalsContextProvider';

const HeaderWrapper = styled(View)({
  textAlign: 'left',
  marginBottom: 30,
  paddingLeft: 4,
});

const InputWrapper = styled(View)({
  marginBottom: 10,
});

const SaveButton = styled(Button)({
  marginTop: 6,
  width: '100%',
});

const CancelButton = styled(BaseButton)(({ theme: { colors } }) => {
  return { marginTop: 20, marginBottom: 50, padding: 0, backgroundColor: colors.white };
});

type Props = RouteComponentProps<{}> & {
  showCloseButton?: boolean;
};

const ChangeEmail: VoidFunctionComponent<Props> = ({ showCloseButton = false }) => {
  const newEmailInputID = useUniqueID('newEmailInputID');
  const confirmEmailInputID = useUniqueID('confirmEmailInputID');
  const newEmailError = useUniqueID('newEmailError');
  const confirmEmailError = useUniqueID('confirmEmailError');
  const { colors } = useEmotionTheme();
  const userEmail = getParamByName('email');
  const token = getParamByName('token');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [confirmationEmailError, setConfirmationEmailError] = useState('');
  const { isMobile } = useWindowWidthState();
  const [state, { changeEmailAction, sendEmailVerificationAction, resetErrorAction }] =
    useEmailVerification();

  const closeModal = useCloseModal();
  const { t: tEmailVerification } = useTranslation('emailVerification');

  useEffect(() => {
    if (state.error) {
      setConfirmationEmailError(state.error);
    }
  }, [state.error]);

  useEffect(() => {
    if (state.changeEmailToken && !state.emailSent) {
      sendEmailVerificationAction(email);
    }
    if (state.changeEmailToken && state.emailSent) {
      closeModal({
        navigateTo: 'emailVerificationSent',
        metadata: {
          email,
          token: state.changeEmailToken,
        },
      });
    }
  }, [closeModal, email, sendEmailVerificationAction, state.changeEmailToken, state.emailSent]);

  const handleEmailChange = (value: string) => {
    resetErrorAction();
    setEmail(value);
    setEmailError('');
  };

  const handleConfirmationEmailChange = (value: string) => {
    resetErrorAction();
    setConfirmationEmail(value);
    setConfirmationEmailError('');
  };

  const validateInput = useCallback(() => {
    if (!email) {
      setEmailError(
        tEmailVerification('change.errorNew', 'Please enter new email address.', undefined)
      );
      return false;
    }

    if (!checkEmailValidity(email)) {
      setEmailError(
        tEmailVerification('change.errorValid', 'Please enter a valid email address.', undefined)
      );
      return false;
    }

    if (!confirmationEmail) {
      setConfirmationEmailError(
        tEmailVerification('change.errorConfirm', 'Please confirm new email address.', undefined)
      );
      return false;
    }

    if (email !== confirmationEmail) {
      setConfirmationEmailError(
        tEmailVerification(
          'change.errorMatch',
          "The email addresses you entered don't match.",
          undefined
        )
      );
      return false;
    }

    if (userEmail === email) {
      setConfirmationEmailError(
        tEmailVerification(
          'change.errorOld',
          'The email you entered is the same as the old one.',
          undefined
        )
      );
      return false;
    }

    if (state.error) {
      setConfirmationEmailError(state.error);
      return false;
    }

    return true;
  }, [confirmationEmail, email, state.error, tEmailVerification, userEmail]);

  const handleSavePress = () => {
    resetErrorAction();
    setEmailError('');
    setConfirmationEmailError('');
    const isValidInput = validateInput();
    if (isValidInput && email && token) {
      const { userID }: { userID: number } = parseJwt(token);

      changeEmailAction({ email, userID, token });
    }
  };

  const onCancelPress = useCallback(() => {
    if (token && userEmail) {
      closeModal({
        navigateTo: 'emailVerificationSent',
        metadata: {
          token,
          email: userEmail,
        },
      });
    }
  }, [closeModal, token, userEmail]);

  return (
    <ModalClosingScreen showCloseButton={showCloseButton}>
      <View
        style={{
          paddingTop: 68,
          paddingLeft: 20,
          paddingRight: 20,
          width: isMobile ? 335 : 425,
          margin: '0px auto',
        }}
      >
        <HeaderWrapper>
          <ExtraHuge>{tEmailVerification('change.title', 'Change email', undefined)}</ExtraHuge>
        </HeaderWrapper>
        <InputWrapper>
          <Label htmlFor={newEmailInputID}>
            {tEmailVerification('change.new', 'Change phone number', undefined)}
          </Label>
          <TSInput
            id={newEmailInputID}
            ariaDescribedBy={emailError ? newEmailError : undefined}
            onChange={handleEmailChange}
            value={email}
            placeholder={tEmailVerification('change.enter', 'Enter email', undefined)}
            style={{ fontSize: 16, borderColor: emailError && colors.red }}
            shouldFocus={!!emailError}
            dataQa="newEmailInput"
          />
          <AnimatedError id={newEmailError} shouldAnimate={Boolean(emailError)}>
            {emailError}
          </AnimatedError>
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor={confirmEmailInputID}>
            {tEmailVerification('change.confirm', 'Confirm new email address', undefined)}
          </Label>
          <TSInput
            id={confirmEmailInputID}
            ariaDescribedBy={confirmationEmailError ? confirmEmailError : undefined}
            onChange={handleConfirmationEmailChange}
            value={confirmationEmail}
            placeholder={tEmailVerification('change.enter', 'Enter email', undefined)}
            style={{ fontSize: 16, borderColor: confirmationEmailError && colors.red }}
            shouldFocus={!!(confirmationEmailError && !emailError)}
            dataQa="confirmNewEmailInput"
          />
          <AnimatedError id={confirmEmailError} shouldAnimate={Boolean(confirmationEmailError)}>
            {confirmationEmailError}
          </AnimatedError>
        </InputWrapper>
        <SaveButton
          onPress={handleSavePress}
          dataQa="saveAndResendEmailButton"
          stretch
          sizeDS="medium"
          text={tEmailVerification('change.save', 'Save & resend email', undefined)}
        >
          <Big variant="bigWide">
            {tEmailVerification('change.save', 'Save & resend email', undefined)}
          </Big>
        </SaveButton>
        <CancelButton onPress={onCancelPress} dataQa="cancelEmailChangeButton">
          <Standard variant="standardDarkGrey">
            {tEmailVerification('change.cancel', 'Cancel', undefined)}
          </Standard>
        </CancelButton>
      </View>
    </ModalClosingScreen>
  );
};

export default ChangeEmail;
